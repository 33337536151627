import _ from '../../../lodashWrapper';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '../../common/select'
import Spinner from '../../common/spinner'
import RiskFiling from '../../common/riskFiling';
import { searchNewsAction } from '../../../ducks/news/actions';
import { searchDocumentsAction } from '../../../ducks/document/actions';
import { parseQuery } from '../../../helpers/utility';
import { getCompanyStocksVariationsAction, stockChartVariationCardClickedAction } from '../../../ducks/stock/actions';
import ErrorAlert from '../../common/errorAlert';


export class Company8KFilings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                date: {
                    value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year'
                }
            }
        };
        // this.state = Object.assign({}, ...this.state,{filter: null})

        this.filterFlags = this.filterFlags.bind(this)
        this.filterDates = this.filterDates.bind(this)
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.searchDocumentsAction(this.props.companyId, 'k8');
        }
        if (this.props.location && '#filing8k' === this.props.location.hash) {
            document.querySelector('#filing8k').scrollIntoView();
        }

        const queryString = this.props.location !== undefined ? parseQuery(this.props.location.search) : {};

        if (queryString.risk_name !== undefined) {
            const obj = {
                value: queryString.risk_name,
                label: queryString.risk_name
            };
            this.filterFlags([obj])
        }
    }

    componentDidUpdate(prevProps) {

        if (prevProps.companyId !== this.props.companyId) {
            this.props.searchDocumentsAction(this.props.companyId, 'k8');
        }
        if (!_.isEqual(prevProps.documentsK8, this.props.documentsK8)) {
            if (this.props.location && '#filing8k' === this.props.location.hash) {
                document.querySelector('#filing8k').scrollIntoView();
            }
        }
    }


    filterFlags(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, flags: filter } })
    }

    filterDates(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, date: filter } })
    }

    render() {

        if (this.props.isPDF) {
            return null;
        }

        let riskFlagFilterValue = null;

        if (this.state.filter && this.state.filter.flag) {
            riskFlagFilterValue = this.state.filter.flags.map((x) => {
                return x.value;
            });
        }

        let content;

        if (this.props.documentsK8Error) {
            content = <ErrorAlert message={this.props.documentsK8Error} />
        } else {
            content = <Spinner loaded={this.props.documentsK8}>
                <form>
                    <label>
                        <Select label="Risk Flag"
                            options={this.props.documentsK8Options.map((x) => {
                                return { value: x, label: x }
                            })}
                            placeholder="Select risk flag..."
                            multi
                            value={riskFlagFilterValue}
                            onChange={this.filterFlags}
                        />
                    </label>
                    <label>
                        <Select label="Date Range"
                            defaultLabel="Past 2 Year"
                            options={[
                                { value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year' },
                                { value: moment().subtract(1, 'years').valueOf(), label: 'Past Year' },
                                { value: moment().subtract(1, 'months').valueOf(), label: 'Past Month' },
                                { value: moment().subtract(1, 'weeks').valueOf(), label: 'Past Week' },
                            ]}
                            placeholder="Select range..."
                            onChange={this.filterDates}
                        />
                    </label>
                </form>

                <RiskFiling data={this.props.documentsK8} companyId={this.props.companyId} filter={this.state.filter} />
            </Spinner>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body eight-k with-spinner'>
                    <span id="filing8k" />
                    <h2>8K Filing</h2>
                    {content}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        companyName: _.get(state, 'company.selected.name'),
        companyId: _.get(state, 'company.selected.company_id'),
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
        documentsK8: _.get(state, 'document.k8'),
        documentsK8Error: _.get(state, 'document.k8_error'),
        documentsK8Options: _.get(state, 'document.k8_options') || []
    };
}

export default connect(mapStateToProps, {
    searchNewsAction,
    searchDocumentsAction,
    getCompanyStocksVariationsAction,
    stockChartVariationCardClickedAction
})(Company8KFilings);
