import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getCompany = (state) => state.company;

function* getFinancial(action) {
  let company = yield select(getCompany);

  if (company[`financial_${action.range}`] === undefined) {

    const queryParams = {
      range: action.range
    };

    try {
      const response = yield call(apigClientWrapper.getCompanyFinancials, action.companyId, { queryParams });
      yield put({ type: actions.GET_FINANCIALS_SUCCESS, range: action.range, payload: response.data });
    } catch (e) {
      yield put({ type: actions.GET_FINANCIALS_FAIL, ...apiFailMsg(e) });
    }
  } else {
    yield put({ type: actions.GET_FINANCIALS_SUCCESS, range: action.range, payload: company[`financial_${action.range}`] });
  }
}

export default [
  takeLatest(actions.GET_FINANCIALS, getFinancial),
]
