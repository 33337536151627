import React from 'react';

const images = {
    k8: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.76 133.96">
        <defs>
            <style>
                {`.doc-eightK-cls-1{fill:none;}
                .doc-eightK-cls-2{clip-path:url(#doc-eightK-clip-path);}
                .doc-eightK-cls-3{fill:#2661e3;}
                .doc-eightK-cls-4{clip-path:url(#doc-eightK-clip-path-2);}
                .doc-eightK-cls-5{clip-path:url(#doc-eightK-clip-path-3);}
                .doc-eightK-cls-6{clip-path:url(#doc-eightK-clip-path-4);}
                .doc-eightK-cls-7{clip-path:url(#doc-eightK-clip-path-5);}
                .doc-eightK-cls-8{clip-path:url(#doc-eightK-clip-path-6);}
                .doc-eightK-cls-9{clip-path:url(#doc-eightK-clip-path-7);}
                .doc-eightK-cls-10{clip-path:url(#doc-eightK-clip-path-8);}
                .doc-eightK-cls-11{clip-path:url(#doc-eightK-clip-path-9);}
                .doc-eightK-cls-12{clip-path:url(#doc-eightK-clip-path-10);}
                .doc-eightK-cls-13{clip-path:url(#doc-eightK-clip-path-11);}`}
            </style>
            <clipPath id="doc-eightK-clip-path">
                <path className="doc-eightK-cls-1"
                      d="M93.78,21.45v83.34L89.26,107V21.48H21.5V107L17,104.79V21.45A4.51,4.51,0,0,1,21.5,17H89.26A4.49,4.49,0,0,1,93.78,21.45Z"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-2">
                <rect className="doc-eightK-cls-1" x="35.05" y="75.52" width="40.66" height="4.5"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-3">
                <rect className="doc-eightK-cls-1" x="35.05" y="89.03" width="27.11" height="4.5"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-4">
                <polygon className="doc-eightK-cls-1"
                         points="40.5 104.79 28.27 116.98 25.08 113.8 37.31 101.61 40.5 104.79"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-5">
                <polygon className="doc-eightK-cls-1"
                         points="31.47 113.8 28.27 116.98 16.98 105.72 16.98 103.86 19.24 101.61 31.47 113.8"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-6">
                <polygon className="doc-eightK-cls-1"
                         points="58.57 104.79 46.34 116.98 43.15 113.8 55.38 101.61 58.57 104.79"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-7">
                <polygon className="doc-eightK-cls-1"
                         points="37.31 101.61 49.54 113.8 46.34 116.98 34.12 104.79 37.31 101.61"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-8">
                <polygon className="doc-eightK-cls-1"
                         points="76.64 104.79 64.42 116.98 61.22 113.8 73.45 101.61 76.64 104.79"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-9">
                <polygon className="doc-eightK-cls-1"
                         points="55.38 101.61 67.61 113.8 64.42 116.98 52.19 104.79 55.38 101.61"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-10">
                <polygon className="doc-eightK-cls-1"
                         points="79.29 113.8 82.49 116.98 93.78 105.72 93.78 103.86 91.52 101.61 79.29 113.8"/>
            </clipPath>
            <clipPath id="doc-eightK-clip-path-11">
                <polygon className="doc-eightK-cls-1"
                         points="73.45 101.61 85.68 113.8 82.49 116.98 70.26 104.79 73.45 101.61"/>
            </clipPath>
        </defs>
        <title>Doc-8K</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g className="doc-eightK-cls-2">
                    <rect className="doc-eightK-cls-3" width="110.76" height="124.02"/>
                </g>
                <g className="doc-eightK-cls-4">
                    <rect className="doc-eightK-cls-3" x="18.07" y="58.54" width="74.62" height="38.46"/>
                </g>
                <g className="doc-eightK-cls-5">
                    <rect className="doc-eightK-cls-3" x="18.07" y="72.05" width="61.07" height="38.46"/>
                </g>
                <path className="doc-eightK-cls-3"
                      d="M32.52,50a6.16,6.16,0,0,1,2.88-2.66A6.15,6.15,0,0,1,33,44.62a7.48,7.48,0,0,1-.56-2.73,6.33,6.33,0,0,1,2.13-4.81,8.48,8.48,0,0,1,6-2,8.48,8.48,0,0,1,6,2,6.33,6.33,0,0,1,2.13,4.81,7.69,7.69,0,0,1-.56,2.73,5.26,5.26,0,0,1-2.44,2.53A7.19,7.19,0,0,1,48.58,50a8.16,8.16,0,0,1,1,3.95,7.38,7.38,0,0,1-2.43,5.57,9.44,9.44,0,0,1-6.77,2.3q-4.35,0-6.58-2.3a7.66,7.66,0,0,1-2.24-5.57A8,8,0,0,1,32.52,50Zm5.29,6.58a4.11,4.11,0,0,0,5.49,0,4.16,4.16,0,0,0,1-3,4.13,4.13,0,0,0-1-3,4.12,4.12,0,0,0-5.45,0,4.13,4.13,0,0,0-1,3A4.16,4.16,0,0,0,37.81,56.57Zm.33-11.8a3.26,3.26,0,0,0,5.67-2.3A3.28,3.28,0,0,0,43,40.06a3.28,3.28,0,0,0-5.69,2.41A3.15,3.15,0,0,0,38.14,44.77Z"/>
                <path className="doc-eightK-cls-3"
                      d="M53.8,34.78h5.42V45.64L69.4,34.78h7.11L65.7,45.62,77.07,61.13H70l-8.12-11.5-2.65,2.69v8.81H53.8Z"/>
                <g className="doc-eightK-cls-6">
                    <rect className="doc-eightK-cls-3" x="8.1" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-7">
                    <rect className="doc-eightK-cls-3" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-8">
                    <rect className="doc-eightK-cls-3" x="26.17" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-9">
                    <rect className="doc-eightK-cls-3" x="17.14" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-10">
                    <rect className="doc-eightK-cls-3" x="44.24" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-11">
                    <rect className="doc-eightK-cls-3" x="35.21" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-12">
                    <rect className="doc-eightK-cls-3" x="62.31" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-eightK-cls-13">
                    <rect className="doc-eightK-cls-3" x="53.28" y="84.63" width="49.38" height="49.33"/>
                </g>
            </g>
        </g>
    </svg>,
    k10: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.76 133.96">
        <defs>
            <style>
                {`.doc-tenK-cls-1{fill:none;}
                .doc-tenK-cls-2{fill:#2661e3;}
                .doc-tenK-cls-3{clip-path:url(#doc-tenK-clip-path);}
                .doc-tenK-cls-4{clip-path:url(#doc-tenK-clip-path-2);}
                .doc-tenK-cls-5{clip-path:url(#doc-tenK-clip-path-3);}
                .doc-tenK-cls-6{clip-path:url(#doc-tenK-clip-path-4);}
                .doc-tenK-cls-7{clip-path:url(#doc-tenK-clip-path-5);}
                .doc-tenK-cls-8{clip-path:url(#doc-tenK-clip-path-6);}
                .doc-tenK-cls-9{clip-path:url(#doc-tenK-clip-path-7);}
                .doc-tenK-cls-10{clip-path:url(#doc-tenK-clip-path-8);}
                .doc-tenK-cls-11{clip-path:url(#doc-tenK-clip-path-9);}
                .doc-tenK-cls-12{clip-path:url(#doc-tenK-clip-path-10);}
                .doc-tenK-cls-13{clip-path:url(#doc-tenK-clip-path-11);}`}
            </style>
            <clipPath id="doc-tenK-clip-path">
                <path className="doc-tenK-cls-1"
                      d="M93.78,21.45v83.34L89.26,107V21.48H21.5V107L17,104.79V21.45A4.51,4.51,0,0,1,21.5,17H89.26A4.49,4.49,0,0,1,93.78,21.45Z"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-2">
                <rect className="doc-tenK-cls-1" x="35.05" y="75.52" width="40.66" height="4.5"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-3">
                <rect className="doc-tenK-cls-1" x="35.05" y="89.03" width="27.11" height="4.5"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-4">
                <polygon className="doc-tenK-cls-1"
                         points="40.5 104.79 28.27 116.98 25.08 113.8 37.31 101.61 40.5 104.79"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-5">
                <polygon className="doc-tenK-cls-1"
                         points="31.47 113.8 28.27 116.98 16.98 105.72 16.98 103.86 19.24 101.61 31.47 113.8"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-6">
                <polygon className="doc-tenK-cls-1"
                         points="58.57 104.79 46.34 116.98 43.15 113.8 55.38 101.61 58.57 104.79"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-7">
                <polygon className="doc-tenK-cls-1"
                         points="37.31 101.61 49.54 113.8 46.34 116.98 34.12 104.79 37.31 101.61"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-8">
                <polygon className="doc-tenK-cls-1"
                         points="76.64 104.79 64.42 116.98 61.22 113.8 73.45 101.61 76.64 104.79"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-9">
                <polygon className="doc-tenK-cls-1"
                         points="55.38 101.61 67.61 113.8 64.42 116.98 52.19 104.79 55.38 101.61"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-10">
                <polygon className="doc-tenK-cls-1"
                         points="79.29 113.8 82.49 116.98 93.78 105.72 93.78 103.86 91.52 101.61 79.29 113.8"/>
            </clipPath>
            <clipPath id="doc-tenK-clip-path-11">
                <polygon className="doc-tenK-cls-1"
                         points="73.45 101.61 85.68 113.8 82.49 116.98 70.26 104.79 73.45 101.61"/>
            </clipPath>
        </defs>
        <title>Doc-10K</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path className="doc-tenK-cls-2"
                      d="M26.34,42.38V39.16a21.81,21.81,0,0,0,3.13-.3,4.43,4.43,0,0,0,2.32-1.26,4.25,4.25,0,0,0,.92-1.73,4,4,0,0,0,.18-1h4V59H32V42.38Z"/>
                <path className="doc-tenK-cls-2"
                      d="M58.7,38c1.24,2.16,1.85,5.22,1.85,9.2s-.61,7-1.85,9.18-3.38,3.22-6.45,3.22S47,58.5,45.8,56.36,44,51.15,44,47.18s.62-7,1.85-9.2,3.39-3.24,6.45-3.24S57.47,35.82,58.7,38ZM49.46,53.53a2.76,2.76,0,0,0,2.79,2.2A2.71,2.71,0,0,0,55,53.53a23.89,23.89,0,0,0,.65-6.35A23.66,23.66,0,0,0,55,40.73a2.69,2.69,0,0,0-2.77-2.09,2.73,2.73,0,0,0-2.79,2.09,22.66,22.66,0,0,0-.68,6.45A22.87,22.87,0,0,0,49.46,53.53Z"/>
                <path className="doc-tenK-cls-2"
                      d="M64.47,34.51h5V44.59L79,34.51h6.6l-10,10.06L86.08,59H79.5L72,48.3,69.5,50.8V59h-5Z"/>
                <g className="doc-tenK-cls-3">
                    <rect className="doc-tenK-cls-2" width="110.76" height="124.02"/>
                </g>
                <g className="doc-tenK-cls-4">
                    <rect className="doc-tenK-cls-2" x="18.07" y="58.54" width="74.62" height="38.46"/>
                </g>
                <g className="doc-tenK-cls-5">
                    <rect className="doc-tenK-cls-2" x="18.07" y="72.05" width="61.07" height="38.46"/>
                </g>
                <g className="doc-tenK-cls-6">
                    <rect className="doc-tenK-cls-2" x="8.1" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-7">
                    <rect className="doc-tenK-cls-2" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-8">
                    <rect className="doc-tenK-cls-2" x="26.17" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-9">
                    <rect className="doc-tenK-cls-2" x="17.14" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-10">
                    <rect className="doc-tenK-cls-2" x="44.24" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-11">
                    <rect className="doc-tenK-cls-2" x="35.21" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-12">
                    <rect className="doc-tenK-cls-2" x="62.31" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-tenK-cls-13">
                    <rect className="doc-tenK-cls-2" x="53.28" y="84.63" width="49.38" height="49.33"/>
                </g>
            </g>
        </g>
    </svg>,
    q10: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.76 133.96">
        <defs>
            <style>
                {`.doc-tenQ-cls-1{fill:none;}
                .doc-tenQ-cls-2{fill:#2661e3;}
                .doc-tenQ-cls-3{clip-path:url(#doc-tenQ-clip-path);}
                .doc-tenQ-cls-4{clip-path:url(#doc-tenQ-clip-path-2);}
                .doc-tenQ-cls-5{clip-path:url(#doc-tenQ-clip-path-3);}
                .doc-tenQ-cls-6{clip-path:url(#doc-tenQ-clip-path-4);}
                .doc-tenQ-cls-7{clip-path:url(#doc-tenQ-clip-path-5);}
                .doc-tenQ-cls-8{clip-path:url(#doc-tenQ-clip-path-6);}
                .doc-tenQ-cls-9{clip-path:url(#doc-tenQ-clip-path-7);}
                .doc-tenQ-cls-10{clip-path:url(#doc-tenQ-clip-path-8);}
                .doc-tenQ-cls-11{clip-path:url(#doc-tenQ-clip-path-9);}
                .doc-tenQ-cls-12{clip-path:url(#doc-tenQ-clip-path-10);}
                .doc-tenQ-cls-13{clip-path:url(#doc-tenQ-clip-path-11);}`}
            </style>
            <clipPath id="doc-tenQ-clip-path">
                <path className="doc-tenQ-cls-1"
                      d="M93.78,21.45v83.34L89.26,107V21.48H21.5V107L17,104.79V21.45A4.51,4.51,0,0,1,21.5,17H89.26A4.49,4.49,0,0,1,93.78,21.45Z"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-2">
                <rect className="doc-tenQ-cls-1" x="35.05" y="75.52" width="40.66" height="4.5"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-3">
                <rect className="doc-tenQ-cls-1" x="35.05" y="89.03" width="27.11" height="4.5"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-4">
                <polygon className="doc-tenQ-cls-1" points="40.5 104.79 28.27 116.98 25.08 113.8 37.31 101.61 40.5 104.79"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-5">
                <polygon className="doc-tenQ-cls-1"
                         points="31.47 113.8 28.27 116.98 16.98 105.72 16.98 103.86 19.24 101.61 31.47 113.8"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-6">
                <polygon className="doc-tenQ-cls-1"
                         points="58.57 104.79 46.34 116.98 43.15 113.8 55.38 101.61 58.57 104.79"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-7">
                <polygon className="doc-tenQ-cls-1"
                         points="37.31 101.61 49.54 113.8 46.34 116.98 34.12 104.79 37.31 101.61"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-8">
                <polygon className="doc-tenQ-cls-1"
                         points="76.64 104.79 64.42 116.98 61.22 113.8 73.45 101.61 76.64 104.79"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-9">
                <polygon className="doc-tenQ-cls-1"
                         points="55.38 101.61 67.61 113.8 64.42 116.98 52.19 104.79 55.38 101.61"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-10">
                <polygon className="doc-tenQ-cls-1"
                         points="79.29 113.8 82.49 116.98 93.78 105.72 93.78 103.86 91.52 101.61 79.29 113.8"/>
            </clipPath>
            <clipPath id="doc-tenQ-clip-path-11">
                <polygon className="doc-tenQ-cls-1"
                         points="73.45 101.61 85.68 113.8 82.49 116.98 70.26 104.79 73.45 101.61"/>
            </clipPath>
        </defs>
        <title>Doc-10Q</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path className="doc-tenQ-cls-2"
                      d="M25.34,42.38V39.16a21.81,21.81,0,0,0,3.13-.3,4.43,4.43,0,0,0,2.32-1.26,4.25,4.25,0,0,0,.92-1.73,4,4,0,0,0,.18-1h4V59H31V42.38Z"/>
                <path className="doc-tenQ-cls-2"
                      d="M57.7,38c1.24,2.16,1.85,5.22,1.85,9.2s-.61,7-1.85,9.18-3.38,3.22-6.45,3.22S46,58.5,44.8,56.36,43,51.15,43,47.18s.62-7,1.85-9.2,3.39-3.24,6.45-3.24S56.47,35.82,57.7,38ZM48.46,53.53a2.76,2.76,0,0,0,2.79,2.2A2.71,2.71,0,0,0,54,53.53a23.89,23.89,0,0,0,.65-6.35A23.66,23.66,0,0,0,54,40.73a2.69,2.69,0,0,0-2.77-2.09,2.73,2.73,0,0,0-2.79,2.09,22.66,22.66,0,0,0-.68,6.45A22.87,22.87,0,0,0,48.46,53.53Z"/>
                <path className="doc-tenQ-cls-2"
                      d="M85.14,51.82a9.08,9.08,0,0,1-1.91,3.5L86,58l-2.66,2.78L80.44,58a12,12,0,0,1-2.33,1.15,12.41,12.41,0,0,1-3.94.55,11.26,11.26,0,0,1-7.91-2.86q-3.78-3.44-3.79-10.09t3.89-10.14a11.46,11.46,0,0,1,7.89-2.81,11.29,11.29,0,0,1,8,3q3.72,3.44,3.72,9.62A16.26,16.26,0,0,1,85.14,51.82Zm-9.28,3.29A9.79,9.79,0,0,0,77,54.7l-2.47-2.36,2.62-2.74,2.49,2.34a11.25,11.25,0,0,0,.81-2.09,11.76,11.76,0,0,0,.37-3.14,10.43,10.43,0,0,0-1.69-6.37,5.76,5.76,0,0,0-4.92-2.25,6,6,0,0,0-4.85,2.16c-1.2,1.44-1.81,3.59-1.81,6.46,0,3.35.87,5.76,2.6,7.21a6,6,0,0,0,4,1.41A6.34,6.34,0,0,0,75.86,55.11Z"/>
                <g className="doc-tenQ-cls-3">
                    <rect className="doc-tenQ-cls-2" width="110.76" height="124.02"/>
                </g>
                <g className="doc-tenQ-cls-4">
                    <rect className="doc-tenQ-cls-2" x="18.07" y="58.54" width="74.62" height="38.46"/>
                </g>
                <g className="doc-tenQ-cls-5">
                    <rect className="doc-tenQ-cls-2" x="18.07" y="72.05" width="61.07" height="38.46"/>
                </g>
                <g className="doc-tenQ-cls-6">
                    <rect className="doc-tenQ-cls-2" x="8.1" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-7">
                    <rect className="doc-tenQ-cls-2" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-8">
                    <rect className="doc-tenQ-cls-2" x="26.17" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-9">
                    <rect className="doc-tenQ-cls-2" x="17.14" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-10">
                    <rect className="doc-tenQ-cls-2" x="44.24" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-11">
                    <rect className="doc-tenQ-cls-2" x="35.21" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-12">
                    <rect className="doc-tenQ-cls-2" x="62.31" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-tenQ-cls-13">
                    <rect className="doc-tenQ-cls-2" x="53.28" y="84.63" width="49.38" height="49.33"/>
                </g>
            </g>
        </g>
    </svg>,
    proxy: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.76 133.96">
        <defs>
            <style>
                {`.doc-proxy-cls-1{fill:none;}
                .doc-proxy-cls-2{fill:#2661e3;}
                .doc-proxy-cls-3{clip-path:url(#doc-proxy-clip-path);}
                .doc-proxy-cls-4{clip-path:url(#doc-proxy-clip-path-2);}
                .doc-proxy-cls-5{clip-path:url(#doc-proxy-clip-path-3);}
                .doc-proxy-cls-6{clip-path:url(#doc-proxy-clip-path-4);}
                .doc-proxy-cls-7{clip-path:url(#doc-proxy-clip-path-5);}
                .doc-proxy-cls-8{clip-path:url(#doc-proxy-clip-path-6);}
                .doc-proxy-cls-9{clip-path:url(#doc-proxy-clip-path-7);}
                .doc-proxy-cls-10{clip-path:url(#doc-proxy-clip-path-8);}
                .doc-proxy-cls-11{clip-path:url(#doc-proxy-clip-path-9);}
                .doc-proxy-cls-12{clip-path:url(#doc-proxy-clip-path-10);}
                .doc-proxy-cls-13{clip-path:url(#doc-proxy-clip-path-11);}`}
            </style>
            <clipPath id="doc-proxy-clip-path">
                <path className="doc-proxy-cls-1"
                      d="M93.78,21.45v83.34L89.26,107V21.48H21.5V107L17,104.79V21.45A4.51,4.51,0,0,1,21.5,17H89.26A4.49,4.49,0,0,1,93.78,21.45Z"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-2">
                <rect className="doc-proxy-cls-1" x="35.05" y="75.52" width="40.66" height="4.5"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-3">
                <rect className="doc-proxy-cls-1" x="35.05" y="89.03" width="27.11" height="4.5"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-4">
                <polygon className="doc-proxy-cls-1"
                         points="40.5 104.79 28.27 116.98 25.08 113.8 37.31 101.61 40.5 104.79"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-5">
                <polygon className="doc-proxy-cls-1"
                         points="31.47 113.8 28.27 116.98 16.98 105.72 16.98 103.86 19.24 101.61 31.47 113.8"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-6">
                <polygon className="doc-proxy-cls-1"
                         points="58.57 104.79 46.34 116.98 43.15 113.8 55.38 101.61 58.57 104.79"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-7">
                <polygon className="doc-proxy-cls-1"
                         points="37.31 101.61 49.54 113.8 46.34 116.98 34.12 104.79 37.31 101.61"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-8">
                <polygon className="doc-proxy-cls-1"
                         points="76.64 104.79 64.42 116.98 61.22 113.8 73.45 101.61 76.64 104.79"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-9">
                <polygon className="doc-proxy-cls-1"
                         points="55.38 101.61 67.61 113.8 64.42 116.98 52.19 104.79 55.38 101.61"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-10">
                <polygon className="doc-proxy-cls-1"
                         points="79.29 113.8 82.49 116.98 93.78 105.72 93.78 103.86 91.52 101.61 79.29 113.8"/>
            </clipPath>
            <clipPath id="doc-proxy-clip-path-11">
                <polygon className="doc-proxy-cls-1"
                         points="73.45 101.61 85.68 113.8 82.49 116.98 70.26 104.79 73.45 101.61"/>
            </clipPath>
        </defs>
        <title>Doc-Proxy</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path className="doc-proxy-cls-2"
                      d="M64.35,51.52q-2.52,2.11-7.22,2.11h-6V64h-6V35.19H57.52a10,10,0,0,1,6.82,2.23q2.53,2.22,2.54,6.89Q66.88,49.41,64.35,51.52ZM59.76,41.15a4.83,4.83,0,0,0-3.2-1H51.14v8.48h5.42a4.6,4.6,0,0,0,3.2-1,4.23,4.23,0,0,0,1.15-3.28A4,4,0,0,0,59.76,41.15Z"/>
                <g className="doc-proxy-cls-3">
                    <rect className="doc-proxy-cls-2" width="110.76" height="124.02"/>
                </g>
                <g className="doc-proxy-cls-4">
                    <rect className="doc-proxy-cls-2" x="18.07" y="58.54" width="74.62" height="38.46"/>
                </g>
                <g className="doc-proxy-cls-5">
                    <rect className="doc-proxy-cls-2" x="18.07" y="72.05" width="61.07" height="38.46"/>
                </g>
                <g className="doc-proxy-cls-6">
                    <rect className="doc-proxy-cls-2" x="8.1" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-7">
                    <rect className="doc-proxy-cls-2" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-8">
                    <rect className="doc-proxy-cls-2" x="26.17" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-9">
                    <rect className="doc-proxy-cls-2" x="17.14" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-10">
                    <rect className="doc-proxy-cls-2" x="44.24" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-11">
                    <rect className="doc-proxy-cls-2" x="35.21" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-12">
                    <rect className="doc-proxy-cls-2" x="62.31" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-proxy-cls-13">
                    <rect className="doc-proxy-cls-2" x="53.28" y="84.63" width="49.38" height="49.33"/>
                </g>
            </g>
        </g>
    </svg>,
    sca: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 110.76 133.96">
        <defs>
            <style>
                {`.doc-sca-cls-1{fill:none;}
                .doc-sca-cls-2{fill:#2661e3;}
                .doc-sca-cls-3{clip-path:url(#doc-sca-clip-path);}
                .doc-sca-cls-4{clip-path:url(#doc-sca-clip-path-2);}
                .doc-sca-cls-5{clip-path:url(#doc-sca-clip-path-3);}
                .doc-sca-cls-6{clip-path:url(#doc-sca-clip-path-4);}
                .doc-sca-cls-7{clip-path:url(#doc-sca-clip-path-5);}
                .doc-sca-cls-8{clip-path:url(#doc-sca-clip-path-6);}
                .doc-sca-cls-9{clip-path:url(#doc-sca-clip-path-7);}
                .doc-sca-cls-10{clip-path:url(#doc-sca-clip-path-8);}
                .doc-sca-cls-11{clip-path:url(#doc-sca-clip-path-9);}
                .doc-sca-cls-12{clip-path:url(#doc-sca-clip-path-10);}
                .doc-sca-cls-13{clip-path:url(#doc-sca-clip-path-11);}`}
            </style>
            <clipPath id="doc-sca-clip-path">
                <path className="doc-sca-cls-1"
                      d="M93.78,21.45v83.34L89.26,107V21.48H21.5V107L17,104.79V21.45A4.51,4.51,0,0,1,21.5,17H89.26A4.49,4.49,0,0,1,93.78,21.45Z"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-2">
                <rect className="doc-sca-cls-1" x="35.05" y="75.52" width="40.66" height="4.5"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-3">
                <rect className="doc-sca-cls-1" x="35.05" y="89.03" width="27.11" height="4.5"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-4">
                <polygon className="doc-sca-cls-1" points="40.5 104.79 28.27 116.98 25.08 113.8 37.31 101.61 40.5 104.79"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-5">
                <polygon className="doc-sca-cls-1"
                         points="31.47 113.8 28.27 116.98 16.98 105.72 16.98 103.86 19.24 101.61 31.47 113.8"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-6">
                <polygon className="doc-sca-cls-1"
                         points="58.57 104.79 46.34 116.98 43.15 113.8 55.38 101.61 58.57 104.79"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-7">
                <polygon className="doc-sca-cls-1"
                         points="37.31 101.61 49.54 113.8 46.34 116.98 34.12 104.79 37.31 101.61"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-8">
                <polygon className="doc-sca-cls-1"
                         points="76.64 104.79 64.42 116.98 61.22 113.8 73.45 101.61 76.64 104.79"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-9">
                <polygon className="doc-sca-cls-1"
                         points="55.38 101.61 67.61 113.8 64.42 116.98 52.19 104.79 55.38 101.61"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-10">
                <polygon className="doc-sca-cls-1"
                         points="79.29 113.8 82.49 116.98 93.78 105.72 93.78 103.86 91.52 101.61 79.29 113.8"/>
            </clipPath>
            <clipPath id="doc-sca-clip-path-11">
                <polygon className="doc-sca-cls-1"
                         points="73.45 101.61 85.68 113.8 82.49 116.98 70.26 104.79 73.45 101.61"/>
            </clipPath>
        </defs>
        <title>Doc-SCA</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path className="doc-sca-cls-2"
                      d="M32.78,51.2a3.81,3.81,0,0,0,.71,1.92,4.16,4.16,0,0,0,3.33,1.16,7.3,7.3,0,0,0,2.3-.31,2.18,2.18,0,0,0,1.67-2.17A1.57,1.57,0,0,0,40,50.37a8.77,8.77,0,0,0-2.6-.88l-2-.44a12.76,12.76,0,0,1-4.11-1.44,4.56,4.56,0,0,1-1.89-4,5.39,5.39,0,0,1,1.85-4.18,7.94,7.94,0,0,1,5.44-1.66,8.32,8.32,0,0,1,5.11,1.57A5.56,5.56,0,0,1,44,43.84H40.21a2.68,2.68,0,0,0-1.51-2.4A5.26,5.26,0,0,0,36.37,41a4.42,4.42,0,0,0-2.48.61,1.94,1.94,0,0,0-.92,1.7,1.6,1.6,0,0,0,.91,1.5,11,11,0,0,0,2.49.77l3.28.78a8.36,8.36,0,0,1,3.24,1.36,4.59,4.59,0,0,1,1.67,3.82,5.37,5.37,0,0,1-2,4.26A8.36,8.36,0,0,1,37,57.46a9.14,9.14,0,0,1-5.82-1.67,5.52,5.52,0,0,1-2.11-4.59Z"/>
                <path className="doc-sca-cls-2"
                      d="M49.09,40.22a7.7,7.7,0,0,1,5.75-2.31c3.12,0,5.39,1,6.83,3.1A6.73,6.73,0,0,1,63,44.5H59.05a5.33,5.33,0,0,0-1-2A3.87,3.87,0,0,0,55,41.25,3.83,3.83,0,0,0,51.69,43a8.44,8.44,0,0,0-1.2,4.87,7.39,7.39,0,0,0,1.27,4.72A4,4,0,0,0,55,54.12a3.64,3.64,0,0,0,3.06-1.34,5.8,5.8,0,0,0,1-2.17h3.89a8.43,8.43,0,0,1-2.57,5,7.47,7.47,0,0,1-5.3,1.92,8,8,0,0,1-6.28-2.59,10.44,10.44,0,0,1-2.28-7.13Q46.49,42.88,49.09,40.22Z"/>
                <path className="doc-sca-cls-2"
                      d="M71.43,38.27h4.42L82.47,57H78.23L77,53.13H70.11L68.84,57H64.75Zm-.26,11.64H76L73.6,42.55Z"/>
                <g className="doc-sca-cls-3">
                    <rect className="doc-sca-cls-2" width="110.76" height="124.02"/>
                </g>
                <g className="doc-sca-cls-4">
                    <rect className="doc-sca-cls-2" x="18.07" y="58.54" width="74.62" height="38.46"/>
                </g>
                <g className="doc-sca-cls-5">
                    <rect className="doc-sca-cls-2" x="18.07" y="72.05" width="61.07" height="38.46"/>
                </g>
                <g className="doc-sca-cls-6">
                    <rect className="doc-sca-cls-2" x="8.1" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-sca-cls-7">
                    <rect className="doc-sca-cls-2" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-sca-cls-8">
                    <rect className="doc-sca-cls-2" x="26.17" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-sca-cls-9">
                    <rect className="doc-sca-cls-2" x="17.14" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-sca-cls-10">
                    <rect className="doc-sca-cls-2" x="44.24" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-sca-cls-11">
                    <rect className="doc-sca-cls-2" x="35.21" y="84.63" width="49.38" height="49.33"/>
                </g>
                <g className="doc-sca-cls-12">
                    <rect className="doc-sca-cls-2" x="62.31" y="84.63" width="48.45" height="49.33"/>
                </g>
                <g className="doc-sca-cls-13">
                    <rect className="doc-sca-cls-2" x="53.28" y="84.63" width="49.38" height="49.33"/>
                </g>
            </g>
        </g>
    </svg>
};

const DocImage = (props) => {
    return images[props.type] || <div></div>;
};
export default DocImage;
