import _ from 'lodash';
import actions from './types';
import responseMap from '../../helpers/responseMap';
const defaultState = {};

export default function (state = defaultState, action) {
    switch (action.type) {
        case actions.SEARCH_SUCCESS:

            var obj = {};
            obj[action.industry] = _.get(action, 'payload');

            var newObj = {...obj[action.industry], documents: responseMap.documentType(obj[action.industry].documents)}

            return {
                ...state,
                searchResult : newObj,
                status: true
            };
        case actions.CLEAR_SEARCH_RESULT:
            return {
                ...state,
                searchResult : undefined,
                status: undefined
            };
        default:
            return state;
    }
}
