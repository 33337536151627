import _ from 'lodash';
import actions from './types';
import responseMap from '../../helpers/responseMap';

const defaultState = {
    data: null,
    loaded: false,
    error: null
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_RISK_FLAGS_SUCCESS:
            return {
                ...state,
                loaded: true,
                data: responseMap.riskType(_.get(action, 'payload.data'))
            };
        default:
            return state;
    }
}
