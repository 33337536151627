import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call } from 'redux-saga/effects';
import types from './types';
import { apiFailMsg } from '../../helpers/utility';


function* create(action) {
    let validate = true;
    const company = action.payload;
    try {
        
        // if (company.companyName.toLowerCase().indexOf('d') !== -1) {
        //     validate = false;
        //     // call api to check if there are any duplicates
        //     yield put({ type: types.ADD_DUPLICATE, payload: { ...company, companyName: 'DUPLICATE' } });
        // } else {
        //     validate = true;
        // }

    } catch (e) {
        console.log(e);
        yield put({ type: types.CREATE_COMPANY_FAILED });
    }
    if (validate) {
        try {
            const res = yield call(apigClientWrapper.createPrivateCompany, company);
            const companyId = res.data.data.companyIds[0];
            yield put({ type: types.CREATE_COMPANY_SUCCESS, companyId });
        } catch (e) {
   
            yield put({ type: types.CREATE_COMPANY_FAILED, ...apiFailMsg(e) });
        }
    }

}

export default [
    takeLatest(types.CREATE_COMPANY, create),
]