import _ from '../../../lodashWrapper';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Spinner from '../../common/spinner';
import InfoCard from '../../common/infoCard';
import {
    getCompanyDetailsAction,
} from '../../../ducks/company/actions';
import ErrorAlert from '../../common/errorAlert';
// import styles from './_companyOverview.module.scss';

export class CompanyInfo extends Component {

    constructor(props){
        super(props)
        this.state = {filter: null}
    }

    componentDidMount() {
        
        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getCompanyDetailsAction(this.props.company.company_id);
    }

    truncateByWord = sentence => {
        const truncate = word => (word.length > 11) ? word.substring(0, 10) + "..." : word;

        // A sentence with spaces...
        if (sentence && / /.test(sentence) && sentence.length > 12) {
            return sentence.substring(0, 12) + "...";
        }
        return sentence && sentence.split(' ').map(truncate).join(' ');
    };


    render() {
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>Company Info</h2>
                    {this.props.companyDetailError ? <ErrorAlert message={this.props.companyDetailError }/> : <Spinner loaded={this.props.companyDetail}>
                        {this.props.companyDetail &&
                        <div>
                        <p>{this.props.companyDetail.summary}</p>
                        <div className="row company-facts">
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.companyDetail.fiscal_year_ends}</div>
                                        <div className='value-description'>Fiscal Year Ends</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.companyDetail.mrq}</div>
                                        <div className='value-description'>Most Recent Quarter (mrq)</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.companyDetail.mrf}</div>
                                        <div className='value-description'>Most Recent Filing Date</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div
                                            className='value'>{this.truncateByWord(this.props.companyDetail.author)}</div>
                                        <div className='value-description'>Auditor</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.companyDetail.years_in_business}</div>
                                        <div className='value-description'>Years in Business</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className='col-xs-2 info'>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.companyDetail.ipo}</div>
                                        <div className='value-description'>IPO Date</div>
                                    </div>
                                </InfoCard>
                            </div>
                        </div>
                        </div>
                        }
                    </Spinner>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        companyDetail: _.get(state, 'company.detail'),
        companyDetailError: _.get(state, 'error.COMPANY_GET_DETAIL'),
    };
}

export default connect(mapStateToProps, {
    getCompanyDetailsAction,
})(CompanyInfo);
