import React from 'react';
import styles from './_card.module.scss';

const Card = (props) => {
    return (
        <div className={`${styles.card} ${props.className}`} onClick={props.onClick}>
            {props.children}
        </div>
    );
};

export default Card;
