// import _ from 'lodash';
import types from './types';

const defaultState = {
    data: null,
    loaded: false,
    error: null,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case types.GET_PRIVATE_BALANCE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loaded: true
        };
        case types.GET_PRIVATE_BALANCE_FAILED:
            return {
                ...state,
                error: action.payload,
                loaded: true
            };
        default:
            return state;
    }
}
