import types from './types';

// Worksheet
export function openWorksheetFormAction(company) {
    return {
        type: types.OPEN_WORKSHEET_FORM_MODAL,
        company
    };
}

export function closeWorksheetFormAction() {
    return {
        type: types.CLOSE_WORKSHEET_FORM_MODAL
    };
}
