const constants =  {
    note: {
        sectionMap : {
            overview: 0,
            trade: 1,
            financial: 2,
            risk: 3,
            news: 4,
        }
    },
    documentTypeMap: {
        k8: '8-K',
        k10: '10-K',
        q10: '10-Q',
        proxy: 'Proxy',
        sca: 'Complaint'
    },
    peerGroupsDefaultIndex: 1,
    peerGroups: [
        {value: 'sector', label: 'Sector'},
        {value: 'industry', label: 'Industry'},
        {value: 'sector_broad_size', label: 'Broad Sector-Size Group'},
        {value: 'sector_narrow_size', label: 'Narrow Sector-Size Group'},
        {value: 'industry_broad_size', label: 'Broad Industry-Size Group'},
        {value: 'industry_narrow_size', label: 'Narrow Industry-Size Group'}
    ],
    industrySizeDefaultIndex: 2,
    industrySize: [
        {value: 'sector_broad_size', label: 'Broad Sector-Size Group'},
        {value: 'sector_narrow_size', label: 'Narrow Sector-Size Group'},
        {value: 'industry_broad_size', label: 'Broad Industry-Size Group'},
        {value: 'industry_narrow_size', label: 'Narrow Industry-Size Group'}
    ],
    carriers: [
        'American International Group',
        'Great American',
        'Chubb Ltd',
        'Berkeley',
        'XL Group Ltd',
        'Tokio Marine',
        'CNA Financial Corp',
        'Travelers Companies Inc',
        'American Financial Group Inc',
        'Zurich Insurance Group',
        'Berkshire Hathaway Inc',
        'Sompo Holdings Inc',
        'Liberty Mutual',
        'W. R. Berkley Corp',
        'Alleghany Corp',
        'Fairfax Financial Holdings',
        'Nationwide Mutual Group',
        'AXIS',
        'QBE Insurance Group Ltd',
        'Arch Capital Group Ltd',
        'Old Repub International Corp',
        'ICI Mutual Insurance Co. a RRG',
        'Everest Re Group Ltd',
        'Hiscox Insurance Co',
        'Hartford Financial Services',
        'Navigators Group Inc',
        'Cincinnati Financial Corp',
        'Markel Corp',
        'Allianz',
        'Beazley Insurance Co',
        'Argo Group International',
        'Swiss Re AG',
        'RLI Corp',
        'OneBeacon Insurance',
        'Hanover Insurance Group Inc',
        'Aspen Insurance Holdings Ltd',
        'Enstar',
        'CUNA Mutual',
        'Federated Rural Electric Ins',
        'BCS Financial Corp',
        'Church Mutual Insurance Co',
        'Validus Holdings Ltd',
        'Alliance of Nonprofits for Ins',
        'West Bend Mutual Insurance Co.',
        'State Farm',
        'American Family Insurance Grp',
        'Farm Crdt Sys Assn Captive Ins',
        'Hallmark Financial Services',
        'AmTrust Financial Services',
        'Kinsale Insurance Co',
        'Munich Re',
        'Grupo Cooperativo Seguros',
        'CV Starr',
        'Ironshore',
        'Other Lloyds of London Syndicates',
        'Starstone Insurance',
        'Canopius',
        'Hudson Insurance Co',
        'Allied World Assurance Company (AWAC)'
    ],
};

export default constants;
