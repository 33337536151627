import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicTable from '../../common/dynamicTable';
import DataNotFound from '../../common/dataNotFound';
import MenuTab from "../../common/menuTab";
import MenuTabItem from "../../common/menuTabItem";
import Spinner from '../../common/spinner'
import { getCompanyFinancialsSheetAction } from '../../../ducks/financialStatement/actions';
import classNames from "classnames";
import Toggle from '../../common/toggle';

import ErrorAlert from '../../common/errorAlert';

export class CompanyFinancialStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            terms: 'annual',
            type: this.props.type ? this.props.type : 'income'
        }
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.fetchFinancialsSheet(this.state.type);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.fetchFinancialsSheet(this.state.type);
        }
    }

    fetchFinancialsSheet(type) {
        this.props.getCompanyFinancialsSheetAction(this.props.companyId, type);
        this.setState({ type: type });
    }

    prepaseData(data) {

        if (!data[this.state.type]) {
            return [];
        }
        return data[this.state.type][this.state.terms];
    }

    render() {
        let content;
        if (this.props.error && this.props.loaded !== true) {
            content = <ErrorAlert message={this.props.error} />
        } else {
            content = <Spinner loaded={this.props.loaded}>
                <div className="financial-tabs">
                    <Toggle label=""
                        options={[
                            { value: 'annual', label: 'Annual' },
                            { value: 'quarterly', label: 'Quarterly' }
                        ]}
                        value={this.state.terms}
                        onChange={(value) => {
                            this.setState({ terms: value })
                        }}
                    />
                </div>
                {!this.props.data || Object.keys(this.props.data).length === 0 ? <DataNotFound /> : _.map(this.prepaseData(this.props.data), (item, key) =>

                    <DynamicTable key={key}
                        data={item}
                        formatWithCommas={true}
                        className={classNames('table', { last: key === _.last(_.keys(this.props.data)) })}
                    />
                )}
            </Spinner>
        }
        return (
            <div className='panel panel-default'>
                <div className='panel-body financial with-spinner'>
                    <h2>{this.props.componentTitle ? this.props.componentTitle : 'Financial Statement'}</h2>
                    {!this.props.isPDF && <div className="financial-tabs">
                        <MenuTab initialActiveTab={0}>
                            <MenuTabItem
                                action={() => this.fetchFinancialsSheet('income')}>
                                Income Statements
                            </MenuTabItem>
                            <MenuTabItem
                                action={() => this.fetchFinancialsSheet('balance')}>
                                Balance Sheet
                            </MenuTabItem>
                            <MenuTabItem
                                action={() => this.fetchFinancialsSheet('cash')}>
                                Cash Flow
                            </MenuTabItem>
                        </MenuTab>
                    </div>}

                    <div className="with-spinner">
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'financialStatement.data'),
        loaded: _.get(state, 'financialStatement.loaded'),
        error: _.get(state, 'error.COMPANY_GET_FINANCIAL_SHEET'),
    };
}

export default connect(mapStateToProps, {
    getCompanyFinancialsSheetAction
})(CompanyFinancialStatement);
