import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchDocumentsAction } from '../../../ducks/document/actions';
import _ from '../../../lodashWrapper';
import ErrorAlert from '../../common/errorAlert';
import RiskFiling from '../../common/riskFiling';
import Select from '../../common/select';
import Spinner from '../../common/spinner';

export class CompanyProxyStatement extends Component {

    constructor(props) {
        super(props)
        
        this.state = { filter: {
            date: {
                value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year'
            }
        } }

        // this.filterFlags = this.filterFlags.bind(this)
        this.filterDates = this.filterDates.bind(this)
    }

    componentDidMount() {

        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.searchDocumentsAction(this.props.company.company_id, 'proxy');
    }

    // filterFlags(filter) {
    //     this.setState({ ...this.state, filter: { ...this.state.filter, flags: filter } })
    // }

    filterDates(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, date: filter } })
    }

    render() {
        return (
            <div className='panel panel-default'>
                <div className='panel-body proxy with-spinner'>
                    <h2>Proxy Statement</h2>
                    {this.props.documentsProxyError ? <ErrorAlert /> : <Spinner loaded={this.props.documentsProxy}>
                        <form>
                            {/* <label>
                                    <Select label="Risk Flag"
                                            options={[
                                                {value: '1', label: 'Outlier on Earning'},
                                                {value: '2', label: 'Compensation Committee Composition'},
                                                {value: '3', label: 'D&O Comp Peer Comparison'},
                                                {value: '4', label: 'CEO Contact Expiration Date'},
                                                {value: '5', label: 'M&A Activity'}
                                            ]}
                                            placeholder="Select risk flag..."
                                            multi
                                            onChange={this.filterFlags}
                                    />
                                </label> */}
                            <label>
                                <Select label="Date Range"
                                    options={[
                                        { value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year' },
                                        { value: moment().subtract(1, 'years').valueOf(), label: 'Past Year' },
                                        { value: moment().subtract(1, 'months').valueOf(), label: 'Past Month' },
                                        { value: moment().subtract(1, 'weeks').valueOf(), label: 'Past Week' },
                                    ]}
                                    defaultLabel="Past 2 Year"
                                    placeholder="Select range..."
                                    onChange={this.filterDates}
                                />
                            </label>
                        </form>
                        <RiskFiling data={this.props.documentsProxy} companyId={this.props.company.company_id} filter={this.state.filter} />
                    </Spinner>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        documentsProxy: _.get(state, 'document.proxy'),
        documentsProxyError: _.get(state, 'error.DOCUMENT_SEARCH'),
    };
}

export default connect(mapStateToProps, {
    searchDocumentsAction
})(CompanyProxyStatement);
