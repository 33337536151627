import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '../../common/button';
import _ from 'lodash';

class CompanyPdfFooter extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="company-pdf-footer container">
                <div className="wrapper">
                    <div className="cancel-btn">
                        <a className="underline" onClick={() => this.props.closePdfView()}>Back to Worksheet</a> 
                    </div>
                    <div className="archive-review">
                        <Button 
                            disabled={this.props.worksheetPDFGeneratingPDF !== false}
                            onClick={() => this.props.openReviewConfirmModal()}>
                            Archive as 90 Day Review
                        </Button>
                    </div>

                    <div className="archive">
                        <Button 
                            disabled={this.props.worksheetPDFGeneratingPDF !== false}
                            onClick={() => this.props.openConfirmModal()}>
                            Archive as Regular Worksheet
                        </Button>
                    </div>
                    
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        worksheetPDFGeneratingPDF: _.get(state, 'worksheet.generatingPDF'),
        worksheet: _.get(state, 'worksheet.selected'),
    };
}

export default connect(mapStateToProps, {    
})(CompanyPdfFooter);
