import _ from '../../../lodashWrapper';
import React, {Component} from 'react';
import { connect } from 'react-redux';

import MenuTab from '../../common/menuTab'
import MenuTabItem from '../../common/menuTabItem'
import qs from "qs";

class CompanyHeaderMenu extends Component {
    constructor(props) {
        super(props);

        this.viewKeyMap = {
            overview: 0,
            trade: 1,
            financial: 2,
            risk: 3,
            news: 4,
        };
        this.state = {initialActiveTab: this.viewKeyMap[_.get(props, 'match.params.view')]};
    }

    componentWillReceiveProps(nextProps) {
        this.setState({initialActiveTab: this.viewKeyMap[_.get(nextProps, 'match.params.view')]});
    }

    redirectTo(view) {
        const worksheetId = _.get(qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}), 'worksheetId');
        const companyId = _.get(this, 'props.match.params.companyId');

        this.props.history.push(`/company/${companyId}/${view}${worksheetId ? `?worksheetId=${worksheetId}` : ''}`)
    }

    render() {
        const { company_type } = this.props.company;

        if (!company_type) return null;

        let menuItems = [
            <MenuTabItem key={0} action={() => this.redirectTo('overview')}>Overview</MenuTabItem>,
        ];

        if (company_type !== 'private') {
            menuItems = menuItems.concat([
                <MenuTabItem key={1} action={() => this.redirectTo('trade')}>Trading &amp; Ownership</MenuTabItem>,
                <MenuTabItem key={2} action={() => this.redirectTo('financial')}>Financial Statements</MenuTabItem>,
                <MenuTabItem key={3} action={() => this.redirectTo('risk')}>Predictive Analytics</MenuTabItem>,
                <MenuTabItem key={4} action={() => this.redirectTo('news')}>News &amp; Events</MenuTabItem>
            ]);
        }

        return (
            <div className="company-header-menu container">
                <MenuTab uppercase={true} initialActiveTab={this.state.initialActiveTab}>
                    {
                        menuItems.map(function(item){
                            return item;
                          })
                    }
                </MenuTab>
            </div>
        );
    }
}

export default connect(
    state => ({ company: _.get(state, 'company.selected') || {}, }),
)(CompanyHeaderMenu);
