import classNames from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import Button from '../button';
import Link from '../link';

moment.suppressDeprecationWarnings = true;

class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPopupVisible: false,
            selectedDay: props.value && new Date(moment(props.value).format('MM/DD/YYYY')),
            selectedDays: props.value && props.value.from && {
                from: new Date(moment(props.value.from).format('MM/DD/YYYY')),
                to: new Date(moment(props.value.to).format('MM/DD/YYYY'))
            } || {}
        };
    }

    componentWillReceiveProps(newProps) {

        this.setState({
            selectedDays: newProps.value && {
                selectedDay: newProps.value && new Date(moment(newProps.value).format('MM/DD/YYYY')),
                from: newProps.value.from && new Date(moment(newProps.value.from).format('MM/DD/YYYY')),
                to: newProps.value.to && new Date(moment(newProps.value.to).format('MM/DD/YYYY'))
            } || {}
        });

    }

    handleOnSubmit() {
        if (this.props.onSubmit) {
            this.props.onSubmit({
                from: this.state.selectedDays.from && moment(this.state.selectedDays.from),
                to: this.state.selectedDays.to && moment(this.state.selectedDays.to)
            })
        }
    }

    handleRangeDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state.selectedDays);
        this.setState({ selectedDays: range });
        if (this.props.onChange) {
            this.props.onChange(range)
        }
    }

    handleSingleDayClick(day, { selected }) {
        const value = selected ? null : day;
        this.setState({
            selectedDay: value,
        });
        setTimeout(() => this.closePopup(), 500);
        if (this.props.onChange) {
            this.props.onChange(value && moment(value))
        }
    }

    openPopup() {
        this.setState({ isPopupVisible: true });
    }

    closePopup() {
        this.checkDateStates();
        this.setState({ isPopupVisible: false, tempSelectedDay: undefined });
    }

    checkDateStates() {
        if (this.state.tempSelectedDay) {
            let aDate = moment(this.state.tempSelectedDay, 'MM/DD/YYYY', true);
            if (!aDate.isValid()) {
                aDate = moment(this.state.tempSelectedDay, 'M/D/YYYY', true);
            }

            if (aDate.isValid()) {
                this.handleSingleDayClick(new Date(aDate.format()), { selected: undefined });
            } else {
                if (this.state.selectedDay) {
                    aDate = moment(this.state.selectedDay, 'MM/DD/YYYY', true);
                    if (!aDate.isValid()) {
                        this.setState({ isPopupVisible: false, selectedDay: '' });

                    }
                } else {
                    this.setState({ isPopupVisible: false, selectedDay: '' });
                }
            }
        }

        this.setState({ isPopupVisible: false, tempSelectedDay: undefined });
    }

    togglePopup(event) {
        event.preventDefault();
        if (this.state.isPopupVisible) {
            this.checkDateStates();
        }
        this.setState({ isPopupVisible: !this.state.isPopupVisible });
    }

    clear() {
        this.setState({ selectedDays: {} });
    }

    renderSinglePopup() {
        if (!this.state.isPopupVisible) {
            return null;
        }
        return (
            <div>
                <div className="popup-wrapper" onClick={(event) => this.togglePopup(event)} />
                <div className="popup">
                    <DayPicker selectedDays={this.state.selectedDay}
                        month={this.state.selectedDay}
                        onDayClick={(day, options) => this.handleSingleDayClick(day, options)}
                    />
                </div>
            </div>
        );
    }

    renderRangePopup(fromDateString, toDateString) {
        if (!this.state.isPopupVisible) {
            return null;
        }
        const { from, to } = this.state.selectedDays;
        const modifiers = { start: from, end: to };
        return (
            <div>
                <div className="popup-wrapper" onClick={(event) => this.togglePopup(event)} />
                <div className="popup">
                    <div className="side-form">
                        <div className="input-group">
                            <div className="input-label">Start Date</div>
                            <input value={fromDateString}
                                onChange={() => {
                                }}
                            />
                        </div>
                        <div className="input-group">
                            <div className="input-label">End Date</div>
                            <input value={toDateString}
                                onChange={() => {
                                }}
                            />
                        </div>
                        <div className="clear-btn">
                            <Link onClick={() => this.clear()}>Clear</Link>
                        </div>
                        <div className="apply-btn">
                            <Button onClick={(event) => {
                                this.togglePopup(event);
                                this.handleOnSubmit();
                            }}>Apply</Button>
                        </div>
                    </div>
                    <DayPicker selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={(day) => this.handleRangeDayClick(day)}
                    />
                </div>
            </div>
        );
    }

    handleSingleDayChange(event) {
        this.setState({ tempSelectedDay: event.target.value })
    }

    render() {
        const extraClasses = {
            'single-day': this.props.singleDay
        };

        const fromDateString = this.state.selectedDays.from && moment(this.state.selectedDays.from).format('MM/DD/YYYY') || '';

        const toDateString = this.state.selectedDays.to && moment(this.state.selectedDays.to).format('MM/DD/YYYY') || '';
        const displayRangeValue = fromDateString + ' - ' + toDateString;
        let displaySingleValue = this.state.selectedDay && moment(this.state.selectedDay).format('MM/DD/YYYY') || '';

        if (this.state.tempSelectedDay) {
            displaySingleValue = this.state.tempSelectedDay;
        }

        const value = this.props.singleDay ? displaySingleValue : (fromDateString || toDateString) && displayRangeValue;

        return (
            <div className={classNames('common-element date-picker', extraClasses)}>
                <div className="wrapper">
                    <div className="input-label">{this.props.label}</div>
                    <input value={value}
                        placeholder={this.props.placeholder}
                        onClick={() => this.openPopup()}
                        onChange={this.handleSingleDayChange.bind(this)}
                        onBlur={() =>
                            setTimeout(() => {
                                if (!this.state.isPopupVisible) {
                                    this.closePopup();
                                }
                            }, 200)
                        }
                        required={this.props.required}
                    />
                    <img src="/images/calendar.png" onClick={(event) => this.togglePopup(event)} />
                    {
                        this.props.singleDay ?
                            this.renderSinglePopup() :
                            this.renderRangePopup(fromDateString, toDateString)
                    }
                </div>
            </div>
        );
    }
}

export default DatePicker;
