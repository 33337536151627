import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyFinancialStatement from './companyFinancialStatement';
import CompanyDebtChart from './companyDebtChart';
import NoteController from '../../note/noteController'
import CompanyFinancialRatio from './companyFinancialRatio';
import Company10k10q from './company10k10q';

class CompanyFinancial extends Component {


    render() {

        return (
            <div className="company-financial container">

                <CompanyFinancialStatement />

                <CompanyFinancialRatio />

                <CompanyDebtChart />

                <Company10k10q />

                <NoteController section="financial" />
                
            </div>
        );
    }
}


export default connect(null, {

})(CompanyFinancial);
