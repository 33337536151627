import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import Info from '../info';
import InfoImage from '../../images/info';
import classNames from 'classnames';
import DataNotFound from '../dataNotFound';

class DynamicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandable: this.props.expandable && this.props.expandable === true,
      topExpanded: false,
      bottomExpanded: false,
    };
    this.addCommas = this.addCommas.bind(this);
    this.printTDs = this.printTDs.bind(this);
    this.printTBodyContent = this.printTBodyContent.bind(this);
  }

  addCommas = (val) => {
    if (val === undefined || val === null) {
      return <span>-</span>;
    }
    if (val && this.props.formatWithCommas) {
      let parts = val.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    }
    return val;
  };

  printTBodyContent = () => {
    return _.map(this.props.data.table.data, (row, rowIndex) => {
      const additionalClasses = {
        'subtotal-tr': row.subtotal === true,
        'first-td-bold':
          this.props.data.table.expandable &&
          this.props.data.table.expandable.keyIndex &&
          rowIndex === this.props.data.table.expandable.keyIndex,
      };

      if (this.props.trClassName) {
        additionalClasses[this.props.trClassName] = true;
      }

      if (row.trClassName) {
        additionalClasses[row.trClassName] = true;
      }

      return (
        <tr
          onMouseEnter={() => {
            if (this.props.trOnMouseEnter) {
              this.props.trOnMouseEnter(rowIndex, this.props.tableKey);
            }
          }}
          onMouseLeave={() => {
            if (this.props.trOnMouseLeave) {
              this.props.trOnMouseLeave();
            }
          }}
          key={rowIndex}
          className={classNames(additionalClasses)}
        >
          {this.printTDs(row, rowIndex)}
        </tr>
      );
    });
  };

  printTDs = (row) => {
    return _.map(this.props.data.table.columns, (item, itemIndex) => {
      const className = [];

      if (row[item.field + 'ClassName']) {
        className.push(row[item.field + 'ClassName']);
      }
      if (item.tdClassName) {
        className.push(item.tdClassName);
      }
      if (row.className) {
        className.push(row.className);
      }

      return (
        <td key={itemIndex} className={className.join(' ')}>
          <div className="main-td-content">
            {row.unformatted
              ? row[item.field]
              : this.addCommas(row[item.field])}
          </div>
          {item.subField && (
            <div className="sub-td-content">{row[item.subField]}</div>
          )}
        </td>
      );
    });
  };

  render() {
    if (!this.props.data) return null;

    return (
      <div className="common-element dynamic-table">
        {this.props.data.title && <h3>{this.props.data.title}</h3>}
        {this.props.data.table.data.length === 0 ? (
          <DataNotFound />
        ) : (
          <table className={this.props.className}>
            {!this.props.hideHeader && (
              <thead>
                <tr>
                  {_.map(this.props.data.table.columns, (item, index) => {
                    return (
                      <th key={index} className={item.className}>
                        <div>
                          {item.title}
                          {item.tooltip && (
                            <Info text={item.tooltip}>
                              <InfoImage />
                            </Info>
                          )}
                        </div>
                        {item.subTitle && (
                          <div style={{ fontSize: '10px' }}>
                            {item.subTitle}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>{this.printTBodyContent()}</tbody>
          </table>
        )}
      </div>
    );
  }
}

export default DynamicTable;
