import types from './types';

export function openSubmissionModalAction() {
    return {
        type: types.OPEN_SUBMISSION_MODAL
    };
}
export function closeSubmissionModalAction() {
    return {
        type: types.CLOSE_SUBMISSION_MODAL
    };
}

export function changeSubmissionModalIndexAction(index) {
    return {
        type: types.CHANGE_SUBMISSION_MODAL_INDEX,
        payload: index
    };
}


export function getSubmissionData(companyId, year) {

    return {
        type: types.GET_SUBMISSION_DATA,
        year,
        companyId
    };
}

// Get MC questions
export function getMCQuestions() {

    return {
        type: types.GET_QUESTIONS
    };
}

export function submitAction(companyId, submit, year, month) {
    return {
        type: types.SUBMIT,
        companyId,
        payload: submit,
        year,
        month
    };
}