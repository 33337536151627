import Actions from './types';

export const getCompanyStocksAction = (companyId) => (
    {
        type: Actions.GET_STOCK,
        companyId: companyId
    }
);

export const getCompanyStocksVariationsAction = (companyId) => (
    {
        type: Actions.GET_STOCK_VARIATION,
        companyId: companyId
    }
);

export function stockChartVariationCardClickedAction(data, page) {
    return {
        type: Actions.STOCK_CHART_VARIATION_CARD_CLICKED,
        data: data,
        page: page
    }
}

export function overviewStockChartAction() {
    return {
        type: Actions.OVERVIEW_STOCK_CHART_MOUSELEAVE,
    }
}