import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './_menuTab.module.scss';

export class MenuTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: props.initialActiveTab
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialActiveTab !== this.props.initialActiveTab) {
            this.setState({ activeTab: nextProps.initialActiveTab })
        }
    }

    render() {
        const { children } = this.props;
        const modifiedChildren = React.Children.map(children, (child, index) =>
            React.cloneElement(child, {
                active: index === this.state.activeTab,
                activate: () => {
                    if (this.props.activated) {
                        this.props.activated(index);
                    }

                    this.setState({ activeTab: index })
                }
            })
        );
        
        return (
            <div style={this.props.style} className={`common-element ${styles.menuTab} ${styles[this.props.className]}`}>
                <div className={classNames('clearfix', {
                    [styles.uppercase]: this.props.uppercase
                })}>
                    {modifiedChildren}
                </div>
            </div>
        );
    }
}
export default MenuTab;
