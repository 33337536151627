import actions from './types';
import apigClientWrapper from "../../helpers/apigClientWrapper"

export function getWorksheetsAction() {
    return {
        type: actions.SEARCH
    };
}

export function getWorksheetAction(worksheetId) {
    return {
        type: actions.SELECT,
        worksheetId: worksheetId
    };
}

export function goViewOnlyAction(companyId, companyName) {
    return {
        type: actions.GO_VIEW_ONLY,
        companyId: companyId,
        companyName: companyName,
    };
}

export function companySelectedInSearchAction(companyId) {
    return {
        type: actions.SELECT_COMPANY,
        companyId: companyId
    };
}

export function worksheetSelectedAction(companyId, worksheetId, companyName) {
    return {
        type: actions.SELECT_WORKSHEET,
        companyId: companyId,
        companyName: companyName,
        worksheetId: worksheetId
    };
}

export function worksheetSelectedSuccessAction(payload) {

    return {
        type: actions.SELECT_SUCCESS,
        payload
    };
}

export function worksheetClearPDFAction() {
    return {
        type: actions.CLEAR_WORKSHEET_PDF
    };
}

export function worksheetArchiveAction(review) {
    return {
        type: actions.ARCHIVE_WORKSHEET,
        review: review,
    };
}

export function worksheetGetPDFSignedUrlAction(worksheetId) {
    return {
        type: actions.GET_WORKSHEET_PDF_PRESIGNEDURL,
        worksheetId: worksheetId
    };
}

export function worksheetClearPDFSignedUrlAction() {
    return {
        type: actions.CLEAR_WORKSHEET_PDF_PRESIGNEDURL,
    };
}

export function worksheetUpdatePDFAction(worksheetId) {
    return {
        type: actions.UPDATE_WORKSHEET_PDF,
        worksheetId: worksheetId
    };
}

export function worksheetGeneratePDFAction(companyId, companyName, worksheetId) {
    return {
        type: actions.GENERATE_WORKSHEET_PDF,
        companyName: companyName,
        worksheetId: worksheetId,
        companyId: companyId
    };
}

export function createWorksheetAction(companyId, companyName, ticker, submission) {
    const notes = [
        {
            note_id: '1',
            note_text: '',
            note_page: 'overview'
        },
        {
            note_id: '2',
            note_text: '',
            note_page: 'trading'
        },
        {
            note_id: '3',
            note_text: '',
            note_page: 'financial'
        },
        {
            note_id: '4',
            note_text: '',
            note_page: 'risk'
        },
        {
            note_id: '5',
            note_text: '',
            note_page: 'news'
        }
    ];
    return {
        type: actions.CREATE,
        payload: apigClientWrapper.createUserWorksheet({
            cik: companyId,
            company_name: companyName,
            ticker: ticker,
            state: 'wip',
            submission,
            notes
        })
    };
}

export function createWorksheetDoneAction(companyId, companyName, worksheetId){
    return {
        type: actions.CREATE_WORKSHEET_DONE,
        companyId: companyId,
        companyName: companyName,
        worksheetId: worksheetId
    };
}



export function updateWorksheetAction(companyId, worksheetId, worksheet) {
    return {
        type: actions.UPDATE,
        worksheetId: worksheetId,
        worksheet: worksheet,
        companyId: companyId,
        // payload: apigClientWrapper.updateUserWorksheet(worksheetId, {cik: companyId, ...worksheet}).then(() => ({data: worksheet}))
    };
}

export function autoUpdateWorksheetAction(companyId, worksheet, currentSection, note) {
    return {
        type: actions.AUTO_NOTE_UPDATE,
        companyId: companyId,
        worksheet: worksheet,
        currentSection: currentSection,
        note: note
    };
}

export function deleteWorksheetAction(worksheetId) {
    return {
        type: actions.DELETE,
        payload: apigClientWrapper.deleteUserWorksheet(worksheetId)
    };
}

export function worksheetClearSubmissionAction() {
    return {
        type: actions.CLEAR_SUBMISSION
    };
}

export function showWorksheetModalAction(config) {
    return {
        type: actions.SHOW_MODAL,
        payload: config
    };
}

export function hideWorksheetModalAction() {
    return {
        type: actions.HIDE_MODAL
    };
}
