import React, { Component } from 'react';

export default class RiskPredictorPeerGroupTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.close();
        }
    }

    open() {
        this.setState({ show: true })
    }

    close() {
        this.setState({ show: false })
    }

    toggle() {
        this.setState({ show: !this.state.show })
    }
    render() {

        const peerGroups = this.props.peerGroups;
        return (
            <div ref={this.setWrapperRef} className={'risk-predictor-tooltip ' + (this.state.show ? '' : 'hidden')}>
                <div className="risk-predictor-tooltip-content">
                    <div className="risk-predicor-company-peer-groups-title">
                        <strong>
                            Peer Group Companies ({peerGroups.length})
                        </strong>
                        <div className="close" onClick={() => { this.close() }}>
                            <img src="/images/x.svg" />
                        </div>
                    </div>
                    <div className="risk-predicor-company-peer-groups">
                        <div className="list-header">
                            <div className="list-name">
                                Name
                            </div>
                            <div className="list-percentile text-right">
                                Percentile
                            </div>
                        </div>
                        <div className="risk-predicor-company-list">
                            {peerGroups.map((x, index) => {
                                return <div className="company-row" key={'risk-indicator' + index}>
                                    <div className="list-name">
                                        {x.companyname}
                                    </div>
                                    <div className="list-percentile text-right">
                                        <span>
                                            {Math.round(x.percentile * 100)}
                                        </span>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>

        );


    }
}