import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getStock = (state) => state.stock;

export function* getStocks(action) {
    let stock = yield select(getStock);

    if (stock.chart === null) {
        try {
            const response = yield call(apigClientWrapper.getCompanyStocks, action.companyId);
            yield put({ type: actions.GET_STOCK_SUCCESS, payload: response.data, peerGroup: action.peerGroup });
        } catch (e) {
            yield put({ type: actions.GET_STOCK_FAIL, ...apiFailMsg(e) });
        }
    }
}

function* getVariations(action) {
    let stock = yield select(getStock);
    if (stock.variations === null) {
        try {
            const response = yield call(apigClientWrapper.getCompanyStocksVariations, action.companyId);
            yield put({ type: actions.GET_STOCK_VARIATION_SUCCESS, payload: response.data });
        } catch (e) {
            yield put({ type: actions.GET_STOCK_VARIATION_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeLatest(actions.GET_STOCK, getStocks),
    takeLatest(actions.GET_STOCK_VARIATION, getVariations),
]
