import React, { Component } from 'react';
import CompanyAttachment from './companyAttachment';
import CompanyCoverageProgram from './companyCoverageProgram';
import CompanyExpiringProgram from './companyExpiringProgram';
import CompanyTopRisk from './companyTopRisk';
import NoteController from '../../note/noteController';
import { withRouter } from 'react-router';

class CompanyPricing extends Component {
    
    render() {
        return (
            <div className="company-pricing container">
                <CompanyTopRisk />
                <CompanyAttachment />
                <CompanyCoverageProgram />
                <CompanyExpiringProgram />
                <NoteController section="risk" />
                {/* <CompanyAPTP/> */}
            </div>
        );
    }
}

export default withRouter(CompanyPricing);
