import Actions from './types';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import storageWrapper from '../../helpers/storageWrapper';

export function loginAction() {
    return {
        type: Actions.LOGIN
    };
}

export function loginInitAction() {
    return {
        type: Actions.LOGIN_INIT
    };
}

export function logoutAction() {
    delete window.apigClient;
    storageWrapper.clearState('clientConfig');
    return {
        type: Actions.LOGOUT
    }
}

export function initAction() {
    return {
        type: Actions.INIT,
        payload: apigClientWrapper.getUser().catch(response => {
            if ('Network Error' === response.message) {
                delete window.apigClient;
                storageWrapper.clearState('accessToken');
                storageWrapper.clearState('clientConfig');
                storageWrapper.clearState('userProfile');
                storageWrapper.clearState('user');
            }

            return response;
        })
    }
}
