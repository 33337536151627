import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from '../../common/datePicker';
import Select from '../../common/select'
import Spinner from '../../common/spinner'
import EventsBar from '../../common/eventsBar';
import { searchNewsAction } from '../../../ducks/news/actions';
import { parseQuery } from '../../../helpers/utility';
import { getCompanyStocksVariationsAction, stockChartVariationCardClickedAction } from '../../../ducks/stock/actions';
import qs from "qs";
import ErrorAlert from '../../common/errorAlert';
import { withRouter } from 'react-router';

export class CompanyNewsItems extends Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(props)
        this.filterFlags = this.filterFlags.bind(this)
        this.filterDates = this.filterDates.bind(this)
        this.printNews = this.printNews.bind(this)
        this.printNoDataFound = this.printNoDataFound.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState(this.propsToState(nextProps))
    }

    propsToState(props) {
        let queryParams = {};
        if (this.props.history) {
            queryParams = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        }

        return {
            newsFilter: {
                ...(this.state && this.state.newsFilter),
                query: props.companyName,
                // from: queryParams.from,
                // to: queryParams.to
            },
            dateRangeValue: {
                from: queryParams.from,
                to: queryParams.to
            }
        };
    }

    componentDidMount() {

        if (this.state.newsFilter.query) {
            this.props.searchNewsAction(this.props.companyId, this.state.newsFilter);
        }

        if (this.props.companyId) {
            this.props.getCompanyStocksVariationsAction(this.props.companyId, '1y');
        }
        if (this.props.location && '#filing8k' === this.props.location.hash) {
            document.querySelector('#filing8k').scrollIntoView();
        }

        const queryString = this.props.location !== undefined ? parseQuery(this.props.location.search) : {};

        if (queryString.risk_name !== undefined) {
            const obj = {
                value: queryString.risk_name,
                label: queryString.risk_name
            };
            this.filterFlags([obj])
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (!_.isEqual(prevState.newsFilter, this.state.newsFilter)) {
            this.props.searchNewsAction(this.props.companyId, this.state.newsFilter);
        }
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyStocksVariationsAction(this.props.companyId, '1y');
        }
    }

    printNews() {

        let rows = [];
        _.map(this.props.news, (item, index) => {
            let showNews = _.isNil(this.state.newsFilter.groupCategories);
            // Check group category filter
            if (showNews === false && this.state.newsFilter.groupCategories) {
                showNews = this.state.newsFilter.groupCategories.findIndex((x) => x.label.toLowerCase() === item.groupCategory.toLowerCase()) !== -1;
            }

            // Check date range
            if (this.state.dateRangeValue !== undefined) {
                if (this.state.dateRangeValue.from !== undefined) {
                    if (moment(this.state.dateRangeValue.from).isAfter(item.date)) {
                        showNews = false;
                    }
                }
                if (showNews === true && this.state.dateRangeValue.to !== undefined) {
                    if (moment(this.state.dateRangeValue.to).isBefore(item.date)) {
                        showNews = false;
                    }
                }
            }
            if (showNews) {
                let bool = true;
                if (this.props.isPDF === true) {
                    if (Object.keys(this.props.events).length > 0) {
                        for (let v of this.props.events) {
                            const newsDate = new Date(item.date);
                            let variationDate = new Date(v.date);
                            newsDate.setHours(0, 0, 0, 0);
                            variationDate.setHours(0, 0, 0, 0);
                            bool = newsDate.getTime() === variationDate.getTime();
                            if (bool === false) {
                                variationDate.setDate(variationDate.getDate() - 1);
                                bool = newsDate.getTime() === variationDate.getTime();
                            }
                        }
                    } else {
                        bool = false;
                    }
                }

                if (bool) {
                    rows.push(
                        <div className="news-item clearfix" key={index}>
                            <h3>
                                <div className="news-title-wrapper">
                                    <div className="news-title">{item.title}</div>
                                    <div className="group-category-tag">{item.groupCategory}</div>
                                </div>
                            </h3>
                            <h4 style={{ color: '#848484' }}>{item.category} </h4>
                            <span>{item.source} - {item.since}</span>
                            <p>
                                {item.excerpt}
                            </p>
                        </div>
                    )
                }
            }
        });
        if (rows.length === 0) {
            return this.printNoDataFound();
        } else {
            return rows;
        }
    }

    printNoDataFound() {
        return <div className="common-element empty-data">
            No data found.
        </div>
    }

    filterFlags(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, flags: filter } })
    }

    filterDates(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, date: filter } })
    }

    render() {
        const newsTypes = [
            { value: '1', label: 'Equity Listings & Index Constituents' },
            { value: '2', label: 'Delays & Restatements' },
            { value: '3', label: 'Business Strategy' },
            { value: '4', label: 'Earnings Announcement & Guidance' },
            { value: '5', label: 'M&A' },
            { value: '6', label: 'D&O Changes & Shareholder Meetings' },
            { value: '7', label: 'Other Risks' },
            { value: '8', label: 'Litigation & Investigations' },
            { value: '9', label: 'Others' },
            { value: '10', label: 'Restructuring & Unusual Activities' },
            { value: '11', label: 'Investor Activism' },
            { value: '12', label: 'Bankruptcy' },
            { value: '13', label: 'Buyback' },
            { value: '14', label: 'Fixed Income' },
            { value: '15', label: 'Auditor & Accounting Changes' }
        ];


        let content;

        if (this.props.eventsError) {
            content = <ErrorAlert message={this.props.eventsError} />
        } else {
            content = <Spinner loaded={this.props.events && this.props.newsLoaded}>
                {this.props.isPDF !== true && this.props.news && <div>

                    <p> Major Stock Price Variations Filter </p>

                    <EventsBar
                        selected={(e) => {
                            this.props.stockChartVariationCardClickedAction(e, 'news');
                        }}
                        data={this.props.events} companyId={this.props.companyId}
                        worksheetId={this.props.worksheetId} />

                    <form className="announcements-form">
                        <label>
                            <Select label="Category"
                                options={newsTypes}
                                placeholder="Select category..."
                                multi
                                onChange={(value) => {
                                    this.setState({
                                        newsFilter: {
                                            ...this.state.newsFilter,
                                            categories: undefined,
                                            groupCategories: value
                                        }
                                    })
                                }}
                            />
                        </label>
                        <label>
                            <DatePicker label="Date Range"
                                placeholder="Select range..."
                                value={this.state.dateRangeValue}
                                // onChange={(value) => {
                                //     this.setState({dateRangeValue: value})
                                // }}
                                onSubmit={(value) => {
                                    const query = qs.stringify({
                                        worksheetId: this.props.worksheetId,
                                        from: value.from && value.from.format('YYYY-MM-DD'),
                                        to: value.to && value.to.format('YYYY-MM-DD')
                                    }, { addQueryPrefix: true });

                                    const dateRange = {
                                        from: value.from && value.from.format('YYYY-MM-DD'),
                                        to: value.to && value.to.format('YYYY-MM-DD')
                                    }

                                    this.setState({ dateRangeValue: dateRange });
                                    this.props.history.push(`/company/${this.props.companyId}/news${query}`);
                                }}
                            />
                        </label>
                    </form>
                    <p>Showing categories {this.state.newsFilter.groupCategories && !_.isEmpty(this.state.newsFilter.groupCategories) ? _.chain(this.state.newsFilter.groupCategories).map('label').join(', ').value() : 'All'}</p>

                </div>}
                <div className="news-items">
                    {(!this.props.news || !this.props.news.length) &&
                        (
                            this.printNoDataFound()
                        )
                    }

                    {this.props.news && (
                        this.printNews()
                    )}
                </div>
            </Spinner>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>News &amp; Events</h2>
                    {content}
                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        news: _.get(state, 'news.data'),
        newsLoaded: _.get(state, 'news.loaded'),
        newsError: _.get(state, 'news.error'),
        events: _.get(state, 'stock.variations'),
        eventsError: _.get(state, 'error.NEWS_SEARCH'),
        companyName: _.get(state, 'company.selected.name'),
        companyId: _.get(state, 'company.selected.company_id'),
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
    };
}

export default withRouter(connect(mapStateToProps, {
    searchNewsAction,
    getCompanyStocksVariationsAction,
    stockChartVariationCardClickedAction
})(CompanyNewsItems));
