import _ from 'lodash';
import types from './types';

const defaultData = {
    "table": {
        "columns": [
            {
                "title": "Retention + Attachment",
                "field": "layer"
            },
            {
                "title": "Model Frequency",
                "field": "modelFrequency"
            },
            {
                "title": "Sweet Spot",
                "field": "sweetSpot"
            },
            {
                "title": "Technical Price",
                "field": "technicalPrice"
            },
        ],
        "data": [
            {
                "attachPoint": "",
                "beingEvaluated": false,
                "hasFailed": false,
                "layer": "",
                "modelFrequency": "",
                "sweetSpot": "",
                "technicalPrice": "",
            }
        ],
    },
    "retention": 1000000,
    "limit": 5000000,
    "companyName": "",
    "companyId": "",
    "gSectorName": "",
    "revenuePrior": 0,
    "revenuePrior2": 0,
    "earningsPrior": 0,
    "earningsPrior2": 0,
    "transaction": 0,
    "ipoYear": new Date().getFullYear(),
    "yearFounded": "",
}


export const defaultState = {
    error: null,
    data: defaultData,
    isEditable: true,
    isPreProcessing: false,
};

export default function (state = defaultState, action) {
    const payload = _.get(action, 'payload')
    const tableData = _.get(state, 'data.table.data');
    let error = null;

    switch (action.type) {
        case types.APPEND:
            return {
                ...state,
                data: {
                    ...state.data,
                    table: {
                        ...state.data.table,
                        data: [
                            ...state.data.table.data,
                            payload,
                        ]
                    }
                }
            };
        case types.CREATE_FAIL:
            tableData[payload.index] = payload;
            tableData[payload.index].beingEvaluated = false;
            error = {
                name: "FailedValidation",
                message: `${payload.payload}`
            }
            return {
                ...state,
                error,
                isPreProcessing: false,
                data: {
                    ...state.data,
                    table: {
                        ...state.data.table,
                        data: tableData
                    }
                }
            };
        case types.ACCEPT:
        case types.SETTLE_EVALUATION:
            const idx = _.get(action, 'payload.index');
            const updatedRow = _.get(action, 'payload');
            return {
                ...state,
                error: null,
                isPreProcessing: (action.type === types.ACCEPT) ? true : false,
                data: {
                    ...state.data,
                    table: {
                        ...state.data.table,
                        data: state.data.table.data.map((row, i) => i === idx ? {
                            ...row,
                            ...updatedRow,
                        } : row)
                    }
                }
            };

        case types.CREATE_SUCCESS:
            tableData[payload.index] = payload;
            tableData[payload.index].beingEvaluated = true;
            return {
                ...state,
                isEditable: false,
                isPreProcessing: false,
                data: {
                    ...state.data,
                    table: {
                        ...state.data.table,
                        data: [
                            ...tableData
                        ]
                    }
                }
            };
        case types.GET_FAIL:
            error = {
                name: "FailedEvaluation",
                message: `${payload.payload}`,
                index: `${payload.index}`,
            }
            tableData[payload.index].beingEvaluated = false;
            tableData[payload.index].hasFailed = true;
            tableData[payload.index].trClassName = "";
            return {
                ...state,
                error,
                data: {
                    ...state.data,
                    table: {
                        ...state.data.table,
                        data: tableData
                    }
                }
            };
        case types.RESET:
            return {
                ...defaultState
            }
        case types.UPDATE:
            const updateTopLevel = Object.keys(state.data).map(
            (key) => (key === payload.key) ?
                {
                    [key]: payload[payload.key]
                } : {
                    [key]: state.data[key]
                }).reduce((acc, cur) => {
                    return {
                        ...acc,
                        ...cur,
                    };
                });
            if (tableData[tableData.length - 1][payload.key]) {
                tableData[tableData.length - 1][payload.key] = payload[payload.key];
            }
            return {
                ...state,
                data: {
                    ...state.data,
                    ...updateTopLevel,
                    table: {
                        ...state.data.table,
                        data: [
                            ...tableData
                        ],
                    }
                }
            }
        default:
            return state;
    }
}
