import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getRatio = (state) => state.ratio;

function* getRatios(action) {
    let ratio = yield select(getRatio);
    if (ratio.ratio === undefined || ratio.ratio[action.peerGroup] === undefined) {
        const queryParams = {
            peerGroup: action.peerGroup
        };
        try {
            const response = yield call(apigClientWrapper.getCompanyRatios, action.companyId, { queryParams });
            yield put({ type: actions.GET_RATIO_SUCCESS, payload: response, peerGroup: action.peerGroup });
        } catch (e) {
            yield put({ type: actions.GET_RATIO_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeEvery(actions.GET_RATIO, getRatios),
]
