import _ from 'lodash';
import actions from './types';
const defaultState = {
    data: null,
    error: null,
    loaded: false,
};

export default function (state = defaultState, action = null) {
    
    switch (action.type) {
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                loaded: true,
                data : _.get(action, 'payload.data')
            };
        default:
            return state;
    }
}
