import actions from './types';

export function showNoteAction() {
    return {
        type: actions.SHOW
    };
}

export function hideNoteAction() {
    return {
        type: actions.HIDE
    };
}

export function editNoteAction(value) {
    return {
        type: actions.EDIT,
        payload: value
    };
}

export function changeSectionAction(section) {
    return {
        type: actions.CHANGE_SECTION,
        payload: section
    };
}
