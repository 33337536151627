import _ from '../../../lodashWrapper';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import DataNotFound from "../../common/dataNotFound";
import Spinner from '../../common/spinner'
import Info from '../../common/info';
import InfoCard from '../../common/infoCard';
import InfoImage from '../../images/info';
import {
    getCompanyHoldersAction,
} from '../../../ducks/holder/actions';
import ErrorAlert from '../../common/errorAlert';

export class CompanyHolders extends Component {

    constructor(props){
        super(props)
        this.state = {filter: null}
      }

    componentDidMount() {
        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getCompanyHoldersAction(this.props.company.company_id);
    }

    render() {
        let content;
        if (this.props.companyHolderError) {
            content = <ErrorAlert message={this.props.companyHolderError}/>
        } else {
            content = <Spinner loaded={this.props.companyHolder}>
            { (this.props.companyHolder === null || Object.keys(this.props.companyHolder).length == 0) ? <DataNotFound />:  <div className="row">
                <div className='col-xs-3 info'>
                    <InfoCard>
                        <div className='value'>{this.props.companyHolder.percent_all_insider_shares}%
                        </div>
                        <div className='value-description'>% of Shares Held by All Insider</div>
                    </InfoCard>
                </div>
                <div className='col-xs-3 info'>
                    <InfoCard>
                        <div className='value'>{this.props.companyHolder.last_filed}</div>
                        <div className='value-description'>Last Filed Date</div>
                    </InfoCard>
                </div>
            </div>}
        </Spinner>
        }
        
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2 className='inline'>Holders</h2>
                    <Info text="Source: S&amp;P Cap IQ"><InfoImage/></Info>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        companyHolder: _.get(state, 'holder.holder'),
        companyHolderError: _.get(state, 'error.COMPANY_GET_HOLDER'),
    };
}

export default connect(mapStateToProps, {
    getCompanyHoldersAction,
})(CompanyHolders);
