import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import MenuTab from '../../common/menuTab'
import MenuTabItem from '../../common/menuTabItem'
import qs from "qs";
import { getCompanyByIdAction } from '../../../ducks/priv_company/actions';

class PrivateHeaderMenu extends Component {
    constructor(props) {
        super(props);
        this.viewKeyMap = {
            overview: 0,
            // trade: 1,
            financial: 1,
            // risk: 3,
            // news: 4,
        };

        this.state = { initialActiveTab: this.viewKeyMap[_.get(this, 'props.match.params.view')] };
        // this.state = { initialActiveTab: 0 };
        
        this.props.getCompanyByIdAction(_.get(this, 'props.match.params.companyId'));
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ initialActiveTab: this.viewKeyMap[_.get(nextProps, 'match.params.view')] })
    }

    redirectTo(view) {
        const worksheetId = _.get(qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true }), 'worksheetId');
        const companyId = _.get(this, 'props.match.params.companyId');
        this.props.history.push(`/private/${companyId}/${view}${worksheetId ? `?worksheetId=${worksheetId}` : ''}`)
    }

    render() {
        const menuItems = [
            <MenuTabItem key={0} action={() => this.redirectTo('overview')}>Overview</MenuTabItem>,
            // <MenuTabItem key={1} action={() => this.redirectTo('trade')}>Trading &amp; Ownership</MenuTabItem>,
            <MenuTabItem key={1} action={() => this.redirectTo('financial')}>Financial Statements</MenuTabItem>,
            // <MenuTabItem key={3} action={() => this.redirectTo('risk')}>Predictive Analytics</MenuTabItem>,
            // <MenuTabItem key={4} action={() => this.redirectTo('news')}>News &amp; Events</MenuTabItem>
        ];

        return (
            <div className="company-header-menu container">
                <MenuTab uppercase={true} initialActiveTab={this.state.initialActiveTab}>
                    {
                        menuItems.map(function (item) {
                            return item;
                        })
                    }
                </MenuTab>
            </div>
        );
    }
}



export default connect(null, {
    getCompanyByIdAction
})(PrivateHeaderMenu);


