import classNames from 'classnames';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { getCompanyRatiosAction } from '../../../ducks/ratio/actions';
import _ from '../../../lodashWrapper';
import DynamicTable from '../../common/dynamicTable';
import ErrorAlert from '../../common/errorAlert';
import RiskPredictor from '../../common/riskPredictor';
import Select from '../../common/select';
import Spinner from '../../common/spinner';

export class CompanyFinancialRatio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: null,
            industryFilter: {
                industry: { value: constants.industrySize[constants.industrySizeDefaultIndex].value, label: constants.industrySize[constants.industrySizeDefaultIndex].label }
            },
            peerGroupFilter: {
                peerGroup: { value: constants.peerGroups[constants.peerGroupsDefaultIndex].value, label: constants.peerGroups[constants.peerGroupsDefaultIndex].label }
            },
        }
    }

    startInterval(index, key) {
        if (!this.intervalId) {
            this.setState({ currentCount: 0 });
            this.intervalId = setInterval(() => {
                const count = this.state.currentCount + 1;
                this.setState({ currentCount: count });
            }, 1500);

            this.setState({ hoverIndex: index, hoverKey: key });
        }
    }

    clearInterval() {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.setState({ hoverIndex: null, hoverKey: null });
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.loadData();
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.loadData();
        }
    }

    loadData(peerGroup) {
        
        const pg = peerGroup ? peerGroup : this.state.peerGroupFilter.peerGroup.value;
        this.props.getCompanyRatiosAction(this.props.companyId, pg);
    }

    prepareRow(row, index, key) {
        if (row['Peer Group Comparison'] === undefined) {
            return row;
        }
        let animation;
        const p = row['Peer Group Comparison'];
        if (index === this.state.hoverIndex && key === this.state.hoverKey) {
            animation = this.state.currentCount % 2 === 0 ? 0 : 1;
        }

        let value;
        Object.keys(row).forEach((key) => {
            const lowerCaseKey = key.toLowerCase();
            if (value === undefined && lowerCaseKey.indexOf('q') === 0) {
                value = row[key];
            }
        });

        const obj = {
            ...row,
            'Peer Group Comparison': p ? <div
            >{p ? <RiskPredictor
                animation={animation}
                data={{
                    label: <span><strong>{value}</strong> {p.rank !== undefined ? <strong>({(p.rank * 100).toFixed(0)}%)</strong> : ''} among <strong>{row['Peer Group Comparison'].count}</strong> companies</span>,
                    count: row['Peer Group Comparison'].count,
                    animation: animation,
                    p10: p.data.p10,
                    p25: p.data.p25,
                    p50: p.data.p50,
                    p75: p.data.p75,
                    p90: p.data.p90,
                    min: p.data.min,
                    max: p.data.high,
                    value: value,

                    // label: `${this.props.company.name}: `
                }} /> : null}</div> : null
        }

        return obj;
    }
    prepareData(item, key) {
        if (!item) {
            return;
        }
        return {
            ...item,
            table: {
                columns: item.table.columns,
                data: _.map(item.table.data, (row, index) => this.prepareRow(row, index, key))
            }
        }
    }

    trOnMouseEnter(index, key) {
        this.startInterval(index, key);
    }

    trOnMouseLeave() {
        this.clearInterval();
    }

    isDataReady() {
        if (!this.props.companyRatio) {
            return false;
        }
        if (!this.props.companyRatio[this.state.peerGroupFilter.peerGroup.value]) {
            return false;
        }
        return true;
    }

    render() {
        // let riskFlagFilterValue = null;
        // if (this.state.filter) {
        //     riskFlagFilterValue = this.state.filter.flags.map((x) => {
        //         return x.value;
        //     });
        // }

        let content;

        if (this.props.companyRatioError) {
            content = <ErrorAlert message={this.props.companyRatioError} />
        } else {
            content = <Spinner loaded={this.isDataReady()}>
                {this.isDataReady() &&
                    _.map(this.props.companyRatio[this.state.peerGroupFilter.peerGroup.value], (item, key) => {
                        return <DynamicTable key={key}
                            tableKey={key}
                            trOnMouseEnter={this.trOnMouseEnter.bind(this)}
                            trOnMouseLeave={this.trOnMouseLeave.bind(this)}
                            data={this.prepareData(item, key)}
                            className={classNames('table', { last: key === _.last(_.keys(this.props.companyRatio[this.state.peerGroupFilter.peerGroup.value])) })}
                        />
                    }

                    )}
            </Spinner>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body ratios with-spinner'>
                    <div>
                        <h2>Ratios</h2>
                        <div>
                            <Select label="Peer Group Filter"
                                placeholder="Select Peer Group..."
                                value={this.state.peerGroupFilter.peerGroup}
                                clearable={false}
                                options={constants.peerGroups}
                                onChange={(value) => {
                                    this.setState({
                                        peerGroupFilter: {
                                            ...this.state.peerGroupFilter,
                                            peerGroup: value
                                        }
                                    });
                                    this.loadData(value.value);
                                }}
                            />
                        </div>
                    </div>
                    <p>&nbsp;</p>
                    {content}
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        companyRatio: _.get(state, 'ratio.data'),
        loaded: _.get(state, 'ratio.loaded'),
        companyRatioError: _.get(state, 'error.COMPANY_GET_RATIO'),
    };
}

export default connect(mapStateToProps, {
    getCompanyRatiosAction
})(CompanyFinancialRatio);
