// import _ from 'lodash';
import types from './types';
import companyFormtypes from '../priv_company_form/types';

const defaultState = {
    selected: null,
    loaded: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case types.GET_PRIVATE:
            return {
                ...state,
                selected: {
                    companyId: action.companyId
                },
                loaded: true
        };
        case types.SET_PRIVATE_SELECTED_COMPANYID:
        case companyFormtypes.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                selected: {
                    companyId: action.companyId
                },
        };
        case types.GET_PRIVATE_SUCCESS:
            return {
                ...state,
                selected: action.payload,
                loaded: true
            };
        default:
            return state;
    }
}
