import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: null,
    loading: true,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_TRADING_SUCCESS:
            return {
                ...state,
                loading: false,
                data: _.get(action, 'payload.data')
            };
            
        default:
            return state;
    }
}
