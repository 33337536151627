import React from 'react';
import styles from './_tag.module.scss';

export const Tag = (props) => {
    let classItems;
    if (props.className) {
        classItems = props.className.split(' ').map (x => {
            return styles[x];
        });
    }
    return (
        <div className={`${styles.tag} ${classItems && classItems.join(' ')}`}>
            <span>{props.text}</span>
        </div>
    );
};

export default Tag;