import _get from 'lodash/get';
import omit from 'lodash/omit';
import map from 'lodash/map';
import times from 'lodash/times';
import sampleSize from 'lodash/sampleSize';
import find from 'lodash/find';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import clone from 'lodash/clone';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import chain from 'lodash/chain';
import mapValues from 'lodash/mapValues';
import mapKeys from 'lodash/mapKeys';
import capitalize from 'lodash/capitalize';

export default {
    get: _get,
    map,
    mapKeys,
    mapValues,
    findIndex,
    isNil,
    omit,
    isUndefined,
    times,
    sampleSize,
    includes,
    isEqual,
    isNumber,
    chain,
    keys,
    isEmpty,
    last,
    find,
    capitalize,
    clone
}
