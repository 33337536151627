import { call, put, takeLatest } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import types from './types';


function* getEmployment(action) {
    try {
        const response = yield call(apigClientWrapper.getPrivateCompanyEmployment, action.companyId);
        yield put({ type: types.GET_PRIVATE_EMPLOYMENT_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({ type: types.GET_PRIVATE_EMPLOYMENT_FAILED, ...apiFailMsg(e) });
    }
}

export default [
    takeLatest(types.GET_PRIVATE_EMPLOYMENT, getEmployment)
]
