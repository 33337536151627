import _ from 'lodash';
import actions from './types';
import responseMap from '../../helpers/responseMap';

const defaultState = { companyId: null, selected: {}, loaded: false };

export default function (state = defaultState, action) {
    let data;
    switch (action.type) {
        case actions.SEARCH_SUCCESS:
            data = _.get(action, 'payload.data') || {};
            return {
                ...state,
                companyId: action.companyId,
                [data.type]: responseMap.documentType(data.documents),
                [data.type + '_options']: data.options,
            };
        case actions.SEARCH_FAIL:
            data = _.get(action, 'payload.data') || {};
            return {
                ...state,
                [data.type + 'error']: action.payload,
                [data.type + 'error_status']: action.status,
            };
        case actions.SELECT_SUCCESS:
            data = _.get(action, 'payload.data') || {};
            return {
                ...state,
                selected: { ...state.selected, url: data.url }
            };
        case actions.FETCH_SUCCESS:
            data = _.get(action, 'payload.data') || {};
            return {
                ...state,
                success: true,
                loaded: true,
                selected: { ...state.selected, data: data }
            };
        case actions.FETCH_FAILED:
            var error = _.get(action, 'error');
            return {
                ...state,
                success: false,
                loaded: true,
                error: error.response.status
            };
        case actions.BADACTORS_SEARCH_SUCCESS:
            return {
                ...state,
                persons: { ...state.persons, [action.personId]: action.payload }
            };
        case actions.CLEAR:
            return {
                ...state,
                selected: {}
            };
        case actions.RESET:
            return defaultState;
        default:
            return state;
    }
}
