import _ from 'lodash';
import types from './types';

const defaultState = {
    data: null,
    loaded: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case types.GET_ESTIMATION_SUCCESS:
            var cordinatesData = Object.keys(_.get(action, 'payload.data')).length === 0 ? [] : _.get(action, 'payload.data.coordinates');
            return {
                ...state,
                loaded: true,
                data: cordinatesData
            };
        default:
            return state;
    }
}
