import actions from './types';
import storageWrapper from '../../helpers/storageWrapper';

const defaultState = {};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.LOGIN_INIT:
            return {...state, authInProgress: true};
        // case actions.LOGIN:
        case actions.INIT:
            if (!action.error) {
                if (action.payload.data) {
                    return {...state, ...action.payload.data};
                }
                return {...state, ...{
                    user_id: storageWrapper.getState('userProfile').sub,
                    email: storageWrapper.getState('userProfile').email
                }};
            }
            if ('UserNotFoundException' === action.payload.code || 'NotAuthorizedException' === action.payload.code
            || 'Network Error' === action.payload.message) {
                return {...state, authInProgress: false, authError: true};
            }
            throw action.payload;
        case actions.LOGOUT:
            return {note: state.note};
        default:
            return state;
    }
}
