// import _ from 'lodash';
import actions from './types';

const defaultState = {
    submissionModalIndex: 0,
    submissionModalOpen: false,
    questionSets: null,
    loaded: false,
    submitting: false,
    submissionData: null,
    submissionDataLoaded: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.OPEN_SUBMISSION_MODAL:
            return {
                ...state,
                submissionModalOpen: true
            };
        case actions.CLOSE_SUBMISSION_MODAL:
            return defaultState;
        case actions.CHANGE_SUBMISSION_MODAL_INDEX:
            return {
                ...state,
                submissionModalIndex: action.payload,
            };
        case actions.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                loaded: true,
                questionSets: action.payload
            };
        case actions.SUBMIT:
            return {
                ...state,
                submitting: true,
            };
        case actions.SUBMIT_SUCCESS:
            return {
                ...state,
                submitting: false,
            };
        case actions.GET_SUBMISSION_DATA:
            return {
                ...state,
                submissionDataLoaded: false,
            };
        case actions.GET_SUBMISSION_DATA_SUCCESS:
            return {
                ...state,
                submissionData: action.payload,
                submissionDataLoaded: true,
            };
        default:
            return state;
    }
}
