import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ComposedChart, Bar, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip} from 'recharts';
import Spinner from '../../common/spinner'
import MenuTab from '../../common/menuTab';
import MenuTabItem from '../../common/menuTabItem';
import {getCompanyFinancialsAction} from '../../../ducks/financials/actions';
import DataNotFound from '../../common/dataNotFound';
import ErrorAlert from '../../common/errorAlert';

export class CompanyFinancialChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            revenue: true,
            operating_margin: true,
            net_income: true,
            earnings: true,
            avg: true
        }
        this.validateData = this.validateData.bind(this);
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.getCompanyFinancialsAction(this.props.companyId, 'annual');
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyFinancialsAction(this.props.companyId, 'annual');
        }
    }

    prepareData(data) {
        if (Object.keys(data).length === 0) {
            return {};
        }
        if(data.net_income && data.net_income.length > 3){
            data.net_income = data.net_income.slice(Math.max(data.net_income.length - 3, 1));
        }

        if(data.revenue && data.revenue.length > 3){
            data.revenue = data.revenue.slice(Math.max(data.revenue.length - 3, 1));
        }

        const netIncomeLength = data.net_income.length > 3 ? 3 : data.net_income.length;
        const revenueLength = data.revenue.length > 3 ? 3 : data.revenue.length;

        const length = Math.max(netIncomeLength, revenueLength);
        if (netIncomeLength != revenueLength) {
            const biggerObj = netIncomeLength > revenueLength ? data.net_income : data.revenue;
            let smallerObj = netIncomeLength > revenueLength ? data.revenue : data.net_income;

            for(let bigObj of  biggerObj) {
                const findObj = smallerObj.find((x) => x.x == bigObj.x);
                if (findObj === undefined) {
                    smallerObj.push({x: bigObj.x, y: 0});
                }
            }
            smallerObj = _.sortBy(smallerObj, "x");
            if (netIncomeLength > revenueLength) {
                data.revenue = smallerObj;
            } else {
                data.net_income = smallerObj;
            }
        }

        return _.times(length, (index) =>
            _.chain(data)
                // .omit(['peer_group_avg'])
                .mapValues(value => {
                    return value[index] ? Number(value[index].y) : 0;
                })
                .set('date', (data.revenue && data.revenue[index] ? data.revenue[index].x : 0))
                .value()
        );

    }

    renderCheckbox(key, label, className, boxElement) {
        return <label>
            <input type="checkbox"
                   checked={this.state[key]}
                   onChange={(event) => {
                       this.setState({[key]: event.target.checked});
                   }}
            />
            <span className={`block ${className}`}>{boxElement}</span>
            <span>{label}</span>
        </label>
    }

    renderBar(key, name, color) {
        if (!this.state[key]) {
            return null;
        }
        return <Bar dataKey={key} fill={color} barSize={20} name={name} />
    }

    validateData (data){
        let valid = true;
        if(Object.keys(data).length === 0 ){
            valid = false;
        } else {
            for(let d of data) {
                if(d.date === undefined){
                    valid = false;
                    break;
                }
            }
        }

        return valid;
    }

    render() {
        let content;
        if (this.props.error)  {
            content = <ErrorAlert message={this.props.error}/>
        } else {
            let chartData ;

            if (this.props.data){
                chartData = this.prepareData(this.props.data);
            }

            content = <Spinner loaded={this.props.data}>
            { (!chartData || Object.keys(chartData).length === 0 || !this.validateData(chartData)) ? <DataNotFound /> : <div className='row'>
                <div className='col-xs-3'>
                    <form>
                        {this.renderCheckbox('revenue', 'Revenue', 'blueishPurple')}
                        {this.renderCheckbox('net_income', 'Net Income', 'blue')}
                    </form>
                </div>
                <div className='col-xs-9'>
                    <div>
                        <MenuTab initialActiveTab={0}>
                            <MenuTabItem
                                action={() => this.props.getCompanyFinancialsAction(this.props.companyId, 'annual')}>
                                Annual
                            </MenuTabItem>
                            <MenuTabItem
                                action={() => this.props.getCompanyFinancialsAction(this.props.companyId, 'quarterly')}>
                                Quarterly
                            </MenuTabItem>
                        </MenuTab>
                        <ResponsiveContainer width={'100%'} height={344}>
                            <ComposedChart data={chartData}
                                        margin={{top: 40, right: 0, left: 0, bottom: 5}}>
                                <CartesianGrid vertical={false}/>
                                <YAxis axisLine={false} tickCount={10} tickLine={false}/>
                                <XAxis axisLine={false} dataKey='date' tickLine={false}/>
                                {this.renderBar('revenue', 'Revenue', '#292d80')}
                                {this.renderBar('net_income', 'Net Income', '#4680ff')}
                                <Tooltip/>
                            </ComposedChart>
                        </ResponsiveContainer>
                        
                    </div>
                </div>
            </div>}
            
        </Spinner>
        }
        return (
            <div className='panel panel-default'>
                <div className='panel-body financial with-spinner'>
                    <h2>Financials</h2>
                    <h4>(All values in million $)</h4>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'financials.data'),
        error: _.get(state, 'error.COMPANY_GET_FINANCIALS')
    };
}

export default connect(mapStateToProps, {
    getCompanyFinancialsAction
})(CompanyFinancialChart);