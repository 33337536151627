import types from './types';


export function getFiduciaryAction(companyId) {

    return {
        type: types.GET_PRIVATE_FIDUCIARY,
        companyId: companyId
    };
}
