import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getPredictor = (state) => state.predictor;

function* fetchPredictor(action) {
    let company = yield select(getPredictor);
    if (company.predictor === undefined) {
        try {
            const response = yield call(apigClientWrapper.getCompanyPredictors, action.companyId);
            yield put({ type: actions.GET_PREDICTOR_SUCCESS, payload: response.data });
        } catch (e) {
            yield put({ type: actions.GET_PREDICTOR_FAIL, ...apiFailMsg(e) });
        }

    }
}

export default [

    takeLatest(actions.GET_PREDICTOR, fetchPredictor),
]
