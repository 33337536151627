import actions from './types';

export function searchDocumentsAction(companyId, type) {
    return {
        type: actions.SEARCH,
        companyId: companyId,
        searchDocumentType: type
    }
}

export function getBadActorsAction(mscads, personId) {
    return {
        type: actions.BADACTORS_SEARCH,
        mscads: mscads,
        personId: personId
    }
}

export function selectDocumentAction(companyId, documentId, type) {

    return {
        type: actions.SELECT,
        companyId: companyId,
        documentId: documentId,
        documentType: type
    }
}

export function fetchDocumentAction(url) {
    console.log("----fetching: ", url);
    return {
        type: actions.FETCH,
        url: url
    }
}

export function clearDocumentAction() {
    return {
        type: actions.CLEAR
    }
}

export function openDocumentPDFAction(url) {
    return {
        type: actions.OPEN_PDF,
        url: url,
    }
}

export function openDocumentTxtAction(url, success) {
    return {
        type: actions.OPEN_TXT,
        url: url,
        success: success
    }
}

export function documentResetAction() {
    return {
        type: actions.RESET
    }
}