import _ from 'lodash';
import actions from './types';

const defaultState = {
    selected: {},
    selectedInSearch: {},
    detail: null
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                list: _.get(action, 'payload', null),
                searchInProgress: false
            };
        case actions.CLEAR:
            return {
                ...state,
                list: undefined,
                searchInProgress: false
            };
        case actions.SEARCH_FAIL:
            return {
                ...state,
                searchInProgress: false
            };
        case actions.SEARCH_INIT:
            return {
                ...state,
                searchInProgress: true
            };
        case actions.SELECT_COMPANY_ID:
            return {
                ...state,
                selectedInSearch: state.selectedInSearch.companyId === action.companyId ? state.selectedInSearch : { companyId: action.companyId, companyName: action.companyName }
            };
        case actions.SELECT_SUCCESS:
            return {
                ...state,
                selected: _.get(action, 'payload') || {}
            };
        case actions.DESELECT_ONLY_COMPANY:
            return {
                ...state,
                selected: {}
            };
        case actions.GET_DETAIL_SUCCESS:
            return {
                ...state,
                detail: _.get(action, 'payload.data')
            };
        case actions.DESELECT:
            return defaultState;


        default:
            return state;
    }
}
