import apigClientWrapper from '../../helpers/apigClientWrapper';
import axios from 'axios';
import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import types from './types';
import constants from '../../constants';
import { apiFailMsg } from '../../helpers/utility';

const getDocument = (state) => state.document;

const typeMap = constants.documentTypeMap;

function* documentSearch(action) {
  const queryParams = {
    documentType: typeMap[action.searchDocumentType]
  };
  let document = yield select(getDocument);

  if (document[action.searchDocumentType] === undefined) {
    try {
      const response = yield call(apigClientWrapper.getDocuments, action.companyId, { queryParams });
      response.data.type = action.searchDocumentType;
      yield put({ type: types.SEARCH_SUCCESS, payload: response, companyId: action.companyId });
    } catch (e) {

      yield put({ type: `${types.SEARCH_FAIL}`, ...apiFailMsg(e), docType: action.searchDocumentType });
    }
  }
}

function* documentSelect(action) {
  const queryParams = {
    documentType: typeMap[action.documentType]
  };
  try {
    const response = yield call(apigClientWrapper.getDocument, action.companyId, action.documentId, { queryParams });

    yield put({ type: types.SELECT_SUCCESS, payload: response });
  } catch (e) {

    yield put({ type: `${types.SELECT_FAIL}`, ...apiFailMsg(e) });
  }
}

function* documentFetch(action) {
  const start = new Date().getTime();

  try {
    const response = yield call(axios.get, action.url);
    const end = new Date().getTime();
    const time = end - start;
    yield put({ type: types.FETCH_SUCCESS, url: action.url, payload: response, processTime: time });
  }
  catch (error) {
    yield put({ type: types.FETCH_FAILED, error: error });
  }
}

function* badactorsSearch(action) {
  let document = yield select(getDocument);
  const queryParams = {
    mscads: action.mscads
  };
  const person = document.persons === undefined ? undefined : document.persons[action.personId];
  if (person === undefined) {
    try {
      const response = yield call(apigClientWrapper.getBadActors, { queryParams })
      yield put({ type: types.BADACTORS_SEARCH_SUCCESS, payload: response.data, personId: action.personId });
    } catch (e) {
      yield put({ type: `${types.BADACTORS_SEARCH_FAIL}`, ...apiFailMsg(e), docType: action.searchDocumentType });
    }
  }
}

export default [
  takeEvery(types.SEARCH, documentSearch),
  takeLatest(types.BADACTORS_SEARCH, badactorsSearch),
  takeEvery(types.FETCH, documentFetch),
  takeLatest(types.SELECT, documentSelect),
]