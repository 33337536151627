export default {
    GET: 'PROGRAM_GET',
    GET_EXPIRED: 'PROGRAM_GET_EXPIRED',
    GET_SUCCESS: 'PROGRAM_GET_SUCCESS',
    GET_FAIL: 'PROGRAM_GET_FAIL',
    GET_EXPIRED_SUCCESS: 'PROGRAM_GET_EXPIRED_SUCCESS',
    GET_EXPIRED_FAIL: 'PROGRAM_GET_EXPIRED_FAIL',
    CREATE: 'PROGRAM_CREATE',
    UPDATE: 'PROGRAM_UPDATE',
    UPDATE_SUCCESS: 'PROGRAM_UPDATE_SUCCESS',
    UPDATE_FAIL: 'PROGRAM_UPDATE_FAIL',
    CLEAR_DATA: 'PROGRAM_CLEAR_DATA',
};