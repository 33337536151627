import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';

import reducers from './reducers';

import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// Todo: Remove 'window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' before build for production

const sagaMiddleware = createSagaMiddleware()

const store = createStore(reducers, composeEnhancers(
    applyMiddleware(promise),
    applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(rootSaga);

export default store;
