import React from 'react';

const ExpandImage = () => {
    return <svg id="bbb" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
            <style>
                {`.expand-cls-1{fill:#ddd;}
                .expand-cls-2{fill:#fff;fillRule:evenodd;}`}</style>
        </defs>
        <title>Expand</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <circle className="expand-cls-1 change-color-in-this-element" cx="50" cy="50" r="50"/>
                <path className="expand-cls-2"
                      d="M62.8,34H57.14a2,2,0,0,1-1.4-.59A2,2,0,0,1,55.16,32h0a2,2,0,0,1,.58-1.42,2,2,0,0,1,1.4-.59H67.68a1.93,1.93,0,0,1,1.36.57h0A2,2,0,0,1,69.6,32V42.61A2,2,0,0,1,69,44a2,2,0,0,1-1.4.59h0a2,2,0,0,1-2-2V36.88l-7.33,7.41a2,2,0,0,1-2.83,0h0a2,2,0,0,1,0-2.87Zm0,31.58-7.32-7.41a2,2,0,0,1,0-2.87h0a2,2,0,0,1,2.83,0l7.33,7.41V57a2,2,0,0,1,2-2h0a2,2,0,0,1,1.4.59A2,2,0,0,1,69.6,57V67.65A2,2,0,0,1,69,69a1.93,1.93,0,0,1-1.36.57H57.14a2,2,0,0,1-1.4-.59,2,2,0,0,1-.58-1.42h0a2,2,0,0,1,.58-1.41,2,2,0,0,1,1.4-.59ZM36.8,34l7.32,7.41a2,2,0,0,1,0,2.87h0a2,2,0,0,1-2.83,0L34,36.88v5.73a2,2,0,0,1-2,2h0a2,2,0,0,1-1.4-.59A2,2,0,0,1,30,42.61V32a2,2,0,0,1,.56-1.38h0A1.93,1.93,0,0,1,31.92,30H42.46a2,2,0,0,1,1.4.59A2,2,0,0,1,44.44,32h0a2,2,0,0,1-.58,1.41,2,2,0,0,1-1.4.59Zm0,31.58h5.66a2,2,0,0,1,1.4.59,2,2,0,0,1,.58,1.41h0A2,2,0,0,1,43.86,69a2,2,0,0,1-1.4.59H31.92A1.93,1.93,0,0,1,30.56,69,2,2,0,0,1,30,67.65V57a2,2,0,0,1,.58-1.41A2,2,0,0,1,32,55h0a2,2,0,0,1,2,2v5.73l7.33-7.41a2,2,0,0,1,2.83,0h0a2,2,0,0,1,0,2.87Z"/>
            </g>
        </g>
    </svg>;
};
export default ExpandImage;
