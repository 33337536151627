import * as XLSX from 'xlsx';
import {
    saveAs
} from 'file-saver';

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i);
    }
    return buf;
}

const convertFromDynamicTable = (table) => {
    const data = table.table;
    const dataContainer = [];
    const colums = []
    for (let c of data.columns) {
        colums.push(c.title);
    }

    dataContainer.push(colums);

    for (let d of data.data) {
        const dc = [];
        for (let c of data.columns) {
            if (typeof d[c.field] === 'string') {
                dc.push(d[c.field]);
            } else if (typeof d[c.field] === 'object' && d[c.field].props && d[c.field].props.value) {
                dc.push(d[c.field].props.value);
            } else {
                dc.push('');
            }
        }
        dataContainer.push(dc);
    }
    return dataContainer;
}

const saveExcel = (data, fileName) => {
    /* generate worksheet */
    const ws = XLSX.utils.aoa_to_sheet(data);

    /* generate workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary'
    });
    saveAs(new Blob([s2ab(wbout)]), fileName);
}

export default {
    saveExcel,
    convertFromDynamicTable
}