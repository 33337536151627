import _ from '../../../lodashWrapper';
import React from 'react';
import { Link } from "react-router-dom";

import Card from '../card';

import DotImage from '../../images/dot';
import DocImage from '../../images/doc';
import DataNotFound from '../dataNotFound';
import ExpandImage from '../../images/expand';
import moment from 'moment';

const RiskFiling = (props) => {
    const filter_date = props.filter && props.filter.date && props.filter.date.value
    const filter_flags = props.filter && props.filter.flags && props.filter.flags.length && new Set(_.map(props.filter.flags, 'label').map(f => f.toLowerCase())) || []

    var data = filter_date ? props.data.filter(d => moment(d.filingDate).isAfter(filter_date)) : props.data
    data = filter_flags.length < 1 ?
        data :
        data.filter(
            d =>
                Object.values(d.notifications).filter(
                    flag => filter_flags.has(flag.toLowerCase())
                ).length > 0
        )
    return (
        <div className={`common-element risk-filing ${props.className}`}>
            {_.map(data, (item, key) => {
                const url = `/company/${props.companyId}/document/${item.document_id}?type=${item.type}`;
                const flags = item.notifications

                return <Link key={key} to={url} target="_blank">
                    <Card className='risk-item'>
                        <div className='risk-icon'><DocImage type={item.type} /></div>
                        <div className='risk-name'>{item.name}</div>
                        <div className='risk-flag'>
                            {_.map(flags, (item, key) =>
                                <div className="positive" key={key}><DotImage />{item}</div>
                            )}
                        </div>
                        <div className='risk-date'>
                            <span>{item.filingDate}</span>
                            <small>Filing Date</small>
                        </div>
                        <div className='risk-right hovering'><ExpandImage /></div>
                    </Card>
                </Link>
            })}
            {data.length === 0 && <DataNotFound />}
        </div>
    );
};

export default RiskFiling;
