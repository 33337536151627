import apigClientWrapper from '../../helpers/apigClientWrapper';
import { delay } from 'redux-saga/effects'
import { put, takeEvery, call, select } from 'redux-saga/effects';
import types from './types';
import worksheetTypes from '../worksheet/types';
import _ from 'lodash';
import { apiFailMsg } from '../../helpers/utility';

const getWorksheet = (state) => state.worksheet;

function* getProgramAction(action) {
    let worksheet = yield select(getWorksheet);
    const companyId = action.companyId ? action.companyId : worksheet.selected.cik;
    const queryParams = {
        worksheet_id: worksheet.selected.worksheet_id
    };

    try {
        const response = yield call(apigClientWrapper.getProgram, companyId, { queryParams });
        yield put({ type: types.GET_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.GET_FAIL, ...apiFailMsg(e) });
    }

}

function* getProgramWithDelayAction(action) {
    yield delay(1000);
    let worksheet = yield select(getWorksheet);
    const companyId = action.companyId ? action.companyId : worksheet.selected.cik;
    const queryParams = {
        worksheet_id: worksheet.selected.worksheet_id
    };

    try {
        const response = yield call(apigClientWrapper.getProgram, companyId, { queryParams });
        yield put({ type: types.GET_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.GET_FAIL, ...apiFailMsg(e) });
    }
}

function* getExpiredProgramAction(action) {
    const worksheet = yield select(getWorksheet);
    const companyId = action.companyId ? action.companyId : worksheet.selected.cik;

    const queryParams = {
        type: 'expired',
        worksheet_id: worksheet.selected.worksheet_id
    };

    try {
        const response = yield call(apigClientWrapper.getProgram, companyId, { queryParams });
        yield put({ type: types.GET_EXPIRED_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.GET_EXPIRED_FAIL, ...apiFailMsg(e) });
    }

}

function* getExpiredProgramsAction(action) {
    yield delay(2000);

    const worksheet = yield select(getWorksheet);
    const companyId = action.companyId ? action.companyId : worksheet.selected.cik;

    const queryParams = {
        type: 'expired',
        worksheet_id: worksheet.selected.worksheet_id
    };

    try {
        const response = yield call(apigClientWrapper.getProgram, companyId, { queryParams });
        yield put({ type: types.GET_EXPIRED_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.GET_EXPIRED_FAIL, ...apiFailMsg(e) });
    }

}

function* create(action) {
    const { companyId,
        program,
        worksheet_id,
        effective_date } = action;

    const queryParams = {
        tower_size: program.tower_size || 0,
        num_layers: program.num_layers || 0,
        retention: program.retention || 0,
        worksheet_id: worksheet_id,
        effective_date: effective_date,
        table: {
            columns: program.table.columns,
            data: _.map(program.table.data, row => ({
                layer: row.layer,
                carrier: row.carrier || '-',
                other_carrier: row.other_carrier,
                limit: row.limit && String(row.limit) || '0',
                premium: row.premium && String(row.premium) || '0',
            }))
        }
    };

    try {
        const response = yield call(apigClientWrapper.createProgram, companyId, queryParams);
        yield put({ type: types.UPDATE_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.UPDATE_FAIL, ...apiFailMsg(e) });
    }

}

function* update(action) {
    const { companyId,
        program } = action;
    const queryParams = {
        tower_size: program.tower_size || 0,
        num_layers: program.num_layers || 0,
        retention: program.retention || 0,
        worksheet_id: program.worksheet_id,
        program_id: program.program_id,
        effective_date: program.effective_date,
        table: {
            columns: program.table.columns,
            data: _.map(program.table.data, row => ({
                layer: row.layer,
                carrier: row.carrier || '-',
                other_carrier: row.other_carrier,
                limit: row.limit && String(row.limit) || '0',
                premium: row.premium && String(row.premium) || '0',
            }))
        }
    };

    try {
        const response = yield call(apigClientWrapper.updateProgram, companyId, queryParams);
        yield put({ type: types.UPDATE_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.UPDATE_FAIL, ...apiFailMsg(e) });
    }
}

export default[
    takeEvery(types.GET, getProgramAction),
    takeEvery(types.GET_EXPIRED, getExpiredProgramAction),
    takeEvery(types.UPDATE_SUCCESS, getProgramWithDelayAction),
    takeEvery(types.UPDATE_SUCCESS, getExpiredProgramsAction),
    takeEvery(types.UPDATE, update),
    takeEvery(types.CREATE, create),
    takeEvery(worksheetTypes.UPDATE_SUCCESS, getProgramWithDelayAction),
    takeEvery(worksheetTypes.UPDATE_SUCCESS, getExpiredProgramsAction)
]