import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Line, LineChart, ReferenceDot, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import _ from '../../../../lodashWrapper';

export class StockChart extends Component {

    shouldComponentUpdate(nextProps) {
        return !_.isEqual(this.props.data, nextProps.data) ||
            !_.isEqual(this.props.visibleData, nextProps.visibleData) ||
            this.props.focusedX !== nextProps.focusedX
    }

    updatePos(dateObject) {
        this.setState({
            'date': dateObject
        });
    }

    formula(price, base) {
        const rate = (price - base) / base * 100;

        return Math.round(rate * 100) / 100;
    }

    calcpercentage(data) {
        const res = [];
        let basecompany, basedowj, basenasdaq, basesp500;
        let firstLoop = true;
        for (let d of data) {
            const clone = Object.assign({}, d, {
                company_price: d.company,
                dowj_price: d.dowj,
                nasdaq_price: d.nasdaq,
                sp500_price: d.sp500,
            });
            if (firstLoop === true) {
                firstLoop = false;
                basecompany = d.company;
                basedowj = d.dowj;
                basenasdaq = d.nasdaq;
                basesp500 = d.sp500;
            }
            const isNasdaqVisible = this.props.visibleData.nasdaq;
            const isDowjVisible = this.props.visibleData.dowj;
            const isSp500Visible = this.props.visibleData.sp500;
            const isIndexVisible = isNasdaqVisible || isDowjVisible || isSp500Visible;

            clone.company_price = clone.company;
            clone.dowj_price = clone.dowj;
            clone.nasdaq_price = clone.nasdaq;
            clone.sp500_price = clone.sp500;

            if (isIndexVisible) {
                clone.company = this.formula(clone.company, basecompany);
            }

            clone.dowj = this.formula(clone.dowj, basedowj);
            clone.nasdaq = this.formula(clone.nasdaq, basenasdaq);
            clone.sp500 = this.formula(clone.sp500, basesp500);

            res.push(clone);
        }
        return res;
    }

    render() {

        const isNasdaqVisible = this.props.visibleData.nasdaq;
        const isDowjVisible = this.props.visibleData.dowj;
        const isSp500Visible = this.props.visibleData.sp500;
        const isIndexVisible = isNasdaqVisible || isDowjVisible || isSp500Visible;
        const isFocusedX = _.isNumber(this.props.focusedX) && 0 <= this.props.focusedX;
        const focusedX = this.props.focusedX;

        const chartContainerWidth = this.props.chartWidth ? this.props.chartWidth : "100%";
        return (
            <ResponsiveContainer width={chartContainerWidth} height={600}>
                <LineChart data={this.calcpercentage(this.props.data)}
                    margin={{ top: 130, left: -20, right: 0 }}
                    padding={{ top: 10, bottom: 10 }}
                    onMouseLeave={() => {
                        if (this.props.onMouseLeave) {
                            this.props.onMouseLeave();
                        }
                    }}
                    onMouseMove={point => {
                        const pointObj = _.get(point, 'activePayload[0].payload');
                        if (pointObj) {
                            this.props.onMouseMove(pointObj.index);
                            this.updatePos(pointObj.dateObject);
                        }
                    }}
                >
                    <XAxis dataKey="date" tickLine={false} tickMargin={10} interval={0} padding={{ right: 15 }} />
                    <YAxis yAxisId="stock" unit={isIndexVisible ? '%' : ''} tickLine={false} tickMargin={10} interval={0} axisLine={false} domain={['auto', 'auto']} />
                    {/* <YAxis yAxisId="stock" hide={!isIndexVisible} orientation="right" tickLine={false} tickMargin={10} interval={0} axisLine={false} domain={['auto', 'auto']}/>
                <YAxis yAxisId="stock" hide={!isIndexVisible} orientation="right" tickLine={false} tickMargin={10} interval={0} axisLine={false} domain={['auto', 'auto']}/>
                <YAxis yAxisId="stock" hide={!isIndexVisible} orientation="right" tickLine={false} tickMargin={10} interval={0} axisLine={false} domain={['auto', 'auto']}/> */}

                    <Line yAxisId="stock" type="linear" dataKey="company" stroke="#4680ff" strokeWidth="2"
                        isAnimationActive={false} dot={false} />
                    {isNasdaqVisible && <Line yAxisId="stock" type="linear" dataKey="nasdaq" stroke="#292d80" strokeWidth="2"
                        isAnimationActive={false} dot={false} />}
                    {isDowjVisible && <Line yAxisId="stock" type="linear" dataKey="dowj" stroke="#f36539" strokeWidth="2"
                        isAnimationActive={false} dot={false} />}
                    {isSp500Visible && <Line yAxisId="stock" type="linear" dataKey="sp500" stroke="#3db7e4" strokeWidth="2"
                        isAnimationActive={false} dot={false} />}
                    {isFocusedX && <ReferenceLine yAxisId="stock" x={focusedX} stroke="#dbdbdb" strokeWidth={1.5} />}
                    {isFocusedX && <ReferenceDot yAxisId="stock" x={focusedX} y={_.get(this.props, `data[${focusedX}].company`)} r={4}
                        fill="#4680ff" stroke="#ffffff" strokeWidth={2} />}
                    {isFocusedX && isNasdaqVisible && <ReferenceDot yAxisId="stock"
                        x={focusedX}
                        y={_.get(this.props, `data[${focusedX}].nasdaq`)}
                        r={4} fill="#292d80" stroke="#ffffff" strokeWidth={2} />}
                    {isFocusedX && isDowjVisible && <ReferenceDot yAxisId="stock" x={focusedX}
                        y={_.get(this.props, `data[${focusedX}].dowj`)} r={4}
                        fill="#f36539" stroke="#ffffff" strokeWidth={2} />}
                    {isFocusedX && isSp500Visible && <ReferenceDot yAxisId="stock" x={focusedX}
                        y={_.get(this.props, `data[${focusedX}].sp500`)} r={4}
                        fill="#3db7e4" stroke="#ffffff" strokeWidth={2} />}
                        
                    <Tooltip formatter={(value, name, props) => {
                        const displayValue = isIndexVisible ? props.payload[name + '_price'] : props.payload[name];
                        return <NumberFormat
                            value={displayValue}
                            thousandSeparator={true}
                            displayType={'text'}
                        />;
                    }} labelFormatter={()=> {
                        if (this.state && this.state.date !== null) {
                            return this.state.date.format('MM/DD/YYYY')}
                        }
                    }  />
                </LineChart>
            </ResponsiveContainer>
        );
    }
}
