import types from './types';


export function getCompanyByIdAction(companyId) {
    return {
        type: types.GET_PRIVATE,
        companyId: companyId
    };
}

export function setCompanyIdAction(companyId) {
    return {
        type: types.SET_PRIVATE_SELECTED_COMPANYID,
        companyId: companyId
    };
}
