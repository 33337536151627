import _ from 'lodash';
import { call, put, takeLatest, all } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import { default as types } from './types';

function* getDnO({ companyId, companyName, submissionId, zipCode }) {
  try {
    const params = {
      companyName,
      ...(submissionId !== null ? { submission_id: submissionId } : {}),
      ...(zipCode !== null ? { company_zip: zipCode } : {}),
    };
    const [model] = yield all([
      call(apigClientWrapper.getDnoModel, params),
    ]);

    yield put({
      type: types.GET_PRIVATE_COMPANY_DNO_SUCCESS,
      payload: {
        predictions: _.get(model, 'data.predictions') || [],
      },
      companyId,
    });
  } catch (e) {
    console.log(e, '---', apiFailMsg(e));
    yield put({
      type: types.GET_PRIVATE_COMPANY_DNO_FAIL,
      ...apiFailMsg(e),
    });
  }
}

function* getSnP({ companyId, companySnpId, submissionId }) {
  try {
    const params = {
      companyId: companySnpId,
      ...(submissionId !== null ? { submission_id: submissionId } : {}),
    };
    const [balance, income, score] = yield all([
      call(apigClientWrapper.getSnpBalanceSheet, params),
      call(apigClientWrapper.getSnpIncomeStatement, params),
      call(apigClientWrapper.getSnpScore, params),
    ]);

    yield put({
      type: types.GET_PRIVATE_COMPANY_SNP_SUCCESS,
      payload: {
        balance: _.get(balance, 'data.BalanceSheet') || {},
        income: _.get(income, 'data.IncomeStatement') || {},
        score: _.get(score, 'data') || {},
      },
      companyId,
    });
  } catch (e) {
    yield put({
      type: types.GET_PRIVATE_COMPANY_SNP_FAIL,
      ...apiFailMsg(e),
    });
  }
}

export default [
  takeLatest(types.GET_PRIVATE_COMPANY_DNO, getDnO),
  takeLatest(types.GET_PRIVATE_COMPANY_SNP, getSnP),
];
