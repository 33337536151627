import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Spinner from '../../common/spinner'
import Loading from '../../common/loading'
import DynamicTable from '../../common/dynamicTable';
import Select from '../../common/select';
import Button from '../../common/button';
import PlusImage from '../../images/plus';
import { getProgramAction, updateProgramAction, createProgramAction, clearDataProgramAction } from '../../../ducks/program/actions';
import { calcAttachment, calcRPM, calcROL, calcModelTechnicalPrice, calcMinmumTechnicalPrice } from '../../../helpers/riskCalculator';
import constants from '../../../constants';
import DataNotFound from "../../common/dataNotFound";
import excel from "../../../helpers/excel";
import moment from 'moment';
import ErrorAlert from '../../common/errorAlert';
import * as XLSX from 'xlsx';

const OTHER = 'Other';
export class CompanyCoverageProgram extends Component {
    constructor(props) {
        super(props);
        this.state = { data: { table: { data: [{}] } } };
        this.loadData = this.loadData.bind(this);
        this.isEditable = this.isEditable.bind(this);
        this.apiCalled = false;
        this.overrall = 'Overall Tower Size (If Known)';
        this.totalLayers = 'Total Layers (If Known)';
        this.retention = 'Retention';
    }

    componentDidMount() {
        if (this.props.worksheetInitialized === true && this.props.companyId) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        this.props.clearDataProgramAction();
    }

    loadData() {
        if (this.apiCalled === false) {
            this.apiCalled = true;
            this.props.getProgramAction(this.props.companyId);
        }
    }

    isEditable() {
        return (this.props.worksheet !== undefined && this.props.worksheet.worksheet_id !== undefined) || (this.props.data !== undefined && this.props.data.worksheet_id !== undefined);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.allDataLoaded === true && (!_.isEqual(prevProps.allDataLoaded, this.props.allDataLoaded) || !_.isEqual(prevState.data, this.state.data))) {
            try {
                this.setState({
                    displayData: this.prepareData()
                })
            } catch (error) {
                console.error(error);
            }
            if (!this.state.data && this.props.data && this.props.data) {
                this.setState({
                    data: this.props.data
                })
            }
            if (this.state.disableForm === undefined && this.props.isEmptyTable === false) {
                this.setState(
                    { disableForm: true }
                );
            }
        }
        if (this.props.worksheetInitialized === true && this.props.companyId) {
            this.loadData();
        }
    }

    appendColumn(table = [], column, position = table.length) {
        if (table.table && table.table.columns) {
            table.table.columns.splice(position, 0, column)
        }
        return table;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data && !_.isEqual(nextProps.data, this.props.data)) {
            this.setState({
                data: this.appendColumn(nextProps.data.table, {
                    title: "AvT",
                    field: "avt",
                    tooltip: "Premium/Techincal Price",
                }, 6)
            });
        }
    }

    getClassNameByRisk(risk) {
        return risk > .045 ? "high" : (risk >= .0175 ? "medium" : (risk >= .01 ? "low" : "xlow"));
    }

    prepareRow(row, index) {
        const layers = [
            'Primary',
            '1st XS',
            '2nd XS',
            '3rd XS',
            '4th XS',
            '5th XS',
            '6th XS',
            '7th XS',
            '8th XS',
            '9th XS',
            '10th XS'
        ];
        const carrierOptions = this.props.carriers.sort().map((x) => {
            return { value: x, label: x };
        })

        const isEditable = this.isEditable();

        return {
            ...row,
            layer: row.layer || layers[index] || index + 'th XS',
            carrier: isEditable ? <div><Select
                className="width-small"
                value={_.find(carrierOptions, { label: row.carrier })}
                options={carrierOptions}
                onChange={(value) => {
                    this.setState({ data: _.chain(this.state.data).cloneDeep().set(`table.data[${index}].carrier`, value === null ? '' : value.label).value() })
                }} />
                {row.carrier === OTHER && <input value={row.other_carrier} placeholder="Enter Carrier" style={{ marginTop: '10px' }} type="text" onChange={(value) => {
                    this.setState({ data: _.chain(this.state.data).cloneDeep().set(`table.data[${index}].other_carrier`, value === null ? '' : value.target.value).value() })
                }} />}
            </div> : row.carrier,
            limit: <NumberFormat
                value={row.limit || ''}
                thousandSeparator={true}
                prefix={'$'}
                displayType={isEditable ? 'input' : 'text'}
                onValueChange={(value) => {
                    this.setState({ data: _.chain(this.state.data).cloneDeep().set(`table.data[${index}].limit`, value.floatValue).value() })
                }} />,
            attachment: row.attachment ? <NumberFormat
                value={row.attachment || ''}
                thousandSeparator={true}
                displayType='text'
                prefix={'$'} /> : '-',
            premium: <NumberFormat
                value={row.premium || ''}
                thousandSeparator={true}
                prefix={'$'}
                displayType={isEditable ? 'input' : 'text'}
                onValueChange={(value) => {
                    this.setState({ data: _.chain(this.state.data).cloneDeep().set(`table.data[${index}].premium`, value.floatValue).value() })
                }} />,
            technicalPrice: <NumberFormat
                value={row.technicalPrice || '-'}
                thousandSeparator={true}
                prefix={'$'}
                displayType={'text'}
            />,
            avt: <NumberFormat
                value={row.avt || ''}
                displayType={'text'}
            />,
            rpm: <NumberFormat
                value={row.rpm || ''}
                thousandSeparator={true}
                displayType={'text'}
            />,
            rol: <NumberFormat
                value={row.rol || ''}
                thousandSeparator={true}
                suffix={'%'}
                displayType={'text'}
            />,
            del: isEditable && index != 0 ? <img
                src="/images/x.svg"
                onClick={() => {
                    if (this.state.disableForm === true) {
                        return;
                    }
                    const newValues = _.cloneDeep(this.state.data);
                    newValues.table.data[index] = null;
                    newValues.table.data = _.compact(newValues.table.data);
                    this.setState({ data: newValues });
                    this.prepareData();

                }} /> : ''
        };
    }

    prepareData() {
        if (!this.props.data) {
            return;
        }
        const values = this.state.data;

        if (!values.table) {
            return;
        }

        if (values.table && values.table.data && values.table.data.length > 0) {
            for (let i = 0; i < values.table.data.length; i++) {
                if (i === 0) {
                    values.table.data[i].attachment = values.retention;
                    values.table.data[i].rpm = calcRPM(values.table.data[i].limit, values.table.data[i].premium);
                } else {
                    // const attachmentValue = i === 1 ? 0 : values.table.data[i - 1].attachment;
                    const attachmentValue = values.table.data[i - 1].attachment;
                    values.table.data[i].attachment = calcAttachment(
                        values.table.data[i - 1].limit,
                        attachmentValue
                    );
                }
                values.table.data[i].rpm = calcRPM(values.table.data[i].limit, values.table.data[i].premium);
                if (i !== 0) {
                    values.table.data[i].rol = calcROL(values.table.data[i].rpm, values.table.data[i - 1].rpm);
                }

                if (typeof values.table.data[i].limit === 'string') {
                    values.table.data[i].limit = parseInt(values.table.data[i].limit);
                }

                if (typeof values.table.data[i].premium === 'string') {
                    values.table.data[i].premium = parseInt(values.table.data[i].premium);
                }

                // const attachmentProb = this.props.attachmentData.settlement_probabilities_table.data[i].company;

                // const modelTechnicalPrice = calcModelTechnicalPrice(values.table.data[i].limit, attachmentProb);
                // const minumumTechnicalPrice = calcMinmumTechnicalPrice(values.table.data[i].limit);
                // values.table.data[i].technicalPrice = Math.max(modelTechnicalPrice, minumumTechnicalPrice);

                try {
                    let index = parseInt(values.table.data[i].attachment / 1000000);
                    if (!isNaN(index)) {
                        let exactMatch = false;
                        if (index > 20) {
                            // 25 -> 21
                            // 
                            index = this.props.attachmentData.settlement_probabilities_table.data.findIndex(x => {
                                if (x.item === 'Primary') {
                                    return false;
                                }

                                const item = parseInt(x.item.replace(/\D/g, ''));
                                if (item === index) {
                                    exactMatch = true;
                                    return true;
                                }
                                return (item > index)
                            });

                            if (index > this.props.attachmentData.settlement_probabilities_table.data.length) {
                                index = this.props.attachmentData.settlement_probabilities_table.data.length - 1;
                            } else if (exactMatch !== true) {
                                index = index - 1;
                            }

                        }

                        const attachmentProb = this.props.attachmentData.settlement_probabilities_table.data[index].company;
                        values.table.data[i].attachmentProb = attachmentProb;

                        let multi = 1;
                        if (this.props.attachmentData.model === 'ipo_yr2') {
                            multi = 4;
                        }
                        const modelTechnicalPrice = calcModelTechnicalPrice(values.table.data[i].limit, attachmentProb, multi);
                        const minumumTechnicalPrice = calcMinmumTechnicalPrice(values.table.data[i].limit);
                        values.table.data[i].technicalPrice = Math.max(modelTechnicalPrice, minumumTechnicalPrice);

                        const avt = (values.table.data[i].premium/values.table.data[i].technicalPrice);
                        values.table.data[i].avt = avt.toFixed(2);

                        const attachmentValue = this.props.attachmentData.settlement_probabilities_table.data[index].company;
                        values.table.data[i].attachmentClassName = this.getClassNameByRisk(attachmentValue);
                        values.table.data[i].technicalPriceClassName = !Number.isNaN(avt) && ((avt > 1.3) ? "xlow" : ((avt > .75) ? "low" : ((avt > .5) ? "medium" : "high")));
                    } else {
                        values.table.data[i].attachmentClassName = '';
                        values.table.data[i].technicalPriceClassName = '';
                    }

                } catch (e) {
                    console.log(e);
                }

            }
        } else {
            return;
        }

        return {
            ...values,
            table: {
                columns: values.table.columns.concat([{ field: 'del' }]),
                data: _.map(values.table.data, (row, index) => this.prepareRow(row, index))
            }
        }
    }

    excelDownload(data) {
        if (data) {
            const tableData = [];
            if (data.table) {
                const colums = []
                for (let c of data.table.columns) {
                    colums.push(c.title);
                }
                tableData.push(colums);
            }
            const arr = excel.convertFromDynamicTable(this.prepareData(data), 'sheet.xlsx');

            const top = [this.overrall, this.totalLayers, this.retention];

            let res = [];

            res.push(top);
            res.push([data.tower_size, data.num_layers, data.retention]);
            res.push([]); // extra line
            res = res.concat(arr);

            const dt = new Date();
            const date = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();
            const fileName = date + ' Coverage Program Details ' + this.props.company.name.replace(/\./g, '') + '.xlsx';

            excel.saveExcel(res, fileName);
        }
    }

    isWorksheetIdEmpty() {
        let worksheetIdEmpty = false;
        if (!window.location.search) {
            return true;
        } else if (this.props.worksheetInitialized === true) {
            worksheetIdEmpty = Object.keys(this.props.worksheet).length > 0 ? false : true;
        }
        return worksheetIdEmpty;
    }

    render() {

        const showSubmitBtn = this.isEditable();
        const showDatNotFound = showSubmitBtn === false && this.props.data && this.props.data.table.empty === true;
        const worksheetIdEmpty = this.isWorksheetIdEmpty();

        let content;

        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />
        } else if (this.props.isPDF !== true && worksheetIdEmpty) {
            content = <div className="text-danger">This is the View Only mode, please create a worksheet to enter the coverage program details.</div>;
        } else {
            content = <Spinner loaded={this.props.loaded}>
                <div className={this.state.disableForm === true ? 'form-disabled' : ''}>
                    {this.state.displayData && <div>
                        {showDatNotFound ? <DataNotFound /> : <div className='row top-row'>
                            <fieldset disabled={this.state.disableForm === true ? 'disabled' : ''}>
                                <div className='col-xs-3'>
                                    <label>
                                        <div>{this.overrall}</div>
                                        <NumberFormat value={this.state.data.tower_size || ''}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            displayType={showSubmitBtn ? 'input' : 'text'}
                                            onValueChange={(value) => {
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        tower_size: value.floatValue
                                                    }
                                                })
                                            }} />
                                    </label>
                                </div>
                                <div className='col-xs-3'>
                                    <label>
                                        <div>{this.totalLayers}</div>
                                        <NumberFormat value={this.state.data.num_layers || ''}
                                            displayType={showSubmitBtn ? 'input' : 'text'}
                                            onChange={(event) => {
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        num_layers: Number(event.target.value)
                                                    }
                                                })
                                            }} />
                                    </label>
                                </div>
                                <div className='col-xs-3'>
                                    <label>
                                        <div>{this.retention}</div>
                                        <NumberFormat value={this.state.data.retention || ''}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            displayType={showSubmitBtn ? 'input' : 'text'}
                                            onValueChange={(value) => {
                                                this.setState({
                                                    data: {
                                                        ...this.state.data,
                                                        retention: value.floatValue
                                                    }
                                                })
                                            }} />
                                    </label>
                                </div>
                            </fieldset>
                        </div>}
                        <div className={'row'}>
                            <div className='col-xs-12'>
                                {!showDatNotFound && <div>
                                    <fieldset disabled={this.state.disableForm === true ? 'disabled' : ''}>
                                        <DynamicTable className="table last program-table" data={this.state.displayData} />
                                    </fieldset>
                                    {showSubmitBtn && <div className='add-layer' onClick={() => {
                                        if (this.state.disableForm === true) {
                                            return;
                                        }
                                        let newValues = _.cloneDeep(this.state.data);
                                        newValues.table.data.push({});
                                        this.setState({ data: newValues });
                                    }}>
                                        <PlusImage /><span>Add a layer</span>
                                    </div>}
                                </div>}

                                {showSubmitBtn && <div className='submit'>
                                    {this.props.processing !== true && <div className="button-container">
                                        {this.state.disableForm === true ? <Button
                                            onClick={() => {
                                                this.setState({ disableForm: !this.state.disableForm });
                                            }}
                                        >Edit</Button> : <Button
                                            onClick={() => {
                                                let newValues = _.cloneDeep(this.state.data);

                                                if (this.props.worksheet !== undefined) {
                                                    if (newValues.program_id) {
                                                        this.props.updateProgramAction(this.props.companyId, newValues);
                                                    } else {
                                                        this.props.createProgramAction(this.props.companyId, newValues, this.props.worksheet.worksheet_id, this.props.worksheet.submission.effective_date);
                                                    }
                                                }
                                                this.setState({ disableForm: true });
                                            }}>Submit</Button>}

                                        <div className="common-element button excel-download-btn">
                                            <Button type="button" onClick={() => {
                                                this.excelDownload(this.state.displayData);
                                            }}>Download as Excel</Button>
                                        </div>

                                        <div className="common-element button excel-download-btn">
                                            <Button type="button" onClick={() => {
                                                this.inputElement.click();
                                            }}>Import from Excel</Button>
                                            <input className="hidden" ref={input => this.inputElement = input} type="file" onChange={(e) => {
                                                this.readExcel(e);
                                            }} />
                                        </div>
                                    </div>}
                                    {this.props.processing === true && <Loading />}
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
            </Spinner>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body coverage with-spinner'>
                    <h2>Coverage Program Details {this.props.worksheet && this.props.worksheet.submission && <span>{moment(this.props.worksheet.submission.effective_date).format('MM/DD/YYYY')}</span>}</h2>
                    {content}
                </div>
            </div>
        );
    }

    processExcelData(excelData) {
        for (let d of excelData) {
            let carrier = d.carrier.replace(/^(\d)+\s-\s/gi, '').trim().toLocaleLowerCase();

            let item = this.props.carriers.filter(x => x.toLocaleLowerCase() === carrier);

            if (item.length === 0) {
                const pieces = carrier.split(' ');
                const skipWords = ['company', 'group', 'insurance'];
                for (let p of pieces) {
                    if (skipWords.indexOf(p.toLowerCase()) !== -1) {
                        continue;
                    }
                    // find partial match by word: excel -> list
                    item = this.props.carriers.filter((x) => {
                        return x.toLocaleLowerCase().split(' ').includes(p);
                    });

                    if (item.length === 1) {
                        break;
                    }
                    // find partial match by substring
                    item = this.props.carriers.filter((x) => {
                        return x.toLocaleLowerCase().includes(p);
                    });
                    if (item.length === 1) {
                        break;
                    }
                }
            }

            if (item.length === 1) {
                d.carrier = item[0];
            } else {
                d.other_carrier = d.carrier;
                d.carrier = OTHER;
            }
        }

        return excelData;
    }

    readExcel(e) {
        let files = e.target.files, file;
        if (!files || files.length == 0) return;
        file = files[0];
        const fileReader = new FileReader();
        const cb = (excelData) => {
            this.inputElement.value = '';
            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    table: {
                        ...this.state.data.table,
                        data: this.processExcelData(excelData)
                    }
                }
            }
            );
        }

        fileReader.onload = (e, ) => {
            // const filename = file.name;
            // pre-process data
            let binary = "";
            const bytes = new Uint8Array(e.target.result);
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            // call 'xlsx' to read the file
            const workbook = XLSX.read(binary, { type: 'binary' });
            const index = workbook.SheetNames.findIndex(x => x.toLocaleLowerCase() === 'underlying coverage');

            if (index === -1) {
                alert("Sheet 'Underlying Coverage' not found");
                return;
            }
            const coverageSheet = workbook.SheetNames[index];

            //Read all rows from First Sheet into an JSON array.
            const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[coverageSheet]);
            const excelData = [];
            let isCarrierRow = false;
            let insurerKey, limitKey, premiumKey;
            for (let row of excelRows) {

                if (isCarrierRow) {
                    if (row[insurerKey] && row[limitKey] && row[premiumKey]) {
                        excelData.push(
                            {
                                carrier: row[insurerKey],
                                limit: row[limitKey],
                                premium: row[premiumKey]
                            })
                    }

                } else {
                    let rowHasInsurer = false;
                    let rowHasLimit = false;
                    let rowHasPremium = false;

                    Object.keys(row).forEach((key) => {
                        if (isCarrierRow) {
                            excelData.push()
                        } else {
                            if (typeof row[key] === 'string') {
                                if (row[key].toLowerCase().trim() === 'insurer') {
                                    rowHasInsurer = true;
                                    insurerKey = key;
                                }

                                if (row[key].toLowerCase().trim() === 'limit') {
                                    rowHasLimit = true;
                                    limitKey = key;
                                }


                                if (row[key].toLowerCase().trim() === 'premium') {
                                    rowHasPremium = true;
                                    premiumKey = key;
                                }
                            }
                        }
                    });

                    if (rowHasInsurer && rowHasLimit && rowHasPremium) {
                        isCarrierRow = true;
                    }

                }
            }

            cb(excelData);

        };
        fileReader.readAsArrayBuffer(file);

    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected'),
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'program.data') ? { table: _.get(state, 'program.data') } : undefined,
        loaded: _.get(state, 'program.loaded'),
        carriers: _.get(state, 'program.data.carriers') || constants.carriers,
        error: _.get(state, 'error.PROGRAM_GET'),
        isEmptyTable: _.get(state, 'program.data.empty'),
        worksheet: _.get(state, 'worksheet.selected'),
        worksheetInitialized: _.get(state, 'worksheet.initialized'),
        processing: _.get(state, 'program.processing'),
        attachmentLoaded: _.get(state, 'attachment.loaded'),
        attachmentData: _.get(state, 'attachment.data'),
        allDataLoaded: _.get(state, 'attachment.loaded') === true && _.get(state, 'program.loaded') === true,
    };
}

export default connect(mapStateToProps, {
    getProgramAction,
    createProgramAction,
    clearDataProgramAction,
    updateProgramAction
})(CompanyCoverageProgram);
