import storageWrapper from './storageWrapper';
import apigClientFactoryNode from 'aws-api-gateway-client';
import appConfig from '../config';

const getUserId = () => storageWrapper.getState('userProfile').sub;

const config = {
    region: appConfig.amazon.Region,
    invokeUrl: appConfig.amazon.InvokeUrl,
};

const apigClient = apigClientFactoryNode.newClient(config);

const apigClientPrivate = apigClientFactoryNode.newClient({
    region: appConfig.amazon.Region,
    invokeUrl: 'https://ug2p39rmgh.execute-api.us-east-1.amazonaws.com/dev',
});

const getHeaders = ({ params }) => {
    let retVal = {};
    let authorizationHeaders = {
        Authorization: `Bearer ${storageWrapper.getState('accessToken')}`
    };

    if (params) {
        retVal = params;
        retVal.headers = authorizationHeaders;
    } else {
        retVal.headers = authorizationHeaders;
    }

    return retVal;
};

export default {
    getPrivateSubmission: (params) => apigClientPrivate.invokeApi(null, `/submission/get`, 'GET', getHeaders({ params: {queryParams: params }})),
    createPrivateSubmission: (payload) => apigClientPrivate.invokeApi(null, `/submission/create`, 'POST', getHeaders({}), payload),
    getDnoModel: (params) => apigClientPrivate.invokeApi(null, `/model`, 'GET', getHeaders({ params: {queryParams: params}})),
    getSnpBalanceSheet: (params) => apigClientPrivate.invokeApi(null, `/snp/balanceSheet`, 'POST', getHeaders({}), params),
    getSnpIncomeStatement: (params) => apigClientPrivate.invokeApi(null, `/snp/incomeStatement`, 'POST', getHeaders({}), params),
    getSnpScore: (params) => apigClientPrivate.invokeApi(null, `/snp/score`, 'POST', getHeaders({}), params),

    getUser: () => apigClient.invokeApi(null, `/users/${getUserId()}`, 'GET', getHeaders({})),
    getUserWorksheets: () => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets`, 'GET', getHeaders({})),
    getUserWorksheet: worksheetId => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${worksheetId}`, 'GET', getHeaders({})),
    createUserWorksheet: worksheet => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets`, 'POST', getHeaders({}), worksheet),
    updateUserWorksheet: (worksheetId, worksheet) => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${worksheetId}`, 'PUT', getHeaders({}), worksheet),
    archiveUserWorksheet: (object) => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${object.worksheet_id}/imageright`, 'POST', getHeaders({}), object),
    deleteUserWorksheet: worksheetId => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${worksheetId}`, 'DELETE', getHeaders({})),
    getWorksheetPDF: worksheetId => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${worksheetId}/pdf`, 'GET', getHeaders({})),
    createWorksheetPDF: (request) => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${request.worksheet_id}/pdf`, 'POST', getHeaders({}), request),
    updateWorksheetPDF: (request) => apigClient.invokeApi(null, `/users/${getUserId()}/worksheets/${request.worksheet_id}/pdf`, 'PUT', getHeaders({}), request),
    getCompanies: companyName => apigClient.invokeApi(null, '/companies', 'GET', getHeaders({ params: {queryParams: {companyName}}})),
    getCompany: companyId => apigClient.invokeApi(null, `/companies/${companyId}`, 'GET', getHeaders({})),
    getCompanyDetails: companyId => apigClient.invokeApi(null, `/companies/${companyId}/details`, 'GET', getHeaders({})),
    getCompanyEarnings: companyId => apigClient.invokeApi(null, `/companies/${companyId}/earnings`, 'GET', getHeaders({})),
    getCompanyEstimations: companyId => apigClient.invokeApi(null, `/companies/${companyId}/estimations`, 'GET', getHeaders({})),
    getCompanyFinancials: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/financials`, 'GET', getHeaders({ params: queryParams})),
    getCompanyFinancialsDebt: companyId => apigClient.invokeApi(null, `/companies/${companyId}/financials/debt`, 'GET', getHeaders({})),
    getCompanyFinancialsSheet: (companyId, type) => apigClient.invokeApi(null, `/companies/${companyId}/financials/${type}`, 'GET', getHeaders({})),
    getCompanyHolders: (companyId) => apigClient.invokeApi(null, `/companies/${companyId}/holders`, 'GET', getHeaders({})),
    getCompanyHoldersList: (companyId, type) => apigClient.invokeApi(null, `/companies/${companyId}/holders/${type}`, 'GET', getHeaders({})),
    getCompanyPredictors: companyId => apigClient.invokeApi(null, `/companies/${companyId}/predictors`, 'GET', getHeaders({})),
    getCompanyRatings: companyId => apigClient.invokeApi(null, `/companies/${companyId}/ratings`, 'GET', getHeaders({})),
    getCompanyRatios: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/ratios`, 'GET', getHeaders({params: queryParams})),
    getCompanyRisks: companyId => apigClient.invokeApi(null, `/companies/${companyId}/risks`, 'GET', getHeaders({})),
    getCompanyStats: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/stats`, 'GET', getHeaders({ params: queryParams})),
    getCompanyStocks: companyId => apigClient.invokeApi(null, `/companies/${companyId}/stocks`, 'GET', getHeaders({ params: {queryParams: {range: 'TODO_REMOVE'} }})),
    getCompanyStocksVariations: companyId => apigClient.invokeApi(null, `/companies/${companyId}/stocks/variations`, 'GET', getHeaders({ params: {queryParams: {range: 'TODO_REMOVE'} }})),
    getCompanyTradings: companyId => apigClient.invokeApi(null, `/companies/${companyId}/tradings`, 'GET', getHeaders({})),
    getIndustries: queryParams => apigClient.invokeApi(null, '/industries', 'GET', getHeaders({ params: queryParams })),
    getAttachments: companyId => apigClient.invokeApi(null, `/companies/${companyId}/attachments`, 'GET', getHeaders({})),
    getPremiums: () => apigClient.invokeApi(null, '/premiums', 'GET', getHeaders({})),
    getProgram: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/programs`, 'GET', getHeaders({ params: queryParams })),
    createProgram: (companyId, program) => apigClient.invokeApi(null, `/companies/${companyId}/programs`, 'POST', getHeaders({}), program),
    updateProgram: (companyId, program) => apigClient.invokeApi(null, `/companies/${companyId}/programs/${program.program_id}`, 'PUT', getHeaders({}), program),
    getDocuments: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/documents`, 'GET', getHeaders({ params: queryParams })),
    getDocument: (companyId, documentId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/documents/${documentId}`, 'GET', getHeaders({ params: queryParams })),
    getBadActors: (queryParams) => apigClient.invokeApi(null, `/documents`, 'GET', getHeaders({ params: queryParams })),
    getNews: (companyId, queryParams) => apigClient.invokeApi(null, `/companies/${companyId}/events`, 'GET', getHeaders({ params: queryParams })),
    getPrivateCompanies: companyName => apigClient.invokeApi(null, '/private', 'GET', getHeaders({ params: {queryParams: {companyName}}})),
    getPrivateCompany: companyId => apigClient.invokeApi(null, `/private/${companyId}`, 'GET', getHeaders({})),
    createPrivateCompany: request => apigClient.invokeApi(null, '/private', 'POST', getHeaders({}), request),
    updatePrivateCompany: privateCompany => apigClient.invokeApi(null, `/private/${privateCompany.companyId}`, 'PUT', getHeaders({}), privateCompany),
    getPrivateCompanyCompensation: companyId => apigClient.invokeApi(null, `/private/${companyId}/cash`, 'GET', getHeaders({})),
    getPrivateCompanyEmployment: companyId => apigClient.invokeApi(null, `/private/${companyId}/employment`, 'GET', getHeaders({})),
    getPrivateCompanyFiduciaries: companyId => apigClient.invokeApi(null, `/private/${companyId}/fiduciaries`, 'GET', getHeaders({})),
    getPrivateCompanyJurisdictions: companyId => apigClient.invokeApi(null, `/private/${companyId}/jurisdictions`, 'GET', getHeaders({})),
    getPrivateCompanyOwnerships: companyId => apigClient.invokeApi(null, `/private/${companyId}/ownerships`, 'GET', getHeaders({})),
    getPrivateCompanyBalance: companyId => apigClient.invokeApi(null, `/private/${companyId}/balance`, 'GET', getHeaders({})),
    getPrivateCompanyCashFlow: companyId => apigClient.invokeApi(null, `/private/${companyId}/cash`, 'GET', getHeaders({})),
    getPrivateCompanyIncome: companyId => apigClient.invokeApi(null, `/private/${companyId}/income`, 'GET', getHeaders({})),
    getPrivateSubmissionData: (companyId, queryParams) => apigClient.invokeApi(null, `/private/${companyId}/submission`, 'GET', getHeaders({ params: queryParams})),
    submitPrivateCompany: (companyId, submission) => apigClient.invokeApi(null, `/private/${companyId}/submission`, 'POST', getHeaders({}), submission),
    createIpopy1Request: (payload) => apigClient.invokeApi(null, `/private/ipopy1`, 'POST', getHeaders({}), payload),
    getIpopy1Request: queryParams => apigClient.invokeApi(null, `/private/ipopy1`, 'GET', getHeaders({ params: { queryParams }})),
};


