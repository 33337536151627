import React from 'react';

const UpImage = () => {
    return (
        <svg viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>CardActionBtn/Arrow/Up</title>
            <desc>Created with Sketch.</desc>
            <defs>
                <circle id="path-1" cx="22.5" cy="22.5" r="22.5"></circle>
            </defs>
            <g id="CardActionBtn/Arrow/Up" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <use id="Mask" fill="#2661E3" xlinkHref="#path-1"></use>
                <polygon id="Triangle" fill="#FFFFFF" mask="url(#mask-2)" transform="translate(23.000000, 21.625000) rotate(-180.000000) translate(-23.000000, -21.625000) " points="23 27.25 14 16 32 16"></polygon>
            </g>
        </svg>
    );
};
export default UpImage;
