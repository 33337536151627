import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logoutAction } from '../../ducks/user/actions';
import { withAuth } from '@okta/okta-react';
import ProfileImage from '../images/profile';
import { compose } from 'redux';
import storageWrapper from '../../helpers/storageWrapper';
import 'babel-polyfill';
import styles from './_userMenu.module.scss';

export class UserMenu extends Component {
    constructor(props) {
        super(props)

        this.state = { userProfile: {} };
        this.loadUserProfile = this.loadUserProfile.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentDidMount() {
        this.loadUserProfile();
    }

    loadUserProfile() {
        let userProfile = storageWrapper.getState('userProfile');
        this.setState({ userProfile: userProfile });
    }

    async logout() {
        this.props.logoutAction();
        // this.props.logout('/');

        this.props.auth.logout('/').then(() => {
            window.location.reload();
        });
    }

    render() {
        let name = this.state.userProfile.name;
        
        return (
            <div className={styles.userMenu}>
                <div className={styles.overlay} />
                <div className={styles.wrapper}>
                    <div className={styles.userName}>
                        <div className={styles.profileImage}><ProfileImage /></div>
                        <div className={styles.name}>{name}</div>
                    </div>
                    <div className={`${styles.item} ${styles.borderTop}`}>
                        <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/15PAcADakOhAo2PErcF1WFqturOW5GkKLLjn-Xe3Ep_M/edit?usp=sharing">User Guide</a>
                    </div>
                    <div className={styles.item} onClick={() => this.logout()}>Logout</div>
                </div>
            </div>
        );
    }
}

const userMenuContainer = compose(
    withRouter,
    withAuth,
    connect(null, {
        logoutAction
    })
)(UserMenu);

export default userMenuContainer;