import _ from '../../../lodashWrapper';
import React, {Component} from 'react';
import Info from '../info';
import DataNotFound from '../dataNotFound';
import DynamicTable from '../dynamicTable';
import InfoImage from "../../images/info";
import FlagImage from "../../images/flag";
import UpImage from "../../images/up";
import DownImage from "../../images/down";
import NumberFormat from 'react-number-format';

class BadActorsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expandable: this.props.expandable && this.props.expandable === true,
            topExpanded: false,
            bottomExpanded: false,
            expandedIndex: -1,     
        };
        this.addCommas = this.addCommas.bind(this);
        this.printTDs = this.printTDs.bind(this);
        this.printTBodyContent = this.printTBodyContent.bind(this);
    }

    addCommas = (val) => {
        if (val && this.props.formatWithCommas) {
            let parts = val.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        return val;
    }

    prepareRow(row) {
        
        return {
            ...row,
            risks: <ul>{
                _.map(row.risks, (r, index) => {
                    if (r.riskFlag !== true) {
                        return;
                    }
                    return <li key={`risks${index}`}>{r.riskName}</li>
                })
            }</ul>,
            filename: row.filename ? <a rel="noopener noreferrer" target="_blank" className="text-underline" href={`/company/${row.company_id}/document/${row.document_id}?type=sca`}>{row.filename}</a> : '(Undocumented)', 
            "total_amount": <NumberFormat
                value={row.total_amount || ''}
                thousandSeparator={true}
                prefix={'$'}
                displayType={'text'}
                />
        };
    }

    prepareData(tableData) {
        if (Object.keys(tableData).length === 0) {
            return
        }
        return {
            ...tableData,
            table: {
                columns: tableData.table.columns,
                data: _.map(tableData.table.data, (row, index) => this.prepareRow(row, index))
            }
        }
    }

    printTDs = (row, rowIndex, mscads, personId) => {

        const riskFlagStyle = {
            padding: '5px'
        };

        const expandStyle = {
            width: '20px'
        };

        let showflag = false;
        

        return [_.map(this.props.data.table.columns, (item, itemIndex) => {
            if (item.field == 'riskFlag') {
                return <td style={riskFlagStyle} key={'td' + itemIndex}
                    className={row[item.field + 'ClassName']}
                    >
                    {row[item.field].length > 0 ? (showflag = true && (mscads = row[item.field]) && (personId = row['personId']) && <FlagImage />) : null}
                </td>;
            }

            return <td key={'td' + itemIndex}
                       className={row[item.field + 'ClassName']}>
                       <div className="main-td-content">{this.addCommas(row[item.field])}</div>
                        {item.subField && <div className="sub-td-content">{row[item.subField]}</div>}
                </td>;
        }),
        [showflag && (<td key={'tdh' + rowIndex}><div className="hovering" style={expandStyle}><div className="cursor-pointer" >{this.state.expandedIndex === rowIndex ? <UpImage/>: <DownImage/>}</div></div></td>)]
        ]
    }

    printTBodyContent = () => {
        return <tbody>
            {
                _.map(this.props.data.table.data,  (row, rowIndex) => {
                    
                    let className = this.state.expandedIndex === rowIndex ? 'bad-actor-highlited' : '';
                    const className2 = this.state.expandedIndex === rowIndex ? 'bad-actor-highlited2' : 'hidden';
                    const className3 = this.state.expandedIndex === rowIndex ? 'bad-actor-highlited3' : 'hidden';
                    const personId = row.personId;
                    const mscads = row.riskFlag || [];
                    if (mscads.length > 0) {
                        className += ' has-risks'; 
                    }

                    const result = [<tr key={'a' + rowIndex} className={className} 
                    onClick={() => {
                        if (mscads.length > 0) {
                            this.expandBtnClicked(rowIndex, mscads, personId)
                        }                        
                    }
                    }
                    >
                    {
                        this.printTDs(row, rowIndex, mscads, personId)
                    }
                    </tr>];

                    if (row.msci_flagged_director_profile) {
                        result.push(<tr key={'b' + rowIndex} className={className3} >
                        {
                            <td colSpan="10">
                                <div>MSCI Board Integrity Flag</div>
                                <div>{row.msci_flagged_director_profile}</div>
                            </td>
                        }
                    </tr>);
                    }

                    result.push(<tr key={'c' + rowIndex} className={className2} >
                    {
                        this.props.persons[personId] !== undefined ? <td colSpan="10">
                            <DynamicTable data={this.prepareData(this.props.persons[personId])} />
                        
                        </td> :
                        <td className="text-center" colSpan="10">
                            <img src="/images/loading.gif" />
                        </td>
                    }
                </tr>);

                    return result
                })
            }
        </tbody>
    }

    expandBtnClicked(index, mscads, personId){

        if (this.state.expandedIndex === index) {
            this.setState({
                expandedIndex: -1
              });
        } else {
            this.setState({
                expandedIndex: index
            });
            this.props.callAPI(mscads, personId);
        }
    }



    render () {
        return (
            <div className="common-element dynamic-table bad-actors-table">
                {this.props.data.title && <h3>{this.props.data.title}</h3>}
                {this.props.data.table.data.length === 0 ? <DataNotFound /> : <table className={this.props.className}>
                    {!this.props.hideHeader && <thead>
                    <tr>
                        {
                            _.map(this.props.data.table.columns, (item, index) => {
                                return <th key={'te' + index} className={item.className}>
                                    {item.title}
                                    {item.tooltip && <Info text={item.tooltip}><InfoImage/></Info>}
                                </th>
                            })
                        }
                    </tr>
                    </thead>}
                    {this.printTBodyContent()}
                </table>}
            </div>
        );
    }
}

export default BadActorsTable;
