export default {
    ACCEPT: 'IPOPY1_ACCEPT',
    APPEND: 'IPOPY1_APPEND',
    CREATE: 'IPOPY1_CREATE',
    CREATE_FAIL: 'IPOPY1_UPDATE_FAIL',
    CREATE_SUCCESS: 'IPOPY1_UPDATE_SUCCESS',
    GET: 'IPOPY1_GET',
    GET_EVALUATION_SUCCESS: 'IPOPY1_GET_EVALUTATION_SUCCESS',
    GET_EVALUTION: 'IPOPY1_GET_EVALUTATION',
    GET_FAIL: 'IPOPY1_GET_FAIL',
    GET_SUCCESS: 'IPOPY1_GET_SUCCESS',
    RESET: 'IPOPY1_RESET',
    SETTLE_EVALUATION: 'IPOPY1_SETTLE_EVALUATION',
    UPDATE: 'IPOPY1_UPDATE',
};
