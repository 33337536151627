import React from 'react';
import { withRouter } from 'react-router';

import classNames from 'classnames';
import styles from './_link.module.scss';


export const Link = (props) => {

    function onClick() {
        if (props.to && !props.native) {
            props.history.push(props.to);
        }
        if (props.onClick) {
            props.onClick();
        }
    }

    function renderLink() {
        if (props.native) {
            return <a href={props.to} rel="noopener noreferrer" target="_blank">{props.children}</a>;
        } else {
            return <span className={styles.a}>{props.children}</span>;
        }
    }
    let classItems;
    if (props.className) {
        classItems = props.className.split(' ').map(x => {
            return styles[x];
        });
    }

    return (
        <div className={`${styles.link} ${classItems && classItems.join(' ')}
                                ${classNames({ [styles.disable]: props.disabled })}`}
            onClick={() => onClick()}>
            {renderLink()}
        </div>
    );
};

export default withRouter(Link);
