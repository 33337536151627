import React, { Component } from 'react';
import apigClientWrapper from '../../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../../helpers/utility';
import NoteController from '../../note/noteController';
import Spinner from '../../common/spinner';
import ErrorAlert from '../../common/errorAlert';
import Tag from '../../common/tag';
import PrivateCompanyDOClaimsProbability from './privateCompanyDOClaimsProbability';
import PrivateCompanySPData from './privateCompanySPData';

export class Submission extends Component {
  state = {};

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const { submissionId } = this.props.match.params;

      if (submissionId) {
        const { data: company } = await apigClientWrapper.getPrivateSubmission({
          submission_id: submissionId,
        });
        const { data } = await apigClientWrapper.getDnoModel({
          submission_id: submissionId,
          dnb_company_id: company.dnb_company_id,
        });

        this.setState({ dnbData: data, company });
      }
    } catch (error) {
      const { status, payload } = apiFailMsg(error);

      if (status === 400 || status === 404) {
        this.setState({
          error: 'No Company with that Name and Address Combination Found',
        });
      }

      this.setState({ error: payload });
    }
  };

  render() {
    const { company = {}, error, dnbData } = this.state;

    return (
      <div>
        <div className="company-header">
          <div className="company-header-summary">
            <div id="company-summary" className="container">
              <div className="row">
                <div className="col-xs-12">
                  <span className="title">
                    {company.dnb_company_name}
                    <Tag text="Private" className={'lime-green otc'} />
                  </span>
                </div>
              </div>
              <div className="details">
                <div className="row">
                  <div className="col-xs-3">
                    <div className="row">
                      <div className="col-xs-4 faint">Headquarters</div>
                      <div className="col-xs-8">{company.headquarters}</div>
                    </div>
                    <div className="row">
                      <div className="col-xs-4 faint">Website</div>
                      <div className="col-xs-8">
                        <a
                          className="company-website"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={'http://' + company.website}
                        >
                          {company.website}
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-4 faint">Phone</div>
                      <div className="col-xs-8">{company.phone_number}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="company-overview container with-spinner">
          {error ? (
            <ErrorAlert message={error} />
          ) : (
            <Spinner loaded={Object.keys(company).length > 0}>
              <React.Fragment>
                <PrivateCompanyDOClaimsProbability data={dnbData} />
                <PrivateCompanySPData data={{ income: {}, balance: {} }} />
                <NoteController section="overview" />
              </React.Fragment>
            </Spinner>
          )}
        </div>
      </div>
    );
  }
}

export default Submission;
