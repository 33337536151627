import React, { Component } from 'react';
import { SecureRoute, withAuth } from '@okta/okta-react';
import { withRouter } from 'react-router';
import AppBar from './appBar/appBar';
import Company from './routes';
import storageWrapper from '../helpers/storageWrapper';
import { initializeAmplitudeAction, routeChangedAction } from '../ducks/app/actions';
import { loginAction } from '../ducks/user/actions';

import 'babel-polyfill';
import { compose } from 'redux';
import { connect } from 'react-redux';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = { authenticated: null, loaded: null };
    this.checkAuthentication = this.checkAuthentication.bind(this);
    this.checkAuthentication();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  async checkAuthentication() {
    console.log('authenticating....');
    const authenticated = await this.props.auth.isAuthenticated();
    console.log('authenticating result', authenticated);
    if (authenticated !== this.state.authenticated) {
      this.setState({ authenticated });
      if (!authenticated) {
        this.props.auth.login('/');
      }
      try {
        storageWrapper.saveState('userProfile', await this.props.auth.getUser());
        storageWrapper.saveState('accessToken', await this.props.auth.getAccessToken());
      } catch (ex) {
        window.location.reload(true);
      }
      const userProfile = storageWrapper.getState('userProfile');
      if (userProfile === null) {
        this.props.auth.login('/');
        return;
      }
      this.props.initializeAmplitudeAction();
      this.props.loginAction(userProfile);
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate() {
    this.checkAuthentication();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const origin = window.location.origin;
      const previousPath = origin + this.props.location.pathname + this.props.location.search;
      const nextPath = origin + nextProps.location.pathname + nextProps.location.search;
      this.props.routeChangedAction(nextPath, previousPath);
    }
  }

  async login() {
    // Redirect to '/' after login
    this.props.login('/');
  }

  async logout() {
    // Redirect to '/' after logout
    this.props.logout('/');
  }


  render() {
    if (!this.state.loaded) {
      return <div>Loading...</div>
    }
    if (this.state.authenticated === null) return null;


    return <div>
      <SecureRoute path='/:module?/:companyId?/:view?' component={AppBar} />
      <SecureRoute path='*' component={Company} />
    </div>;
  }
}

const AppContainer = compose(
  withRouter,
  withAuth,
  connect(null, { routeChangedAction, loginAction, initializeAmplitudeAction })
)(App);

export default AppContainer;
