import actions from './types';
const defaultState = {};


export default function (state = defaultState, action) {
  const { type, payload, status } = action;


  switch (action.type) {

    case actions.CLEAR:
      if (payload) {
        delete state[payload]
        return state;
      } else {
        return defaultState;
      }
    default:
      var failMatches = /(.*)_(FAIL)/.exec(type);
      var successMatches = /(.*)_(SUCCESS)/.exec(type);
      if (successMatches) {
        const [, requestName] = successMatches;
        return {
          ...state,
          [requestName]: undefined,
          [requestName + '_STATUS']: undefined
        };
      }

      if (!failMatches) return state;

      var [, requestName] = failMatches;

      return {
        ...state,
        [requestName]: payload,
        [requestName + '_STATUS']: status
      };
  }
}