import React, { useRef } from 'react';
import InfoImage from "../../images/info";
// import { convertToBigNumber } from "../../helpers/utility";
import RiskPredictorPeerGroupTooltip from '../riskPredictorPeerGroupTooltip';
import styles from './_riskPredictor.module.scss';

// const calculateSteps = (min, max, count) => {
//     const steps = [];
//     const hop = (max - min) / count;
//     let i = 0;

//     for (i; i <= count; i++) {
//         steps.push({
//             position: 100 / count * i,
//             value: min + hop * i
//         });
//     }
//     return steps
// };

// const renderPositiveIndicator = (value, display) => {
//     return display && 0 < value ? '+' : ''
// };

// const convertBigNumber = (value) => {
//     return convertToBigNumber(value);
// }

// const renderLabel = (label, value, reversed, positiveIndicator) => {

//     const labelElement = <span>{label || ''}</span>;
//     const valueElement = <span className='step-label-value'>
//         {renderPositiveIndicator(value, positiveIndicator)}{convertBigNumber(value)}
//         </span>;
//     return <div className='step-label'>
//         {reversed ? valueElement : labelElement}
//         {reversed ? labelElement : valueElement}
//     </div>;
// };

const RiskPredictor = (props) => {
    const {
        // min = 0, 
        // max = 100, 
        p10,
        p25,
        p50,
        p75,
        p90,
        peerGroups,
        p_rank, // percentile rank
        label = null,
        // stepCount = 2,
        // avg = null,
        // stepLabels = ['Low:', 'Med:', 'Max:']
    } = props.data;
    let { value } = props.data;


    let valuePosition = ((value - p10) / (p90 - p10) * 100);

    let outlier = true;
    if (value > p90) {
        if (p_rank) {
            valuePosition = p_rank * 100;
        } else {
            valuePosition = 100;
        }
    } else if (value < p10) {
        if (p_rank) {
            valuePosition = p_rank * 100;
        } else {
            valuePosition = 0;
        }
    } else {
        outlier = false;
    }

    const p25Position = (((p25 - p10) / (p90 - p10) * 100));
    const p50Position = (((p50 - p10) / (p90 - p10) * 100));
    const p75Position = (((p75 - p10) / (p90 - p10) * 100));
    // console.log(`${outlier}, value: ${value}, valuePosition: ${valuePosition}%, actualValuePosition: ${actualValuePosition}%, p10: ${p10}, p25: ${p25}, p50: ${p50}, p50Position: ${p50Position}, p75: ${p75}, p90: ${p90}`)
    // const steps = calculateSteps(min, max, stepCount);
    // const p10Position = 0;
    const p90Position = 100;

    if (isNaN(valuePosition)) {
        valuePosition = null;
    }
    // let animationClass1 = '', animationClass2 = '';
    // if (animation !== undefined) {
    //     if (animation === 0) {
    //         animationClass1 = 'risk-mouseover';
    //     } else if (animation === 1) {
    //         animationClass2 = 'risk-mouseover';
    //     }
    // }

    let p10Label = '10%';
    let p25Label = '25%';
    let p50Label = '50%';
    let p75Label = '75%';
    let p90Label = '90%';

    // let p10Value = convertBigNumber(p10);
    // let p25Value = convertBigNumber(p25);
    // let p50Value = convertBigNumber(p50);
    // let p75Value = convertBigNumber(p75);
    // let p90Value = convertBigNumber(p90);

    let p10SpanWidth = 40;
    let p25SpanWidth = 40;
    let p50SpanWidth = 40;
    let p75SpanWidth = 40;
    let p90SpanWidth = 40;

    let p10MarginLeft = -20;
    let p25MarginLeft = -20;
    let p50MarginLeft = -20;
    let p75MarginLeft = -20;
    let p90MarginLeft = -20;

    const spacing = 5;
    let merge = 0;
    if (p90Position - p75Position < spacing) {
        p75Label += '/' + p90Label;
        p90Label = '';

        // p75Value += '/' + p90Value;
        // p90Value = ''

        p75SpanWidth += p90SpanWidth;
        p75MarginLeft += p90MarginLeft;
        merge++;
    }

    if (p75Position - p50Position < spacing) {
        p50Label += '/' + p75Label;
        p75Label = '';

        // p50Value += '/' + p75Value;
        // p75Value = '';

        p50SpanWidth += p75SpanWidth;
        p50MarginLeft += p75MarginLeft + 10;
        merge++;
    }

    if (p50Position - p25Position < spacing) {
        p25Label += '/' + p50Label;
        p50Label = '';

        // p25Value += '/' + p50Value;
        // p50Value = '';

        p25SpanWidth += p50SpanWidth;
        p25MarginLeft += p50MarginLeft + 10;
        merge++;
    }

    if (p25Position < spacing) {
        p10Label += '/' + p25Label;
        p25Label = '';

        // p10Value += '/' + p25Value;
        // p25Value = '';

        p10SpanWidth += p25SpanWidth;
        if (merge >= 1) {
            p10MarginLeft += p25MarginLeft - 15;
        } else {
            p10MarginLeft += p25MarginLeft;
        }
    }

    const childRef = useRef();

    return (
        <div className={styles.riskPredictorContainer}>
            <div className={styles.riskPredictor}>
                <div className="text-left">
                    <div style={{ fontSize: '11px' }}>
                        {label}
                        {peerGroups && <span style={{ position: 'relative' }}><InfoImage onClick={() => {
                            childRef.current.open();
                        }} /><RiskPredictorPeerGroupTooltip ref={childRef} peerGroups={peerGroups} /></span>}

                    </div>
                </div>
                <div className={styles.riskLinesInner}>
                    {valuePosition !== null && outlier === true && <div className={`${styles.verticalLine} ${styles.redLine}`} style={{ left: valuePosition + '%' }}></div>}
                    <div className={styles.riskLines}>
                        {valuePosition !== null && outlier === false && <div className={`${styles.verticalLine} ${styles.redLine}`} style={{ left: valuePosition + '%' }}></div>}

                        <div className={styles.riskLineSolid} style={{ width: (p75Position - p25Position) + '%', left: (p25Position) + '%' }}></div>
                        <div className={styles.verticalLine}></div>
                        <div className={styles.verticalLine} style={{ left: p25Position + '%' }}></div>
                        <div className={styles.verticalLine} style={{ left: p50Position + '%' }}></div>
                        <div className={styles.verticalLine} style={{ left: p75Position + '%' }}></div>
                        <div className={styles.verticalLine} style={{ left: 100 + '%' }}></div>
                        <div className={styles.riskLineDots} style={{ width: (100) + '%' }}></div>
                        <div className={styles.riskLinesAnimation}>
                            <div>
                                <span style={{ marginLeft: p10MarginLeft + 'px', width: p10SpanWidth + 'px' }}>{p10Label}</span>
                                <span style={{ marginLeft: p25MarginLeft + 'px', width: p25SpanWidth + 'px', left: p25Position + '%' }}>{p25Label}</span>
                                <span style={{ marginLeft: p50MarginLeft + 'px', width: p50SpanWidth + 'px', left: p50Position + '%' }}>{p50Label}</span>
                                <span style={{ marginLeft: p75MarginLeft + 'px', width: p75SpanWidth + 'px', left: p75Position + '%' }}>{p75Label}</span>
                                <span style={{ marginLeft: p90MarginLeft + 'px', width: p90SpanWidth + 'px', left: 100 + '%' }}>{p90Label}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RiskPredictor;
