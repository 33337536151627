import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuTab from '../../common/menuTab'
import MenuTabItem from '../../common/menuTabItem';
import { changeSubmissionModalIndexAction, getMCQuestions, closeSubmissionModalAction, submitAction, getSubmissionData } from '../../../ducks/priv_submission/actions';
import _ from '../../../lodashWrapper';
import QuestionsForm from './questionsForm';
import Button from '../../common/button';
import Link from '../../common/link';
import styled from 'styled-components';
import Select from '../../common/select';


const Section = styled.section`
    max-height: 600px;
    overflow: auto;
`

export class PrivateCompanyForm extends Component {
    constructor(props) {
        super(props);

        const currentYear = new Date().getFullYear();

        this.statusTypes = [];

        this.state = { year: null, month: null }

        for (let i = 0; i < 5; i++) {
            this.statusTypes.push({ value: currentYear - i, label: currentYear - i });
        }

        this.props.getMCQuestions();
        this.data = [];
    }


    switch(index) {
        this.props.changeSubmissionModalIndexAction(index);
    }

    getMenuTabItems(questionSets) {
        const view = [];

        for (let i = 0; i < questionSets.length; i++) {
            view.push(
                <MenuTabItem key={'menuTab' + i} action={() => this.switch(i)}>{questionSets[i].name}</MenuTabItem>
            )
        }
        return view;
    }

    updateAnswers(index, form) {
        this.data[index] = form;
    }

    getContent(questionSets) {
        const views = [];
        for (let i = 0; i < questionSets.length; i++) {
            const answers = {};
            const config = this.props.submissionData.config

            Object.keys(this.props.submissionData).forEach(key => {
                Object.keys(this.props.submissionData[key]).forEach(k => {
                    if (k.indexOf(questionSets[i].questionSetId) === 0) {
                        answers[k] = { answer: this.props.submissionData[key][k].answer };
                    }
                });
            });

            views.push(
                <div key={'modalTab' + i} className={this.props.modalTabIndex === i ? '' : 'hidden'}>
                    <QuestionsForm answers={answers} config={config} index={i} updateAnswers={this.updateAnswers.bind(this)} data={questionSets[i]} />
                </div>
            )
        }
        return views;
    }

    callApi(year) {
        this.props.getSubmissionData(this.props.companyId, year);
    }

    render() {
        let loading = false;
        if (this.props.loaded !== true || this.props.submissionDataLoaded !== true) {
            loading = true;
        }

        return (
            <div>
                <div id="submission-top">
                    <div className="pull-left">
                        <h3>Company Name: {this.props.company.companyName}</h3>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Select label="Year"
                                                options={this.statusTypes}
                                                placeholder="Select year..."
                                                onChange={(value) => {
                                                    this.setState({
                                                        year: value.value
                                                    });
                                                    this.callApi(value.value);
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Select label="Month"
                                                options={[
                                                    { value: 0, label: 'N/A' },
                                                    { value: 1, label: 1 },
                                                    { value: 2, label: 2 },
                                                    { value: 3, label: 3 },
                                                    { value: 4, label: 4 },
                                                    { value: 5, label: 5 },
                                                    { value: 6, label: 6 },
                                                    { value: 7, label: 7 },
                                                    { value: 8, label: 8 },
                                                    { value: 9, label: 9 },
                                                    { value: 10, label: 10 },
                                                    { value: 11, label: 11 },
                                                    { value: 12, label: 12 },
                                                ]}
                                                placeholder="(Optional) Select month..."
                                                onChange={(value) => {
                                                    this.setState({
                                                        month: value.value
                                                    });
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div className="pull-right">
                        <Link className="link" onClick={() => this.props.closeSubmissionModalAction()}>Cancel</Link>
                        <Button disabled={this.props.submitting} onClick={() => {
                            this.props.submitAction(this.props.companyId, this.data, this.state.year, this.state.month);
                        }}>Submit</Button>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                    {loading === false && <div>
                        <p>&nbsp;</p>
                        <MenuTab initialActiveTab={this.props.modalTabIndex}>
                        {this.getMenuTabItems(this.props.questionSets)}
                    </MenuTab></div>}
                </div>
                
                {loading === true ? (this.state.year ? <div style={{ height: '200px' }}>loading</div> : <div style={{ height: '200px' }}></div>) : <Section id="privateSubmissionForm">
                
                    {this.getContent(this.props.questionSets)}
                </Section>
                }

            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        modalTabIndex: _.get(state, 'privateSubmission.submissionModalIndex'),
        questionSets: _.get(state, 'privateSubmission.questionSets'),
        loaded: _.get(state, 'privateSubmission.loaded'),
        submitting: _.get(state, 'privateSubmission.submitting'),
        submissionData: _.get(state, 'privateSubmission.submissionData'),
        submissionDataLoaded: _.get(state, 'privateSubmission.submissionDataLoaded'),
        companyId: _.get(state, 'privateCompany.selected.companyId'),
        company: _.get(state, 'privateCompany.selected'),
    };
}


export default connect(mapStateToProps, {
    changeSubmissionModalIndexAction,
    getMCQuestions,
    closeSubmissionModalAction,
    submitAction,
    getSubmissionData
})(PrivateCompanyForm);
