import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { changeCompanyFormPageAction } from '../../../ducks/priv_company_form/actions';
import { closeFormModalAction } from '../../../ducks/priv_company_form/actions';
import _ from '../../../lodashWrapper';
import PrivateSubmissionForm from './submissionForm';
// import PrivateCreateForm from './createForm';
// import PrivateReviewForm from './reviewForm';
// import SuccessModalForm from './successModal';
// import DuplicateWarning from './duplicateWarning';
// import UnsavedWarning from './unsavedWarning';
// import * as CONSTANTS from '../../../ducks/priv_company_form/constants';

const CloseBtn = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  width: 26px;
  cursor: pointer;
`;
export class PrivateCompanyForm extends Component {
  constructor(props) {
    super(props);
  }

  // getContent() {
  //     switch (this.props.formPage) {
  //         case CONSTANTS.REVIEW_PAGE:
  //             return <PrivateReviewForm />;
  //         case CONSTANTS.DUPLICATE_PAGE:
  //             return <DuplicateWarning />;
  //         case CONSTANTS.UNSAVED_PAGE:
  //             return <UnsavedWarning />;
  //         case CONSTANTS.SUCCESS_PAGE:
  //             return <SuccessModalForm />;
  //         default:
  //             return <PrivateCreateForm />;
  //     }
  // }
  // closeModal() {
  //     if ([CONSTANTS.CREATE_PAGE, CONSTANTS.SUCCESS_PAGE].indexOf(this.props.formPage) !== -1) {
  //         this.props.closeFormModalAction();
  //     } else {
  //         this.props.changeCompanyFormPageAction(CONSTANTS.UNSAVED_PAGE);
  //     }
  // }

  render() {
    return (
      <Modal
        className="company-create-worksheet modal-small"
        overlayClassName="modal-overlay"
        isOpen={this.props.formModalOpen}
      >
        <CloseBtn onClick={this.props.closeFormModalAction}>
          <img src="/images/x.svg" />
        </CloseBtn>
        <PrivateSubmissionForm />
      </Modal>
    );
  }
}
function mapStateToProps(state) {
  return {
    formPage: _.get(state, 'privateCompanyForm.companyFormPage'),
    formModalOpen: _.get(state, 'privateCompanyForm.formModalOpen'),
  };
}

export default connect(
  mapStateToProps,
  { changeCompanyFormPageAction, closeFormModalAction }
)(PrivateCompanyForm);
