import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getRiskFlags = (state) => state.riskFlags;

function* getRisks(action) {
    let riskFlags = yield select(getRiskFlags);
    if (riskFlags.data === null) {
        try {
            const response = yield call(apigClientWrapper.getCompanyRisks, action.companyId);
            yield put({ type: actions.GET_RISK_FLAGS_SUCCESS, payload: response });
        } catch (e) {
            yield put({ type: actions.GET_RISK_FLAGS_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeLatest(actions.GET_RISK_FLAGS, getRisks),
]
