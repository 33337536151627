import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getEstimation = (state) => state.estimation;

function* fetchEstimation(action) {
  let estimation = yield select(getEstimation);
  if (estimation.data === null) {
    try {
      const response = yield call(apigClientWrapper.getCompanyEstimations, action.companyId);
      yield put({ type: actions.GET_ESTIMATION_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: actions.GET_ESTIMATION_FAIL, ...apiFailMsg(e) });
    }
  }
}

export default [
  takeLatest(actions.GET_ESTIMATION, fetchEstimation),
]
