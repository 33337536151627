import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getKeyStats = (state) => state.keyStats;

function* getStats(action) {
    let keyStats = yield select(getKeyStats);
    if (keyStats.data[action.peerGroup] === undefined) {
        try {
            const queryParams = {
                peerGroup: action.peerGroup
            };
            const response = yield call(apigClientWrapper.getCompanyStats, action.companyId, { queryParams });
            yield put({ type: actions.GET_KEY_STATS_SUCCESS, payload: response, peerGroup: action.peerGroup });
        } catch (e) {
            yield put({ type: actions.GET_KEY_STATS_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeLatest(actions.GET_KEY_STATS, getStats),
]
