import React from 'react';

const NoteImage = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <defs>
            <style>{`.note-cls-1{fill:none;}
            .note-cls-2{clip-path:url(#note-clip-path);}`}</style>
            <clipPath id="note-clip-path">
                <path className="note-cls-1"
                      d="M31.83,61.84l6.29,6.29-8.87,2.58Zm31-32.49a.76.76,0,0,1,.49-.21.64.64,0,0,1,.49.21l6.92,6.92a1.22,1.22,0,0,1,.21.49.57.57,0,0,1-.21.48l-5,5-7.9-7.89Zm-8,8,7.89,7.9L41.89,66,34,58.13ZM26.17,76a1.36,1.36,0,0,0,.56-.07l14.6-4.26a4.36,4.36,0,0,0,2.1-1.26L73.54,40.25a4.91,4.91,0,0,0,1.4-3.49,4.76,4.76,0,0,0-1.4-3.43l-6.91-6.92a5,5,0,0,0-6.92,0l-30,30.12a5.85,5.85,0,0,0-1.26,2.09L24.15,73.23a2.15,2.15,0,0,0,.56,2.09A2.09,2.09,0,0,0,26.17,76Z"/>
            </clipPath>
        </defs>
        <title>note</title>
        <g className="note-cls-2">
            <rect x="5.72" y="6.65" width="87.57" height="87.65"/>
        </g>
        <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,95A45,45,0,1,1,95,50,45,45,0,0,1,50,95Z"/>
    </svg>;
};
export default NoteImage;
