import _ from '../../../lodashWrapper';
import React from 'react';
import styles from './_stackBarChart.module.scss';

const StackedBarChart = (props) => {
    const stack = props.data;
    const focus = props.focus;
    const colorHexList = props.colorHexList || [
        '#8B0011',
        '#D95848',
        '#EA9B4C',
        '#F4CB5F',
        '#6FB96E',
        '#3A6132'
    ];

    const total = stack.reduce((a, num) => {
        return a + num;
    });

    return (
        <div className={styles.appaBarChart}>
            <div className={styles.topText}>{props.topText}</div>
            <div className='clearfix' style={props.style}>
            {
                 _.map(stack, (item, index) => {
                    const className = (index === focus) ? styles.focus : '';
                    return <div 
                        onMouseOver={() => {
                            if (props.onMouseOver) {
                                props.onMouseOver(index, props.parentIndex);
                            }
                        }}
                        onMouseOut={() => {
                            if (props.onMouseOut) {
                                props.onMouseOut();
                            }
                        }}
                        className={className} key={index} 
                        ref={element => { 
                            if (element) element.style.setProperty('background', colorHexList[index], 'important'); 
                          }}

                        style={{float: 'left', width: `${ (item / total) * 100}%`, height: '15px'}}
                        ></div>
                 })
            }
        </div>
        {props.bottomText}
        </div>
        
    );
};

export default StackedBarChart;
