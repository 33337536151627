function saveState(key, state) {
    try {
        localStorage.setItem(key, JSON.stringify(state));
    }
    catch (e) {
        // Ignore write errors;
    }
}

function getState(key, def) {
    try {
        return JSON.parse(localStorage.getItem(key)) || def || null;
    }
    catch (e) {
        return def || null;
    }
}

function clearState(key) {
    localStorage.clear(key);
}

export default {
    getState,
    saveState,
    clearState
}