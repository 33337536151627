import _ from '../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DocImage from '../images/doc';
import { fetchDocumentAction, documentResetAction } from '../../ducks/document/actions';

export class DocumentTxt extends Component {
    componentDidMount() {
        this.extractedHtml = '';
        this.props.fetchDocumentAction(this.props.document.url);
    }

    extractHtml(data, htmlTag) {
        const startIndex = data.toLowerCase().indexOf(`<${htmlTag}`);

        if (0 > startIndex) {
            return this.extractedHtml;
        }
        const endIndex = data.toLowerCase().indexOf(`</${htmlTag}`);
        if (startIndex > endIndex) {
            return this.extractedHtml;
        }
        this.extractedHtml += data.substring(startIndex, endIndex);
        return this.extractHtml(data.slice(endIndex))
    }

    componentWillUnmount() {
        this.props.documentResetAction();
    }

    render() {
        if (!this.props.documentLoaded) {
            return <span>Loading...</span>;
        }
        if (this.props.success !== true) {
            return <span>Error...</span>;
        }

        let extractedData = this.extractHtml(this.props.document.data, 'html');
        if (!extractedData) {
            extractedData = this.extractHtml(this.props.document.data, 'HTML');
        }
        extractedData += '</html>'

        return (
            <div className="document-txt">
                <div className="header">
                    <div className="left">
                        <DocImage type={this.props.documentType} />
                        <div className="preview">Preview</div>
                    </div>
                    <div className="right">
                        <a href={this.props.document.url}>
                            <img src="/images/download.svg" />
                        </a>
                    </div>
                </div>
                <div className="content" dangerouslySetInnerHTML={{ __html: extractedData }} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        document: _.get(state, 'document.selected'),
        documentLoaded: _.get(state, 'document.loaded'),
        success: _.get(state, 'document.success'),
    };
}

export default connect(mapStateToProps, {
    fetchDocumentAction,
    documentResetAction
})(DocumentTxt);
