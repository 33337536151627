import _ from '../../lodashWrapper';
import React from 'react';

const Input = (props) => {
    const inputProps = _.omit(props, ['error', 'errorMessage']);

    return (
        <div className="common-element input">
            <div className="wrapper">
                <div className="input-label">{props.label}</div>
                <input value={props.value}
                    onChange={(event) => props.input.onChange(event.target.value)}
                    {...inputProps}
                />
                {props.error ? <div className="error">{props.errorMessage}</div> : null}
            </div>
        </div>
    );
};

export default Input;
