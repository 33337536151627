import React from 'react';

const PlusImage = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.57 128.57">
        <defs>
            <style>{`
                .plus-cls-1,.plus-cls-2{fill:none;}
                .plus-cls-1{clip-rule:evenodd;}
                .plus-cls-3{clip-path:url(#plus-clip-path);}
                .plus-cls-4{clip-path:url(#plus-clip-path-2);}
                .plus-cls-5{fill-rule:evenodd;}
                `}
            </style>
            <clipPath id="plus-clip-path">
                <path className="plus-cls-1"
                      d="M67.86,60.71V39.29a3.57,3.57,0,1,0-7.14,0V60.71H39.29a3.57,3.57,0,1,0,0,7.14H60.71V89.28a3.57,3.57,0,1,0,7.14,0V67.86H89.28a3.57,3.57,0,1,0,0-7.14Z"/>
            </clipPath>
            <clipPath id="plus-clip-path-2">
                <rect className="plus-cls-2" x="14.29" y="14.29" width="100" height="100"/>
            </clipPath>
        </defs>
        <title>Asset 1</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g className="plus-cls-3">
                    <g className="plus-cls-4">
                        <rect width="128.57" height="128.57"/>
                    </g>
                </g>
                <path className="plus-cls-5"
                      d="M64.29,14.29a50,50,0,1,0,50,50A50,50,0,0,0,64.29,14.29Zm0,92.86a42.86,42.86,0,1,1,42.86-42.86A42.86,42.86,0,0,1,64.29,107.14Z"/>
            </g>
        </g>
    </svg>;
};
export default PlusImage;
