import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: {},
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_RATIO_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.ratio,
                    [action.peerGroup]: _.get(action, 'payload.data'),
                    [action.peerGroup + '_loaded']: true
                }
            };
        default:
            return state;
    }
}
