import React from 'react';

const EnterImage = () => {
  return <svg id="xxx" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <defs>
        <style>
          {`.cls-1{fill:#ddd;}.cls-2{fill:#fff;}`}
        </style>
      </defs>
      <title>Enter</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <circle className="cls-1 change-color-in-this-element" cx="50" cy="50" r="50"/>
          <g id="Layer_2-2" data-name="Layer 2">
            <g id="Layer_1-2-2" data-name="Layer 1-2">
              <path className="cls-2" d="M53.39,61.08a2,2,0,0,1-1.5-.59l-.39-.4a2,2,0,0,1,0-2.86l4.83-4.83H31.88a2,2,0,0,1-2-2v-.61a2,2,0,0,1,2-2H56.33L51.5,42.91h0a.46.46,0,0,1-.07-.07h0l0,0-.12-.15-.05-.07,0-.07,0,0a2.1,2.1,0,0,1-.26-1.17v0a2,2,0,0,1,.59-1.25l.39-.39a2,2,0,0,1,1.49-.59l.25,0h.21l.13,0h.12l.09,0h0l.19.11h0a2.24,2.24,0,0,1,.34.27L61,45.9l2.62,2.63A2,2,0,0,1,64.22,50v.2h0a2,2,0,0,1-.59,1.41l-8.9,8.9a2,2,0,0,1-1.18.58h-.16ZM68.66,39.56a1.19,1.19,0,0,1,.88.38h0l.1.13h0v0l0,0h0a1.09,1.09,0,0,1,.14.38s0,.07,0,.1V59.37a1.22,1.22,0,0,1-1.21,1.22H67.36a1.22,1.22,0,0,1-1.21-1.22V40.77a1.21,1.21,0,0,1,1.21-1.21Z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>;
};
export default EnterImage;
