import { call, put, takeLatest } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import types from './types';


function* getCashFlow(action) {
    try {
        const response = yield call(apigClientWrapper.getPrivateCompanyCashFlow, action.companyId);
        yield put({ type: types.GET_PRIVATE_CASH_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({ type: types.GET_PRIVATE_CASH_FAILED, ...apiFailMsg(e) });
    }
}

export default [
    takeLatest(types.GET_PRIVATE_CASH, getCashFlow)
]
