import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import AppReducer from './app';
import UserReducer from './user';
import CompanyReducer from './company';
import DocumentReducer from './document';
import NewsReducer from './news';
import NoteReducer from './note';
import WorksheetReducer from './worksheet';
import IndustryReducer from './industry';
import ProgramReducer from './program';
import AttachmentReducer from './attachment';
import StockReducer from './stock';
import ErrorReducer from './error';
import EarningReducer from './earning';
import TradingReducer from './trading';
import HolderReducer from './holder';
import RatioReducer from './ratio';
import FinancialStatementReducer from './financialStatement';
import FinancialDebtReducer from './financialDebt';
import EstimationReducer from './estimation';
import FinancialsReducer from './financials';
import RiskFlagsReducer from './riskFlags';
import KeyStatsReducer from './keyStats';
import PredictorReducer from './predictor';

import PrivateSubmissionReducer from './priv_submission';
import PrivateWorksheetReducer from './priv_worksheet';
import PrivateCompanyReducer from './priv_company';
import PrivateCompanyFormReducer from './priv_company_form';
import PrivateCompanyEmploymentReducer from './priv_employment';
import PrivateCompanyFiduciaryReducer from './priv_fiduciary';
import PrivateCompanyBalanceReducer from './priv_balance';
import PrivateCompanyCashReducer from './priv_cash';
import PrivateCompanyIncomeReducer from './priv_income';
import PrivateCompanyOwnershipReducer from './priv_ownership';
import PrivateCompanyDataReducer from './priv_company_data';

import Ipopy1Reducer from './ipopy1';

import { companyTypes } from './company';

const allReducers = combineReducers({
    app: AppReducer,
    attachment: AttachmentReducer,
    company: CompanyReducer,
    document: DocumentReducer,
    earning: EarningReducer,
    estimation: EstimationReducer,
    error: ErrorReducer,
    financials: FinancialsReducer,
    financialStatement: FinancialStatementReducer,
    financialDebt: FinancialDebtReducer,
    form: FormReducer,
    holder: HolderReducer,
    industry: IndustryReducer,
    keyStats: KeyStatsReducer,
    news: NewsReducer,
    note: NoteReducer,
    predictor: PredictorReducer,
    privateCompanyData: PrivateCompanyDataReducer,
    privateCompany: PrivateCompanyReducer,
    privateEmployment: PrivateCompanyEmploymentReducer,
    privateCompanyForm: PrivateCompanyFormReducer,
    privateSubmission: PrivateSubmissionReducer,
    privateWorksheet: PrivateWorksheetReducer,
    privateFiduciary: PrivateCompanyFiduciaryReducer,
    privateOwnership: PrivateCompanyOwnershipReducer,
    privateBalance: PrivateCompanyBalanceReducer,
    privateCash: PrivateCompanyCashReducer,
    privateIncome: PrivateCompanyIncomeReducer,
    program: ProgramReducer,
    ratio: RatioReducer,
    riskFlags: RiskFlagsReducer,
    stock: StockReducer,
    trading: TradingReducer,
    user: UserReducer,
    worksheet: WorksheetReducer,
    ipopy1: Ipopy1Reducer,
});


const rootReducer = (state, action) => {
    if (action.type === companyTypes.DESELECT) {
        return allReducers(Object.assign({}, { user: state.user }), action)
    }

    return allReducers(state, action)
}

export default rootReducer;
