import React, { Component } from 'react';
import { getValue } from '../../../helpers/utility';
import DynamicTable from '../../common/dynamicTable';
import DataNotFound from '../../common/dataNotFound';
import MenuTab from '../../common/menuTab';
import MenuTabItem from '../../common/menuTabItem';
import Spinner from '../../common/spinner';
import ErrorAlert from '../../common/errorAlert';
import InfoCard from '../../common/infoCard';
import Info from '../../common/info';
import InfoImage from '../../images/info';

const renderNumber = (number) =>
  getValue(number, false) ? `$${number} mil` : 'N/A';

export class PrivateCompanySPData extends Component {
  state = { view: 'income' };

  render() {
    const { isPDF, data } = this.props;
    const { view } = this.state;
    const viewData = data[view];
    const tableData = viewData && {
      table: {
        columns: [
          { title: 'Item', field: 'item' },
          { title: 'Value', field: 'value' },
        ],
        data:
          view === 'income'
            ? Object.keys(viewData).map((key) => ({
                item: key,
                value: renderNumber(viewData[key]),
                subtotal: true,
              }))
            : [
                {
                  item: 'Total Assets',
                  value: renderNumber(viewData['Total Assets']),
                  subtotal: true,
                },
                {
                  item: 'Cash & Short Term Investments',
                  value: renderNumber(
                    viewData['Cash & Short Term Investments']
                  ),
                  subtotal: true,
                },
                {
                  item: 'Cash',
                  value: renderNumber(viewData['Cash']),
                },
                {
                  item: 'Short Term Investments',
                  value: renderNumber(viewData['Short Term Investments']),
                },
                {
                  item: 'Total Equity',
                  value: renderNumber(viewData['Total Equity']),
                  subtotal: true,
                },
                {
                  item: 'Total Current Liabilities',
                  value: renderNumber(viewData['Total Current Liabilities']),
                  subtotal: true,
                },
                {
                  item: 'Net Property, Plant & Equipment',
                  value: renderNumber(
                    viewData['Net Property, Plant & Equipment']
                  ),
                  subtotal: true,
                },
              ],
      },
    };

    return (
      <div className="panel panel-default">
        <div className="panel-body financial with-spinner">
          <h2>
            S&P platform data{' '}
            <Info text="Source: S&amp;P Cap IQ">
              <InfoImage />
            </Info>
          </h2>
          <div className="row" style={{ marginBottom: '40px' }}>
            <div className="col-xs-3">
              <InfoCard>
                <div>
                  <div className="value">{getValue(data.score)}</div>
                  <div className="value-description">S&P Score</div>
                </div>
              </InfoCard>
            </div>
          </div>
          {!isPDF && (
            <div className="financial-tabs" style={{ marginBottom: '15px' }}>
              <MenuTab initialActiveTab={0}>
                <MenuTabItem action={() => this.setState({ view: 'income' })}>
                  Income Statements
                </MenuTabItem>
                <MenuTabItem action={() => this.setState({ view: 'balance' })}>
                  Balance Sheet
                </MenuTabItem>
              </MenuTab>
            </div>
          )}
          <div className="with-spinner">
          <ErrorAlert message="No match found for S&P financial data" style={{ marginTop: '20px' }} />
            {/* {error && loaded === true ? (
              <ErrorAlert message={error} />
            ) : (
              <Spinner loaded={loaded}>
                {!viewData ? (
                  <DataNotFound />
                ) : (
                  <DynamicTable
                    data={tableData}
                    formatWithCommas
                    className="table"
                  />
                )}
              </Spinner>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateCompanySPData;
