import React, { Component } from 'react';

import Button from '../button';
import styles from './_toggle.module.scss';

export class Toggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value
        };
    }

    componentWillReceiveProps(newProps) {
        this.setState({ value: newProps.value });
    }

    handleClick(event, index) {
        event.preventDefault();
        const value = this.props.options[index].value;
        this.setState({ value: value });
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return (
            <div className={styles.toggle}>
                <div className={styles.inputLabel}>{this.props.label}</div>
                <div className={styles.left}>
                    <Button className="border-gray" clicked={this.props.options[0].value === this.state.value}
                        onClick={(event) => this.handleClick(event, 0)}
                    >
                        {this.props.options[0].label}
                    </Button>
                </div>
                <div className={styles.right}>
                    <Button className="border-gray" clicked={this.props.options[1].value === this.state.value}
                        onClick={(event) => this.handleClick(event, 1)}
                    >
                        {this.props.options[1].label}
                    </Button>
                </div>
                {this.props.required && (this.state.value === undefined || this.state.value === '' || this.state.value === null) && <input required />}
            </div>
        );
    }
}

export default Toggle;
