export default {
    SEARCH: 'DOCUMENT_SEARCH',
    SEARCH_SUCCESS: 'DOCUMENT_SEARCH_SUCCESS',
    SEARCH_FAIL: 'DOCUMENT_SEARCH_FAIL',
    SELECT: 'DOCUMENT_SELECT',
    SELECT_SUCCESS: 'DOCUMENT_SELECT_SUCCESS',
    SELECT_FAIL: 'DOCUMENT_SELECT_FAIL',
    FETCH: 'DOCUMENT_FETCH',
    FETCH_SUCCESS: 'DOCUMENT_FETCH_SUCCESS',
    FETCH_FAILED: 'DOCUMENT_FETCH_FAILED',
    CLEAR: 'DOCUMENT_CLEAR',
    OPEN_PDF: 'DOCUMENT_OPEN_PDF',
    OPEN_TXT: 'DOCUMENT_OPEN_TXT',
    RESET: 'DOCUMENT_RESET',
    BADACTORS_SEARCH: 'BADACTORS_SEARCH_GET',
    BADACTORS_SEARCH_SUCCESS: 'BADACTORS_SEARCH_GET_SUCCESS',
    BADACTORS_SEARCH_FAIL: 'BADACTORS_SEARCH_GET_FAIL',
}
