import React, { Component } from 'react';
import { connect } from 'react-redux';
import NoteController from '../../note/noteController';
import Company8KFilings from './company8KFilings';
import CompanyNewsItems from './companyNewsItems';

export class CompanyNews extends Component {

    render() {
        const className = `company-news ${this.props.isPDF !== true && 'container'}`;

        return (
            <div className={className}>
                <CompanyNewsItems />

                <Company8KFilings isPDF={this.props.isPDF} />

                <NoteController section="news" />
            </div>
        );
    }
}

export default connect(null, {

})(CompanyNews);
