import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getEarning = (state) => state.earning;

function* fetchEarning(action) {
  let company = yield select(getEarning);
  if (company.data === null) {
    try {
      const response = yield call(apigClientWrapper.getCompanyEarnings, action.companyId);
      yield put({ type: actions.GET_EARNING_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: actions.GET_EARNING_FAIL, ...apiFailMsg(e) });
    }
  }
}

export default [
  takeEvery(actions.GET_EARNING, fetchEarning),
]
