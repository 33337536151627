import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: {},
    error: null,
    loaded: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_KEY_STATS_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.stats,
                    loaded: true,
                    [action.peerGroup]: _.get(action, 'payload.data')
                }
            };
        default:
            return state;
    }
}
