import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getWorksheetDeleteDate } from '../../../helpers/utility';
import Tag from '../../common/tag';
import Info from '../../common/info';
import InfoImage from '../../images/info';
import CompanyCreateWorksheet from '../createWorksheet/companyCreateWorksheet';
import NotificationAlert from '../../images/notification-alert';
import storageWrapper from '../../../helpers/storageWrapper';

class CompanyHeaderSummary extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { company, isPDF } = this.props;
    const tooltipAGR = `It's a comprehensive measure of corporate integrity which studies results and corporate behavior.
      Updated quarterly, the AGR score ranges from 0 - 100:

      Very Aggressive – 0 - 10 comprising 10% of North American companies

      Aggressive – 11 - 35 , comprising 25% of North American companies

      Average – 36 - 85, comprising 50% of North American companies

      Conservative – 86 - 100, comprising 15% of North American companies`;

    let warningMessage = null;
    if (this.props.worksheet && this.props.worksheet.submission) {
      if (this.props.worksheet.submission.state !== 'archived') {
        const deleteDate = getWorksheetDeleteDate(this.props.worksheet);

        if (deleteDate !== null) {
          warningMessage = (
            <div id="alert-warning-wrapper">
              <div className="alert-warning">
                <NotificationAlert />
                <span>
                  Worksheet will be deleted on {deleteDate.format('MM/DD/YYYY')}
                  . Please make sure you archive to IR before then
                </span>
              </div>
            </div>
          );
        }
      }
    }
    let sweetSpot = 'N/A';
    if (
      company.rounded_sweetspot_char ||
      company.rounded_sweetspot_char_bermuda
    ) {
      if (
        ['underwriter_bermuda'].indexOf(
          storageWrapper.getState('userProfile').appi_role
        ) === -1
      ) {
        sweetSpot = company.rounded_sweetspot_char;
      } else {
        sweetSpot = company.rounded_sweetspot_char_bermuda;
      }
    } else {
      sweetSpot = company.sweet_spot;
    }

    return (
      <div className="company-header-summary">
        {warningMessage}
        <div id="company-summary" className="container">
          <div className="row">
            <div className="col-xs-12">
              <span className="title">
                {company.name} ({company.ticker || "'-'"})
                {company.otc_flag == 1 && (
                  <Tag text={'OTC'} className={'darkblue otc'} />
                )}
                {company.company_type === 'private' && (
                  <Tag text="Private" className={'lime-green otc'} />
                )}
              </span>
            </div>
          </div>
          {company.company_type === 'public' && (
            <div className="row">
              <div className="col-xs-12">
                <span className="title-sic">{company.sic}</span>
              </div>
            </div>
          )}
          {isPDF !== true ? (
            <div className="details">
              {company.company_type === 'public' && (
                <div className="row">
                  <div className="col-xs-3">
                    <div className="row">
                      <div className="col-xs-4 faint">Headquarters</div>
                      <div className="col-xs-8">{company.headquarters}</div>
                    </div>
                    <div className="row">
                      <div className="col-xs-4 faint">Incorporated</div>
                      <div className="col-xs-8">
                        {company.incorporationcountryname}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-4 faint">Website</div>
                      <div id="company-website-col" className="col-xs-8">
                        <a
                          className="company-website"
                          rel="noopener noreferrer"
                          target="_blank"
                          href={'http://' + company.website}
                        >
                          {company.website}
                        </a>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-4 faint">Phone</div>
                      <div className="col-xs-8">{company.phone}</div>
                    </div>
                  </div>
                  <div className="col-xs-8 col-xs-offset-1">
                    <table width="100%">
                      <tbody>
                        <tr>
                          <td>
                            <h3>
                              {company.market_cap}{' '}
                              <span className="price-delta up">
                                {company.market_cap_price_delta || 'n/a'}
                              </span>
                            </h3>
                          </td>
                          <td>
                            <h3>{company.cfra_risk_score || 'N/A'}</h3>
                          </td>
                          <td>
                            <h3>{company.msci_agr_rating || 'N/A'}</h3>
                          </td>
                          <td>
                            <h3>{company.msci_governance || 'N/A'}</h3>
                          </td>
                          <td>
                            <h3>
                              {sweetSpot || 'N/A'}
                              {sweetSpot &&
                                company.predictive_model === 'ipo_yr2' && (
                                  <span className="price-delta">
                                    {' '}
                                    (YR 2 Model)
                                  </span>
                                )}
                            </h3>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="faint">Market Cap</div>
                          </td>
                          <td>
                            <div className="faint">
                              CFRA Risk Score{' '}
                              <Info text="This is an earnings score that leverages earnings trends and persistence to assess accrual reliability. The higher the score the riskier the company is. It ranges between 1 and 10.">
                                <InfoImage />
                              </Info>
                            </div>
                          </td>
                          <td>
                            <div className="faint">
                              MSCI AGR Rating
                              <Info text={tooltipAGR}>
                                <InfoImage />
                              </Info>
                            </div>
                          </td>
                          <td>
                            <div className="faint">
                              MSCI Governance Metrics
                              <Info text="It's a 0 - 10 score that assesses governance based on board pay, ownership & control and accounting considerations. 10 is a perfect score and points are deducted based on negative triggering events">
                                <InfoImage />
                              </Info>
                            </div>
                          </td>
                          <td>
                            <div className="faint">
                              Sweet Spot{' '}
                              <Info text="Optimal attachment point given the financial and governance health of the company">
                                <InfoImage />
                              </Info>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td>
                            {company.cfra_as_of_date && (
                              <div className="faint">
                                As of {company.cfra_as_of_date}
                              </div>
                            )}
                          </td>
                          <td>
                            {company.msci_agr_as_of_date && (
                              <div className="faint">
                                As of {company.msci_agr_as_of_date}
                              </div>
                            )}
                          </td>
                          <td>
                            {company.msci_gm_as_of_date && (
                              <div className="faint">
                                As of {company.msci_gm_as_of_date}
                              </div>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <table id="pdf-summary-table">
              {company.company_type === 'public' && (
                <tbody>
                  <tr>
                    <td>Headquarters</td>
                    <td>{company.headquarters}</td>
                    <td className="vertical-top" rowSpan="3">
                      <table>
                        <tbody>
                          <tr>
                            <td>{company.market_cap}</td>
                            <td>{company.cfra_risk_score}</td>
                            <td>{company.msci_agr_rating}</td>
                            <td>{company.msci_governance}</td>
                            <td>{sweetSpot}</td>
                          </tr>
                          <tr>
                            <td>
                              <span className="faint">Market Cap</span>
                            </td>
                            <td>
                              <span className="faint">CFRA Risk Score</span>
                            </td>
                            <td>
                              <span className="faint">MSCI AGR Rating</span>
                            </td>
                            <td>
                              <span className="faint">
                                MSCI Governance Metrics
                              </span>
                            </td>
                            <td>
                              <span className="faint">Sweet Spot</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>Incorporated</td>
                    <td>{company.incorporationcountryname}</td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>{company.website}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{company.headquarters}</td>
                  </tr>
                </tbody>
              )}
            </table>
          )}

          {isPDF !== true && <CompanyCreateWorksheet />}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    worksheet: _.get(state, 'worksheet.selected'),
    company: _.get(state, 'company.selected') || {},
    company_list: _.get(state, 'company.list') || [],
  };
}

export default connect(mapStateToProps)(CompanyHeaderSummary);
