import { Component } from 'react';
import { connect } from 'react-redux';
import { openDocumentPDFAction, documentResetAction } from '../../ducks/document/actions';

export class DocumentPdf extends Component {
    componentDidMount() {
        this.props.openDocumentPDFAction(this.props.url);
        window.open(this.props.url, '_self')
    }

    render() {
        return null;
    }
    componentWillUnmount() {
        this.props.documentResetAction();
    }
}

export default connect(null, {
    openDocumentPDFAction,
    documentResetAction
})(DocumentPdf);
