import React, { Component } from 'react';
import { connect } from 'react-redux';


import PrivateFinancialStatement from './privateFinancialStatement';
import PrivateOwnership from './privateFinancialOwnership';

import NoteController from '../../note/noteController'


export class PrivateOverview extends Component {
    render() {

        return (
            <div className='container'>
                <div style={{ height: '50px' }}></div>
                <PrivateFinancialStatement/>
                <PrivateOwnership/>
                {/* <PrivateInfo {...infoProps} />
                <PrivateEmployees {...employeesProps} />
                <PrivateFiduciary {...fiduciaryProps}/>   
                <PrivateLitigation {...litigationProps}/>
                <PrivateEEOC {...EEOCProps}/>  */}
                <NoteController section="financial"/>
            </div>
        );
    }
}


export default connect(null, {

})(PrivateOverview);
