import React from 'react';

const ProfileImage = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 377 375">
	<path d="M188.5,0C84.9,0,1,83.9,1,187.5C1,291.1,84.9,375,188.5,375S376,291.1,376,187.5C376,83.9,292.1,0,188.5,0z M188.5,356.2
		c-93.2,0-168.8-75.6-168.8-168.8S95.3,18.8,188.5,18.8s168.8,75.6,168.8,168.8S281.7,356.2,188.5,356.2z"/>
	<path d="M282.7,241.2c-21.3-15.1-40.2-23.4-62.1-27.2v-10.8c13.4-10,21.9-25.7,21.9-43.6v-21c0.2-30-24-54.2-54-54.2
		s-54.2,24.2-54.2,54.2v21.3c0,17.9,8.7,33.6,21.9,43.6v10.8c-21.9,3.8-40.8,12.1-61.9,27.2c-2.3,1.7-3.6,4.3-3.6,7v33.8
		c0,4.7,3.8,8.5,8.5,8.5h178.6c4.7,0,8.5-3.8,8.5-8.5v-34C286.3,245.4,285,242.9,282.7,241.2z M269.3,273.5H107.7v-20.8
		c19.6-13.4,37.2-20.2,58-22.7c4.3-0.4,7.4-4.3,7.4-8.5v-9.8c4.9,1.5,10,2.1,15.3,2.1s10.4-0.9,15.3-2.1v9.8c0,4.3,3.2,7.9,7.4,8.5
		c20.8,2.6,38.5,9.4,58,22.7V273.5z M151.3,138.5c0-20.4,16.8-37.2,37.2-37.2s37.2,16.8,37.2,37.2v21.3c0,20.4-16.8,37.2-37.2,37.2
		s-37.2-16.8-37.2-37.2V138.5z"/>
</svg>;
};
export default ProfileImage;
