import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from '../../../lodashWrapper';
import NoteController from '../../note/noteController';
import Spinner from '../../common/spinner';
import ErrorAlert from '../../common/errorAlert';
import CompanyEstimationChart from './companyEstimationChart';
import CompanyFinancialChart from './companyFinancialChart';
import CompanyKeyStats from './companyKeyStats';
import CompanyRiskFlags from './companyRiskFlags';
import CompanyStockChart from './companyStockChart';
import CompanyHolders from './companyHolders';
import CompanyInfo from './companyInfo';

export class CompanyOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { filter: null };
  }

  render() {
    const { company, companySelectError } = this.props;

    return (
      <div className="company-overview container with-spinner">
        {companySelectError ? (
          <ErrorAlert message={companySelectError} />
        ) : (
          <Spinner loaded={!!company?.company_type}>
            <React.Fragment>
              <React.Fragment>
                <CompanyInfo />
                <CompanyStockChart />
                <CompanyRiskFlags />
                <CompanyHolders />
                <CompanyKeyStats />
                <div className="row">
                  <div className="col-sm-12 col-md-3">
                    <CompanyEstimationChart />
                  </div>
                  <div className="col-sm-12 col-md-9">
                    <CompanyFinancialChart />
                  </div>
                </div>
              </React.Fragment>
              <NoteController section="overview" />
            </React.Fragment>
          </Spinner>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  company: _.get(state, 'company.selected') || {},
  companySelectError: _.get(state, `error.COMPANY_SELECT`),
}))(CompanyOverview);
