import _ from '../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import { withRouter } from 'react-router';
import DocumentPdf from './documentPdf';
import DocumentTxt from './documentTxt';
import { selectDocumentAction, fetchDocumentAction, clearDocumentAction } from '../../ducks/document/actions';
import ErrorAlert from '../common/errorAlert';

export class Document extends Component {
    componentWillMount() {
        const queryParams = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true });
        this.companyId = this.props.match.params.companyId;
        this.documentId = this.props.match.params.documentId;
        this.documentType = queryParams.type;
    }

    componentDidMount() {
        this.props.selectDocumentAction(this.companyId, this.documentId, this.documentType)
    }

    componentWillUnmount() {
        this.props.clearDocumentAction();
    }

    render() {
        if (this.props.error) {
            return <ErrorAlert message={this.props.error} />
        }
        if (!this.props.document.url) {
            return <span>Loading...</span>;
        }
        return (
            <div className="document">
                {'sca' === this.documentType ?
                    <DocumentPdf url={this.props.document.url} documentType={this.documentType} /> :
                    <DocumentTxt url={this.props.document.url} documentType={this.documentType} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        document: _.get(state, 'document.selected'),
        error: _.get(state, 'error.DOCUMENT_SELECT'),
    };
}

export default withRouter(connect(mapStateToProps, {
    selectDocumentAction,
    fetchDocumentAction,
    clearDocumentAction
})(Document));
