import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Info from "../../common/info";
import InfoCard from "../../common/infoCard";
import DataNotFound from "../../common/dataNotFound";
import BadActorsTable from '../../common/badActorsTable';
import MenuTab from "../../common/menuTab";
import MenuTabItem from "../../common/menuTabItem";
import Spinner from '../../common/spinner'
import FlagImage from "../../images/flag";
import InfoImage from "../../images/info";
import {
    getCompanyHoldersAction,
    getCompanyHoldersListAction,
    holdersTabChangedAction,
} from '../../../ducks/holder/actions';

import {

    stockChartVariationCardClickedAction
} from '../../../ducks/stock/actions';
import {
    getBadActorsAction
} from '../../../ducks/document/actions';
import ErrorAlert from '../../common/errorAlert';

export class CompanyHolders extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: this.props.activeTab ? this.props.activeTab : 'direct' };
        this.holdersTabChanged = this.holdersTabChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.getCompanyHoldersAction(this.props.companyId);
            this.fetchHoldersList(this.state.activeTab);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyHoldersAction(this.props.companyId);
            this.fetchHoldersList(this.state.activeTab);
        }
    }

    fetchHoldersList(type) {
        this.setState({ activeTab: type });
        this.props.getCompanyHoldersListAction(this.props.companyId, type);
    }

    fetchBadActors(mscadIds, personId) {
        this.props.getBadActorsAction(mscadIds, personId);
    }

    holdersTabChanged(index) {
        let tabName = '';
        switch (index) {
            case 0:
                tabName = 'direct';
                break;
            case 1:
                tabName = 'transaction';
                break;
            case 2:
                tabName = 'institution';
                break;
            default:
                return;
        }
        this.setState({ activeTab: tabName });
        this.props.holdersTabChangedAction(index, tabName);
    }

    render() {
        let content;
        if (this.props.companyHolderListError) {
            content = <ErrorAlert message={this.props.companyHolderListError} />
        } else {
            content = <Spinner loaded={this.props.companyHolder}>
                <div>
                    <div className="with-spinner">
                        <Spinner loaded={this.props.companyHolderList[this.state.activeTab]}>
                            {this.props.companyHolderList[this.state.activeTab] === 'direct' && <div className='legend'>
                                <FlagImage />
                                <div className='value'>Bad Actor</div>
                                <Info text="Flag to identify D&amp;O that have been linked in the past to class actions or misbehaviors.">
                                    <InfoImage />
                                </Info>
                            </div>}

                            {this.props.companyHolderList[this.state.activeTab] && Object.keys(this.props.companyHolderList[this.state.activeTab]).length === 0 ? <DataNotFound /> :
                                <BadActorsTable callAPI={this.fetchBadActors.bind(this)} className={classNames(['table', 'last', this.state.activeTab])}
                                    persons={this.props.persons} data={{ table: this.props.companyHolderList[this.state.activeTab] }} />
                            }

                        </Spinner>
                    </div>
                </div>

            </Spinner>
        }



        return (
            <div className='panel panel-default'>
                <div className='panel-body holders with-spinner'>
                    <h2 className='inline'>{this.props.componentTitle ? this.props.componentTitle : 'Holders'}</h2>

                    <Info text='Source: S&amp;P Cap IQ'><InfoImage /></Info>

                    {this.props.showHolders !== false && <div>
                        {!this.props.companyHolder || Object.keys(this.props.companyHolder).length === 0 ? null : <div>
                            <div className="row">
                                <div className='col-xs-3 info'>
                                    <InfoCard>
                                        <div>
                                            <div className="value">{this.props.companyHolder.percent_all_insider_shares}%</div>
                                            <div className="value-description">% of Shares Held by All Insider</div>
                                        </div>
                                    </InfoCard>
                                </div>
                                <div className='col-xs-3 info'>
                                    <InfoCard>
                                        <div>
                                            <div className="value">{this.props.companyHolder.last_filed}</div>
                                            <div className="value-description">Last Filed Date</div>
                                        </div>
                                    </InfoCard>
                                </div>
                            </div>
                        </div>}
                    </div>}
                    {!this.props.isPDF &&
                        <MenuTab initialActiveTab={0} className={'holders'} activated={(index) => {
                            this.holdersTabChanged(index);
                        }}>
                            <MenuTabItem
                                action={() => this.fetchHoldersList('direct')}>
                                Direct Holders
                    </MenuTabItem>
                            <MenuTabItem
                                action={() => this.fetchHoldersList('transaction')}>
                                Last 12M Insider Transaction
                    </MenuTabItem>
                            <MenuTabItem
                                action={() => this.fetchHoldersList('institution')}>
                                Institutional Holders
                    </MenuTabItem>
                        </MenuTab>
                    }


                    {content}


                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        companyHolder: _.get(state, 'holder.holder'),
        persons: _.get(state, 'document.persons') || {},
        holderListLoaded: _.get(state, 'holder.holderListLoaded') || false,
        companyHolderList: _.get(state, 'holder.holdersData') || {},
        companyHolderListError: _.get(state, 'error.COMPANY_GET_HOLDER_LIST')
    };
}

export default connect(mapStateToProps, {
    getBadActorsAction,
    getCompanyHoldersAction,
    getCompanyHoldersListAction,
    holdersTabChangedAction,
    stockChartVariationCardClickedAction
})(CompanyHolders);
