import React from 'react';
import styles from './_infoCard.module.scss';

export const InfoCard = (props) => {
    return (
        <div className={`info-card ${styles.infoCardContainer}`}>
            <div className={`${props.className ? props.className: ''}`}>
                {props.children}
            </div>
        </div>
    );
};

export default InfoCard;
