import classNames from 'classnames';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import RichTextEditor from 'react-rte';
import constants from '../../constants/';
import { editNoteAction } from '../../ducks/note/actions';
import { autoUpdateWorksheetAction, showWorksheetModalAction, updateWorksheetAction } from '../../ducks/worksheet/actions';
import _ from 'lodash';
import Button from '../common/button';

export class Note extends Component {
    generatePdf() {
        const { history, location } = this.props;
        this.props.close();
        this.handleFilter = this.handleFilter.bind(this);
        history.push(`/company/${this.props.companyId}/pdf${location.search}`);
    }

    onNoteChange(noteValue) {
        this.props.editNoteAction(noteValue);
        this.handleFilter(this.props.worksheet, noteValue, this.props.currentSection);
    }

    handleFilter = debounce((worksheet, e, section) => {
        this.props.autoUpdateWorksheetAction(this.props.companyId, worksheet, section, e)
    }, 500)

    render() {
        // const id = _.get(this, 'props.match.params.id');
        const submission = this.props.worksheet.submission || {};
        let placeHolder = '';

        switch (this.props.currentSection) {
            case 'overview':
                placeHolder = `New or Renewal\nAccount description, or Opportunity\nTerms and Conditions\nSummary and recommendations\nPros and cons\nAuthority`;
                break;
            case 'trade':
                placeHolder = `Notes on institutional / insider holders\nNotes on insider transactions\nNotes on D&O compensation, other potential concerns`;
                break;
            case 'financial':
                placeHolder = 'Notes on financial statements\nNotes on ratios, debt maturity etc';
                break;
            case 'risk':
                placeHolder = `History on account\nProgram details`;
                break;
            case 'news':
                placeHolder = `Notes on additional news\nNotes on main stock variations with related news `;
                break;
        }

        let renewalType = '';
        if (submission.renewal === false) {
            renewalType = 'No';
        } else if (submission.renewal === true) {
            renewalType = 'Yes';
        }

        return (
            <div className={classNames('user-note', { open: this.props.isOpen })}>
                <div className="submission-detail">
                    <div className="header">
                        <span className="title">Submission Detail</span>
                        <span className="pull-right">
                            <Button onClick={() => this.props.showWorksheetModalAction()}>
                                Edit Submission
                            </Button>
                        </span>
                    </div>
                    <div className="detail">
                        <span className="name">Submission Number</span>
                        <span className="value">{submission.submission_id}</span>
                    </div>
                    <div className="detail">
                        <span className="name">Policy Number</span>
                        <span className="value">{submission.policy_id}</span>
                    </div>
                    <div className="detail">
                        <span className="name">Agency</span>
                        <span className="value">{submission.agency_id}</span>
                    </div>
                    <div className="detail">
                        <span className="name">Effective Date</span>
                        <span className="value">{submission.effective_date}</span>
                    </div>
                    <div className="detail">
                        <span className="name">Type</span>
                        <span className="value">{submission.type}</span>
                    </div>
                    <div className="detail">
                        <span className="name">Renewal</span>
                        <span className="value">{renewalType}</span>
                    </div>
                </div>
                <div className="editor">
                    {this.props.noteValue && <RichTextEditor
                        placeholder={placeHolder}
                        value={this.props.noteValue}
                        onChange={(value) => this.onNoteChange(value)}
                    />}
                    <div className="footer clearfix">
                        <span>Last saved on {moment(this.props.noteUpdateDate).format('MM/DD/YYYY HH:mm:ss')}</span>
                        <span className="pull-right">
                            <Button onClick={() => this.generatePdf()}>
                                Generate PDF
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const sectionMap = constants.note.sectionMap;
    return {
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
        companyId: _.get(state, 'company.selected.company_id'),
        worksheet: _.get(state, 'worksheet.selected'),
        noteValue: _.get(state, `note.data.${state.note.currentSection}`),
        currentSection: _.get(state, `note.currentSection`),
        noteUpdateDate: _.get(state, `worksheet.selected.notes.${sectionMap[state.note.currentSection]}.updated_at`)
    };
}

export default withRouter(connect(mapStateToProps, {
    editNoteAction,
    autoUpdateWorksheetAction,
    updateWorksheetAction,
    showWorksheetModalAction
})(Note));
