// import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import CompanyHeaderMenu from './privateHeaderMenu';
// import qs from 'qs';
import CompanyHeaderSummary from './privateHeaderSummary';
import styles from './_companyHeader.module.scss';


class PrivateHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        // const companyId = _.get(this, 'props.match.params.companyId');
        // const worksheetId = _.get(qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}), 'worksheetId');
        // if (companyId) {
        //     this.props.selectCompanyAction(companyId);
        // }
        // if (worksheetId) {
        //     this.setState({lastFetchedWorksheetId: worksheetId});
        //     this.props.getWorksheetAction(worksheetId);
        // }
    }

    componentDidUpdate() {
        // const worksheetId = _.get(qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}), 'worksheetId');
        // if (worksheetId !== this.state.lastFetchedWorksheetId) {
        //     this.setState({lastFetchedWorksheetId: worksheetId});
        //     this.props.getWorksheetAction(worksheetId);
        // }
    }

    componentWillUnmount() {
        // this.props.worksheetClearSubmissionAction();
    }

    render() {
        
        // const pattern = /^\/company\/\d+\/pdf/g
        // const hideHeader = pattern.test(window.location.pathname) === true ? "hidden" : "";
        // const class1 = styles.privateHeader + hideHeader;
        const class1 = styles.privateHeader;
        return (
            <div className={class1} >
                <Route path='/private' component={CompanyHeaderSummary}/>
                <Route path='/private/:companyId/:view?' component={CompanyHeaderMenu}/>
            </div>
        );
    }
}


export default PrivateHeader
