import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getHolder = (state) => state.holder;

function* getHolders(action) {
  let holder = yield select(getHolder);
  if (holder.holder === null) {
    try {
      const response = yield call(apigClientWrapper.getCompanyHolders, action.companyId);
      yield put({ type: actions.GET_HOLDER_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: actions.GET_HOLDER_FAIL, ...apiFailMsg(e) });
    }
  }
}

function* getCompanyHoldersList(action) {
  let holder = yield select(getHolder);
  if (holder.holdersData[action.requestType] === undefined) {
    try {
      const response = yield call(apigClientWrapper.getCompanyHoldersList, action.companyId, action.requestType);
      yield put({ type: actions.GET_HOLDER_LIST_SUCCESS, payload: response.data, requestType: action.requestType });
    } catch (e) {
      yield put({ type: actions.GET_HOLDER_LIST_FAIL, ...apiFailMsg(e) });
    }
  }
}


export default[
  takeEvery(actions.GET_HOLDER, getHolders),
  takeEvery(actions.GET_HOLDER_LIST, getCompanyHoldersList),
]
