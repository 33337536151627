import actions from './types';

export function searchNewsAction(companyId , filter={}) {

    return {
        type: actions.SEARCH,
        companyId: companyId,
        filter: filter
    }
}
