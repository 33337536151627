import _ from 'lodash';
import actions from './types';

const defaultState = {selected: {}, initialized: false};

export default function (state = defaultState, action) {
    switch (action.type) {
        case actions.SEARCH_SUCCESS:
            return {
                ...state,
                list: _.get(action, 'payload.results')
            };
        case actions.SELECT_SUCCESS:

            return {
                ...state,
                initialized: true,
                selected: _.get(action, 'payload') || {}
            };
        case actions.UPDATE_SUCCESS:
        case actions.AUTO_NOTE_UPDATE_SUCCESS:
            return {
                ...state,
                selected: _.get(action, 'payload.data') || {}
            };
        case actions.CLEAR_SUBMISSION:
            return {
                ...state,
                selected: {}
            };
        case actions.GENERATE_WORKSHEET_PDF_START:
            return {
                ...state,
                pdfStatusError: undefined,
                generatingPDF: true
            };
        case actions.GENERATE_WORKSHEET_PDF_SUCCESS:
            return {
                ...state,
                pdfURL:  _.get(action, 'payload.url'),
                generatingPDF: false
            };
        case actions.UPDATE_WORKSHEET_PDF: 
            return {
                ...state,
                updatingPDF: true
            };
        case actions.ARCHIVE_WORKSHEET: 
            return {
                ...state,
                archivingWorksheet: true
            };
        case actions.ARCHIVE_WORKSHEET_SUCCESS: 
            return {
                ...state,
                archivingWorksheet: false,
                archivingWorksheetSuccess: true
            };
        case actions.ARCHIVE_WORKSHEET_FAILED: 
            return {
                ...state,
                archivingWorksheet: false,
                archivingWorksheetSuccess: false
            };
        case actions.UPDATE_WORKSHEET_PDF_SUCCESS: 
            return {
                ...state,
                updatingPDF: false
            };
        case actions.GET_WORKSHEET_PDF_PRESIGNEDURL_SUCCESS:
            return {
                ...state,
                presignedUrl: action.payload.url
            };

        case actions.GET_WORKSHEET_PDF_PRESIGNEDURL_FAILED:
                return {
                    ...state,
                    presignedUrl: undefined,
                    pdfStatusError: true, 
                };
        case actions.CLEAR_WORKSHEET_PDF_PRESIGNEDURL:
            return {
                ...state,
                presignedUrl: undefined
            };
        case actions.CLEAR_WORKSHEET_PDF: 
            return {
                ...state,
                pdfURL:  undefined,
                pdfStatusError: undefined, 
                generatingPDF: undefined,
                updatingPDF: undefined
            };
        case actions.SELECT_COMPANY:
            var exist = state.list.find((x) => x.cik === action.companyId);
            if(exist !== undefined){
                return {
                    ...state,
                    selected: exist
                }
            } else {
                return {
                    ...state,
                    selected: {}}
                }
        case actions.SHOW_MODAL:
            return {
                ...state,
                isModalVisible: true,
                viewOnlyBtnHidden: !!_.get(action, 'payload.viewOnlyBtnHidden')
            };
        case actions.HIDE_MODAL:
            return {
                ...state,
                isModalVisible: false
            };
        default:
            return state;
    }
}
