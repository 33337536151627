import React from 'react';
import styles from './_spinner.module.scss';

export const Spinner = (props) => {
    return (
        <div>
            {props.loaded ? props.children : <div className={styles.loader}></div> }
        </div>
    );
};

export default Spinner;
