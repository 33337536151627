import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: null,
    error: null,
    loaded: false
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_FINANCIALS_SUCCESS:
            return {
                ...state,
                data: _.get(action, 'payload'),
                [`financial_${_.get(action, 'range')}`]: _.get(action, 'payload')
            };
            
        default:
            return state;
    }
}
