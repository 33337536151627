import _ from 'lodash';
import actions from './types';

const defaultState = {};

export default function (state = defaultState, action) {
    switch (action.type) {
        case actions.GET_SUCCESS: 
            return {
                ...state, data: _.get(action, 'payload'), companyId: _.get(action, 'companyId'), loaded: true
            };
        default:
            return state;
    }
}
