import _ from '../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import storageWrapper from "../../helpers/storageWrapper";
import { withRouter } from 'react-router';
import { initAction } from "../../ducks/user/actions";
import { deselectCompanyAction } from "../../ducks/company/actions";
import { showNoteAction, hideNoteAction } from "../../ducks/note/actions";
import FeedbackImage from '../images/feedback';
import Note from '../note/note';
import UserMenu from './userMenu';
import { Link } from 'react-router-dom'

import NoteImage from '../images/note';
import ProfileImage from '../images/profile';
import { showWorksheetModalAction } from "../../ducks/worksheet/actions";

export class AppBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUserMenuVisible: false,
            noteEditorOpen: false
        }
    }

    componentWillMount() {
        let accessToken;

        try {
            accessToken = storageWrapper.getState('accessToken');
        }
        catch (e) {
            storageWrapper.clearState('accessToken');
        }

        if (accessToken) {
            this.props.initAction().then((action) => {
                if ('Network Error' === action.payload.message) {
                    this.props.history.push('/');
                }
                else if (_.get(this, 'props.history.location.pathname') === '/') {
                    this.props.history.push('/company');
                }
            });
        } else {
            this.props.history.push('/');
        }
    }

    onSubmitLoginForm(values) {
        if (!values.query) {
            return;
        }
        this.props.history.push(`/company?query=${values.query}`);
        this.props.reset();
    }

    closePdfView() {
        const { history, location } = this.props;
        const companyId = _.get(this, 'props.match.params.companyId');
        history.push(`/company/${companyId}/overview${location.search}`);
    }

    closeNote() {
        this.props.hideNoteAction();
    }

    showOrHideNote() {
        this.setState({
            isUserMenuVisible: false
        });
        if (this.props.isNoteVisible) {
            this.props.hideNoteAction();
        } else {
            this.props.showNoteAction();
        }
    }

    showOrHideUserMenu() {
        this.setState({
            isUserMenuVisible: !this.state.isUserMenuVisible,
            noteEditorOpen: false
        });
        this.props.hideNoteAction();
    }

    handleMyWorksheets() {
        this.props.history.push('/company?query=');
    }

    handleMyNotes() {
        if (this.props.isWorksheetCreated) {
            this.showOrHideNote();
        } else {
            this.props.showWorksheetModalAction({ viewOnlyBtnHidden: true });
        }
    }

    handleLogo() {
        if (this.props.isUserLogged) {
            this.props.history.push('/company?query=');
        }
    }

    renderRight() {
        if (!this.props.isUserLogged) {
            return;
        } else if ('pdf' === this.props.match.params.view) {
            return (
                <div>
                    {/* <span className="print"><img src="/images/print.svg"/></span>
                    <span className="download"><img src="/images/download.svg"/></span> */}
                    <span className="close"
                        onClick={() => this.closePdfView()}
                    >
                        <img src="/images/x.svg" />
                    </span>
                </div>
            );
        } else {
            const noteClass = this.state.noteEditorOpen ? "note open" : "note";
            
            return (
                <div>
                    {this.props.isCompanySelected ?
                        <span className='submission' onClick={() => this.handleMyWorksheets()}>My Worksheets</span>
                        : null}
                    {this.props.isCompanySelected ?
                        <span className={noteClass} onClick={() => this.handleMyNotes()}>
                            <NoteImage />
                        </span>
                        : null}
                    <span className="profile" onClick={() => this.showOrHideUserMenu()}>
                        <ProfileImage />
                        {!this.state.isUserMenuVisible ? null : <UserMenu />}
                    </span>
                </div>
            );
        }
    }

    render() {
        if (/^\/company\/.*\/document/.test(this.props.match.url)) {
            return null;
        }

        const submit = (data) => this.onSubmitLoginForm(data);
        const { handleSubmit } = this.props;
        const bodyLines = [
            'Below are templates for issues or feature requests. Delete whichever you do not need.',
            'Please feel free to add screenshots as attachments.',
            '',
            'ISSUE REPORT',
            '<Steps to reproduce>',
            '',
            '<Expected behavior>',
            '',
            'FEATURE REQUEST',
            '<Description>',
            '',
            '<Rationale>'
        ];

        const mailTo = `mailto:jira@argodigital.atlassian.net?cc=paul.duca@argogroupus.com&subject=<Enter your issue or feature request here>&body=${bodyLines.join('%0D')}`;

        return (
            <div className='app-bar'>
                <span id="feeback-wrapper">
                    <a href={mailTo}>
                        <FeedbackImage />
                        <span id="feedback-text">Feedback</span>
                    </a></span>

                <div className='clearfix content'>
                    <div className='left'>
                        <div>
                            <form id='searchFrom' onSubmit={handleSubmit(submit)}>
                                <Link to='/company?query=' onClick={() => {
                                    if (this.props.location.pathname !== '/company'){
                                        this.props.deselectCompanyAction();
                                    }
                                }}>
                                    <img id='logo' src='/images/argo-logo.svg' />
                                </Link>

                                {this.props.isUserLogged && <div className='input'>
                                    <Field name='query'
                                        placeholder='Start Searching'
                                        type='text'
                                        component='input'
                                        className="data-hj-whitelist"
                                    />
                                    <span className="icon cursor-pointer">
                                        <img onClick={handleSubmit(submit)} src='/images/search.svg' />
                                    </span>
                                </div>}
                            </form>
                        </div>
                    </div>
                    <div className='right'>
                        <div className="wrapper">
                            {this.renderRight()}
                        </div>
                    </div>
                </div>
                <Note isOpen={this.props.isNoteVisible} close={() => this.closeNote()} />
            </div>
        );
    }
}

const form = reduxForm({ form: 'search' })(AppBar);

function mapStateToProps(state) {
    return {
        isUserLogged: _.get(state, 'user.user_id'),
        isWorksheetCreated: _.get(state, 'worksheet.selected.worksheet_id'),
        isNoteVisible: state.note.isVisible,
        isCompanySelected: Object.keys(_.get(state, 'company.selected')).length > 0
    };
}

export default withRouter(connect(mapStateToProps, {
    initAction,
    showNoteAction,
    hideNoteAction,
    showWorksheetModalAction,
    deselectCompanyAction
})(form));