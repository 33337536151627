import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../common/spinner';
import DynamicTable from '../../common/dynamicTable';
import { getOwnerships } from '../../../ducks/priv_ownership/actions';
import _ from 'lodash';
import ErrorAlert from '../../common/errorAlert';

export class PrivateOwnership extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount() {
        if (this.props.companyId) {
            this.props.getOwnerships(this.props.companyId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getOwnerships(this.props.companyId);
        }
    }


    render() {
        let content;
        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />
        } else {
            content = <Spinner loaded={this.props.loaded}>
                {this.props.loaded && <DynamicTable className="table" data={this.props.data} />}
            </Spinner>;
        }
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>Ownership{this.props.asOf && ` (as of ${this.props.asOf})`}</h2>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'privateCompany.selected.companyId'),
        data: _.get(state, 'privateOwnership.data.ownerships'),
        asOf: _.get(state, 'privateOwnership.data.ownerships.asOf'),
        loaded: _.get(state, 'privateOwnership.loaded'),
        error: _.get(state, 'privateOwnership.error'),
    };
}
export default connect(mapStateToProps, {
    getOwnerships
})(PrivateOwnership);
