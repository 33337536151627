import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './_companyHeader.module.scss';
import Modal from 'react-modal';
import _ from '../../../lodashWrapper';
import PrivateSubmissionForm from '../submissionForm/submissionForm';
import { openSubmissionModalAction, closeSubmissionModalAction } from '../../../ducks/priv_submission/actions';
import { selectCompanyActionSuccess } from "../../../ducks/company/actions";
import { worksheetSelectedSuccessAction } from "../../../ducks/worksheet/actions";


class PrivateHeaderSummary extends Component {
    constructor (props) {
        super(props)
  
        const worksheet = {
            cik: '0001652044',
            ticker: 'GOOG.L',
            email: 'naofumi.ezaki@argolimited.com',
            state: 'wip',
            updated_at: '2019-08-26T21:23:57.995Z',
            user_id: '00u1ov7a1125TUVSg2p7',
            company_name: 'Alphabet Inc.',
            archived_revl_s3loc: '344343/92bc0dc9-e356-4c53-84f3-00297d889b42-REVL.pdf',
            userId: '00u1ov7a1125TUVSg2p7',
            notes: [
              {
                note_id: '1',
                note_page: 'overview',
                note_text: `
                <p><strong>Directors &amp; Officers</strong></p>
                <ul>
                  <li>Review of financial position:</li>
                  <li>Funding:</li>
                  <li>Auditor comments:</li>
                  <li>M&amp;A activity:</li>
                  <li>Claim history:</li>
                </ul>
                <p><strong>Employment Practices</strong></p>
                <ul>
                  <li>Level of Third Party exposure:</li>
                  <li>HR practices in place:</li>
                  <li>Layoffs</li>
                  <li>Claim history:</li>
                </ul>
                <p><strong>Fiduciary</strong></p>
                <ul>
                  <li>ERIA compliant:</li>
                  <li>ESOP:</li>
                  <li>BIPA compliant:</li>
                  <li>Claim history:</li>
                </ul>`,
                updated_at: '2019-08-26T21:23:57.875Z'
              },
              {
                note_id: '2',
                note_page: 'trading',
                updated_at: '2019-08-26T21:23:57.875Z',
                note_text: ''
              },
              {
                note_id: '3',
                note_page: 'financial',
                updated_at: '2019-08-26T21:23:57.875Z',
                note_text: '<p>Financial</p>'
              },
              {
                note_id: '4',
                note_page: 'risk',
                updated_at: '2019-08-26T21:23:57.875Z',
                note_text: '<p>asdfsadfafsd</p>'
              },
              {
                note_id: '5',
                note_page: 'news',
                updated_at: '2019-08-26T21:23:57.875Z',
                note_text: '<p>radfafsd</p>'
              }
            ],
            submission: {
              submission_id: 'hajsfkhakjsdf',
              submitted: false,
              policy_id: '344343',
              renewal: false,
              agency_id: '4344',
              effective_date: '2019-05-29',
              location: '34',
              type: 'xs'
            },
            worksheetId: '232332',
            worksheet_id: '232332',
            type: 'private',
            created_timestamp: '2019-05-30T17:06:43.667Z',
          }

        this.props.selectCompanyActionSuccess({payload: this.data});
        this.props.worksheetSelectedSuccessAction(worksheet);
    }
    componentWillMount() {
        Modal.setAppElement('body');
       
    }

    render() {
        const data = this.props.company || {};
        return <div className="container">
            <div>
                <div className={styles.title}>
                    {data.companyName}
                </div>
                <div className={styles.titleSic}>
                    {data.sic}
                </div>
                <div className={styles.details}>
                    <div className="row">
                        <div className="col-xs-4">
                            <div className="row">
                                <div className="col-xs-4">
                                    Headquarter
                                </div>
                                <div className="col-xs-8">
                                    {data.streetAddress}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-4">
                                    Website
                                </div>
                                <div className="col-xs-8">
                                    {data.website}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-4">
                                    Phone
                                </div>
                                <div className="col-xs-8">
                                    {data.officePhoneValue}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-8">
                            <table width="100%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <h3>
                                            { data.yearFounded && new Date().getFullYear() - data.yearFounded}
                                            </h3>
                                        </td>
                                        <td>
                                            <h3>
                                                {data.yearFounded}
                                            </h3>
                                        </td>
                                        <td>
                                            <h3>
                                                {data.totalRevenue}
                                            </h3>
                                        </td>
                                        <td>
                                            <h3>
                                                {data.zScore}
                                            </h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Years in Business</td>
                                        <td>Year founded</td>
                                        <td>Total Revenue({data.totalRevenueYear})</td>
                                        <td>Z-Score</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
            <Modal className="modal-large"
                overlayClassName="modal-overlay"
                isOpen={this.props.modalOpen}
                onRequestClose={() => this.props.closeSubmissionModalAction()}
            >
                <PrivateSubmissionForm />
            </Modal>
        </div>


    }
}

function mapStateToProps(state) {
    return {
        modalOpen: _.get(state, 'privateSubmission.submissionModalOpen'),
        company: _.get(state, 'privateCompany.selected')
    };
}

export default connect(mapStateToProps, {
    openSubmissionModalAction,
    closeSubmissionModalAction,
    selectCompanyActionSuccess,
    worksheetSelectedSuccessAction
})(PrivateHeaderSummary);


