import _ from '../lodashWrapper';

const typeMap = {
    '8-K': 'k8',
    '8-K/A': 'k8',
    '10-K': 'k10',
    '10-K/A': 'k10',
    '10-Q': 'q10',
    'Proxy': 'proxy',
    'Complaint': 'sca',
    '10-K 10-Q' : ['k10', 'q10']
};

function documentType(data) {
    return _.map(data, (item) => ({
        ...item,
        type: typeMap[item.type]
    }));
}

function riskType(data) {
    return _.map(data, (item) => ({
        ...item,
        type: typeMap[item.filing_type]
    }));
}


export default {
    documentType,
    riskType,
}
