// import _ from 'lodash';
import actions from './types';

const defaultState = {
    worksheetFormModalOpen: false,
    loaded: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.OPEN_WORKSHEET_FORM_MODAL:
            return {
                ...state,
                worksheetFormModalOpen: true,
                formModalOpen: false,
            };
        case actions.CLOSE_WORKSHEET_FORM_MODAL:
            return {
                ...state,
                worksheetFormModalOpen: false,
                formModalOpen: false,
            };
        default:
            return state;
    }
}
