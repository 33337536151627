import types from './types';


export function changeCompanyFormPageAction(page) {
    return {
        type: types.CHANGE_FORM_PAGE,
        payload: page
    };
}


export function saveCompanyFormAction(form) {
    return {
        type: types.SAVE_COMPANY_FORM,
        payload: form
    };
}

export function getCompanyByIdAction(companyId) {
    return {
        type: types.GET_PRIVATE,
        companyId: companyId
    };
}

// Company Creation
export function openFormModalAction() {
    return {
        type: types.OPEN_COMPANY_FORM_MODAL
    };
}
export function closeFormModalAction() {
    return {
        type: types.CLOSE_COMPANY_FORM_MODAL
    };
}

export function createCompanyAction(form) {
    return {
        type: types.CREATE_COMPANY,
        payload: form
    };
}
