import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, takeLatest, call, select, all } from 'redux-saga/effects';
import types from './types';
import * as actions from './actions';
import { apiFailMsg } from '../../helpers/utility';

const getCompany = (state) => state.company;

function* companySearch(action) {

    const showPrivate = process.env.SHOW_PRIVATE !== null;

    if (showPrivate === true) {
        try {
            const [res, res2] = yield all([
                yield call(apigClientWrapper.getCompanies, action.query),
                yield call(apigClientWrapper.getPrivateCompanies, action.query)
            ]);
            const privCompanies = res2.data.map(x => {
                x.type = 'Private';
                return x;
            })
            const response = privCompanies.concat(res.data.searchResults);

            yield put({ type: types.SEARCH_SUCCESS, payload: response, query: action.query });
        } catch (e) {
            yield put({ type: types.SEARCH_FAIL, ...apiFailMsg(e) });
        }
    } else {
        try {
            const response = yield call(apigClientWrapper.getCompanies, action.query);
            yield put({ type: types.SEARCH_SUCCESS, payload: response.data.searchResults, query: action.query });
        } catch (e) {
            yield put({ type: types.SEARCH_FAIL, ...apiFailMsg(e) });
        }
    }


}

function* companySelect(action) {
    let company = yield select(getCompany);
    const currentCompanyId = company.selectedInSearch.companyId;

    yield put({ type: types.SELECT_COMPANY_ID, companyId: action.companyId, companyName: action.companyName });
    company = yield select(getCompany);

    let companyChanged = false;
    if (company.selectedInSearch.companyId !== currentCompanyId) {
        companyChanged = true;
        yield put({ type: types.DESELECT_ONLY_COMPANY });
    }

    if (Object.keys(company.selected).length === 0 || companyChanged) {
        try {
            const response = yield call(apigClientWrapper.getCompany, action.companyId);
            yield put(actions.selectCompanyActionSuccess(response.data.company));
        } catch (e) {
            yield put({ type: types.SELECT_FAIL, ...apiFailMsg(e) });
        }

    }
}


function* getDetail(action) {
    let company = yield select(getCompany);
    if (!company.detail) {
        try {
            const response = yield call(apigClientWrapper.getCompanyDetails, action.companyId);
            yield put({ type: types.GET_DETAIL_SUCCESS, payload: response });
        } catch (e) {
            yield put({ type: types.GET_DETAIL_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeLatest(types.SELECT, companySelect),
    takeLatest(types.SEARCH, companySearch),
    takeEvery(types.GET_DETAIL, getDetail),
    // takeEvery(types.GET_RATINGS, getRatings),

]
