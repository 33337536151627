import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import types from './types';
import { apiFailMsg } from '../../helpers/utility';

const getIndustry = (state) => state.industry;

function* getIndustries(action) {

    const queryParams = {
        companyId: action.companyId
    };

    if (action.componentType) {
        queryParams.type = action.componentType
    }

    let industry = yield select(getIndustry);

    if (industry.status === undefined) {
        try {
            yield put({ type: types.CLEAR_SEARCH_RESULT });
            const response = yield call(apigClientWrapper.getIndustries, { queryParams });
            yield put({ type: types.SEARCH_SUCCESS, payload: response.data });
        } catch (e) {
            console.log(e)
            yield put({ type: types.SEARCH_FAIL, ...apiFailMsg(e) });
        }

    } else {
        yield put({ type: types.SEARCH_SUCCESS, payload: industry.searchResult });
    }
}

export default [
    takeEvery(types.SEARCH, getIndustries),
]