import classNames from 'classnames';
import React from 'react';
import _ from '../../../lodashWrapper';
import DataNotFound from '../dataNotFound';
import Info from '../info';
import InfoImage from '../../images/info';

const DynamicTable2 = (props) => {

    const addCommas = (val) => {
        if (val === undefined || val === null) {
            return <span>-</span>;
        }
        if (val && props.formatWithCommas) {
            let parts = val.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        return val;
    }

    const printTBodyContent = (table) => {
        return _.map(table.data, (row, rowIndex) => {

            const additionalClasses = {
                'subtotal-tr': row.subtotal === true,
                'first-td-bold': table.expandable && table.expandable.keyIndex && rowIndex === table.expandable.keyIndex
            };

            return <tr
                onMouseEnter={() => {
                    if (props.trOnMouseEnter) {
                        props.trOnMouseEnter(rowIndex, props.tableKey);
                    }
                }}
                onMouseLeave={() => {
                    if (props.trOnMouseLeave) {
                        props.trOnMouseLeave();
                    }
                }}

                key={rowIndex} className={classNames(additionalClasses)} >
                {
                    printTDs(row, props.data.table.columns)
                }
            </tr>
        })
    }

    const printTsBodyContent = (tables) => {
        let hash = Math.random().toString(36).substring(7);
        return _.map(tables, (row, rowIndex) => {
            const theadViews = [];
            if (row.title) {
                theadViews.push(<tr key={row.title + hash + rowIndex}>
                    <td style={{borderTop: 'none'}}><h2 style={{margin: 0, marginTop: '20px', fontWeiht: 'bold'}}>{row.title}</h2></td>
                </tr>);
            }
            const view2 = <tr
                key={hash + row.title  + rowIndex}
                onMouseEnter={() => {
                    if (props.trOnMouseEnter) {
                        props.trOnMouseEnter(rowIndex, props.tableKey);
                    }
                }}
                onMouseLeave={() => {
                    if (props.trOnMouseLeave) {
                        props.trOnMouseLeave();
                    }
                }}
            >
                {
                    printTHs(row.table.columns, rowIndex)
                }
            </tr>

            theadViews.push(view2);
            const tbodyViews = [];

            for (let r of row.table.data) {
                hash = Math.random().toString(36).substring(7);
                tbodyViews.push(<tr key={hash + 'tbody'}>
                    {printTDs(r, row.table.columns)}
                </tr>
                )
            }

            return [
                <thead key={row.title + rowIndex}>{theadViews}</thead>,
                <tbody key={'tb' + row.title + rowIndex}>{tbodyViews}</tbody>
            ]
        })
    }
    const printTHs = (columns) => {
        return _.map(columns, (item, itemIndex) => {
            const className = [];

            return <th style={{borderTop: 'none'}} key={'asfdsaf' + itemIndex}
                className={className.join(' ')}>
                {item.title}
            </th>
        })
    }

    const printTDs = (row, columns) => {
        return _.map(columns, (item, itemIndex) => {
            const className = [];

            if (item.tdClassName) {
                className.push(item.tdClassName);
            }
            if (row.className) {
                className.push(row.className);
            }
            if (row[item.field + 'ClassName']) {
                className.push(row[item.field + 'ClassName']);
            }
            return <td key={itemIndex}
                className={className.join(' ')}>
                <div className="main-td-content">{addCommas(row[item.field])}</div>
                {item.subField && <div className="sub-td-content">{row[item.subField]}</div>}
            </td>
        })
    }

    if (!props.data) {
        return null;
    }

    if (props.data.table && props.data.tables) {
        return null;
    }


    return (
        <div className="common-element dynamic-table">
            {props.data.table && <div>
                {props.data.title && <h3>{props.data.title}</h3>}
                {props.data.table.data.length === 0 ? <DataNotFound /> : <table className={props.className}>
                    <thead>
                        {(!props.hideHeader && !props.tables) && 
                            <tr>
                                {
                                    _.map(props.data.table.columns, (item, index) => {
                                        return <th key={index} className={item.className}>
                                            <div>{item.title}{item.tooltip && <Info text={item.tooltip}><InfoImage /></Info>}</div>
                                            {item.subTitle && <div style={{ fontSize: '10px' }}>{item.subTitle}</div>}
                                        </th>
                                    })
                                }
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {
                            printTBodyContent(props.data.table)
                        }
                    </tbody>
                </table>}
            </div>}
            {props.data.tables && <div>
                <table className={props.className}>
                    {
                        printTsBodyContent(props.data.tables)
                    }
                </table>
            </div>

            }</div>);
}
export default DynamicTable2;
