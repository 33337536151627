import React, { Component } from 'react';
import Input from '../../common/input';
import Select from '../../common/select';
import styled from 'styled-components';
import Button from '../../common/button';
import _ from 'lodash';
import states from '../../../json/states.json';
const TD = styled.td`
    padding: 5px;
`
const Section = styled.section`
    padding: 20px;
`

export class QuestionsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            answersForReporting: this.populateData(this.props.data.questions, this.props.answers)
        };
        this.props.updateAnswers(this.props.index, this.state.answersForReporting);
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.updateAnswers) {
            this.props.updateAnswers(this.props.index, nextState.answersForReporting);
        }
    }

    populateData(questions, answers) {
        const answersForReporting = {};
        for (let q of questions) {
            if (['table', 'dynamictable'].indexOf(q.inputType) !== -1) {
                let isTableWithRowHeaders = false;
                if (this.props.config) {
                    isTableWithRowHeaders = this.props.config.tableWithRowHeaders.find(x => x === q.questionId) === undefined ? false : true;
                }
                const numOfRows = q.attributes.rows.length;
                const numOfColumns = q.attributes.rows[0].columns.length;
                const ans = [];

                for (let i = 0; i < numOfRows; i++) {
                    const a = [];
                    for (let j = 0; j < numOfColumns; j++) {
                        if (j === 0 && isTableWithRowHeaders) {
                            const rowHeaderValue = q.attributes.rows[i].columns[0].text;
                            a.push(rowHeaderValue)
                        } else {
                            a.push('')
                        }

                    }
                    ans.push(a);
                }

                answersForReporting[q.questionId] = {
                    answer: ans,
                    questionType: q.inputType
                }
            } else {
                answersForReporting[q.questionId] = {
                    answer: '',
                    questionType: q.inputType
                }
            }
        }

        if (answers) {

            Object.keys(answers).forEach(key => {
                if (answers[key].answer !== null && answersForReporting[key]) {
                    if (Array.isArray(answersForReporting[key].answer) === true) {
                        for (let i = 0; i < answers[key].answer.length; i++) {
                            answersForReporting[key].answer[i] = answers[key].answer[i]
                        }
                    } else {
                        answersForReporting[key].answer = answers[key].answer
                    }

                }
            });
        }
        return answersForReporting;
    }

    getTable(obj) {
        let isTableWithRowHeaders = false;
        let hasStateDropDown = false;
        if (this.props.config) {
            isTableWithRowHeaders = this.props.config.tableWithRowHeaders.find(x => x === obj.questionId) === undefined ? false : true;
            hasStateDropDown = this.props.config.stateDropDown.find(x => x === obj.questionId) === undefined ? false : true;
        }

        return <div>
            <table className="table">
                <thead>
                    <tr>
                        {obj.attributes.headers.map((x, index) => {
                            return <th key={index + x}>
                                {x}
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {obj.attributes.rows.map((x, index) => {
                        return <tr key={index + x.rowId}>
                            {
                                x.columns.map((y, i) => {
                                    return <TD key={index + y.text + i}>
                                        {(i === 0 && isTableWithRowHeaders) ? y.text : (hasStateDropDown ? '' : this.getInput({ ...obj, ...y }, index, i))}
                                    </TD>
                                })
                            }
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }

    getAnswersForReporting(obj) {
        return this.state.answersForReporting[obj.questionId].answer;
    }

    getDynamicTable(obj) {

        let hasStateDropDown = false;
        if (this.props.config) {
            hasStateDropDown = this.props.config.stateDropDown.find(x => x.questionId === obj.questionId);
        }
        return <div>
            <table>
                <thead>
                    <tr>
                        {obj.attributes.headers.map((x, index) => {
                            return <th key={index + x}>
                                {x}
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {this.getAnswersForReporting(obj).map((x, index) => {
                        const columns = obj.attributes.rows[0].columns;

                        return <tr data-col={index} key={index}>
                            {[
                                columns.map((y, i) => {
                                    if (hasStateDropDown && hasStateDropDown.index === i) {
                                        return <TD data-col={index + y.text + i} key={index + y.text + i}>
                                            {this.getDropDown({ ...obj, ...y }, obj.questionId, states.map(x => {
                                                return { value: x.abbreviation, label: x.abbreviation }
                                            }), index, i)}
                                        </TD>
                                    } else {
                                        return <TD data-col={index + y.text + i} key={index + y.text + i}>
                                            {this.getInput({ ...obj, ...y }, index, i)}
                                        </TD>
                                    }

                                }),
                                <td key={index + 'td'} onClick={() => {
                                    this.remove(obj, index);
                                }}>Remove</td>
                            ]
                            }
                        </tr>
                    })
                    }

                </tbody>
            </table>
            <Button onClick={() => {
                this.addRow(obj);
            }}>Add {obj.text}</Button>
        </div>
    }

    remove(obj, index) {
        const data = this.state.answersForReporting[obj.questionId];
        data.answer.splice(index, 1);
        this.setState({
            ...this.state, answersForReporting: {
                ...this.state.answersForReporting,
                [obj.questionId]: data
            }
        })
    }

    addRow(obj) {
        const findIndex = this.state.data.questions.findIndex(
            x => x.questionId === obj.questionId
        );
        if (findIndex !== -1) {
            const attributes = this.state.data.questions[findIndex].attributes;
            const question = attributes.rows;
            question.push(_.cloneDeep(question[0]));

            for (let i = 0; i < question.length; i++) {
                question[i].rowId = `${i + 1}`;
            }
            attributes.rows = question;
            const answersForReporting = _.cloneDeep(this.state.answersForReporting);

            const a = [];
            for (let j = 0; j < attributes.rows[0].columns.length; j++) {
                a.push('');
            }

            answersForReporting[obj.questionId].answer.push(a);

            this.setState({
                answersForReporting: answersForReporting,
                data: {
                    ...this.data,
                    questions: [
                        ...this.state.data.questions,
                    ]
                }
            });
        }
    }

    getDropDown(obj, key, options, firstIndex, secondIndex) {
        const text = obj.text;
        const isTable = this.state.answersForReporting[key].questionType.indexOf('table') !== -1 ? true : false;


        return <div data-id={key}>
            {isTable ? <Select label={text}
                options={
                    options
                }
                value={this.state.answersForReporting[key].answer[firstIndex][secondIndex] ? this.state.answersForReporting[key].answer[firstIndex][secondIndex] : ''}
                onChange={(value) => {
                    const answer = this.state.answersForReporting[key].answer;
                    answer[firstIndex][secondIndex] = value ? value.value : '';
                    this.setState({
                        answersForReporting: {
                            ...this.state.answersForReporting,
                            [key]: {
                                ...this.state.answersForReporting[key],
                                answer: answer
                            }
                        }
                    })
                }}></Select> :
                <Select label={text}
                    options={
                        options
                    }
                    value={this.state.answersForReporting[key] ? this.state.answersForReporting[key].answer : ''}
                    onChange={(value) => {
                        console.log(value);
                        this.setState({
                            answersForReporting: {
                                ...this.state.answersForReporting,
                                [key]: {
                                    ...this.state.answersForReporting[key],
                                    answer: value ? value.value : ''
                                }
                            }
                        })
                    }}>
                </Select>}
        </div>

    }

    getDropDownMulti(text, key) {
        return <div>{text + key}</div>
    }

    getFreeform(obj, key, inputFormat, firstIndex, secondIndex) {

        const text = obj.text;
        if (inputFormat === 'numeric' || inputFormat === 'percentage') {
            inputFormat = 'number';
        } else if (inputFormat === 'date') {
            inputFormat = 'month';
        } else {
            inputFormat = 'text';
        }
        const isTable = (obj.attributes && obj.attributes.headers) ? true : false;
        let inputValue;
        if (isTable) {
            inputValue = (this.state.answersForReporting[key].answer[firstIndex]
                && this.state.answersForReporting[key].answer[firstIndex][secondIndex]
            )
                ? this.state.answersForReporting[key].answer[firstIndex][secondIndex] : '';
        } else {
            inputValue = this.state.answersForReporting[key] ? this.state.answersForReporting[key].answer : '';
        }

        return <div>
            {isTable ? <Input data-id={key} label={text}
                type={inputFormat}
                value={inputValue}
                onChange={(event) => {
                    const answer = this.state.answersForReporting[key].answer;
                    answer[firstIndex][secondIndex] = event.target.value;

                    this.setState({
                        answersForReporting: {
                            ...this.state.answersForReporting,
                            [key]: {
                                ...this.state.answersForReporting[key],
                                answer: answer
                            }
                        }
                    });
                }}
            /> : <Input data-id={key} label={text}
                type={inputFormat}
                value={inputValue}
                onChange={(event) => {
                    this.setState({
                        answersForReporting: {
                            ...this.state.answersForReporting,
                            [key]: {
                                ...this.state.answersForReporting[key],
                                answer: event.target.value
                            }
                        }
                    });

                }}
                />}
        </div>
    }

    getInput(x, index, secondIndex) {
        let obj = null;

        const key = `${x.questionId}`;
        switch (x.inputType.toLowerCase()) {
            case 'table':
                if (['PROE9', 'PROF1', 'PROO1'].indexOf(x.questionId) !== -1) {
                    return this.getDynamicTable(x);
                } else {
                    return this.getTable(x);
                }
            case 'dynamictable':
                return this.getDynamicTable(x);
            case 'freeform':
                obj = this.getFreeform(x, key, x.inputFormat, index, secondIndex);
                break;
            case 'dropdown':
                var options = x.attributes.options.map(x => {
                    let value = x.text === 'N/A' ? '' : x.text;
                    if (value === 'Yes') {
                        value = 1;
                    } else if (value === 'No') {
                        value = 0;
                    }
                    return { value: value, label: x.text }
                })
                return this.getDropDown(x, key, options, index, secondIndex);
            // case 'dropdownmulti':
            //     return this.getDropDownMulti(x, index);
            default:
                return null;
        }
        return <div key={index}>{obj}</div>;
    }

    render() {
        return <div>
            {this.state.data.questions.map((x, index) => {
                return <Section key={index}>{this.getInput(x)}</Section>;
            })}

        </div>
    }
}


export default QuestionsForm;