import React from 'react';
import styles from './_errorAlert.module.scss';

export const ErrorAlert = ({ message, style }) => {
  return (
    <div style={style}>
      <div className={styles.errorAlert}>
        <div>{message || 'Server Error'}</div>
      </div>
    </div>
  );
};

export default ErrorAlert;
