import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Security, ImplicitCallback } from '@okta/okta-react';
import { BrowserRouter, Route } from 'react-router-dom';
const config = {
    issuer: process.env.OKTA_ISSUER,
    redirect_uri: window.location.origin + '/implicit/callback',
    client_id: process.env.OKTA_CLIENTID
}

import App from './modules/app';
import store from './ducks/store';

import './app.scss';
import './modules/appBar/_appBar.scss';
import './modules/public/createWorksheet/_companyCreateWorksheet.scss'
import './modules/public/financial/_companyFinancial.scss'
import './modules/public/header/_companyHeader.scss';
import './modules/public/news/_companyNews.scss';
import './modules/public/overview/_companyOverview.scss';
import './modules/public/pricing/_companyPricing.scss';
import './modules/public/pdf/_companyPdf.scss';
import './modules/company/search/_companySearch.scss';
import './modules/public/trade/_companyTrade.scss';
import './modules/common/_common.scss';
import './modules/document/_document.scss';
import './modules/note/_note.scss';

ReactDOM.render(
    <BrowserRouter>
        <Security issuer={config.issuer}
            client_id={config.client_id}
            redirect_uri={config.redirect_uri}
            scope={['openid', 'appi_profile', 'profile', 'email']}
        >
            <Provider store={store}>
                <App />
            </Provider>
            <Route path='/implicit/callback' component={ImplicitCallback} />
        </Security>
    </BrowserRouter>,
    document.getElementById('app')
);
