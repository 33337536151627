import apigClientWrapper from '../../helpers/apigClientWrapper';
import { delay } from 'redux-saga/effects'
import { put, takeEvery, call, select } from 'redux-saga/effects';
import types from './types';
import worksheetTypes from '../worksheet/types';
import _ from 'lodash';
import { apiFailMsg } from '../../helpers/utility';

// Number of retries to get first evaluation result.
// Requests are sent in 8s intervals, approx. 18 retries
// is needed for evaluation (in best scenario)
const EVALUATION_GET_TOP_LIMIT = 3 * 18;

// Evaluations can be canceled at any time. This flag exits
// follow-up requests after reset.
let STOP_EVALUATIONS = false;

const serialize = (data) => {
    return {
        "attachPoint": data.attach_point,
        "companyId": data.companyid,
        "earningsPrior": data.earnings_prior,
        "earningsPrior2": data.earnings_prior2,
        "gSectorName": data.gsector_name,
        "gSectorName2": data.gsector_name2,
        "index": data.index,
        "ipoYear": data.offeryear,
        "limit": data.limit,
        "modelFrequency": data.final_freq_yr1 || "",
        "retention": data.retention,
        "revenuePrior": data.revenues_prior,
        "revenuePrior2": data.revenues_prior2,
        "technicalPrice": data.technical_premium_yr1 || "",
        "transaction": data.transaction_size,
        "sweetSpot": data.sweet_spot_yr1_final || data.sweet_spot_yr1,
        "yearFounded": data.yearfounded,
    };
}

const deSerialize = (data) => {
    return {
        "attach_point": data.attachPoint,
        "companyid": data.companyId,
        "earnings_prior": data.earningsPrior,
        "earnings_prior2": data.earningsPrior2,
        "gsector_name": data.gSectorName,
        "gsector_name2": data.gSectorName2,
        "gvkey": 0,
        "index": data.index,
        "ipo_year": data.ipoYear,
        "limit": data.limit,
        "offeryear": data.ipoYear,
        "retention": data.retention,
        "revenues_prior": data.revenuePrior,
        "revenues_prior2": data.revenuePrior2,
        "transaction_size": data.transaction,
        "sweet_spot_yr1_final": data.sweetSpot,
        "yearfounded": data.yearFounded,
    };
}

function* getIpopy1WithDelayAction(action) {
    yield delay(8000);
    const { beingEvaluated, index, requestid, counter } = action.payload;
    try {
        let response = yield call(apigClientWrapper.getIpopy1Request, { requestid });
        response.index = index;
        response.beingEvaluated = beingEvaluated;
        response.counter = -1 + counter;
        yield put({ type: types.GET_SUCCESS, payload: response });
    } catch (error) {
        yield put({
            type: types.GET_FAIL,
            payload: {
                index,
                ...apiFailMsg(error),
            }
        });
    }
}

function* getIpopy1Action(action) {
    const { data, counter, index } = action.payload;
    const { statusCode } = data;

    if (STOP_EVALUATIONS) {
        return yield STOP_EVALUATIONS;
    }

    if (counter < 1) {
        const error = {
            response: {
                data: {
                    message: "evaluation timeout"
                },
                status: 400,
            }
        }
        yield put({
            type: types.GET_FAIL,
            payload: {
                ...data,
                ...apiFailMsg(error),
                index,
            }
        });
    } else if (statusCode === 202) {
        yield put({ type: types.GET_EVALUTION, payload: {
            ...data,
            counter,
            index
        }})
    } else {
        yield put({ type: types.GET_EVALUATION_SUCCESS, payload: {
            ...data,
            index
        }})
    };
}

function* getIpopy1CompleteAction(action) {
    const payload = serialize(action.payload);
    payload.beingEvaluated = false;
    yield put({ type: types.SETTLE_EVALUATION, payload });
}

function* resetIpopy1Action(action) {
    STOP_EVALUATIONS = true;
    yield STOP_EVALUATIONS;
}

function* createIpopy1(action) {
    STOP_EVALUATIONS = false;
    yield put({ type: types.ACCEPT, payload: action.payload });

    const payload = deSerialize(action.payload);

    try {
        const response = yield call(apigClientWrapper.createIpopy1Request, payload);
        const data = serialize(response.data);
        data.requestid = response.data.requestid;
        data.index = payload.index;
        data.counter =  EVALUATION_GET_TOP_LIMIT * (1 + payload.index);

        yield put({ type: types.CREATE_SUCCESS, payload: { ...data } });
        yield put({ type: types.APPEND, payload: serialize(response.data) });
    } catch (error) {
        error["data.index"] = payload.index;
        yield put({ type: types.CREATE_FAIL, payload: {
            index: payload.index,
            ...action.payload,
            ...apiFailMsg(error),
        }});
    }
}

export default[
    takeEvery(types.GET_SUCCESS, getIpopy1Action),
    takeEvery(types.CREATE_SUCCESS, getIpopy1WithDelayAction),
    takeEvery(types.GET_EVALUTION, getIpopy1WithDelayAction),
    takeEvery(types.GET_EVALUATION_SUCCESS, getIpopy1CompleteAction),
    takeEvery(types.CREATE, createIpopy1),
    takeEvery(types.RESET, resetIpopy1Action),
]
