export const calcAttachment = (limit, attachment) => {
    if (typeof attachment === 'string') {
        attachment = parseFloat(attachment);
    }
    if (typeof limit === 'string') {
        limit = parseFloat(limit);
    }
    return limit + attachment;
}

export const calcRPM = (limit, premium) => {
    premium = parseFloat(premium);
    if (typeof premium === 'string') {
        premium = parseFloat(premium);
    }
    if (typeof limit === 'string') {
        limit = parseFloat(limit);
    }
    const result = premium / limit;
    if (isNaN(result)) {
        return false;
    }
    return (result * 1000000).toFixed(0);
}

export const calcROL = (rpm, prvRpm) => {
    if (typeof rpm === 'string') {
        rpm = parseFloat(rpm);
    }
    if (typeof prvRpm === 'string') {
        prvRpm = parseFloat(prvRpm);
    }
    const result = rpm / prvRpm;
    if (isNaN(result)) {
        return false;
    }
    return (result * 100).toFixed(0);
}


export const calcModelTechnicalPrice = (limit, probOfSCA, multi) => {
    if (typeof limit === 'string') {
        limit = parseFloat(limit);
    }

    if (typeof probOfSCA === 'string') {
        probOfSCA = parseFloat(probOfSCA);
    }
    const result = limit * probOfSCA / (1 - .4) * multi;

    return (result).toFixed(0);
}

export const calcMinmumTechnicalPrice = (limit) => {

    if (typeof limit === 'string') {
        limit = parseFloat(limit);
    }
    const result = limit * 3500 / (1 - .4) / 1000000;

    return (result).toFixed(0);
}
