import _ from "../../../lodashWrapper";
import React, { Component } from 'react';
import { connect } from "react-redux";

import { searchCompanyAction, searchInitCompanyAction, clearCompanyAction } from "../../../ducks/company/actions";
import qs from 'qs';
import { withRouter } from 'react-router';


class CompanySearchHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { query: '' }
    }

    componentWillMount() {
        this.prepareData(this.props.history.location.search);
        this.unlisten = this.props.history.listen((location) => {
            this.prepareData(location.search);
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    prepareData(query) {
        if (query) {
            query = _.get(qs.parse(query.substring(1)), 'query');
            if (query) {
                this.props.searchInitCompanyAction();
                this.props.searchCompanyAction(query);
                this.setState({ query })
            }
        } else {
            this.props.clearCompanyAction();
        }
    }

    onSubmit(event) {
        if (event) {
            event.preventDefault();
        }
        this.props.history.push(`/company?query=${encodeURIComponent(this.state.query) || ''}`);
        this.prepareData(this.props.history.location.search);
    }

    render() {
        return (
            <div className="company-header">
                <div className="container">
                    <div className="company-header-summary">
                        <div className="clearfix">
                            <span className="title">Welcome to Argo Pro Underwriting Dashboard</span>
                        </div>
                        <form id='searchByCompanyFrom' onSubmit={(event) => this.onSubmit(event)}>
                            <div className="search-bar">
                                <span className="left"><img src="/images/search.svg" /></span>
                                <input value={this.state.query}
                                    onChange={(event) => this.setState({ query: event.target.value })}
                                    placeholder="Search by company's name"
                                    className="data-hj-whitelist"
                                />
                                <a className="right" onClick={() => this.onSubmit()}>Search</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(connect(null, {
    clearCompanyAction,
    searchCompanyAction,
    searchInitCompanyAction
})(CompanySearchHeader));
