import actions from './types';

const defaultState = {selected: {}};

export default function (state = defaultState, action) {
    switch (action.type) {
        case actions.ROUTE_CHANGED:
            return {
                ...state,
                url: action.url
            };
        default:
            return state;
    }
}
