import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getFinancialDebt = (state) => state.financialDebt;

function* getCompanyFinancialsDebt(action) {
  let financialDebt = yield select(getFinancialDebt);
  if (financialDebt.data === null) {
    try {
      const response = yield call(apigClientWrapper.getCompanyFinancialsDebt, action.companyId);
      yield put({ type: actions.GET_FINANCIAL_DEBT_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: actions.GET_FINANCIAL_DEBT_FAIL, ...apiFailMsg(e) });
    }

  }
}

export default [
  takeEvery(actions.GET_FINANCIAL_DEBT, getCompanyFinancialsDebt),
]
