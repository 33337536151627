
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';

const getNews = (state) => state.news;

function* newsSearch(action) {
    let news = yield select(getNews);

    const filter = action.filter;

    const queryParams = {
        companyName: filter.query
    };
    if (filter.from) {
        queryParams.startDate = filter.from;
    }
    if (filter.to) {
        queryParams.endDate = filter.to;
    }
    if (filter.categories) {
        queryParams.filters = filter.categories;
    }

    if (news.list === undefined) {
        
        try {
            const response = yield call(apigClientWrapper.getNews, action.companyId, { queryParams });
            yield put({ type: actions.SEARCH_SUCCESS, payload: response });
        } catch (e) {
            yield put({ type: actions.SEARCH_FAIL, ...apiFailMsg(e) });
        }
    }
}

export default [
    takeEvery(actions.SEARCH, newsSearch),
]