import React from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import _ from '../../lodashWrapper';

import Tag from './tag';
import qs from "qs";

const EventsBar = (props) => {
    function redirectToNews(date) {
        const query = qs.stringify({
            worksheetId: props.worksheetId,
            from: moment(date, 'MM/DD/YYYY').subtract(3, 'days').format('YYYY-MM-DD'),
            to: moment(date, 'MM/DD/YYYY').add(3, 'days').format('YYYY-MM-DD'),
        }, { addQueryPrefix: true });
        props.history.push(`/company/${props.companyId}/news${query}`)
    }

    if (!Object.keys(props.data).length) {
        return (
            <div className="common-element empty-data">
                No data found.
            </div>
        )
    }

    return (
        <div className="common-element events-bar">
            {
                _.map(props.data, (item, index) => {
                    const clz = 'panel panel-default company-event-container ' + (item.is_negative ? 'down-event' : 'up-event');
                    return <div key={index} className="event"
                        onClick={() => {
                            if (props.selected) {
                                props.selected(item);
                            }
                            redirectToNews(item.date);
                        }}
                        onMouseEnter={() => props.onItemEnter && props.onItemEnter(item)}
                        onMouseLeave={() => props.onItemLeave && props.onItemLeave(item)}
                    >
                        <div className={clz}>
                            <div className="panel-body">
                                <h3>{item.is_negative ? '' : '+'}{item.value}%</h3>
                                <Tag text={item.text} className="newsEvents" />
                                <p>
                                    {item.date}
                                </p>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
    );
};

export default withRouter(EventsBar);
