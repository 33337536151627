import amplitude from 'amplitude-js';

import { takeEvery, select } from 'redux-saga/effects';

import { worksheetTypes } from '../worksheet';
import { appTypes } from '../app';
import { companyTypes } from '../company';
import { noteTypes } from '../note';
import { documentTypes } from '../document';
import { holderTypes } from '../holder';
import { stockTypes } from '../stock';
import { userTypes } from '../user';
import storageWrapper from '../../helpers/storageWrapper';

const getCompany = (state) => state.company;

const configureAmplitude = () => {
    const userProfile = storageWrapper.getState('userProfile');
    const oktaStroage = storageWrapper.getState('okta-token-storage');
    const email = userProfile.email;
    const name = userProfile.name;
    const role = oktaStroage.idToken.claims.appi_role ? oktaStroage.idToken.claims.appi_role : undefined;

    amplitude.getInstance().init(process.env.AMPLITUDE_APP_TOKEN, email);
    const userProperties = {
        User: {
            Email: email,
            name: name,
            role: role
        },
        Session: {
            Environment: process.env.NODE_ENV
        }
    };
    amplitude.getInstance().setUserProperties(userProperties);

};

function sendEventLogLog(type, eventData) {
    if (process.env.NODE_ENV !== 'development') {
        amplitude.getInstance().logEvent(type, eventData);
    }
}

function* amplitudeUserInit() {
    configureAmplitude();
    yield 'ok';
}

function* routeChange(action) {
    const eventData = {
        currentPage: action.currentPage,
        previousPage: action.previousPage
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* amplitudeCompanySearch(action) {
    const eventData = {
        query: action.query
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

// function* worksheetGoViewOnly(action) {
//     const eventData = {
//         url: action.url
//     };
//     sendEventLogLog(action.type, eventData);
//     yield 'ok';
// }

function* compareIndex(action) {
    const eventData = {
        index: action.index,
        checked: action.checked,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* worksheetSelect(action) {
    const eventData = {
        companyId: action.companyId,
        companyName: action.companyName,
        worksheetId: action.worksheetId,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}


function* submitWorksheetUpdate(action) {
    const eventData = {
        form: action.payload,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* showNote(action) {
    let company = yield select(getCompany);
    if (company.selected) {
        const eventData = {
            company: { ...company.selected }
        };
        sendEventLogLog(action.type, eventData);
    }
}

function* goViewOnly(action) {
    const eventData = {
        companyId: action.companyId,
        companyName: action.companyName,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}


function* createWorkSheetDone(action) {
    const eventData = {
        companyId: action.companyId,
        companyName: action.companyName,
        worksheetId: action.worksheetId
    };

    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* holdersTabChanged(action) {
    const eventData = {
        index: action.index,
        tabName: action.tabName,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}


function* stockChartVariationCardClicked(action) {
    let company = yield select(getCompany);

    const eventData = {
        companyId: company.selected.company_id,
        companyName: company.selected.name,
        page: action.page,
        ...action.data
    };
    sendEventLogLog(action.type, eventData);
}

function* documentOpenPDF(action) {
    const eventData = {
        index: action.index,
        url: action.url,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* documentTxtFailed(action) {
    const eventData = {
        error: action.error,
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* documentTxtSuccess(action) {
    const eventData = {
        url: action.url,
        processTime: action.processTime
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}

function* overviewStockChartAction(action) {
    let company = yield select(getCompany);

    const eventData = {
        companyId: company.selected.company_id,
        companyName: company.selected.name
    };
    sendEventLogLog(action.type, eventData);
}

function* sendLoginAction(action) {
    const eventData = {
        userInfo: storageWrapper.getState('userProfile')
    };
    sendEventLogLog(action.type, eventData);
    yield 'ok';
}


export default [
    takeEvery(appTypes.INITIALIZE_AMPLITUDE, amplitudeUserInit),
    takeEvery(appTypes.ROUTE_CHANGED, routeChange),
    takeEvery(companyTypes.SEARCH, amplitudeCompanySearch),
    takeEvery(worksheetTypes.SELECT_WORKSHEET, worksheetSelect),
    takeEvery(companyTypes.COMPARE_INDEX, compareIndex),
    takeEvery(holderTypes.HOLDERS_TAB_CHANGED, holdersTabChanged),
    takeEvery(worksheetTypes.UPDATE, submitWorksheetUpdate),
    takeEvery(noteTypes.SHOW, showNote),
    takeEvery(worksheetTypes.GO_VIEW_ONLY, goViewOnly),
    takeEvery(worksheetTypes.CREATE_WORKSHEET_DONE, createWorkSheetDone),
    takeEvery(documentTypes.OPEN_PDF, documentOpenPDF),
    takeEvery(documentTypes.FETCH_SUCCESS, documentTxtSuccess),
    takeEvery(documentTypes.FETCH_FAILED, documentTxtFailed),
    takeEvery(stockTypes.STOCK_CHART_VARIATION_CARD_CLICKED, stockChartVariationCardClicked),
    takeEvery(stockTypes.OVERVIEW_STOCK_CHART_MOUSELEAVE, overviewStockChartAction),
    takeEvery(userTypes.LOGIN, sendLoginAction),
]