import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EventsBar from '../../common/eventsBar';
import Spinner from '../../common/spinner'
import MenuTab from "../../common/menuTab";
import MenuTabItem from "../../common/menuTabItem";
import DataNotFound from "../../common/dataNotFound";
import {
    compareIndexAction
} from '../../../ducks/company/actions';
import {
    getCompanyStocksAction,
    getCompanyStocksVariationsAction,
    stockChartVariationCardClickedAction,
    overviewStockChartAction,
} from '../../../ducks/stock/actions';
import { StockChart } from './components/StockChart';
import ErrorAlert from '../../common/errorAlert';

const menuTabStyle = {
    position: 'absolute',
    width: '400px',
    top: '95px',
    left: '220px',
    zIndex: 1,
}

export class CompanyStockChart extends Component {
    constructor(props) {
        super(props);
        this.loaded = false;
        this.state = {
            nasdaq: false,
            dowj: false,
            sp500: false,
            focusedX: null,
            week52: {},
            pointMetrics: {},
            hideAxis: true,
            sinceDate: null,
            showModal: false
        }

        if (this.props.companyId) {
            this.fetchData();
        }
        this.getPointMetricsClass = this.getPointMetricsClass.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.fetchData();
        }
    }

    fetchData() {
        this.props.getCompanyStocksAction(this.props.companyId);
        this.props.getCompanyStocksVariationsAction(this.props.companyId);
    }

    prepareData(data) {
        if (data.dowj_index === undefined && Object.keys(data).length === 0) {
            return {};
        }

        for (let dow of data.dowj_index.coordinates) {
            let findIndex = data.company_index.coordinates.findIndex(
                x => x.y_stock_date === dow.y_stock_date
            );

            if (findIndex === -1) {
                findIndex = data.company_index.coordinates.findIndex(
                    x => moment(x.y_stock_date) > moment(dow.y_stock_date)
                );
                if (findIndex !== -1) {
                    const cloneObj = _.chain(data.company_index.coordinates[findIndex]).cloneDeep().set('y_stock_date', dow.y_stock_date).value();
                    data.company_index.coordinates.splice(findIndex, 0, cloneObj);
                }
            }
        }
        let lastDisplayedDate;
        return _.chain(data)
            .get('company_index.coordinates')
            .map((item, index) => {
                const dateObject = moment(item.y_stock_date, 'MM/DD/YYYY');
                let date = dateObject.month() ? dateObject.format('MMM') : dateObject.format('YYYY');
                if (lastDisplayedDate !== date) {
                    lastDisplayedDate = date;
                } else {
                    date = '';
                }

                let nasV = data.nasdaq_index.coordinates[index],
                    dowV = data.dowj_index.coordinates[index],
                    spV = data.sp500_index.coordinates[index];

                return {
                    index,
                    date,
                    dateObject,
                    company: Number(item.x_stock_value),
                    company_percent: Number(item.point_metrics.stock_percent_change),
                    nasdaq: Number(nasV ? nasV.x_stock_value : undefined),
                    dowj: Number(dowV ? dowV.x_stock_value : undefined),
                    sp500: Number(spV ? spV.x_stock_value : undefined),
                };
            })
            .value();
    }

    filterData(sinceDate) {
        this.setState({
            sinceDate: sinceDate,
        })
    }

    // if StateValue is not empty Object, it has higher priority
    getPointMetricsClass(propValue, stateValue) {
        let isPositive = true;
        if (stateValue !== undefined) {
            if (stateValue < 0) {
                isPositive = false;
            }
        } else {
            if (propValue < 0) {
                isPositive = false;
            }
        }

        return isPositive ? 'positive' : 'negative';
    }

    setPointMertics(pointIndex) {
        this.setState({ pointMetrics: _.get(this.props.data, `company_index.coordinates.${pointIndex}.point_metrics`, {}) })
    }

    renderChart() {
        if (!this.props.data) {
            return null;
        }
        const data1 = this.prepareData(this.props.data);

        const data = {
            filteredData: data1,
            data: data1,
            week52: { 'volume': this.props.data['52w_volume'], 'price': this.props.data['52w_price'] }
        }

        if (!data.filteredData) {
            return null;
        }

        if (this.state.sinceDate) {
            data.filteredData = _.filter(data.filteredData, item => item.dateObject.isAfter(this.state.sinceDate))
        }

        const chartContainerWidth = this.props.chartWidth ? this.props.chartWidth : "100%";

        return (

            <div>
                {!this.props.data && !this.props.events ? <DataNotFound /> : <div id="overview-stockchart">
                    <div className="legend">
                        <label>
                            <span>
                                <input type="checkbox"
                                    checked={this.state.nasdaq}
                                    onChange={event => {
                                        this.setState({ nasdaq: event.target.checked });
                                        this.props.compareIndexAction('nasdaq', event.target.checked);
                                    }}
                                />
                            </span>
                            <span className="color" />
                            <span className="label-data">NASDAQ</span>
                        </label><br />
                        <label>
                            <span>
                                <input type="checkbox"
                                    checked={this.state.dowj}
                                    onChange={event => {
                                        this.setState({ dowj: event.target.checked });
                                        this.props.compareIndexAction('dowj', event.target.checked);
                                    }}
                                />
                            </span>
                            <span className="color" />
                            <span className="label-data">DOW JONES</span>
                        </label><br />
                        <label>
                            <span>
                                <input type="checkbox"
                                    checked={this.state.sp500}
                                    onChange={event => {
                                        this.setState({ sp500: event.target.checked });
                                        this.props.compareIndexAction('sp500', event.target.checked);
                                    }}
                                />
                            </span>
                            <span className="color" />
                            <span className="label-data">S&amp;P 500</span>
                        </label><br />
                    </div>
                    <MenuTab style={menuTabStyle} initialActiveTab={0}>
                        <MenuTabItem action={() => this.filterData(moment().subtract(2, 'years'))}>2Y</MenuTabItem>
                        <MenuTabItem action={() => this.filterData(moment().subtract(1, 'years'))}>1Y</MenuTabItem>
                        <MenuTabItem action={() => this.filterData(moment().startOf('year'))}>YTD</MenuTabItem>
                        <MenuTabItem action={() => this.filterData(moment().subtract(6, 'months'))}>6M</MenuTabItem>
                        <MenuTabItem action={() => this.filterData(moment().subtract(1, 'months'))}>1M</MenuTabItem>
                    </MenuTab>

                    <div className="trading-volumes">
                        <div className="row">

                            <div className="col-xs-6">

                                <div className="col-xs-5 term">
                                    Trading Volume
                            </div>
                                <div className="col-xs-7 value core">
                                    {
                                        this.state.pointMetrics.trading_volume ||
                                        this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.trading_volume
                                    }
                                    <span className={
                                        // this.state.pointMetrics.trading_percent_change >= 0 ? "positive" : "negative"
                                        this.getPointMetricsClass(this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.trading_percent_change, this.state.pointMetrics.trading_percent_change)
                                    }>
                                        {
                                            this.state.pointMetrics.trading_percent_change ||
                                            this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.trading_percent_change
                                        }%</span>
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="col-xs-5 term">
                                    Stock Price
                            </div>
                                <div className="col-xs-7 value core">
                                    {this.state.pointMetrics.stock_price || this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.stock_price}
                                    <span className={
                                        this.getPointMetricsClass(this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.stock_percent_change, this.state.pointMetrics.stock_percent_change)
                                    }>
                                        {
                                            this.state.pointMetrics.stock_percent_change ||
                                            this.props.data.company_index.coordinates[this.props.data.company_index.coordinates.length - 1].point_metrics.stock_percent_change
                                        }%</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-6">
                                <div className="col-xs-5 term">
                                    52W. L-H
                            </div>
                                <div className="col-xs-7 value">
                                    {this.props.data['52w_volume'] || ' '}
                                </div>
                            </div>
                            <div className="col-xs-6">
                                <div className="col-xs-5 term">
                                    52W. L-H
                            </div>
                                <div className="col-xs-7 value">
                                    {this.props.data['52w_price'] || ' '}
                                </div>
                            </div>
                        </div>

                    </div>

                    <StockChart data={data.filteredData}
                        chartWidth={chartContainerWidth}
                        visibleData={{
                            nasdaq: this.state.nasdaq,
                            dowj: this.state.dowj,
                            sp500: this.state.sp500
                        }}
                        onMouseLeave={() => {
                            this.props.overviewStockChartAction();
                        }}
                        showAxis={this.state.hideAxis}
                        focusedX={this.state.focusedX}
                        onMouseMove={(pointIndex) => this.setPointMertics(pointIndex)}
                    />

                    <h2 className="drops-and-ups">Major Stock Price Variations</h2>
                    {this.props.events && <EventsBar data={this.props.events}

                        companyId={this.props.companyId}
                        worksheetId={this.props.worksheetId}
                        selected={(e) => {
                            this.props.stockChartVariationCardClickedAction(e, 'overview');
                        }}
                        onItemEnter={(event) => {
                            const pointIndex = _.findIndex(data.filteredData, (data) =>
                                moment(event.date, 'MM/DD/YYYY').isSame(data.dateObject)
                            );
                            this.setState({ focusedX: pointIndex });
                            this.setPointMertics(pointIndex);
                        }}
                        onItemLeave={() => this.setState({ focusedX: null })}
                    />}


                </div>}
            </div>
        );
    }


    render() {
        const loading = this.props.variationsLoading && this.props.chartLoading;
        return (
            <div className='panel panel-default'>
                <div className='panel-body stock-chart with-spinner'>
                    {this.props.data !== undefined &&
                        <div id="stock-datasource">
                            <div>Stock Exchange: <span>{this.props.exchange}</span></div>
                            <div>Currency: <span>{this.props.currency}</span></div>
                        </div>
                    }
                    <h2>
                        Stock Chart
                    </h2>
                    {
                        this.props.stockError ? <ErrorAlert message={this.props.stockError} /> :
                            <Spinner loaded={!loading}>
                                {this.renderChart()}
                            </Spinner>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        exchange: _.get(state, 'stock.chart.exchange'),
        currency: _.get(state, 'stock.chart.currency'),
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
        data: _.get(state, 'stock.chart'),
        stockError: _.get(state, 'error.COMPANY_GET_STOCK'),
        events: _.get(state, 'stock.variations'),
        chartLoading: _.get(state, 'stock.chartLoading'),
        variationsLoading: _.get(state, 'stock.variationsLoading')
    };
}

export default connect(mapStateToProps, {
    getCompanyStocksAction,
    getCompanyStocksVariationsAction,
    compareIndexAction,
    overviewStockChartAction,
    stockChartVariationCardClickedAction
})(CompanyStockChart);