import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';
import _ from 'lodash';

const getFinancialStatement = (state) => state.financialStatement;

function* getCompanyFinancialsSheet(action) {
    let financialStatement = yield select(getFinancialStatement);

    if (financialStatement.data[action.requestType] === undefined) {
        try {
            const response = yield call(apigClientWrapper.getCompanyFinancialsSheet, action.companyId, action.requestType);
            let res = response.data;
            let result = {};

            if (Object.keys(res).length > 0 && action.requestType !== 'balance') {
                Object.keys(res).forEach((k) => {
                    let obj = {};

                    const responseData = res[k];
                    let columns = [];

                    Object.keys(responseData).forEach((key) => {
                        const item = responseData[key];
                        if (columns.length < item.table.columns.length) {
                            columns = item.table.columns;
                        }
                    });

                    Object.keys(responseData).forEach((key) => {
                        const item = responseData[key];
                        if (Object.keys(obj).length === 0) {
                            obj = _.clone(item, true);
                            obj.table.columns = columns;
                        } else {
                            const clone = _.clone(item, true);
                            for (let a of clone.table.data) {
                                obj.table.data.push(a);
                            }
                        }
                    });
                    obj.title = undefined;
                    result[k] = { table: obj }
                });
            } else {
                result = response.data;
            }
            yield put({ type: actions.GET_FINANCIAL_SHEET_SUCCESS, payload: result, requestType: action.requestType });
        } catch (e) {
            yield put({ type: actions.GET_FINANCIAL_SHEET_FAIL, ...apiFailMsg(e) });
        }

    } else {
        yield put({ type: actions.GET_FINANCIAL_SHEET_SUCCESS, payload: financialStatement.data[action.requestType], requestType: action.requestType });
    }
}

export default [
    takeEvery(actions.GET_FINANCIAL_SHEET, getCompanyFinancialsSheet),
]
