import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Info from "../../common/info";
import DynamicTable from '../../common/dynamicTable';
import Spinner from '../../common/spinner'
import { getCompanyEarningsAction } from '../../../ducks/earning/actions';
import DataNotFound from '../../common/dataNotFound';
import ErrorAlert from '../../common/errorAlert';
import InfoImage from "../../images/info";

export class CompanyEarning extends Component {

    constructor(props) {
        super(props)
        this.state = { filter: null }
    }

    componentDidMount() {
        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getCompanyEarningsAction(this.props.company.company_id);
    }

    render() {
        return (
            <div className="panel panel-default">
                <div className="panel-body earning with-spinner">
                    <h2 className='inline'>Earning</h2>
                    <Info text='Source: S&amp;P Cap IQ'><InfoImage /></Info>
                    {this.props.earningError ? <ErrorAlert message={this.props.earningError} /> :
                        <Spinner loaded={!this.props.loading}>
                            {this.props.companyEarning.estimate && Object.keys(this.props.companyEarning.estimate).length > 0 && this.props.companyEarning.estimate.table.columns.length > 0 ? <DynamicTable className="table" data={this.props.companyEarning.estimate} /> : <DataNotFound title={_.get(this.props.companyEarning, 'estimate.title')} label="No Earning Estimate Found" />}
                            {this.props.companyEarning.history && Object.keys(this.props.companyEarning.history).length > 0 && this.props.companyEarning.history.table.columns.length > 0 ? <DynamicTable className="table" data={this.props.companyEarning.history} /> : <DataNotFound title={_.get(this.props.companyEarning, 'history.title')} label="No Earnings History Found" />}
                        </Spinner>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        companyEarning: _.get(state, 'earning.data') || {},
        loading: _.get(state, 'earning.loading'),
        earningError: _.get(state, 'error.COMPANY_GET_EARNING')
    };
}

export default connect(mapStateToProps, {
    getCompanyEarningsAction,
})(CompanyEarning);
