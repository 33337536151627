import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { changeSectionAction, showNoteAction, hideNoteAction } from "../../ducks/note/actions";
import { updateWorksheetAction } from '../../ducks/worksheet/actions';

export class NoteController extends Component {
    componentDidMount() {
        this.props.changeSectionAction(this.props.section);
        if (this.props.isNoteVisible) {
            this.props.showNoteAction();
        }
    }

    render() {
        return <div />
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
        worksheet: _.get(state, 'worksheet.selected'),
        noteValue: _.get(state, `note.data.${state.note.currentSection}`),
        isNoteVisible: state.note.isVisible
    };
}

export default connect(mapStateToProps, {
    changeSectionAction,
    showNoteAction,
    hideNoteAction,
    updateWorksheetAction
})(NoteController);
