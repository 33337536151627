import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CartesianGrid, Line, ResponsiveContainer, LineChart, XAxis, YAxis, Tooltip } from 'recharts';

import Info from '../../common/info';
import InfoImage from '../../images/info';

import Spinner from '../../common/spinner'
import {
    getCompanyEstimationsAction
} from '../../../ducks/estimation/actions';
import DataNotFound from '../../common/dataNotFound';
import ErrorAlert from '../../common/errorAlert';

export class CompanyEstimationChart extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.getCompanyEstimationsAction(this.props.companyId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyEstimationsAction(this.props.companyId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            try {
                this.setState({ data: this.prepareData(nextProps.data) })
            } catch (error) {
                console.error(error);
            }
        }
    }

    prepareData(data) {
        return _.map(data, (item) => ({
            // Translate "2017 Q1" into "Q1 '17"
            x: item.estimate.x.split(" ").reverse().join(" ").replace(/20([\d]{2})/, (a, b) => "'" + b),
            e: Number(item.estimate.y),
            a: Number(_.get(item, 'actual.y')) || 'n/a'
        }));
    }

    render() {

        let content;

        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />
        } else {
            content = <Spinner loaded={this.props.dataLoaded === true}>
                <div className="eps-legend">
                    <div className="blob estimated"><span>Estimated</span></div>
                    <div className="blob actual"><span>Actual</span></div>
                </div>

                {this.props.data && this.props.data.length > 0 ?
                    <ResponsiveContainer width={'100%'} height={314}>
                        <LineChart margin={{ top: 20, right: 0, bottom: 20, left: 0 }}
                            data={this.prepareData(this.props.data)}>
                            <CartesianGrid vertical={false} />
                            <XAxis dataKey="x" tickLine={false} tickMargin={10} interval={0}
                                padding={{ left: 0, right: 25 }} />
                            <YAxis tickLine={false} />
                            <Line name='Actual' isAnimationActive={false} type="monotone" stroke="none" dataKey="a"
                                dot={{ fill: '#2a2d7e', r: 8 }} />
                            <Line name='Estimated' isAnimationActive={false} type="monotone" stroke="none" dataKey="e"
                                dot={{ fill: 'none', stroke: "#9b9b9b", strokeWidth: 2, r: 8 }} />
                            <Tooltip />
                        </LineChart></ResponsiveContainer> : <DataNotFound />}


            </Spinner>
        }
        return (
            <div className='panel panel-default'>
                <div className='panel-body eps with-spinner'>
                    <h2 className='inline'>EPS Estimation</h2>
                    <Info text="Consensus vs Actuals"><InfoImage /></Info>
                    {content}

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'estimation.data'),
        error: _.get(state, 'error.COMPANY_GET_ESTIMATION'),
        dataLoaded: _.get(state, 'estimation.loaded'),
    };
}

export default connect(mapStateToProps, {
    getCompanyEstimationsAction
})(CompanyEstimationChart);
