import DynamicTable from '../../common/dynamicTable';
import Input from '../../common/input';
import NumberFormat from 'react-number-format';
import PlusImage from '../../images/plus';
import React, { Component } from 'react';
import Select from '../../common/select';
import TrashImage from '../../images/delete';
import _ from 'lodash';
import styled from 'styled-components';
import styles from './_companyIpopy1.scss';
import { calcAttachment } from '../../../helpers/riskCalculator';
import { connect } from 'react-redux';
import { createIpopy1Action, resetIpopy1Action, updateIpopy1Action } from '../../../ducks/ipopy1/actions';
import { defaultState } from '../../../ducks/ipopy1/reducers';

const InputWrapper = styled.div`
  padding-bottom: 16px;
`;

const gSector2Map = {
    "Banks": "Financials",
    "Biotechnology": "Biotechnology",
    "Communication Services": "Communication Services",
    "Consumer Discretionary": "Consumer Discretionary",
    "Consumer Staples": "Consumer Staples",
    "Energy": "Energy",
    "Financials": "Financials",
    "Health Care": "Health Care",
    "Industrials": "Industrials",
    "Information Technology": "Information Technology",
    "Investment Company": "Financials",
    "Health Care": "Health Care",
    "Materials": "Materials",
    "Utilities": "Utilities",
}

const sectorOptions = Object.keys(gSector2Map).map((name) => ({ label: name }));

const currentYear = new Date().getFullYear();

const MAX_EVALUATIONS = 25;

class DisplayError extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: this.props.error,
        }
    }
    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.setState({
                error: this.props.error
            })
        }
    }
    render() {
        const { error } = this.state;
        const content = error ?
            <p className="display-error" key="display-error">Problem processing the request: { error.message }.</p> :
            null;
        return (
            content
        )
    }
}

export class IpoPy1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            isEditable: this.props.isEditable,
            isAtEvaluationsLimit: false,
            isPreProcessing: this.props.isPreProcessing,
        };
    }

    componentDidMount() {
        try {
            this.setState({
                displayData: this.prepareTable()
            })
        } catch (error) {
            console.error(error);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevProps.data.table.data, this.props.data.table.data)) {
            this.setState({
                displayData: this.prepareTable(),
                isEditable: this.props.isEditable,
            });
        }
        if (!_.isEqual(prevProps.isPreProcessing, this.props.isPreProcessing)) {
            this.setState({
                isPreProcessing: this.props.isPreProcessing,
            });
        }
        if (
            (!_.isEqual(prevProps.error, this.props.error)) ||
            (!_.isEqual(prevProps.data.retention, this.props.data.retention))
        ) {
            this.setState({
                displayData: this.prepareTable(),
            });
        }
    }

    prepareTable() {
        const data = this.props.data;
        if (!data.table) {
            return;
        }

        if (data.table && data.table.data && data.table.data.length > 0) {
            for (let i = 0; i < data.table.data.length; i++ ) {
                // setting first row using defaults
                data.table.data[i].limit = data.limit;
                data.table.data[i].retention = data.retention;
                data.table.data[i].revenuePrior = data.revenuePrior;
                data.table.data[i].revenuePrior2 = data.revenuePrior2;
                data.table.data[i].earningsPrior = data.earningsPrior;
                data.table.data[i].earningsPrior2 = data.earningsPrior2;
                data.table.data[i].ipoYear = data.ipoYear;
                // presentation related
                if (data.table.data[i].hasFailed || data.table.data[i].beingEvaluated) {
                    if (data.table.data[i].beingEvaluated) {
                        data.table.data[i].trClassName = "in-progress";
                    }
                    if (data.table.data[i].hasFailed) {
                        data.table.data[i].trClassName = "in-error";
                    }
                } else {
                    data.table.data[i].trClassName = "";
                }

                if (i === 0) {
                    data.table.data[i].layer = Number.isNaN(data.retention) ? defaultState.data["retention"] : String(data.retention);
                } else {
                    data.table.data[i].layer = String(calcAttachment(
                        data.table.data[i - 1].limit,
                        data.table.data[i - 1].layer
                    ));
                }
            }
        }

        const preparedData = {
            ...data,
            table: {
                columns: data.table.columns,
                data: _.map(data.table.data, (row, index) => this.prepareTableRow(row, index)),
            }
        };
        return preparedData;
    }

    getUpdatedDataSet(key, value) {
        let newState = _.cloneDeep({
            ...this.state.data,
        });

        newState.table.data = newState.table.data.map((row, idx) => {
            return {
                ...row,
                [key]: (idx === newState.table.data.length - 1) ? value : row[key]
            }
        });
        return newState;
    }

    updateDataStore(key, value, callback) {
        this.setState({
            data: {
                ...this.state.data,
                [key]: value
            }
        }, () => {
            this.setState({
                data: this.getUpdatedDataSet(key, value),
            }, () => {
                return typeof callback === 'function' && callback();
            })
        });
    }

    prepareTableRow(row, index) {
        return {
            ...row,
            layer: <
                NumberFormat
                    displayType='text'
                    prefix={'$'}
                    thousandSeparator={","}
                    value={row.layer || ''}
                />,
            modelFrequency: <
                NumberFormat
                    decimalScale={1}
                    displayType='text'
                    fixedDecimalScale
                    prefix={'%'}
                    thousandSeparator={","}
                    value={100*(row.modelFrequency) || ''}
                />,
            sweetSpot: <
                NumberFormat
                    decimalScale={1}
                    displayType='text'
                    prefix={'$'}
                    suffix={'M'}
                    thousandSeparator={","}
                    value={(Number(row.sweetSpot) / Math.pow(10, 6)) || ''}
                />,
            technicalPrice: <
                NumberFormat
                    decimalScale={0}
                    displayType='text'
                    prefix={'$'}
                    thousandSeparator={","}
                    value={(Number(row.technicalPrice) / Math.pow(10, 0)) || ''}
                />,
        }
    }

    reset() {
        this.setState({
            ...defaultState
        });
        this.props.resetIpopy1Action();
    }

    render() {
        const { isEditable } = this.state;
        let content =
            <div className="ipopy1">
                <div className="company-header">
                    <div className="company-header-summary">
                        <div className="container">
                            <div className="row">
                                <span className="title">IPO Public Year 1</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="company-overview container">
                    <div className="panel panel-default">
                        <div className="panel-body">
                            <h2>Evaluation input</h2>
                            <DisplayError error={this.props.error} />
                            <form>
                                <div className="row">
                                    <div className="col-xs-6">
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Company name</div>
                                            {isEditable ?
                                                <Input
                                                    value={this.state.data.companyName}
                                                    onChange={(event) => {
                                                        this.setState({
                                                            data: {
                                                                ...this.state.data,
                                                                companyName: event.target.value
                                                            }
                                                        })
                                                    }}
                                                    required={true}
                                                 /> :
                                                 this.state.data.companyName
                                            }
                                        </InputWrapper>
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Company ID</div>
                                            <NumberFormat
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('companyId', value);
                                                }}
                                                isAllowed={(values) => {
                                                    const { floatValue } = values;
                                                    return floatValue > 0;
                                                }}
                                                value={this.state.data.companyId}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-6">
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Limit</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                value={this.state.data.limit}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('limit', value, () => {
                                                        const update = this.state.data.table.data[this.state.data.table.data.length - 1];
                                                        if (update) {
                                                            this.props.updateIpopy1Action(update, 'limit');
                                                        }
                                                    });
                                                }} />
                                        </InputWrapper>
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Retention</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                value={this.state.data.retention}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('retention', value, () => {
                                                        const update = this.state.data.table.data[this.state.data.table.data.length - 1];
                                                        if (update) {
                                                            this.props.updateIpopy1Action(update, 'retention');
                                                        }
                                                    });
                                                }} />
                                        </InputWrapper>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-3">
                                        <InputWrapper  className="common-element input">
                                            <div className="input-label">Transaction size</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('transaction', value);
                                                }}
                                                isAllowed={(values) => {
                                                    const { floatValue } = values;
                                                    return floatValue <= 10000;
                                                }}
                                                prefix={'$'}
                                                suffix={'M'}
                                                thousandSeparator={true}
                                                value={this.state.data.transaction}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Sector name</div>
                                            {isEditable ?
                                                <Select
                                                    key={"select-company-sector-" + this.state.data.gSectorName.replace(/\W/g, "-")}
                                                    className="width-small"
                                                    value={_.find(sectorOptions, { label: this.state.data.gSectorName }) || ""}
                                                    options={sectorOptions}
                                                    onChange={(val) => {
                                                        const value = val && val.label || '';
                                                        this.updateDataStore('gSectorName', value);
                                                    }}
                                                 /> :
                                             this.state.data.gSectorName}
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">IPO year</div>
                                            <NumberFormat
                                                displayType={isEditable ? 'input' : 'text'}
                                                format="####"
                                                isAllowed={(values) => {
                                                    const { floatValue } = values;
                                                    return floatValue > currentYear - 1;
                                                }}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('ipoYear', value);
                                                }}
                                                value={this.state.data.ipoYear}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper className="common-element input">
                                            <div className="input-label">Year founded</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                displayType={isEditable ? 'input' : 'text'}
                                                format="####"
                                                isAllowed={(values) => {
                                                    const { floatValue } = values;
                                                    return floatValue < 1 + currentYear;
                                                }}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('yearFounded', value);
                                                }}
                                                value={this.state.data.yearFounded}
                                            />
                                        </InputWrapper>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-3">
                                        <InputWrapper  className="common-element input">
                                            <div className="input-label">Revenue prior</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('revenuePrior', Number.isNaN(value) ? "" : value)
                                                }}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                value={this.state.data.revenuePrior}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper  className="common-element input">
                                            <div className="input-label">Revenue prior 2</div>
                                            <NumberFormat
                                                allowNegative={false}
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('revenuePrior2', Number.isNaN(value) ? "" : value);
                                                }}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                value={this.state.data.revenuePrior2}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper  className="common-element input">
                                            <div className="input-label">Earnings prior</div>
                                            <NumberFormat
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('earningsPrior', Number.isNaN(value) ? "" : value);
                                                }}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                value={this.state.data.earningsPrior}
                                            />
                                        </InputWrapper>
                                    </div>
                                    <div className="col-xs-3">
                                        <InputWrapper  className="common-element input">
                                            <div className="input-label">Earnings prior 2</div>
                                            <NumberFormat
                                                displayType={isEditable ? 'input' : 'text'}
                                                onValueChange={(val) => {
                                                    const value = val.floatValue;
                                                    this.updateDataStore('earningsPrior2', Number.isNaN(value) ? "" : value);
                                                }}
                                                prefix={'$'}
                                                thousandSeparator={true}
                                                value={this.state.data.earningsPrior2}
                                            />
                                        </InputWrapper>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        {this.state.displayData &&
                                            [
                                                 <DynamicTable
                                                     key="ipopy1-table"
                                                     data={this.state.displayData}
                                                     className="table major"
                                                 />,

                                                [!this.state.isAtEvaluationsLimit && <div
                                                    key="ipopy1-table-add-layer-button"
                                                    className="add-layer"
                                                    onClick={() => {
                                                        if (this.state.isPreProcessing === true) {
                                                            return;
                                                        }

                                                        let newData = _.cloneDeep({
                                                            ...this.props.data,
                                                        });

                                                        let evaluationPayload = newData.table.data.at(newData.table.data.length - 1)

                                                        this.state.data.table.data.forEach(
                                                            (row, idx) => {
                                                                if (idx === 0) {
                                                                    Object.keys(row).forEach((key) => {
                                                                        if (row[key]) {
                                                                            evaluationPayload[key] = row[key];
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        );
                                                        // calculate moving arguments: attachPoint
                                                        if (newData.table.data.length === 1) {
                                                            evaluationPayload.attachPoint = 0;
                                                        } else {
                                                            evaluationPayload.attachPoint = calcAttachment(
                                                                newData.table.data[newData.table.data.length - 2].limit,
                                                                newData.table.data[newData.table.data.length - 2].attachPoint
                                                            );
                                                        }
                                                        evaluationPayload.gSectorName2 = gSector2Map[evaluationPayload.gSectorName];
                                                        evaluationPayload.index = newData.table.data.length - 1;
                                                        this.props.createIpopy1Action(evaluationPayload);
                                                        if (newData.table.data.length >= MAX_EVALUATIONS) {
                                                          this.setState({
                                                            isAtEvaluationsLimit: true
                                                          });
                                                        }
                                                    }}>
                                                    <PlusImage /><span>Add a layer</span>
                                                </div> || <div key="ipopy1-table-add-layer-msg" className="end-layer">All evaluations completed or in progress</div>
                                                ]
                                            ]
                                        }
                                    </div>
                                </div>
                                <div
                                    key="ipopy1-reset-button"
                                    className="reset-form"
                                    onClick={() => {
                                        this.reset();
                                    }}>
                                    <TrashImage /><span>Remove evaluations and reset the form</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>;
        return (
            content
        );
    }
};

function mapStateToProps(state) {
    return {
        error: _.get(state, 'ipopy1.error'),
        data: _.get(state, 'ipopy1.data'),
        isEditable: _.get(state, 'ipopy1.isEditable'),
        isPreProcessing: _.get(state, 'ipopy1.isPreProcessing'),
    };
};



export default connect(mapStateToProps, {
    createIpopy1Action,
    resetIpopy1Action,
    updateIpopy1Action,
})(IpoPy1);
