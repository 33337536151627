import React from 'react';

const FlagImage = () => {
    return <svg className="flag-image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.75 162.5">
        <defs>
            <style>
                {`.flag-cls-1,.flag-cls-2{fill:none;}
                .flag-cls-1{clip-rule:evenodd;}
                .flag-cls-3{clip-path:url(#flag-clip-path);}
                .flag-cls-4{clip-path:url(#flag-clip-path-2);}
                .flag-cls-5{fill:#f5622d;}
                .flag-cls-6{clip-path:url(#flag-clip-path-3);}`}
            </style>
            <clipPath id="flag-clip-path">
                <path className="flag-cls-1" d="M37.5,31.25h6.25v100H31.25V37.5A6.25,6.25,0,0,1,37.5,31.25Z"/>
            </clipPath>
            <clipPath id="flag-clip-path-2">
                <rect className="flag-cls-2" x="31.25" y="31.25" width="81.25" height="100"/>
            </clipPath>
            <clipPath id="flag-clip-path-3">
                <path className="flag-cls-1" d="M50,31.25h56.25a6.25,6.25,0,0,1,6.25,6.25V68.75A6.25,6.25,0,0,1,106.25,75H50Z"/>
            </clipPath>
        </defs>
        <title>Flag</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <g className="flag-cls-3">
                    <g className="flag-cls-4">
                        <rect className="flag-cls-5" width="75" height="162.5"/>
                    </g>
                </g>
                <g className="flag-cls-6">
                    <g className="flag-cls-4">
                        <rect className="flag-cls-5" x="18.75" width="125" height="106.25"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>;
};
export default FlagImage;
