import React, { Component } from 'react';
import ReactSelect from 'react-select';
import _ from '../../lodashWrapper';
import classNames from 'classnames';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        if (props.defaultLabel) {
            this.state.value = _.find(props.options, { label: props.defaultLabel })
        }
    }

    handleChange = (value) => {
        this.setState({ value: value });
        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    render() {
        const value = this.state.value || this.props.value;
        return (
            <div className={classNames('common-element select', this.props.className)}>
                <div className="input-label">{this.props.label}</div>
                <ReactSelect value={value}
                    isDisabled={this.props.isDisabled}
                    onChange={(value) => this.handleChange(value)}
                    options={this.props.options}
                    clearable={this.props.clearable !== undefined ? this.props.clearable : true}
                    placeholder={this.props.placeholder}
                    multi={this.props.multi}
                />
            </div>
        );
    }
}

export default Select;
