import _ from '../../../lodashWrapper';
import React from 'react';
import { withRouter } from 'react-router';

import Card from '../card';
import DocImage from '../../images/doc';
import EnterImage from '../../images/enter';
import qs from "qs";
import DataNotFound from '../dataNotFound';

export const ScaRiskFiling = (props) => {
    function redirectTo(section, hash, risk_name) {
        const worksheetId = _.get(qs.parse(props.history.location.search, { ignoreQueryPrefix: true }), 'worksheetId');
        const companyId = _.get(props, 'match.params.companyId');

        if (risk_name === 'Changes in D&O') {
            risk_name = 'Departure of Directors or Certain Officers; Election of Directors; Appointment of Certain Officers: Compensatory Arrangements of Certain Officers';
        }
        props.history.push(`/company/${companyId}/${section}?worksheetId=${worksheetId}&risk_name=${encodeURIComponent(risk_name)}#${hash}`)
    }

    return (
        <div className={`common-element sca-risk-filing ${props.className}`}>
            {props.data && props.data.length > 0 ? <div className="row">
                {_.map(props.data, (item, key) => {
                    return <div key={key} className='col-xs-6' onClick={() => redirectTo(
                        'k8' === item.type ? 'news' : 'financial',
                        'k8' === item.type ? 'filing8k' : 'indicators10k10q',
                        item.risk_name
                    )}>
                        <Card className='sec-item'>
                            {(item.type instanceof Array) ? (
                                _.map(item.type, (t, key) => {
                                    return <div key={key} className='sec-icon'><DocImage type={t} /></div>
                                })
                            ) : (
                                    <div className='sec-icon'><DocImage type={item.type} /></div>
                                )}
                            <div className='sec-name'>
                                <div>{item.risk_name}</div>
                            </div>
                            <div className='sec-freq'>
                                <span>{item.risk_frequency}</span>
                                <small>Frequency</small>
                            </div>
                            <div className='sec-right-icon hovering'><EnterImage /></div>
                        </Card>
                    </div>
                })}
            </div> : <DataNotFound />}

        </div>
    );
};

export default withRouter(ScaRiskFiling);
