import apigClientWrapper from '../../helpers/apigClientWrapper';
import _ from 'lodash';
import { put, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects'
import actions from './types';
import constants from '../../constants';
import { apiFailMsg } from '../../helpers/utility';

const getWorksheet = (state) => state.worksheet;

function* autoNoteUpdate(action) {

    const worksheet = _.cloneDeep(action.worksheet);
    const sectionMap = constants.note.sectionMap;
    const note = worksheet.notes[sectionMap[action.currentSection]];
    const newValue = action.note.toString('html');

    if (worksheet.type === 'private') {
        return;
    }

    if (note.note_text !== newValue) {
        note.note_text = newValue;
        yield call(apigClientWrapper.updateUserWorksheet, worksheet.worksheetId, { cik: action.companyId, ...worksheet });
        const payload = { data: worksheet };
        yield put({ type: actions.AUTO_NOTE_UPDATE_SUCCESS, payload: payload });
    }
}

function* generatePDF(action) {
    yield put({ type: actions.GENERATE_WORKSHEET_PDF_START });
    let currentWorkSheet = yield select(getWorksheet);

    const selected = currentWorkSheet.selected.submission;
    const obj = {
        ...selected,
        coverage_type: selected.type,
        company_id: action.companyId,
        company_name: action.companyName,
        date: new Date().toString(),
        worksheet_id: action.worksheetId,
    };
    let error = false;
    let response;
    try {
        response = yield call(apigClientWrapper.createWorksheetPDF, obj);
        if (response.data.url === undefined) {
            error = true;
        } else {
            yield put({ type: actions.GENERATE_WORKSHEET_PDF_SUCCESS, payload: response.data });
        }
    } catch (e) {
        error = true;
    }
    console.log(error);

    if (error === true) {
        yield call(delay, 1000);
        try {
            response = yield call(apigClientWrapper.createWorksheetPDF, obj);
            if (response.data.url === undefined) {
                error = true;
            } else {
                yield put({ type: actions.GENERATE_WORKSHEET_PDF_SUCCESS, payload: response.data });
            }
        } catch (e) {
            error = true;
        }
    }

    if (error === true) {
        yield put({ type: actions.GET_WORKSHEET_PDF_PRESIGNEDURL_FAILED });
    }
}

function* updateWorksheet(action) {
    const obj = { cik: action.companyId, ...action.worksheet };
    yield call(apigClientWrapper.updateUserWorksheet, action.worksheetId, obj);
    yield put({ type: actions.UPDATE_SUCCESS, payload: { data: action.worksheet } });
}

function* archiveWorksheet(action) {
    let currentWorkSheet = yield select(getWorksheet);
    const obj = {
        worksheet_id: currentWorkSheet.selected.worksheet_id,
        documentType: (currentWorkSheet.selected.submission.renewal === true ? 'RENR' : 'REVL'),
        review: action.review
    };

    try {
        const response = yield call(apigClientWrapper.archiveUserWorksheet, obj);
        if (response.data && response.data.statusCode == 200) {
            yield put({ type: actions.ARCHIVE_WORKSHEET_SUCCESS });
        } else {
            yield put({ type: actions.ARCHIVE_WORKSHEET_FAILED });
        }
    } catch (e) {
        yield put({ type: actions.ARCHIVE_WORKSHEET_FAILED });
    }
}


function* getWorksheetPDFSignedUrl(action) {
    const response = yield call(apigClientWrapper.getWorksheetPDF, action.worksheetId);
    yield put({ type: actions.GET_WORKSHEET_PDF_PRESIGNEDURL_SUCCESS, payload: response.data });
}

function* getWorksheets() {
    try {
        const response = yield call(apigClientWrapper.getUserWorksheets);
        yield put({ type: actions.SEARCH_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: actions.SEARCH_FAIL, ...apiFailMsg(e) });
    }
}

function* updateWorksheetPDF(action) {
    const obj = {
        worksheet_id: action.worksheetId,
    };
    yield call(apigClientWrapper.updateWorksheetPDF, obj);
    let currentWorkSheet = yield select(getWorksheet);
    const newCurrentWorksheet = Object.assign({}, currentWorkSheet.selected, { state: 'pdf' });
    const obj2 = { cik: action.companyId, ...newCurrentWorksheet };
    yield call(apigClientWrapper.updateUserWorksheet, currentWorkSheet.selected.worksheet_id, obj2);
    yield put({ type: actions.UPDATE_WORKSHEET_PDF_SUCCESS });
}

function* selectWorkSheet(action) {
    try {
        const response = yield call(apigClientWrapper.getUserWorksheet, action.worksheetId);
        yield put({ type: actions.SELECT_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: actions.SELECT_FAIL, ...apiFailMsg(e) });
    }
}

export default [
    takeEvery(actions.AUTO_NOTE_UPDATE, autoNoteUpdate),
    takeEvery(actions.SELECT, selectWorkSheet),
    takeLatest(actions.SEARCH, getWorksheets),
    takeLatest(actions.GENERATE_WORKSHEET_PDF, generatePDF),
    takeEvery(actions.UPDATE, updateWorksheet),
    takeLatest(actions.UPDATE_WORKSHEET_PDF, updateWorksheetPDF),
    takeEvery(actions.ARCHIVE_WORKSHEET, archiveWorksheet),
    takeLatest(actions.GET_WORKSHEET_PDF_PRESIGNEDURL, getWorksheetPDFSignedUrl),

    // takeEvery(actions.CREATE, createWorksheet),
]