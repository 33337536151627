import _ from '../../../lodashWrapper';
import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {connect} from "react-redux";
import qs from 'qs';

import CompanyHeaderSummary from './companyHeaderSummary';
import CompanyHeaderMenu from './companyHeaderMenu';

import {selectCompanyAction} from '../../../ducks/company/actions';
import {getWorksheetAction, worksheetClearSubmissionAction} from '../../../ducks/worksheet/actions';

class CompanyHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const companyId = _.get(this, 'props.match.params.companyId');
        const worksheetId = _.get(qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}), 'worksheetId');
        if (companyId) {
            this.props.selectCompanyAction(companyId);
        }
        if (worksheetId) {
            this.setState({lastFetchedWorksheetId: worksheetId});
            this.props.getWorksheetAction(worksheetId);
        }
    }

    componentDidUpdate() {
        const worksheetId = _.get(qs.parse(this.props.history.location.search, {ignoreQueryPrefix: true}), 'worksheetId');
        if (worksheetId !== this.state.lastFetchedWorksheetId) {
            this.setState({lastFetchedWorksheetId: worksheetId});
            this.props.getWorksheetAction(worksheetId);
        }
    }

    componentWillUnmount() {
        this.props.worksheetClearSubmissionAction();
    }

    render() {
        const pattern = /^\/company\/\d+\/pdf/g
        const hideHeader = pattern.test(window.location.pathname) === true ? "hidden" : "";
        const class1 = "company-header " + hideHeader;
        return (
            <div className={class1} >
                <Route path='/company/:companyId' component={CompanyHeaderSummary}/>
                <Route path='/company/:companyId/:view?' component={CompanyHeaderMenu}/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        worksheet: _.get(state, 'worksheet.selected'),
    };
}

export default connect(mapStateToProps, {
    selectCompanyAction,
    getWorksheetAction,
    worksheetClearSubmissionAction
})(CompanyHeader);
