import Actions from './types';

export function searchCompanyAction(query) {
    return {
        type: Actions.SEARCH,
        query: query,
    }
}

export function searchInitCompanyAction() {
    return {
        type: Actions.SEARCH_INIT
    }
}

export function clearCompanyAction() {
    return {
        type: Actions.CLEAR
    }
}

export function selectCompanyAction(companyId, companyName) {
    return {
        type: Actions.SELECT,
        companyId: companyId,
        companyName: companyName
    }
}

export const selectCompanyActionSuccess = data => {
    return {
        type: Actions.SELECT_SUCCESS,
        payload: data
    };
};

export function deselectCompanyAction() {
    return {
        type: Actions.DESELECT
    }
}

export function getCompanyDetailsAction(companyId) {
    return {
        type: Actions.GET_DETAIL,
        companyId: companyId,
    }
}
export function compareIndexAction(index, checked) {
    return {
        type: Actions.COMPARE_INDEX,
        index: index,
        checked: checked,
    }
}