import types from './types';

export function resetIpopy1Action() {
    return {
        type: types.RESET,
    };
}

export function createIpopy1Action(payload) {
    return {
        type: types.CREATE,
        payload: payload,
    };
}

export function updateIpopy1Action(payload, key) {
    return {
        type: types.UPDATE,
        payload: {
            ...payload,
            key,
        },
    };
}
