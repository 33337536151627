import React, { Component } from 'react';
import _ from '../../../lodashWrapper';
import { getValue } from '../../../helpers/utility';
import DynamicTable from '../../common/dynamicTable';

const getProbability = (score) =>
  score === 'N/A'
    ? 'unknown'
    : score > 7
    ? 'high'
    : score > 3
    ? 'normal'
    : 'low';

export class PrivateCompanyDOClaimsProbability extends Component {
  render() {
    const { predictions, dnb_data } = this.props.data;
    const dnoScore = _.get(predictions, 'd&o_decile_score') || 'N/A';
    const eplScore = _.get(predictions, 'epl_decile_score') || 'N/A';
    const combinedScore = _.get(predictions, 'combined_decile_score') || 'N/A';
    const scores = [
      { id: 'D&O', score: dnoScore, probability: getProbability(dnoScore) },
      { id: 'EPL', score: eplScore, probability: getProbability(eplScore) },
      {
        id: 'Combined',
        score: combinedScore,
        probability: getProbability(combinedScore),
      },
    ];
    const tableData = {
      table: {
        columns: [
          { title: 'Item', field: 'item' },
          { title: 'Value', field: 'value' },
          { title: 'Source', field: 'source' },
        ],
        data: [
          {
            item: 'SIC Code',
            value: getValue(dnb_data.sic4),
            source: 'D&B',
            unformatted: true,
          },
          {
            item: 'Year started',
            value: getValue(dnb_data.yrstart),
            source: 'D&B',
            unformatted: true,
          },
          {
            item: 'Revenue (estimated)',
            value: dnb_data.smad_sales ? `$${dnb_data.smad_sales}` : 'N/A',
            source: 'D&B',
          },
          {
            item: 'Employees (estimated)',
            value: getValue(dnb_data.smad_emptotl),
            source: 'D&B',
          },
          {
            item: 'Viability rating',
            value: getValue(dnb_data.viability_rating),
            source: 'D&B',
          },
          {
            item: 'Financial stress score',
            value: getValue(dnb_data.fscore),
            source: 'D&B',
          },
        ],
      },
    };

    return (
      <div className="panel panel-default">
        <div className="panel-body with-spinner">
          <h2>Claims probability</h2>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="col-xs-12">
              <table width="100%">
                <tbody>
                  {scores.map(({ id, score, probability }) => (
                    <React.Fragment key={id}>
                      <tr>
                        <td style={{ paddingTop: '20px' }}>
                          <h3>{score}</h3>
                        </td>
                        <td style={{ paddingTop: '20px' }}>
                          <h3 style={score > 7 ? { color: 'red' } : undefined}>
                            {`${_.capitalize(
                              probability
                            )} ${id} claims probability`}
                          </h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="faint">Score out of 10</div>
                        </td>
                        <td>
                          <div className="faint">
                            {`There is ${probability} likelihood of ${id} Claims based on the external source data.`}
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="col-xs-12">
              <table width="100%">
                <tbody>
                  <tr>
                    <td>
                      <h3>Risk prediction</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="faint">
                        The D&O Claims probability is calculated using Argo's
                        historical loss data and the below external data.
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <DynamicTable
                data={tableData}
                formatWithCommas
                trClassName="first-td-bold"
                className="table last"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivateCompanyDOClaimsProbability;
