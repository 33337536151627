import React from 'react';
import styles from './_dataNotFound.module.scss';
const DataNotFound = (props) => {
    return (
        <div>
            {props.title && <h3>{props.title}</h3>}
            <div className={styles.emptyData}>
                {props.text ? <div>{props.text}</div> : <div>Not Found.</div>}
            </div>
        </div>
    );
};

export default DataNotFound;
