import React from 'react';

const InfoImage = (props) => {

    const onClick = () => {
        if(props.onClick){
            props.onClick();
        }
    };
    return <svg  onClick={onClick} className='info-image' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <title>info</title>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm0,95A45,45,0,1,1,95,50,45,45,0,0,1,50,95Z"/>
                <path d="M45.79,55.6a12.23,12.23,0,0,1,1-5.31,8.91,8.91,0,0,1,2.47-3.19,28.21,28.21,0,0,1,3.83-2.41,26.41,26.41,0,0,0,3-1.87A6.66,6.66,0,0,0,58,40.69a6.54,6.54,0,0,0,.76-3.31,6.66,6.66,0,0,0-2.41-5.63,10.54,10.54,0,0,0-6.6-1.86q-4.59,0-6.74,2.37c-1.44,1.58-2.16,3.89-2.16,7H32.51A19.2,19.2,0,0,1,34.61,30,14.17,14.17,0,0,1,40.53,24a18.67,18.67,0,0,1,8.92-2,21.19,21.19,0,0,1,8.81,1.8,15.09,15.09,0,0,1,6.4,5.32A14.67,14.67,0,0,1,67,37.54a15,15,0,0,1-1.32,6.69,11.68,11.68,0,0,1-3.1,4.08,32.47,32.47,0,0,1-4.47,2.9,19.51,19.51,0,0,0-3.28,2.15,3,3,0,0,0-1,2.32v5.56H45.79Zm-1,22.11V67.45H55.12V77.71Z"/>
            </g>
        </g>
    </svg>;
};
export default InfoImage;
