import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import actions from './types';
import { apiFailMsg } from '../../helpers/utility';

const getTrading = (state) => state.trading;

function* getTradings(action) {
  let trading = yield select(getTrading);
  if (trading.data === null) {
    try {
      const response = yield call(apigClientWrapper.getCompanyTradings, action.companyId);
      yield put({ type: actions.GET_TRADING_SUCCESS, payload: response });
    } catch (e) {
      yield put({ type: actions.GET_TRADING_FAIL, ...apiFailMsg(e) });
    }
  }
}

export default [
  takeEvery(actions.GET_TRADING, getTradings),
]
