import Actions from './types';


export function getCompanyHoldersAction(companyId) {
    return {
        type: Actions.GET_HOLDER,
        companyId: companyId,
    }
}

export function getCompanyHoldersListAction(companyId, type) {
    return {
        type: Actions.GET_HOLDER_LIST,
        companyId: companyId,
        requestType: type,
    }
}

export function holdersTabChangedAction(index, tabName) {
    return {
        type: Actions.HOLDERS_TAB_CHANGED,
        index: index,
        tabName: tabName,
    }
}
