import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import apigClientWrapper from '../../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../../helpers/utility';
import { closeFormModalAction } from '../../../ducks/priv_company_form/actions';
import { getCompanyByIdAction } from '../../../ducks/priv_company/actions';
import Button from '../../common/button';
import Input from '../../common/input';
import Link from '../../common/link';
import ErrorAlert from '../../common/errorAlert';

const InputWrapper = styled.div`
  margin-top: 16px;
`;

export class PrivateCreateSubmissionForm extends Component {
  state = {
    submission_id: '',
    company_name: '',
    zip_code: '',
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ error: undefined });

    const { submission_id, company_name, zip_code } = this.state;

    try {
      await apigClientWrapper.createPrivateSubmission({
        submission_id,
        company_name,
        company_zip: zip_code,
      });

      this.props.closeFormModalAction();
      this.props.history.push(`/submission/${submission_id}`);
    } catch (error) {
      const { status } = apiFailMsg(error);

      if (status === 404) {
        this.props.closeFormModalAction();
        this.props.history.push(`/submission/${submission_id}`);
      }

      if (status === 400) {
        this.setState({
          error: 'No Company with that Name and Address Combination Found',
        });
      }
    }
  };

  render() {
    const { submission_id, company_name, zip_code, error } = this.state;

    return (
      <div>
        <h1>Create a Private Submission</h1>
        <form onSubmit={this.handleSubmit}>
          <InputWrapper>
            <Input
              label="Submission ID*"
              value={submission_id}
              onChange={({ target }) =>
                this.setState({ submission_id: target.value })
              }
              required
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Company Name*"
              value={company_name}
              onChange={({ target }) =>
                this.setState({ company_name: target.value })
              }
              required
            />
          </InputWrapper>
          <InputWrapper>
            <h2>Headquarter Address</h2>
          </InputWrapper>
          <InputWrapper>
            <Input
              label="Zip Code*"
              value={zip_code}
              onChange={({ target }) =>
                this.setState({ zip_code: target.value })
              }
              required
            />
          </InputWrapper>
          {error && (
            <ErrorAlert message={error} style={{ marginTop: '20px' }} />
          )}
          <div className="footer-wrapper">
            <Link className="link" onClick={this.props.closeFormModalAction}>
              Cancel
            </Link>
            <Button type="submit">Generate Score</Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(
  connect(
    null,
    { closeFormModalAction, getCompanyByIdAction }
  )(PrivateCreateSubmissionForm)
);
