import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../common/spinner';
import DynamicTable from '../../common/dynamicTable2';
import MenuTab from "../../common/menuTab";
import MenuTabItem from "../../common/menuTabItem";
import _ from 'lodash';
import { getBalance } from '../../../ducks/priv_balance/actions';
import { getCash } from '../../../ducks/priv_cash/actions';
import { getIncome } from '../../../ducks/priv_income/actions';
import ErrorAlert from '../../common/errorAlert';

export class PrivateFinancialStatement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0
        }
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.loadData();
        }
    }
    loadData() {
        this.props.getBalance(this.props.companyId);
        this.props.getCash(this.props.companyId);
        this.props.getIncome(this.props.companyId);
    }

    prepareData(key) {
        if (this.props[key + 'Error']) {
            return <ErrorAlert message={this.props[key + 'Error']} />;
        }
        return <Spinner loaded={this.props[key + 'Loaded']}>
            {this.props[key + 'Loaded'] && <DynamicTable className="table" data={{ tables: this.props[key] }} />}
        </Spinner>
    }

    render() {
        let key = 'income';
        if (this.state.tabIndex === 1) {
            key = 'balance';
        } else if (this.state.tabIndex === 2) {
            key = 'cash';
        }
        let content = this.prepareData(key);
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>Financial Statements</h2>
                    <Spinner loaded={true}>
                        <div>
                            <MenuTab initialActiveTab={this.state.tabIndex}>
                                <MenuTabItem
                                    action={() => this.setState({ tabIndex: 0 })}>
                                    Income Statements
                            </MenuTabItem>
                                <MenuTabItem
                                    action={() => this.setState({ tabIndex: 1 })}>
                                    Balance Sheet
                            </MenuTabItem>
                                <MenuTabItem
                                    action={() => this.setState({ tabIndex: 2 })}>
                                    Cash Flow
                            </MenuTabItem>
                            </MenuTab>
                        </div>


                        {content}

                    </Spinner>

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'privateCompany.selected.companyId'),
        cash: _.get(state, 'privateCash.data.cash'),
        balance: _.get(state, 'privateBalance.data.balance'),
        income: _.get(state, 'privateIncome.data.income'),
        cashLoaded: _.get(state, 'privateCash.loaded'),
        balanceLoaded: _.get(state, 'privateBalance.loaded'),
        incomeLoaded: _.get(state, 'privateIncome.loaded'),
        cashError: _.get(state, 'privateCash.error'),
        balanceError: _.get(state, 'privateBalance.error'),
        incomeError: _.get(state, 'privateIncome.error'),
    };
}
export default connect(mapStateToProps, {
    getBalance,
    getCash,
    getIncome,
})(PrivateFinancialStatement);
