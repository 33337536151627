export default {
    OPEN_SUBMISSION_MODAL: 'PRIVATE_SUBMISSION_OPEN_SUBMISSION_MODAL',
    CHANGE_SUBMISSION_MODAL_INDEX: 'PRIVATE_SUBMISSION_CHANGE_SUBMISSION_MODAL_INDEX',
    CLOSE_SUBMISSION_MODAL: 'PRIVATE_SUBMISSION_CLOSE_SUBMISSION_MODAL',
    GET_QUESTIONS: 'PRIVATE_SUBMISSION_GET_QUESTIONS',
    GET_QUESTIONS_SUCCESS: 'PRIVATE_SUBMISSION_GET_QUESTIONSSUCCESS',
    GET_QUESTIONS_FAILED: 'PRIVATE_SUBMISSION_GET_QUESTIONS_FAILED',
    GET_SUBMISSION_DATA: 'PRIVATE_SUBMISSION_GET_SUBMISSION_DATA',
    GET_SUBMISSION_DATA_SUCCESS: 'PRIVATE_SUBMISSION_GET_SUBMISSION_DATA_SUCCESS',
    GET_SUBMISSION_DATA_FAILED: 'PRIVATE_SUBMISSION_GET_SUBMISSION_DATA_FAILED',
    SUBMIT: 'PRIVATE_SUBMISSION_SUBMIT',
    SUBMIT_SUCCESS: 'PRIVATE_SUBMISSION_SUBMIT_SUCCESS',
    SUBMIT_FAILED: 'PRIVATE_SUBMISSION_SUBMIT_FAILED',
};