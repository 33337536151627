import _ from 'lodash';
import RichTextEditor from 'react-rte';
import actions from './types';
import { worksheetTypes } from '../worksheet';

const defaultState = {
    data: {
        overview: RichTextEditor.createEmptyValue(),
        trade: RichTextEditor.createEmptyValue(),
        financial: RichTextEditor.createEmptyValue(),
        risk: RichTextEditor.createEmptyValue(),
        news: RichTextEditor.createEmptyValue(),
    }
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case actions.SHOW:
            return {
                ...state,
                isVisible: true
            };
        case actions.HIDE:
            return {
                ...state,
                isVisible: false
            };
        case actions.CHANGE_SECTION:
            return {
                ...state,
                currentSection: action.payload
            };
        case actions.EDIT:
            return {
                ...state,
                data: {
                    ...state.data,
                    [state.currentSection]: action.payload
                }
            };
        case worksheetTypes.SELECT_SUCCESS:
            
            var sectionMap = {
                overview: 'overview',
                trading: 'trade',
                financial: 'financial',
                risk: 'risk',
                news: 'news'
            };
            var mappedNotes = _.chain(action).get('payload.notes')
                .mapKeys(note => sectionMap[note.note_page])
                .mapValues(note => {
                    return RichTextEditor.createValueFromString(note.note_text, 'html')
                })
                .value();
            
            return {
                ...state,
                data: {
                    ...state.data,
                    ...mappedNotes
                }
            };
        default:
            return state;
    }
}
