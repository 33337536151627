import reducer from "./reducers";

import * as privateCompanyIncomeActions from "./actions";

export {
  privateCompanyIncomeActions
}
export { default as privateCompanyIncomeOperations } from "./operations";
export { default as privateCompanyIncomeTypes } from "./types";

export default reducer;