export default {
    SEARCH: 'WORKSHEET_SEARCH',
    SEARCH_SUCCESS: 'WORKSHEET_SEARCH_SUCCESS',
    SEARCH_FAIL: 'WORKSHEET_SEARCH_FAIL',
    SELECT: 'WORKSHEET_SELECT',
    SELECT_SUCCESS: 'WORKSHEET_SELECT_SUCCESS',
    SELECT_FAIL: 'WORKSHEET_SELECT_FAIL',
    CREATE: 'WORKSHEET_CREATE',
    UPDATE: 'WORKSHEET_UPDATE',
    UPDATE_SUCCESS: 'WORKSHEET_UPDATE_SUCCESS',
    UPDATE_FAIL: 'WORKSHEET_UPDATE_FAIL',
    DELETE: 'WORKSHEET_DELETE',
    CLEAR_SUBMISSION: 'WORKSHEET_CLEAR_SUBMISSION',
    GO_VIEW_ONLY: 'WORKSHEET_GO_VIEW_ONLY',
    SELECT_COMPANY: 'WORKSHEET_SELECT_COMPANY',
    SELECT_WORKSHEET: 'WORKSHEET_SELECT_WORKSHEET',
    SHOW_MODAL: 'WORKSHEET_SHOW_MODAL',
    HIDE_MODAL: 'WORKSHEET_HIDE_MODAL',
    AUTO_NOTE_UPDATE: 'WORKSHEET_AUTO_NOTE_UPDATE',
    AUTO_NOTE_UPDATE_SUCCESS: 'WORKSHEET_AUTO_NOTE_UPDATE_SUCCESS',
    AUTO_NOTE_UPDATE_FAIL: 'WORKSHEET_AUTO_NOTE_UPDATE_FAIL',
    CREATE_WORKSHEET_DONE: 'WORKSHEET_CREATE_WORKSHEET_DONE',
    CLEAR_WORKSHEET_PDF: 'WORKSHEET_CLEAR_WORKSHEET_PDF',
    UPDATE_WORKSHEET_PDF: 'WORKSHEET_UPDATE_WORKSHEET_PDF',
    UPDATE_WORKSHEET_PDF_SUCCESS: 'WORKSHEET_UPDATE_WORKSHEET_PDF_SUCCESS',
    ARCHIVE_WORKSHEET: 'WORKSHEET_ARCHIVE_WORKSHEET',
    ARCHIVE_WORKSHEET_SUCCESS: 'WORKSHEET_ARCHIVE_WORKSHEET_SUCCESS',
    ARCHIVE_WORKSHEET_FAILED: 'WORKSHEET_ARCHIVE_WORKSHEET_FAILED',
    GENERATE_WORKSHEET_PDF: 'WORKSHEET_GENERATE_WORKSHEET_PDF',
    GENERATE_WORKSHEET_PDF_START: 'WORKSHEET_GENERATE_WORKSHEET_PDF_START',
    GENERATE_WORKSHEET_PDF_SUCCESS: 'WORKSHEET_GENERATE_WORKSHEET_PDF_SUCCESS',
    GET_WORKSHEET_PDF_PRESIGNEDURL: 'WORKSHEET_GET_WORKSHEET_PDF_PRESIGNEDURL',
    GET_WORKSHEET_PDF_PRESIGNEDURL_SUCCESS: 'WORKSHEET_GET_WORKSHEET_PDF_PRESIGNEDURL_SUCCESS',
    GET_WORKSHEET_PDF_PRESIGNEDURL_FAILED: 'WORKSHEET_POST_PDF_PRESIGNEDURL_FAILED',
    CLEAR_WORKSHEET_PDF_PRESIGNEDURL: 'WORKSHEET_CLEAR_WORKSHEET_PDF_PRESIGNEDURL',
};