import reducer from "./reducers";

import * as privateCompanyOwnershipActions from "./actions";

export {
  privateCompanyOwnershipActions
}
export { default as privateCompanyOwnershipOperations } from "./operations";
export { default as privateCompanyOwnershipTypes } from "./types";

export default reducer;