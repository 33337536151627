import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyHolders from './companyHolders';
import NoteController from '../../note/noteController';
import CompanyEarning from './companyEarning';
import CompanyTradeInfo from './companyTradeInfo';
import CompanyProxyStatement from './companyProxyStatement';

export class CompanyTrade extends Component {
    render() {
        return (
            <div className="company-trade container">
                <CompanyEarning />
                <CompanyTradeInfo />
                <CompanyHolders />
                <CompanyProxyStatement />
                <NoteController section="trade" />
            </div>
        );
    }
}

export default connect(null, {
})(CompanyTrade);
