import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../common/spinner'
import Info from '../../common/info';
import InfoImage from '../../images/info';
import ScaRiskFiling from '../../common/scaRiskFiling'
import {
    getCompanyRiskFlagsAction
} from '../../../ducks/riskFlags/actions';
import ErrorAlert from '../../common/errorAlert';

export class CompanyRiskFlags extends Component {

    constructor(props) {
        super(props)
        this.state = { filter: null }
    }

    componentDidMount() {
        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getCompanyRiskFlagsAction(this.props.company.company_id);
    }

    render() {
        let content;

        if (this.props.companyRiskError) {
            content = <ErrorAlert message={this.props.companyRiskError} />
        } else {
            content = <Spinner loaded={this.props.companyRisk}>
                <ScaRiskFiling data={this.props.companyRisk} />
            </Spinner>
        }
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2 className='inline'>SEC Filing Risk Flags From The Last 4 Quarters </h2>
                    <Info text="Analysis is conducted for last 4 SEC Filings (10K and 10Q)">
                        <InfoImage />
                    </Info>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        companyRisk: _.get(state, 'riskFlags.data'),
        companyRiskError: _.get(state, 'error.COMPANY_GET_RISK_FLAGS'),
    };
}

export default connect(mapStateToProps, {
    getCompanyRiskFlagsAction
})(CompanyRiskFlags);
