import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicTable from '../../common/dynamicTable2';
import { fetchAttachmentAction } from '../../../ducks/attachment/actions';
import DataNotFound from "../../common/dataNotFound";
import Spinner from '../../common/spinner'
import Info from '../../common/info';
import InfoImage from '../../images/info';
import ErrorAlert from '../../common/errorAlert';

export class CompanyAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = { defaultIndexTriggered: false }
        this.loadData = this.loadData.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
    }

    loadData() {
        this.props.fetchAttachmentAction(this.props.companyId);
    }

    scrollTo() {
        if (!this.props.isPDF && this.state.defaultIndexTriggered === false) {
            if (this.refs.elem !== undefined) {

                let index = this.props.data.settlement_probabilities_table.data.findIndex((x) => x.company <= .01);
                if (index > 4) {
                    this.refs.elem.scrollTop = (index - 2) * 40; // 40 is the height of table row
                } else if (index === -1) {
                    this.refs.elem.scrollTop = 76 * 40; // 76 is number items for attachment
                }
                this.setState({ defaultIndexTriggered: true });
            }
        }
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.loadData();
        }

    }

    componentDidUpdate(prevProps) {
        this.scrollTo();

        if (prevProps.companyId !== this.props.companyId) {
            this.loadData();
        }
    }


    // prepareAttachmentData(data) {
    //     return {
    //         ...data,
    //         columns: _.chain(data.columns)
    //             .cloneDeep()
    //             .set('[2].tooltip', 'The universe of north american public companies is broken down into 12 Sector groups and 4 Broad size groups. Sectors are broken down into 42 Industries. Broad size groups are broken down into 10 narrow size groups. The Narrow Industry-Size Group is comprised of companies from the same Industry, in the narrow size group.')
    //             .set('[3].tooltip', 'The universe of north american public companies is broken down into 12 Sector groups and 4 Broad size groups. Sectors are broken down into 42 Industries. Broad size groups are broken down into 10 narrow size groups. The Broad Industry-Size Group is comprised of companies from the same Industry, in the broad size group.')
    //             .set('[4].tooltip', 'The universe of north american public companies is broken down into 12 Sector groups and 4 Broad size groups. Sectors are broken down into 42 Industries. Broad size groups are broken down into 10 narrow size groups. The Narrow Sector-Size Group is comprised of companies from the same Sector, in the narrow size group.')
    //             .set('[5].tooltip', 'The universe of north american public companies is broken down into 12 Sector groups and 4 Broad size groups. Sectors are broken down into 42 Industries. Broad size groups are broken down into 10 narrow size groups. The Broad Sector-Size Group is comprised of companies from the same Sector, in the broad size group.')
    //             .value()
    //     };
    // }

    getClassNameByRisk(risk) {
       return risk > .045 ? "high" : (risk >= .0175 ? "medium" : (risk >= .01 ? "low" : "xlow"));
    }

    prepareSettlementData(data) {
        return {
            ...data,
            data: _.chain(data.data)
                .cloneDeep()
                .map(x => {
                    x.companyClassName = this.getClassNameByRisk(x.company);
                    x.company = (x.company * 100).toFixed(2) + '%';
                    // IPO model
                    let length = 5;

                    for (let i = 1; i <= length; i++) {
                      if (!x[`data${i}`]) {
                        x[`data${i}`] = '-';
                      } else {
                        x[`data${i}ClassName`] = this.getClassNameByRisk(x[`data${i}`]);
                        x[`data${i}`] = (x[`data${i}`] * 100).toFixed(2) + '%';
                      }
                    }

                    return x;
                })
                .value()
        };
    }

    prepareData(data) {
        if (this.props.isPDF === true) {
            const length = data.data.length;
            let index = data.data.findIndex((x) => x.company <= .01);
            const range = 20;
            if (index === -1) {
                return this.prepareSettlementData({ ...data, data: data.data.slice(length - range) });
            } else {
                const start = index - 15 < 0 ? 0 : index - 15;
                const end = index + 15 > length ? length : index + 15;
                return this.prepareSettlementData({ ...data, data: data.data.slice(start, end) });
            }
        } else {
            return this.prepareSettlementData(data);
        }
    }

    render() {
        const { error, errorStatus, loaded, data, isPDF } = this.props;
        const notFoundMsg = 'Insufficient Information in Upstream Data to Provide Analytics';
        let content;

        if (error) {
            content = <ErrorAlert message={errorStatus === 404 ? notFoundMsg : error} />
        } else {
            content = <Spinner loaded={loaded === true}>
                {!data ? <DataNotFound /> :
                    <div className={data.model === 'ipo_yr2' ? 'ipo' : ''}>
                        <div className='row'>
                            <div className='col-xs-12'>
                                {loaded === true && data && <DynamicTable className="table major"
                                    data={{ table: data.attachment_points_table }} />}

                            </div>
                        </div>
                        <div className='row'>
                            <div id="sca-hitting-layer" className='col-xs-12'>
                                <h3>Probablity of a SCA hitting layer</h3>
                                <div className='legend'>
                                    <span className='item'><span className='block xlow' />Very Low Risk</span>
                                    <span className='item'><span className='block low' />Low Risk</span>
                                    <span className='item'><span className='block medium' />Medium Risk</span>
                                    <span className='item'><span className='block high' />High Risk</span>
                                </div>

                                {loaded === true && data && <div>
                                    {isPDF !== true && <table className="table fixed-header-table dynamic-table">
                                        <thead><tr>
                                            {
                                                _.map(data.settlement_probabilities_table.columns, (item, index) => {
                                                    return <th className="div-table-header" key={index} >
                                                        {item.title}
                                                        {item.tooltip && <Info text={item.tooltip}><InfoImage /></Info>}
                                                    </th>
                                                })
                                            }
                                        </tr>
                                        </thead>
                                    </table>}

                                    <div className={isPDF === true ? "hitting-layer-table hitting-layer-table-pdf" : "hitting-layer-table"} ref="elem">
                                        <DynamicTable className="table last"
                                            hideHeader={isPDF !== true && true}
                                            data={{ table: this.prepareData(data.settlement_probabilities_table) }} />
                                    </div>
                                </div>}

                            </div>
                        </div>
                    </div>
                }

            </Spinner>
        }
        return (
            <div className='panel panel-default'>
                <div className='eps with-spinner'>
                    <div className='panel-body attachments'>
                        <h2>Attachment Analysis &amp; Benchmarking</h2>
                        {content}

                    </div>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.snp_company_id'),
        data: _.get(state, 'attachment.data'),
        error: _.get(state, 'error.ATTACHMENT_GET'),
        errorStatus: _.get(state, 'error.ATTACHMENT_GET_STATUS'),
        loaded: _.get(state, 'attachment.loaded')
    };
}

export default connect(mapStateToProps, {
    fetchAttachmentAction
})(CompanyAttachment);
