import apigClientWrapper from '../../helpers/apigClientWrapper';
import { put, takeEvery, call, select } from 'redux-saga/effects';
import types from './types';

import { apiFailMsg } from '../../helpers/utility';

const getAttachment = (state) => state.attachment;

function* fetchAttachments(action) {

    let attachment = yield select(getAttachment);

    if (!attachment || !attachment.data || attachment.companyId !== action.companyId) {

        try {
            const response = yield call(apigClientWrapper.getAttachments, action.companyId);
            yield put({ type: types.GET_SUCCESS, payload: response.data, companyId: action.companyId });
        } catch (e) {

            yield put({ type: types.GET_FAIL, ...apiFailMsg(e) });
        }

    } else {
        yield put({ type: types.GET_SUCCESS, payload: attachment.data });
    }
}

export default [
    takeEvery(types.GET, fetchAttachments)
]