import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCompanyByIdAction } from '../../../ducks/priv_company/actions';
// import styled from 'styled-components';
import { openSubmissionModalAction } from '../../../ducks/priv_submission/actions';
import { closeWorksheetFormAction } from '../../../ducks/priv_worksheet/actions';
import { createWorksheetAction, createWorksheetDoneAction, goViewOnlyAction, updateWorksheetAction, worksheetClearSubmissionAction } from '../../../ducks/worksheet/actions';
import { diffDaysFromToday } from '../../../helpers/utility';
import Button from '../../common/button';
import DatePicker from '../../common/datePicker';
import Input from '../../common/input';
import Link from '../../common/link';
import Toggle from '../../common/toggle';

export class PrivateWorksheetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submission_id: '',
            policy_id: '',
            agency_id: '',
            effective_date: null,
            type: '',
            renewal: '',
            location: '',
            submitted: false,
            companyType: 'private'
        };
        this.props.getCompanyByIdAction(this.props.companyId);
    }

    renderButtons() {
        if (this.props.worksheetId) {
            return <span>
                <Button type="submit">Update Worksheet</Button>
            </span>
        } else {
            return <span>
                {!this.props.viewOnlyBtnHidden && <Button onClick={() => {
                    this.props.closeWorksheetFormAction();
                    this.props.openSubmissionModalAction()
                }}>Enter Submission Data</Button>}
                {/* <Button type="submit"
                    onClick={() => {
                        this.setState({ submitted: true })
                    }}>Create Worksheet
                </Button> */}
            </span>
        }
    }

    goToCompany(worksheetId) {
        let path = `/private/${this.props.companyId}/overview`;
        if (worksheetId) {
            path += `?worksheetId=${worksheetId}`
            this.props.createWorksheetDoneAction(this.props.companyId, this.props.companyName, worksheetId);
        } else {
            this.props.goViewOnlyAction(this.props.companyId, this.props.companyName);
        }

        this.props.history.push(path);
        this.props.closeWorksheetFormAction();
        window.scrollTo(0, 0);
    }

    handleSubmit(event) {
        const state = Object.assign({}, this.state);

        delete state.warningEffectiveDate;
        event.preventDefault();
        if (this.props.worksheetId) {
            this.props.closeWorksheetFormAction();
            this.props.updateWorksheetAction(
                this.props.companyId,
                this.props.worksheetId,
                _.assign({}, this.props.worksheet, { submission: state })
            );
        } else {

            this.props.createWorksheetAction(this.props.companyId, this.props.company.companyName, this.props.company.ticker, state).then((result) => {
                const httpStatus = _.get(result, 'payload.status') || _.get(result, 'payload.response.status');
                if (httpStatus === 200 || httpStatus === 201) {
                    this.goToCompany(_.get(result, 'payload.data.worksheet_id'));
                    this.props.closeWorksheetFormAction();
                } else {
                    let errMsg = _.get(result, 'payload.response.data.message') || 'Bad Request';
                    this.setState({ error: errMsg })
                }

            });
        }
        this.setState({ submitted: false })

    }

    render() {
        const { company } = this.props;

        let isRequired = true; // require only submission ID or policy ID
        if (this.state.submission_id || this.state.policy_id) {
            isRequired = false;
        } else {
            isRequired = true;
        }


        return (
            <Modal className="company-create-worksheet modal-small"
                overlayClassName="modal-overlay"
                isOpen={this.props.formModalOpen}
                onRequestClose={() => this.props.closeWorksheetFormAction()}
            >
                <div className="head-wrapper">
                    <div className="close" onClick={() => this.props.closeWorksheetFormAction()}>
                        <img src="/images/x.svg" />
                    </div>
                    {company && <div>
                        <div className="title">{company.companyName}</div>
                        <div className="info clearfix">
                            <div>
                                <div className="labels">Headquarters</div>
                                <div className="values">{company.streetAddress}</div>
                            </div>
                            <div>
                                <div className="labels">Website</div>
                                <div className="values">

                                    <a className="company-website" rel="noopener noreferrer" target="_blank" href={"http://" + company.website}>{company.website}</a>
                                </div>
                            </div>
                            <div>
                                <div className="labels">Phone</div>
                                <div className="values">{company.phone}</div>
                                <span className="pull-right">
                                    <Link onClick={() => this.goToCompany()}>View Only</Link>
                                </span>
                            </div>

                        </div>


                    </div>}

                </div>
                <form onSubmit={(event) => this.handleSubmit(event)}>
                    <div className="body-wrapper">
                        <div className="text-alert">
                            {this.state.error}
                        </div>
                        <Input label="Submission Number"
                            value={this.state.submission_id}
                            onChange={(event) => {
                                this.setState({ submission_id: event.target.value })
                            }}
                            required={isRequired}
                        />
                        <Input label="Policy Number (Once premium booked)"
                            value={this.state.policy_id}
                            onChange={(event) => {
                                this.setState({ policy_id: event.target.value })
                            }}
                            required={isRequired}
                        />
                        <div className={this.state.warningEffectiveDate ? 'eff-date-warning' : ''}>
                            <DatePicker label="Effective Date"
                                value={this.state.effective_date}
                                onChange={(value) => {
                                    this.setState({ effective_date: value.format("YYYY-MM-DD") })

                                    //Difference in number of days
                                    const diff = diffDaysFromToday(value);
                                    if (diff < -90) {
                                        this.setState({ warningEffectiveDate: `NOTE: Effective date is past by more than 90 days. Your worksheet will be deleted on ${moment().add(7, 'days').format('MM/DD/YYYY')}. Please make sure you archive to IR before then.` });
                                    } else {
                                        this.setState({ warningEffectiveDate: undefined });
                                    }
                                }}
                                singleDay
                                required
                            />
                            <span>{this.state.warningEffectiveDate}</span>
                        </div>

                        <Input label="Broker"
                            value={this.state.agency_id}
                            onChange={(event) => {
                                this.setState({ agency_id: event.target.value })
                            }}
                            required
                        />
                        <Input label="Broker Location"
                            value={this.state.location}
                            onChange={(event) => {
                                this.setState({ location: event.target.value })
                            }}
                            required
                        />
                        <Toggle label="Type"
                            options={[
                                { value: 'primary', label: 'Primary' },
                                { value: 'xs', label: 'XS' }
                            ]}
                            value={this.state.type}
                            onChange={(value) => {
                                this.setState({ type: value })
                            }}
                            required
                        />
                        <div>
                            {(this.state.submitted === true && (!this.state.type || this.state.type === '')) && <div className="text-danger">Required</div>}
                        </div>

                        <Toggle label="Renewal"
                            options={[
                                { value: true, label: 'Yes' },
                                { value: false, label: 'No' }
                            ]}
                            value={this.state.renewal}
                            onChange={(value) => {
                                this.setState({ renewal: value })
                            }}
                            required
                        />
                        <div>
                            {(this.state.submitted === true && (this.state.renewal === undefined || this.state.renewal === '')) && <div className="text-danger">Required</div>}
                        </div>
                    </div>
                    <div className="footer-wrapper">
                        <Link onClick={() => this.props.closeWorksheetFormAction()}>Cancel</Link>
                        {this.renderButtons()}
                    </div>

                </form>
            </Modal>
        )
    }

}
function mapStateToProps(state) {
    return {
        company: _.get(state, 'privateCompany.selected'),
        companyId: _.get(state, 'privateCompany.selected.companyId'),
        formPage: _.get(state, 'privateCompanyForm.companyFormPage'),
        formModalOpen: _.get(state, 'privateWorksheet.worksheetFormModalOpen'),
    };
}


export default withRouter(connect(mapStateToProps, {
    openSubmissionModalAction,
    closeWorksheetFormAction,
    getCompanyByIdAction,
    createWorksheetAction,
    updateWorksheetAction,
    goViewOnlyAction,
    createWorksheetDoneAction,
    worksheetClearSubmissionAction
})(PrivateWorksheetForm));
