import { call, put, takeLatest } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import types from './types';


function* getOwnership(action) {
    try {
        const response = yield call(apigClientWrapper.getPrivateCompanyOwnerships, action.companyId);
        yield put({ type: types.GET_PRIVATE_OWNERSHIP_SUCCESS, payload: response.data});
    } catch (e) {
        yield put({ type: types.GET_PRIVATE_OWNERSHIP_FAILED, ...apiFailMsg(e) });
    }
}

export default [
    takeLatest(types.GET_PRIVATE_OWNERSHIP, getOwnership)
]
