import types from './types';

export function getProgramAction(companyId) {
    return {
        type: types.GET,
        companyId: companyId
    };
}
export function getExpiredProgramAction(companyId) {
    return {
        type: types.GET_EXPIRED,
        companyId: companyId
    };
}

export function updateProgramAction(companyId, program) {
    return {
        type: types.UPDATE,
        companyId: companyId,
        program: program
    };
}

export function clearDataProgramAction() {
    return {
        type: types.CLEAR_DATA,
    };
}

export function createProgramAction(companyId, program, worksheet_id, effective_date) {

    return {
        type: types.CREATE,
        companyId: companyId,
        program: program,
        worksheet_id: worksheet_id,
        effective_date: effective_date,
    };
}