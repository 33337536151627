import types from "./types";

const defaultState = {
  dno: null,
  snp: null,
};

export default function(state = defaultState, action = null) {
  switch (action.type) {
    case types.GET_PRIVATE_COMPANY_DNO_SUCCESS:
      return {
        ...state,
        dno: action.payload,
        dnoError: null,
        loadedDnO: true,
      };
    case types.GET_PRIVATE_COMPANY_SNP_SUCCESS:
      return {
        ...state,
        snp: action.payload,
        loadedSnP: true,
        snpError: null,
      };
    case types.GET_PRIVATE_COMPANY_DNO_FAIL:
      return {
        ...state,
        dnoError: action.payload,
        loadedDnO: true,
      };
    case types.GET_PRIVATE_COMPANY_SNP_FAIL:
      return {
        ...state,
        snpError: action.payload,
        loadedSnP: true,
      };
    default:
      return state;
  }
}
