import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import Spinner from '../../common/spinner'
import { calcAttachment, calcRPM, calcROL, calcModelTechnicalPrice, calcMinmumTechnicalPrice } from '../../../helpers/riskCalculator';
import DynamicTable from '../../common/dynamicTable';
import {
    getExpiredProgramAction,
    updateProgramAction,
    clearDataProgramAction
} from '../../../ducks/program/actions';
import DataNotFound from "../../common/dataNotFound";
import excel from "../../../helpers/excel";
import moment from 'moment';
import ErrorAlert from '../../common/errorAlert';

const TextMoney = props => props.value ? <NumberFormat
    value={props.value}
    thousandSeparator={true}
    displayType={'text'}
    prefix={'$'}
/> : '-';

export class CompanyExpiringProgram extends Component {
    constructor(props) {
        super(props);
        this.state = { data: {} };
        this.getLayerIndex = this.getLayerIndex.bind(this);
        this.overrall = 'Overall Tower Size (If Known)';
        this.totalLayers = 'Total Layers (If Known)';
        this.effective_date = 'Effective Date';

        if (['appi.argo-digital.com'].indexOf(window.location.host) !== -1) {
            this.hideExcel = true;
        }
    }

    componentWillUnmount() {
        this.props.clearDataProgramAction();
    }

    loadData() {
        if (this.props.companyId && this.props.worksheetInitialized && !this.apiCalled) {
            this.apiCalled = true;
            this.props.getExpiredProgramAction(this.props.companyId);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.data) {
    //         try {
    //             this.setState({data: this.prepareData(nextProps.data)});

    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }
    // }

    excelDownload(data) {
        if (data) {
            const tableData = [];
            if (data.table) {
                const colums = []
                for (let c of data.table.columns) {
                    colums.push(c.title);
                }
                tableData.push(colums);
            }
            const arr = excel.convertFromDynamicTable(this.prepareData(data), 'sheet.xlsx');
            const top = [this.overrall, this.totalLayers, this.effective_date];

            let res = [];

            res.push(top);
            res.push([data.tower_size, data.num_layers, data.effective_date]);
            res.push([]); // extra line
            res = res.concat(arr);

            const dt = new Date();

            const date = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate();

            const fileName = date + ' Expiring Program Info ' + this.props.company.name.replace(/\./g, '') + '.xlsx';

            excel.saveExcel(res, fileName);
        }
    }

    getLayerIndex(index) {
        if (index === 0) {
            return 'Primary';
        } else if (index === 1) {
            return `1st XS`;
        }
        else if (index === 2) {
            return `2nd XS`;
        } else if (index === 3) {
            return `3rd XS`;
        }

        return `${index}th XS`;
    }

    prepareData(data) {
        if (this.props.allDataLoaded === true && data.table.data && data.table.data.length > 0) {
            for (let i = 0; i < data.table.data.length; i++) {
                if (i === 0) {
                    data.table.data[i].attachment = data.retention;
                } else {
                    // const attachmentValue = i === 1 ? 0 : data.table.data[i - 1].attachment;
                    const attachmentValue = data.table.data[i - 1].attachment;
                    data.table.data[i].attachment = calcAttachment(
                        data.table.data[i - 1].limit,
                        attachmentValue
                    );
                }
                data.table.data[i].rpm = calcRPM(data.table.data[i].limit, data.table.data[i].premium);
                if (i !== 0) {
                    data.table.data[i].rol = calcROL(data.table.data[i].rpm, data.table.data[i - 1].rpm);
                }

                if (typeof data.table.data[i].limit === 'string') {
                    data.table.data[i].limit = parseInt(data.table.data[i].limit);
                }

                if (typeof data.table.data[i].premium === 'string') {
                    data.table.data[i].premium = parseInt(data.table.data[i].premium);
                }


                try {
                    let index = parseInt(data.table.data[i].attachment / 1000000);
                    if (!isNaN(index)) {
                        let exactMatch = false;
                        if (index > 20) {
                            // 25 -> 21
                            //
                            index = this.props.attachmentData.settlement_probabilities_table.data.findIndex(x => {
                                if (x.item === 'Primary') {
                                    return false;
                                }

                                const item = parseInt(x.item.replace(/\D/g, ''));
                                if (item === index) {
                                    exactMatch = true;
                                    return true;
                                }
                                return (item > index)
                            });

                            if (index > this.props.attachmentData.settlement_probabilities_table.data.length) {
                                index = this.props.attachmentData.settlement_probabilities_table.data.length - 1;
                            } else if (exactMatch !== true) {
                                index = index - 1;
                            }

                        }

                        const attachmentProb = this.props.attachmentData.settlement_probabilities_table.data[index].company;
                        data.table.data[i].attachmentProb = attachmentProb;

                        let multi = 1;
                        if (this.props.attachmentData.model === 'ipo_yr2') {
                            multi = 4;
                        }

                        const modelTechnicalPrice = calcModelTechnicalPrice(data.table.data[i].limit, attachmentProb, multi);
                        const minumumTechnicalPrice = calcMinmumTechnicalPrice(data.table.data[i].limit);
                        data.table.data[i].technicalPrice = Math.max(modelTechnicalPrice, minumumTechnicalPrice);

                        const attachmentValue = this.props.attachmentData.settlement_probabilities_table.data[index].company
                        data.table.data[i].attachmentClassName = (attachmentValue > .03) ? "high" : ((attachmentValue >= .01) ? "medium" : "low");
                    } else {
                        data.table.data[i].attachmentProb = '';
                        data.table.data[i].attachmentClassName = '';
                    }

                } catch (e) {
                    console.log(e);
                }

            }
        }
        return {
            ...data,
            table: {
                ...data.table,
                data: _.map(data.table.data, (item, index) => ({
                    ...item,
                    layer: this.getLayerIndex(index),
                    limit: <TextMoney value={item.limit} />,
                    attachment: <TextMoney value={item.attachment} />,
                    premium: <TextMoney value={item.premium} />,
                    technicalPrice: <NumberFormat
                        value={item.technicalPrice || ''}
                        thousandSeparator={true}
                        prefix={'$'}
                        displayType={'text'}
                    />,
                    rpm: <NumberFormat
                        value={item.rpm || ''}
                        thousandSeparator={true}
                        displayType={'text'}
                    />,
                    rol: <NumberFormat
                        value={item.rol || ''}
                        thousandSeparator={true}
                        suffix={'%'}
                        displayType={'text'}
                    />,
                }))
            }
        }
    }

    render() {
        const { data, error, companyId, worksheetInitialized } = this.props;

        if (error) {
            return (
                <div className='panel panel-default'>
                    <div className='panel-body expiring with-spinner'>
                        <ErrorAlert message={error} />
                    </div>
                </div>
            );
        } else if (data && data.length > 0) {
            return _.map(data, (item, key) => {
                const expireDate = moment(item.effective_date).add('years', 1).subtract(1, 'days');
                const diff = moment().diff(expireDate);

                return <div key={key} className='panel panel-default'>
                    <div className='panel-body expiring with-spinner'>
                        <h2>Program {diff >= 0 ? 'Expired' : 'Expiring'} on {expireDate.format('MM/DD/YYYY')} </h2>

                        <div className='row'>
                            <div className='col-xs-12'>
                                <div>
                                    {item && item.table.data.length > 0}
                                    <div className='row'>
                                        <div className='col-xs-3'>
                                            <div>Overall Tower Size</div>
                                            <NumberFormat value={item.tower_size} displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <div>Total Layers</div>
                                            <NumberFormat value={item.num_layers} displayType={'text'}
                                                thousandSeparator={true} />
                                        </div>
                                        <div className='col-xs-3'>
                                            <div>Effective Date</div>
                                            <span>{moment(item.effective_date).format('MM/DD/YYYY')}</span>
                                        </div>
                                    </div>
                                    <DynamicTable className="table last program-table" data={this.prepareData(item)} />
                                    <p></p>

                                    <div className="common-element button excel-download-btn">
                                        <button type="button" onClick={() => {
                                            this.excelDownload(item);
                                        }}>Download as Excel</button>
                                        <hr></hr>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            });
        } else {
            return (
                <div className='panel panel-default'>
                    <div className='panel-body expiring with-spinner'>
                        <h2>Expiring Program Info</h2>
                        {companyId && worksheetInitialized === false ? (
                            <ErrorAlert message="Please load a worksheet to see Expiring Program Info" />
                        ) : (
                            <Spinner loaded={data}>
                                <div className='row'>
                                    <div className='col-xs-12'>
                                        {(data && data.length === 0) && <DataNotFound />}
                                    </div>
                                </div>
                            </Spinner>
                        )}
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        worksheetInitialized: _.get(state, 'worksheet.initialized'),
        company: _.get(state, 'company.selected'),
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'program.expiredData'),
        error: _.get(state, 'error.PROGRAM_GET_EXPIRED'),
        attachmentLoaded: _.get(state, 'attachment.loaded'),
        attachmentData: _.get(state, 'attachment.data'),
        allDataLoaded: _.get(state, 'attachment.loaded') === true && _.get(state, 'program.loaded') === true,
    };
}

export default connect(mapStateToProps, {
    getExpiredProgramAction,
    clearDataProgramAction,
    updateProgramAction
})(CompanyExpiringProgram);
