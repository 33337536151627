export default  {
    SEARCH: 'COMPANY_SEARCH',
    SEARCH_SUCCESS: 'COMPANY_SEARCH_SUCCESS',
    SEARCH_FAIL: 'COMPANY_SEARCH_FAIL',
    SEARCH_INIT: 'COMPANY_SEARCH_INIT',
    CLEAR: 'COMPANY_CLEAR',
    SELECT: 'COMPANY_SELECT',
    SELECT_SUCCESS: 'COMPANY_SELECT_SUCCESS',
    SELECT_FAIL: 'COMPANY_SELECT_FAIL',
    SELECT_COMPANY_ID: 'COMPANY_SELECT_ID',
    DESELECT_ONLY_COMPANY: 'COMPANY_DESELECT_ONLY_COMPANY',
    DESELECT: 'COMPANY_DESELECT',
    GET_DETAIL: 'COMPANY_GET_DETAIL',
    GET_DETAIL_SUCCESS: 'COMPANY_GET_DETAIL_SUCCESS',
    GET_DETAIL_FAIL: 'COMPANY_GET_DETAIL_FAIL',
    COMPARE_INDEX: 'COMPANY_COMPARE_INDEX',
}