import { takeEvery } from 'redux-saga/effects';
import { userTypes } from '../user';
import storageWrapper from '../../helpers/storageWrapper';

function* init() {
  if (typeof window.FS !== 'undefined') {
    try {
      const userProfile = storageWrapper.getState('userProfile');
      const { email, name } = userProfile;
      window.FS.identify(email, {
        displayName: name,
        email: email,
        reviewsWritten_int: 14,
      });
      console.log('FS initialized');
    } catch (e) {
      console.log('FS errors', e);
    }
  }
  yield 'ok';
}

export default [
  takeEvery(userTypes.LOGIN, init),
]