import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import qs from 'qs';
import Button from '../../common/button';
import Link from '../../common/link';
import Card from '../../common/card';
import Tag from '../../common/tag';
import ArchiveImage from '../../images/archive';
import DeleteImage from '../../images/delete';
import Spinner from '../../common/spinner'
import DatePicker from '../../common/datePicker';
import Select from '../../common/select'
import ErrorAlert from '../../common/errorAlert';
import { companyTypes } from '../../../ducks/company';
import { worksheetTypes } from '../../../ducks/worksheet';
import { closeSubmissionModalAction } from '../../../ducks/priv_submission/actions';
import {
    getWorksheetsAction,
    deleteWorksheetAction,
    showWorksheetModalAction,
    worksheetClearSubmissionAction,
    companySelectedInSearchAction,
    worksheetSelectedAction,
    worksheetGetPDFSignedUrlAction,
    worksheetClearPDFSignedUrlAction,
} from '../../../ducks/worksheet/actions'
import { selectCompanyAction, deselectCompanyAction } from '../../../ducks/company/actions'
import { hideNoteAction } from '../../../ducks/note/actions'
import CompanyCreateWorksheet from '../../public/createWorksheet/companyCreateWorksheet';
import PrivateWorksheetForm from '../../private/worksheet/worksheetForm';
import PrivateSubmissionForm from '../../private/submissionForm/submissionForm';
import PrivateCompanyForm from '../../private/companyForm/companyForm';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

// private
import { setCompanyIdAction } from '../../../ducks/priv_company/actions';
import { openWorksheetFormAction } from '../../../ducks/priv_worksheet/actions';
import { openFormModalAction } from '../../../ducks/priv_company_form/actions';

export class CompanySearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: '',
            selectedTab: '',
            statusFilter: '',
            brokerFilter: '',
            dateRangeValue: null,
            selectedPage: 0,
        };

        this.worksheetLimit = 10;

        this.unlisten = this.props.history.listen((location) => {

            const query2 = _.get(qs.parse(location.search), '?query');
            this.setState({
                ...this.state,
                query: query2,
                selectedPage: 0
            });

        });
    }

    componentDidMount() {
        this.props.getWorksheetsAction();
        this.props.deselectCompanyAction();
        this.props.hideNoteAction();
        const query = _.get(qs.parse(this.props.location.search), '?query');
        this.setState({
            query: query
        })
    }

    componentWillUnmount() {
        this.unlisten();
        this.props.worksheetClearSubmissionAction();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.pdfPreSignedUrl !== undefined) {
            this.setState({ ...this.state, pdfPreSignedUrl: newProps.pdfPreSignedUrl })
            setTimeout(() => {
                this.anchorForPdf.click();
                setTimeout(() => {
                    this.props.worksheetClearPDFSignedUrlAction();
                }, 1000);
            }, 100);
        }
    }

    openWorksheetModal(companyId, companyName) {
        // this.props.companySelectedInSearchAction(companyId);
        this.props.worksheetClearSubmissionAction();
        this.props.showWorksheetModalAction();
        this.props.selectCompanyAction(companyId, companyName);
    }

    renderCompanyResultsTable() {

        const truncate = t => (t.length > 200) ? t.substring(0, 197) + "..." : t;
        return <div>
            <div className="search-result-head">
                <div className="company-name">Company&apos;s Name</div>
                <div className="company-ticker">Ticker</div>
                <div className="company-market">Market Cap</div>
                <div className="company-sic">SIC</div>
                <div className="company-type">Type</div>
            </div>
            {_.map(this.props.companies, item => {
                let statusClass = '';
                if (item.type === 'public') {
                    statusClass = 'strong-blue';
                } else if (item.type === 'private') {
                    statusClass = 'lime-green';
                }
                return <Card key={item.company_id || `priv_${item.companyId}`}
                    className='search-result'
                    onClick={() => {
                        if (item.companyId) {
                            this.props.setCompanyIdAction(item.companyId);
                            this.props.openWorksheetFormAction(item);
                        } else {
                            this.openWorksheetModal(item.company_id, item.name)
                        }
                    }}
                >
                    <div className="company-name">{item.name || item.companyName}</div>
                    <div className="company-ticker">{item.ticker}</div>
                    <div className="company-market">{item.market_cap && `$${item.market_cap}`}</div>
                    <div className="company-sic">{truncate(item.sic)}</div>
                    <div className="company-type"><Tag text={item.type} className={'capitalize width100p ' + statusClass} /></div>
                </Card>
            }

            )}
        </div>
    }

    renderCompanyResults() {

        if (this.props.companiesSearchInProgress === false && this.props.companySearchError) {
            return <div className="with-spinner">
                <h3>Company Results</h3><ErrorAlert message={this.props.companySearchError} />
            </div>
        }

        if (!this.props.companies && !this.props.companiesSearchInProgress) {
            return null;
        }
        return <div className="with-spinner">
            <h3>Company Results</h3>

            <Spinner loaded={!this.props.companiesSearchInProgress}>
                {!_.size(this.props.companies) && <h2 className="no-data">No data found.</h2>}
                {!!_.size(this.props.companies) && this.renderCompanyResultsTable()}
            </Spinner>
        </div>;
    }

    renderWorksheetsHeader() {
        return <div className="search-result-head">
            <div className="worksheet-sub">Sub. ID</div>
            <div className="worksheet-company-name">Company&apos;s Name</div>
            <div className="worksheet-broker">Broker</div>
            <div className="worksheet-location">Location</div>
            <div className="worksheet-type">Type</div>
            <div className="worksheet-policyNo">Policy Number</div>
            <div className="worksheet-date">Effective Date</div>
            <div className="worksheet-status">Status</div>
            <div className="worksheet-remove"></div>
        </div>
    }

    prepareData() {
        let result = this.props.worksheets.map(item => {
            const submission = item.submission || {};
            const state = item.state;
            let stateText = 'WORK IN PROGRESS';
            let statusClass = 'lime-green';
            const start = moment().startOf('day');
            const effectiveDate = moment(submission.effective_date, "YYYY-MM-DD");

            if (this.state.dateRangeValue) {
                if (this.state.dateRangeValue.from) {
                    if (this.state.dateRangeValue.from > effectiveDate) {
                        return null;
                    }
                }

                if (this.state.dateRangeValue.to < effectiveDate) {
                    return null;
                }
            }

            //Difference in number of days
            const diff = Math.floor(moment.duration(start.diff(effectiveDate)).asDays());
            const deadline = 90;
            if (deadline - diff < 10) {
                stateText = `EXPIRING ON ${effectiveDate.add(90, 'days').format("YYYY-MM-DD")}`;
                statusClass = 'brick';
            } else if (state === 'archived') {
                stateText = `${deadline} DAYS REVIEW ARCHIVED`;
                statusClass = '';
                if (item.review !== true) {
                    stateText = 'WORKSHEET ARCHIVED';
                    statusClass = 'strong-blue';
                }
            }
            item.stateText = stateText;
            item.statusClass = statusClass;
            if (this.state.statusFilter) {
                if (this.state.statusFilter === 'wip' && stateText !== 'WORK IN PROGRESS') {
                    return null;
                } else if (this.state.statusFilter === 'archived_reviewed' && statusClass !== '') {
                    return null;
                } else if (this.state.statusFilter === 'archived' && statusClass !== 'strong-blue') {
                    return null;
                } else if (this.state.statusFilter === 'expiring' && statusClass !== 'brick') {
                    return null;
                }
            }

            if (this.state.brokerFilter) {
                if (this.state.brokerFilter !== item.submission.agency_id) {
                    return null;
                }
            }
            return item;
        }).filter(n => n);

        const isTeamTabSelected = this.isTeamTabSelected();

        result = result.filter(x =>
            (isTeamTabSelected === true && x.isTeamWorksheet === true) ||
            (isTeamTabSelected === false && x.isTeamWorksheet !== true)
        );

        if (this.state.query) {
            result = result.filter(
                (x) =>
                    (x.company_name && x.company_name.toLowerCase().indexOf(this.state.query.toLowerCase()) === 0)
                    ||
                    (x.ticker && x.ticker.toLowerCase().indexOf(this.state.query.toLowerCase()) === 0)
            );
        }


        return result;
    }

    renderWorksheetsResults() {

        if (this.props.worksheetsSearchError) {
            return <ErrorAlert message={this.props.worksheetsSearchError} />
        }

        if (!this.props.worksheets) {
            return null;
        }

        if (this.props.worksheets.length === 0) {
            return null;
        }

        let result = this.prepareData();
        const total = result.length;

        result = result.splice((this.state.selectedPage) * this.worksheetLimit, this.worksheetLimit);

        return <div>
            <div className="search-result-head">
                <div className="worksheet-sub">Sub. ID</div>
                <div className="worksheet-company-name">Company&apos;s Name</div>
                <div className="worksheet-broker">Broker</div>
                <div className="worksheet-location">Location</div>
                <div className="worksheet-type">Type</div>
                <div className="worksheet-policyNo">Policy Number</div>
                <div className="worksheet-date">Effective Date</div>
                <div className="worksheet-status">Status</div>
                <div className="worksheet-remove"></div>
            </div>
            {
                _.map(result, item => {
                    const submission = item.submission || {};
                    return <Card key={item.worksheet_id}
                        className='search-result'
                        onClick={() => {
                            this.props.worksheetSelectedAction(item.cik, item.worksheet_id, item.company_name);
                            this.props.history.push(`/company/${item.cik}/overview?worksheetId=${item.worksheet_id}`);
                            window.scrollTo(0, 0);
                        }}
                    >
                        <div className="worksheet-sub">{submission.submission_id}</div>
                        <div className="worksheet-company-name">{item.company_name}</div>
                        <div className="worksheet-broker">{submission.agency_id}</div>
                        <div className="worksheet-location">{submission.location}</div>
                        <div className="worksheet-type">{submission.type}</div>
                        <div className="worksheet-policyNo">{submission.policy_id}</div>
                        <div className="worksheet-date">{submission.effective_date}</div>
                        <div className="worksheet-status">
                            <span className="worksheet-state">
                                <Tag text={item.stateText} className={item.statusClass + ' width100p'} />
                            </span>
                            <span className="worksheet-actions">
                                <a onClick={(event) => {
                                    event.stopPropagation();
                                    this.props.history.push(`/company/${item.cik}/pdf?worksheetId=${item.worksheet_id}`);
                                    window.scrollTo(0, 0);
                                }} className="worksheet-action-btn"><ArchiveImage /><span>PDF</span></a>
                                <a onClick={(event) => {
                                    event.stopPropagation();
                                    this.setState({
                                        isConfirmModalOpen: true,
                                        deleteWorksheetId: item.worksheet_id
                                    });
                                }} className="worksheet-action-btn"><DeleteImage /><span>Delete</span></a>
                            </span>
                        </div>
                    </Card>
                })

            }
            <ReactPaginate previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={result ? Math.ceil(total / this.worksheetLimit) : 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={this.state.selectedPage}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} />
        </div>;


    }

    renderConfirmModal() {
        return <Modal className="confirm-remove-modal modal-tiny"
            overlayClassName="modal-overlay"
            isOpen={this.state.isConfirmModalOpen}
            onRequestClose={() => this.setState({ isConfirmModalOpen: false })}
        >
            <div className="header">
                Delete worksheet
            </div>
            <div className="body">
                Are you sure you want to delete this worksheet?
            </div>
            <div className="footer">
                <Link onClick={() => this.setState({ isConfirmModalOpen: false })}>
                    Cancel
                </Link>
                <Button onClick={() => this.props.deleteWorksheetAction(this.state.deleteWorksheetId).then(() => {
                    setTimeout(() => {
                        // without timeout API returns just deleted worksheet
                        this.props.getWorksheetsAction();
                    }, 1000);
                    this.setState({ isConfirmModalOpen: false })
                })}>
                    Confirm
                </Button>
            </div>
        </Modal>
    }

    isTeamTabSelected() {
        const result = this.state.selectedTab === 'teamworksheets' ? true : false;
        return result;
    }

    handlePageClick = (data) => {
        this.setState({ selectedPage: data.selected });
    };

    render() {
        const defaultTabSelected = this.isTeamTabSelected();
        const statusTypes = [
            { value: 'wip', label: 'WORK IN PROGRESS' },
            { value: 'archived_reviewed', label: '90 DAYS REVIEW ARCHIVED' },
            { value: 'archived', label: 'WORKSHEET ARCHIVED' },
            { value: 'expiring', label: 'EXPIRING' }
        ];
        const showPrivate = process.env.SHOW_PRIVATE !== null;
        return (
            <div className="company-search container">
                <a ref={input => this.anchorForPdf = input} href={this.state.pdfPreSignedUrl} className="hidden" rel="noopener noreferrer" target="_blank">link</a>
                {this.renderCompanyResults()}
                <CompanyCreateWorksheet />

                {showPrivate === true && <div>

                    {this.props.formModalOpen && <PrivateCompanyForm />}
                    {this.props.worksheetFormModalOpen && <PrivateWorksheetForm />}



                    <Modal className="modal-large"
                        overlayClassName="modal-overlay"
                        isOpen={this.props.modalOpen}
                        onRequestClose={() => this.props.closeSubmissionModalAction()}
                    >
                        <PrivateSubmissionForm />
                    </Modal>
                    <br />
                    <Button onClick={() => this.props.openFormModalAction()}>Private submission</Button>

                </div>}
                <ul id="worksheet-tabs">
                    <li className={`${defaultTabSelected === false ? 'focused' : ''}`} >
                        <a href="javascript:void(0)" onClick={() => this.setState({ selectedTab: 'myworrksheets' })}>My Worksheets</a>
                    </li>
                </ul>
                <div id="worksheets-filters">
                    <DatePicker label="Effective Date Range"
                        placeholder="Select range..."
                        value={this.state.dateRangeValue}
                        onSubmit={(value) => {
                            const dateRange = {
                                from: value.from,
                                to: value.to
                            }
                            this.setState({ dateRangeValue: dateRange, selectedPage: 0 });
                        }} />
                    <Select label="Status Filter"
                        options={statusTypes}
                        placeholder="Select status..."
                        onChange={(value) => {
                            const newValue = value === null ? '' : value.value;
                            this.setState({
                                statusFilter: newValue,
                                selectedPage: 0
                            });
                        }}
                    />

                    <Select label="Broker Filter"
                        options={this.props.worksheets ? this.props.worksheets.reduce((a, b) => {
                            if (a.indexOf(b.submission.agency_id) < 0) a.push(b.submission.agency_id);
                            return a;
                        }, []).map((x) => {
                            return { value: x, label: x }
                        }).sort((a, b) => { return a.value - b.value }) : []}
                        placeholder="Select broker..."
                        onChange={(value) => {
                            const newValue = value === null ? '' : value.value;
                            this.setState({
                                brokerFilter: newValue,
                                selectedPage: 0
                            });
                        }}
                    />
                </div>

                {this.renderWorksheetsResults()}

                {this.renderConfirmModal()}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected'),
        pdfPreSignedUrl: _.get(state, 'worksheet.presignedUrl'),
        companies: _.get(state, 'company.list'),
        companiesSearchInProgress: _.get(state, 'company.searchInProgress'),
        worksheets: _.get(state, 'worksheet.list'),
        worksheetsSearchError: _.get(state, `error.${worksheetTypes.SEARCH}`),
        modalOpen: _.get(state, 'privateSubmission.submissionModalOpen'),
        companySearchError: _.get(state, `error.${companyTypes.SEARCH}`),
        worksheetFormModalOpen: _.get(state, 'privateWorksheet.worksheetFormModalOpen'),
        formModalOpen: _.get(state, 'privateCompanyForm.formModalOpen'),
    };
}

export default connect(mapStateToProps, {
    getWorksheetsAction,
    deleteWorksheetAction,
    worksheetGetPDFSignedUrlAction,
    showWorksheetModalAction,
    selectCompanyAction,
    deselectCompanyAction,
    companySelectedInSearchAction,
    worksheetSelectedAction,
    worksheetClearPDFSignedUrlAction,
    worksheetClearSubmissionAction,
    openFormModalAction,
    hideNoteAction,
    setCompanyIdAction,
    openWorksheetFormAction,
    closeSubmissionModalAction
})(CompanySearch);
