import reducer from "./reducers";

import  * as actions from "./actions";
export {
  actions
}
export { default as predictorTypes } from "./types";

export { default as predictorOperations } from "./operations";


export default reducer;