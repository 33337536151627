import React from 'react';
import classNames from 'classnames';
import styles from './_menuTabItem.module.scss';

export const MenuTabItem = (props) => {
    
    let classItems;
    if (props.className) {
        classItems = props.className.split(' ').map (x => {
            return styles[x];
        });
    }

    return (

        <div className={`${styles.menuTabItem} ${classItems && classItems.join(' ')}`}>
            <div
                className={classNames({
                    [styles.active]: props.active
                })}
                onClick={() => {
                    if (props.activate) {
                        props.activate();
                    }
                    if (props.action) {
                        props.action();
                    }
                }}
            >
                {props.children}
            </div>
        </div>
    )
}

export default MenuTabItem;
