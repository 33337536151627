import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfoCard from '../../common/infoCard';
import Spinner from '../../common/spinner';
import DynamicTable from '../../common/dynamicTable';
import { getEmploymentAction } from '../../../ducks/priv_employment/actions';
import _ from 'lodash';
import ErrorAlert from '../../common/errorAlert';
import moment from 'moment';

export class PrivateEmployees extends Component {

    constructor(props) {
        super(props);

    }
    componentDidMount() {
        if (this.props.companyId) {
            this.props.getEmploymentAction(this.props.companyId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getEmploymentAction(this.props.companyId);
        }
    }

    render() {

        let detailsCols = 12 / this.props.details.length;
        if (detailsCols < 4) {
            detailsCols = 4;
        }

        let noteCols = 12 / this.props.notes.length;
        if (noteCols < 4) {
            noteCols = 4;
        }

        let content;

        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />;
        } else {
            content = <Spinner loaded={this.props.loaded}>
                {this.props.loaded && <div className="row">
                    <div className="col-xs-8">
                        <div className="row">
                            <div className={`col-xs-4 info`}>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.prevTurnover || 'N/A'}</div>
                                        <div className='value-description'>Employee Turnover Rate (as of { moment(this.props.prevEmpUpdatedAt).format('MM/YYYY')})</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className={`col-xs-4 info`}>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.turnover || 'N/A'}</div>
                                        <div className='value-description'>Employee Turnover Rate (as of {moment(this.props.empUpdatedAt).format('MM/YYYY')})</div>
                                    </div>
                                </InfoCard>
                            </div>
                            <div className={`col-xs-4 info`}>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{this.props.unionizedEmp || 'N/A'}</div>
                                        <div className='value-description'>Percentage of Employees unionized (as of {moment(this.props.empUpdatedAt).format('MM/YYYY')})</div>
                                    </div>
                                </InfoCard>
                            </div>

                        </div>
                        <p></p>
                        <p></p>
                        <div className="row">
                            <div className={`col-xs-12 info`} key={'private-employees-bargain'}>
                                <InfoCard>
                                    <div>
                                        <div className='value'>{(this.props.barganingDue && this.props.barganingDue === 1 ? 'Yes' : 'No') || 'N/A'}</div>
                                        <div className='value-description'>Collective barganing agreement renewal for unionized eployees in upcoming 12 months?  (as of {moment(this.props.empUpdatedAt).format('MM/YYYY')})</div>
                                    </div>
                                </InfoCard>
                            </div>

                        </div>
                        <p></p>
                        <p></p>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="inner-container">
                                    <DynamicTable className={"table"} data={this.props.employmentType} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-xs-4">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="inner-container">
                                    <DynamicTable className={"table"} data={this.props.compensations} />
                                    {this.props.compensations && this.props.compensations.asOf && <div>as of {this.props.compensations.asOf}</div>}
                                </div>
                                
                            </div>
                        </div>
                        <p></p>
                        <p></p>
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="inner-container">
                                    <DynamicTable className={"table"} data={this.props.jurisdictions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </Spinner>
        }


        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>Employees</h2>
                    {content}

                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'privateCompany.selected.companyId'),
        turnover: _.get(state, 'privateEmployment.data.turnover'),
        prevTurnover: _.get(state, 'privateEmployment.data.prevTurnover'),
        unionizedEmp: _.get(state, 'privateEmployment.data.unionizedEmp'),
        barganingDue: _.get(state, 'privateEmployment.data.barganingDue'),
        employmentType: _.get(state, 'privateEmployment.data.employmentType'),
        compensations: _.get(state, 'privateEmployment.data.compensations'),
        jurisdictions: _.get(state, 'privateEmployment.data.jurisdictions'),
        empUpdatedAt: _.get(state, 'privateEmployment.data.empUpdatedAt'),
        prevEmpUpdatedAt: _.get(state, 'privateEmployment.data.prevEmpUpdatedAt'),
        loaded: _.get(state, 'privateEmployment.loaded'),
        error: _.get(state, 'privateEmployment.error'),
    };
}
export default connect(mapStateToProps, {
    getEmploymentAction
})(PrivateEmployees);
``