import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RiskPredictor from '../../common/riskPredictor';
import StackedBarChart from '../../common/stackedBarChart';
import DynamicTable from '../../common/dynamicTable';
import Spinner from '../../common/spinner'
import { getCompanyKeyStatsAction } from '../../../ducks/keyStats/actions';
import constants from '../../../constants';
import { convertToBigNumber } from "../../../helpers/utility";
import Select from '../../common/select';
import ErrorAlert from '../../common/errorAlert';
import DataNotFound from '../../common/dataNotFound';

export class CompanyKeyStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            peerGroupFilter: {
                peerGroup: {
                    value: constants.peerGroups[constants.peerGroupsDefaultIndex].value,
                    label: constants.peerGroups[constants.peerGroupsDefaultIndex].label
                }
            },
            hoverIndex: null,
            showUnavailable: false
        };

        this.mouseOvered = false;
        this.interval = null;

        this.colorHexList = [
            '#8B0011',
            '#D95848',
            '#EA9B4C',
            '#F4CB5F',
            '#6FB96E',
            '#3A6132'
        ]
    }

    startInterval(index) {
        if (!this.interval) {
            this.setState({ currentCount: 0 });
            this.interval = setInterval(() => {
                const count = this.state.currentCount + 1;
                this.setState({ currentCount: count });
            }, 1500);
            this.setState({ hoverIndex: index });
        }
    }

    clearInterval() {
        clearInterval(this.interval);
        this.interval = null;
        this.setState({ hoverIndex: null });
    }

    loadData(peerGroupValue) {
        if (this.props.companyType === 'public' && this.props.predictive_model === 'sca') {
            const peerGroup = peerGroupValue ? peerGroupValue : this.state.peerGroupFilter.peerGroup.value;
            this.props.getCompanyKeyStatsAction(this.props.companyId, peerGroup);
        } else {
            this.setState({ showUnavailable: true })
        }

    }

    componentDidMount() {
        if (this.props.companyId) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.loadData();
        }
    }


    stackedBarChartOnMouseOver(index, parentIndex) {
        this.mouseOvered = true;
        this.clearInterval();
        this.setState({ hoverIndex: parentIndex, intervalId: null, currentCount: index });
    }

    stackedBarChartOnMouseOut() {
        this.mouseOvered = false;
        this.clearInterval();
    }

    prepareRow(row, index) {
        let animation;

        if (row.peers === undefined) {
            return row;
        }

        const p = row.peers.data;
        let component = null;

        if (row.peers.type === 'boxplot') {
            if (index === this.state.hoverIndex) {
                animation = this.state.currentCount % 2 === 0 ? 0 : 1;
            }
            component = <div
                onMouseOver={() => {
                    this.startInterval(index);
                }}
                onMouseOut={() => {
                    this.clearInterval();
                }}><RiskPredictor
                    animation={animation}
                    data={{
                        label: <span><strong>{`${convertToBigNumber(row.value)}` + (row.peers.rank !== undefined ? ` (${(row.peers.rank * 100).toFixed(0)}%)` : '')}</strong> among <strong>{row.peers.count}</strong> companies</span>,
                        count: row.peers.count,
                        p10: p.p10,
                        p25: p.p25,
                        p50: p.p50,
                        p75: p.p75,
                        p90: p.p90,
                        value: row.value
                        // label: `${this.props.company.name}: `
                    }} /></div>;

        } else if (row.peers.type === 'barchart') {
            if (index === this.state.hoverIndex) {
                animation = this.state.currentCount;
            }

            let text;
            let focusedIndex;
            const data = [
                p.ccc_to_d,
                p.b,
                p.bb,
                p.bbb,
                p.a,
                p.aaa_to_aa,
            ];

            if (animation !== undefined) {
                const list = [
                    { key: 'ccc_to_d', text: `CCC to D` },
                    { key: 'b', text: `B` },
                    { key: 'bb', text: `BB` },
                    { key: 'bbb', text: `BBB` },
                    { key: 'a', text: `A` },
                    { key: 'aaa_to_aa', text: `AA, AAA` },
                ];

                text = <span><strong>{p[list[animation % list.length].key]}</strong> companies from {list[animation % list.length].text}</span>;
                focusedIndex = animation % list.length;
            }

            component = <div className="peer-group-risk-predicator text-left">

                <StackedBarChart
                    parentIndex={index}
                    onMouseOver={this.stackedBarChartOnMouseOver.bind(this)}
                    onMouseOut={this.stackedBarChartOnMouseOut.bind(this)}
                    colorHexList={this.colorHexList} focus={focusedIndex} style={{ padding: '5px 0', minWidth: '300px' }}
                    data={data}
                    topText={<span><strong>{row.peers.rated}</strong> Companies rated in peer group</span>}
                    bottomText={<div style={{ height: '22px', fontSize: '12px' }} className="text-left">
                        {text}
                    </div>}
                ></StackedBarChart>


            </div>;
        } else {
            component = null;
        }

        const obj = {
            ...row,
            peers: component,
        }

        if (row.metric === 'S&P Rating') {
            // For S&P Ratings
            let fontColor = { fontWeight: 'bold' };

            if (['AAA', 'AA+', 'AA', 'AA-'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[5];
            } else if (['A+', 'A', 'A-'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[4];
            } else if (['BBB+', 'BBB', 'BBB-'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[3];
            } else if (['BB+', 'BB', 'BB-'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[2];
            } else if (['B+', 'B', 'B-'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[1];
            } else if (['CCC+', 'CCC', 'CCC-', 'D'].indexOf(row.value) !== -1) {
                fontColor.color = this.colorHexList[0];
            }

            obj.value = <span style={fontColor}>{obj.value}</span>;
            return obj;
        }

        return obj;
    }
    prepareData() {
        if (!this.props.data) {
            return;
        }
        if (!this.props.data[this.state.peerGroupFilter.peerGroup.value]) {
            return;
        }

        const obj = {
            ...this.props.data[this.state.peerGroupFilter.peerGroup.value],
            data: _.map(this.props.data[this.state.peerGroupFilter.peerGroup.value].data, (row, index) => this.prepareRow(row, index))
        }

        return obj;
    }

    trOnMouseEnter(index) {
        if (this.mouseOvered === false) {
            this.startInterval(index);
        }
    }

    trOnMouseLeave() {
        this.clearInterval();
    }

    isDataReady() {
        if (!this.props.data) {
            return false;
        }
        if (!this.props.data[this.state.peerGroupFilter.peerGroup.value]) {
            return false;
        }
        return true;
    }

    render() {
        let content;
        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />
        } 
        else if (this.state.showUnavailable === true) {
            content = <DataNotFound />
        } else {
            content = <div>
                {this.isDataReady() && <div>
                    <Select label="Peer Group Filter"
                        placeholder="Select Peer Group..."
                        value={this.state.peerGroupFilter.peerGroup}
                        clearable={false}
                        options={constants.peerGroups}
                        onChange={(value) => {
                            this.setState({
                                peerGroupFilter: {
                                    ...this.state.peerGroupFilter,
                                    peerGroup: value
                                }
                            });
                            this.loadData(value.value);
                        }}
                    />
                </div>}

                <p>&nbsp;</p>

                <Spinner loaded={this.isDataReady()}>
                    {this.isDataReady() && Object.keys(this.prepareData()).length === 0 ? null : <DynamicTable
                        trOnMouseEnter={this.trOnMouseEnter.bind(this)}
                        trOnMouseLeave={this.trOnMouseLeave.bind(this)}
                        className="table last key-stats" data={{ table: this.prepareData() }} />}
                </Spinner>
            </div>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <div>
                        <h2>Key Stats</h2>
                    </div>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        companyType: _.get(state, 'company.selected.company_type'),
        predictive_model: _.get(state, 'company.selected.predictive_model'),
        data: _.get(state, 'keyStats.data'),
        error: _.get(state, 'error.COMPANY_GET_KEY_STATS')
    };
}

export default connect(mapStateToProps, {
    getCompanyKeyStatsAction
})(CompanyKeyStats);
