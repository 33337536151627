import React from 'react';

const DownImage = () => {
    return (<svg viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
    <title>CardActionBtn/Arrow/Down</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <circle id="path-1" cx="22.5" cy="22.5" r="22.5"></circle>
    </defs>
    <g id="CardActionBtn/Arrow/Down" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
        </mask>
        <use id="Mask" fill="#DDDDDD" xlinkHref="#path-1"></use>
        <polygon id="Triangle" fill="#FFFFFF" mask="url(#mask-2)" transform="translate(23.000000, 24.625000) rotate(-360.000000) translate(-23.000000, -24.625000) " points="23 30.25 14 19 32 19"></polygon>
    </g>
</svg>)
    ;
};
export default DownImage;
