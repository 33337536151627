import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

import Spinner from '../../common/spinner'
import DataNotFound from '../../common/dataNotFound'
import { getCompanyFinancialsDebtAction } from '../../../ducks/financialDebt/actions';
import ErrorAlert from '../../common/errorAlert';

export class CompanyDebtChart extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.prepareData = this.prepareData.bind(this)
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.getCompanyFinancialsDebtAction(this.props.companyId);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyFinancialsDebtAction(this.props.companyId);
        }
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.data) {
    //         console.log('net', nextProps.data);

    //         try {
    //             this.setState({data: this.prepareData(nextProps.data), showNoDataFound: false});
    //         } catch (error) {
    //             console.error(error);
    //         }
    //         if (JSON.stringify(nextProps.data) === JSON.stringify({})){
    //             this.setState({showNoDataFound: true})
    //         }
    //     }
    // }


    prepareData(data) {
        return _.map(data.debt, (item, index) =>
            _.chain(data)
                .mapValues(() => Number(data.debt[index].y))
                .set('date', data.debt[index].x)
                .value()
        );
    }

    render() {
        const chartContainerWidth = this.props.chartWidth ? this.props.chartWidth : "100%";
        let content;
        if (this.props.error) {
            content = <ErrorAlert message={this.props.error} />
        } else {
            content = <Spinner loaded={this.props.data}>
                {!this.props.data || (this.props.data && Object.keys(this.props.data).length === 0) ?
                    <DataNotFound />
                    : <div>
                        <form>
                            <label>
                                <span className='block blue' />
                                <span>Debt</span>
                            </label>
                        </form>
                        <div className="label-y-axis">USD (in Millions)</div>
                        <ResponsiveContainer width={chartContainerWidth} height={400}>
                            <BarChart height={400} data={this.prepareData(this.props.data)}
                                margin={{ top: 5, right: -10, left: -10, bottom: 5 }}>
                                <Tooltip />
                                <XAxis dataKey="date" tickLine={false} axisLine={false} />
                                <YAxis tickLine={false} axisLine={false} />
                                <CartesianGrid vertical={false} />
                                <Bar dataKey="debt" fill="#2a2d7e" barSize={15} name="Debt" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div className="label-x-axis">Year</div>

                    </div>}

            </Spinner>
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body debt with-spinner'>
                    <h2>Debt Maturity Schedule</h2>
                    {content}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        data: _.get(state, 'financialDebt.data'),
        error: _.get(state, 'error.COMPANY_GET_FINANCIAL_DEBT')
    };
}

export default connect(mapStateToProps, {
    getCompanyFinancialsDebtAction
})(CompanyDebtChart);
