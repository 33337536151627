import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: null,
    error: null,
    loaded: false
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_PREDICTOR_SUCCESS:
            return {
                ...state,
                loaded: true,
                data: _.get(action, 'payload')
            };
        default:
            return state;
    }
}
