import { call, put, takeLatest } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import types from './types';


function* getFiduciaries(action) {
    try {
        const response = yield call(apigClientWrapper.getPrivateCompanyFiduciaries, action.companyId); 
        yield put({ type: types.GET_PRIVATE_FIDUCIARY_SUCCESS, payload: response.data});
    } catch (e) {
        
        yield put({ type: types.GET_PRIVATE_FIDUCIARY_FAILED, ...apiFailMsg(e) });
    }
}



export default [
    takeLatest(types.GET_PRIVATE_FIDUCIARY, getFiduciaries)
]
