import _ from 'lodash';
import actions from './types';

const defaultState = {
    loaded: false,
    data: {},
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_FINANCIAL_SHEET:
            return {
                ...state,
                loaded: false,
            };
        case actions.GET_FINANCIAL_SHEET_SUCCESS:
            var obj = {};
            obj[_.get(action, 'requestType')] = _.get(action, 'payload');

            var newFinancialData = { ...state.data, ...obj };

            return {
                ...state,
                loaded: true,
                data: newFinancialData
            };

        default:
            return state;
    }
}
