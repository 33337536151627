import _ from '../../../lodashWrapper';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from '../../common/select'
import DateNotFound from '../../common/dataNotFound'
import Spinner from '../../common/spinner'
import RiskFiling from '../../common/riskFiling';
import { searchDocumentsAction } from '../../../ducks/document/actions';
import RiskPredictor from '../../common/riskPredictor';
import constants from '../../../constants';
import { parseQuery } from '../../../helpers/utility';

export class Company10K10Q extends Component {
    constructor(props) {
        super(props)
        this.state = {
            filter: {
                date: {
                    value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year'
                }
            },
            industryFilter: {
                industry: { value: constants.industrySize[constants.industrySizeDefaultIndex].value, label: constants.industrySize[constants.industrySizeDefaultIndex].label }
            },
            peerGroupFilter: {
                peerGroup: { value: constants.peerGroups[constants.peerGroupsDefaultIndex].value, label: constants.peerGroups[constants.peerGroupsDefaultIndex].label }
            },
        }



        this.filterFlags = this.filterFlags.bind(this)
        this.filterDates = this.filterDates.bind(this)
        this.mergeDocuments = this.mergeDocuments.bind(this)
    }


    componentDidMount() {
        if (this.props.companyId) {
            this.loadData();
        }
        if (this.props.location) {
            if (this.props.location.hash && '#indicators10k10q' === this.props.location.hash) {
                document.querySelector('#indicators10k10q').scrollIntoView();
            }
            const queryString = parseQuery(this.props.location.search);

            if (queryString.risk_name !== undefined) {
                const obj = {
                    value: queryString.risk_name,
                    label: queryString.risk_name
                };
                this.filterFlags([obj])
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.loadData();
        }

        if (!_.isEqual(prevProps.documentsK10, this.props.documentsK10) || !_.isEqual(prevProps.documentsQ10, this.props.documentsQ10)) {
            if (this.props.location && this.props.location.hash && '#indicators10k10q' === this.props.location.hash) {
                document.querySelector('#indicators10k10q').scrollIntoView();
            }
        }
    }

    loadData() {
        this.props.searchDocumentsAction(this.props.companyId, 'k10');
        this.props.searchDocumentsAction(this.props.companyId, 'q10');
    }

    mergeDocuments() {
        const arr1 = this.props.documentsK10 ? this.props.documentsK10 : [];
        const arr2 = this.props.documentsQ10 ? this.props.documentsQ10 : [];
        const mergedArr = [...arr1, ...arr2];

        if (mergedArr.length === 0) {
            return 0;
        } else {
            return mergedArr.sort((a, b) => new Date(b.filingDate) - new Date(a.filingDate));
        }
    }

    filterFlags(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, flags: filter } })
    }

    filterDates(filter) {
        this.setState({ ...this.state, filter: { ...this.state.filter, date: filter } })
    }

    prepareRow(row, index, key) {
        if (row['Peer Group Comparison'] === undefined) {
            return row;
        }
        let animation;
        const p = row['Peer Group Comparison'];
        if (index === this.state.hoverIndex && key === this.state.hoverKey) {
            animation = this.state.currentCount % 2 === 0 ? 0 : 1;
        }

        let value;
        Object.keys(row).forEach((key) => {
            const lowerCaseKey = key.toLowerCase();
            if (value === undefined && lowerCaseKey.indexOf('q') === 0) {
                value = row[key];
            }
        });

        const obj = {
            ...row,
            'Peer Group Comparison': p ? <div
            >{p ? <RiskPredictor
                animation={animation}
                data={{
                    label: <span><strong>{value}</strong> {p.rank !== undefined ? <strong>({(p.rank * 100).toFixed(0)}%)</strong> : ''} among <strong>{row['Peer Group Comparison'].count}</strong> companies</span>,
                    count: row['Peer Group Comparison'].count,
                    animation: animation,
                    p10: p.data.p10,
                    p25: p.data.p25,
                    p50: p.data.p50,
                    p75: p.data.p75,
                    p90: p.data.p90,
                    min: p.data.min,
                    max: p.data.high,
                    value: value,

                    // label: `${this.props.company.name}: `
                }} /> : null}</div> : null
        }

        return obj;
    }
    prepareData(item, key) {
        if (!item) {
            return;
        }
        // if (!item[this.state.peerGroupFilter.peerGroup.value]){
        //     return;
        // }
        return {
            ...item,
            table: {
                columns: item.table.columns,
                data: _.map(item.table.data, (row, index) => this.prepareRow(row, index, key))
            }
        }
    }


    render() {

        let riskFlagFilterValue = null;

        if (this.state.filter && this.state.filter.flags) {
            riskFlagFilterValue = this.state.filter.flags.map((x) => {
                return x.value;
            });
        }

        return (
            <div className='panel panel-default'>
                <div className='panel-body risk with-spinner'>
                    <span id="indicators10k10q" />
                    <h2>Risk Indicators from 10K &amp; 10Q</h2>
                    <Spinner loaded={this.props.documentsK10 && this.props.documentsQ10}>
                        <form>
                            <label>
                                <Select label="Risk Flag"
                                    options={this.props.documentsk10Options.sort().map(x => {
                                        return { value: x, label: x }
                                    })}
                                    placeholder="Select risk flag..."
                                    multi
                                    value={riskFlagFilterValue}
                                    onChange={this.filterFlags}
                                />
                            </label>
                            <label>
                                <Select label="Date Range"
                                    options={[
                                        { value: moment().subtract(2, 'years').valueOf(), label: 'Past 2 Year' },
                                        { value: moment().subtract(1, 'years').valueOf(), label: 'Past Year' },
                                        { value: moment().subtract(1, 'months').valueOf(), label: 'Past Month' },
                                        { value: moment().subtract(1, 'weeks').valueOf(), label: 'Past Week' },
                                    ]}
                                    defaultLabel="Past 2 Year"
                                    placeholder="Select range..."
                                    onChange={this.filterDates}
                                />
                            </label>
                        </form>
                        {/* <RiskFiling data={this.props.documentsK10} companyId={this.props.companyId} filter={this.state.filter}/>
                            <RiskFiling data={this.props.documentsQ10} companyId={this.props.companyId} filter={this.state.filter}/> */}
                        {(this.props.documentsK10 && this.props.documentsQ10 && this.props.documentsK10.length === 0 && this.props.documentsQ10.length === 0) ? <DateNotFound /> : <RiskFiling data={this.mergeDocuments()} companyId={this.props.companyId} filter={this.state.filter} />}

                    </Spinner>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.company_id'),
        documentsK10: _.get(state, 'document.k10'),
        documentsQ10: _.get(state, 'document.q10'),
        documentsk10Options: _.get(state, 'document.k10_options') || [],
        documentsq10Options: _.get(state, 'document.q10_options') || [],
    };
}

export default connect(mapStateToProps, {
    searchDocumentsAction
})(Company10K10Q);
