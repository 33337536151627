import Actions from './types';

export function routeChangedAction(currentPage, prevPage) {
    return {
        type: Actions.ROUTE_CHANGED,
        currentPage: currentPage,
        previousPage: prevPage
    }
}

export function initializeAmplitudeAction() {
    return {
        type: Actions.INITIALIZE_AMPLITUDE,
    }
}