// import _ from 'lodash';
import types from './types';
import * as CONSTANTS from './constants';

const defaultState = {
    companyForm: null,
    companyFormPage: 0,
    companyCreated: false,
    duplicate: null,
    companySelected: null,
    formModalOpen: false,
    loaded: false,
    loading: false,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {

        case types.SAVE_COMPANY_FORM:
            return {
                ...state,
                companyForm: action.payload,
            };

        case types.CHANGE_FORM_PAGE:
            return {
                ...state,
                companyFormPage: action.payload,
            };

        case types.OPEN_COMPANY_FORM_MODAL:
            return {
                ...state,
                formModalOpen: true,
            };

        case types.CLOSE_COMPANY_FORM_MODAL:
            return defaultState;

        case types.CREATE_COMPANY:
            return {
                ...state,
                loading: true,
            };

        case types.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                companyFormPage: CONSTANTS.SUCCESS_PAGE,
                companyCreated: true,
            };

        // case types.ADD_DUPLICATE:
        //     return {
        //         ...state,
        //         companyFormPage: CONSTANTS.DUPLICATE_PAGE,
        //         duplicate: action.payload,
        //     };

        case types.GET_PRIVATE_SUCCESS:
            return {
                ...state,
                companySelected: action.payload,
                loaded: true
            };

        case types.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                questions: action.payload
            };

        default:
            return state;
    }
}
