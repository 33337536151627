import _ from 'lodash';
import actions from './types';

const defaultState = {
    data: null,
    loaded: false,
    error: null,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_FINANCIAL_DEBT_SUCCESS:
            return {
                ...state,
                data: _.get(action, 'payload.data'),
                loaded: true,
            };
        default:
            return state;
    }
}
