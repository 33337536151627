import reducer from "./reducers";

import  * as newsActions from "./actions";

export {
  newsActions
}
export { default as newsOperations } from "./operations";
export { default as newsTypes } from "./types";

export default reducer;