import moment from 'moment';
import _ from '../lodashWrapper';

export const convertToBigNumber = (value) => {
    if (typeof value === 'number') {
        if(value > 1000000000000) {
            value = Math.round(value /1000000000000) + 'T';
        } else if(value > 1000000000) {
            value = Math.round(value /1000000000) + 'B';
        } else if(value > 1000000) {
            value = Math.round(value /1000000) + 'M';
        } else if(value > 1000) {
            value = Math.round(value /1000) + 'K';
        }
        const str = "" + value;
        const index = str.indexOf('.');
        if (index >= 0) {
            return value.toFixed(2);
        }
    }
    return value;
}


export const getWorksheetDeleteDate = (worksheet) => {
    const diff = diffDaysFromToday(worksheet.submission.effective_date);
    if (diff < 0) {

        if (-83 - diff < 7) {
            return moment(worksheet.submission.effective_date).add(90, 'days');
        } else if (diff < -90) {
            return moment(worksheet.updated_at).add(7, 'days');
        }
    }

    return null;
}



export const diffDaysFromToday = (value) => {
    if (typeof value === 'string') { // value could be moment object
        value = moment(value);
    }
    const given = value;
    const current = moment();

    //Difference in number of days
    const diff  = moment.duration(given.diff(current)).asDays();
    return diff;
}


export const parseQuery = (queryString) => {
    let query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

export const apiFailMsg = (e) => {
    const message = _.get(e, 'response.data.message') || 'Server Error';
    const status =  _.get(e, 'response.status') || 500;
    return  {
        payload: message, status: status
    }
}

export const getValue = (value, fallbackValue) =>
  typeof value === "number" || typeof value === "string"
    ? value
    : fallbackValue !== undefined
    ? fallbackValue
    : "N/A";
