import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import _ from '../lodashWrapper';
import CompanySearch from "./company/search/companySearch";
import CompanyHeader from './public/header/companyHeader';
import CompanyOverview from "./public/overview/companyOverview";
import CompanyTrade from "./public/trade/companyTrade";
import CompanySearchHeader from "./public/header/companySearchHeader";

import CompanyPricing from "./public/pricing/companyPricing";
import CompanyNews from "./public/news/companyNews";
import CompanyFinancial from "./public/financial/companyFinancial";
import CompanyPdf from "./public/pdf/companyPdf";
import { connect } from 'react-redux';


// Private
import PrivateHeader from './private/header/privateHeader';
import PrivateOverview from './private/overview/privateOverview';
import PrivateFinancial from './private/financial/privateFinancial';
import Submission from './private/submission';
import IpoPy1 from './private/ipopy1/index';

import Document from './document/document';


class Company extends Component {
    render() {
        const showPrivate = process.env.SHOW_PRIVATE !== null;

        const className = this.props.isVisible === true ? 'note-open' : '';
        return (
            <div id="company-container" className={className}>
                <Switch>
                    <Route path='/company/:companyId/document' component={() => <div />} />
                    <Route path='/company/:companyId' component={CompanyHeader} />
                    {showPrivate && <Route path='/private' component={PrivateHeader} />}
                    <Route path='/company' component={CompanySearchHeader} />
                </Switch>

                <Switch>
                    {showPrivate && <Route path='/private/:companyId/overview' component={PrivateOverview} />}
                    {showPrivate && <Route path='/private/:companyId/financial' component={PrivateFinancial} />}

                    <Route path='/submission/:submissionId' component={Submission} />
                    <Route path='/company/:companyId/document/:documentId' component={Document} />
                    <Route path='/company/:companyId/overview' component={CompanyOverview} />
                    <Route path='/company/:companyId/trade' component={CompanyTrade} />
                    <Route path='/company/:companyId/financial' component={CompanyFinancial} />
                    <Route path='/company/:companyId/news' component={CompanyNews} />
                    <Route path='/company/:companyId/pdf' component={CompanyPdf} />
                    <Route path='/company/:companyId/risk' component={CompanyPricing} />
                    <Route path='/company' component={CompanySearch} />
                    <Route path='/ipopy1' component={IpoPy1} />
                </Switch>


                <Route path="/company/:companyId" exact render={({ match }) => (
                    <Redirect to={`/company/${match.params.companyId}/overview`} />
                )} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isVisible: _.get(state, 'note.isVisible')
    };
}


export default connect(mapStateToProps, {

})(Company);
