import _ from 'lodash';
import types from './types';

const defaultState = {
    data: null,
    error: null,
    loaded: false
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case types.GET_SUCCESS: 
            return {
                ...state,
                data: _.get(action, 'payload'),
                loaded: true,
            };
        case types.GET_EXPIRED_SUCCESS: 
            return {
                ...state, expiredData: _.get(action, 'payload')
            };
        case types.CLEAR_DATA: 
            return {
                ...state, expiredData: undefined, data: null
            };
        case types.CREATE:
        case types.UPDATE:
            return {
                ...state, processing: true
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state, processing: false
            };
        default:
            return state;
    }
}
