import { call, put, takeLatest } from 'redux-saga/effects';
import apigClientWrapper from '../../helpers/apigClientWrapper';
import { apiFailMsg } from '../../helpers/utility';
import { default as actions, default as types } from './types';



function* get(action) {
    try {
        const response = yield call(apigClientWrapper.getPrivateCompany, action.companyId);
        yield put({ type: types.GET_PRIVATE_SUCCESS, payload: response.data, companyId: action.companyId });
    } catch (e) {
        yield put({ type: types.GET_PRIVATE_FAILED, ...apiFailMsg(e) });
    }
}



export default [
    takeLatest(actions.GET_PRIVATE, get),

]
