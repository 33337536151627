import { all } from 'redux-saga/effects';

import { amplitudeOperations } from './amplitude';
import { attachmentOperations } from './attachment';
import { companyOperations } from './company';
import { documentOperations } from './document';
import { industryOperations } from './industry';
import { newsOperations } from './news';
import { programOperations } from './program';
import { worksheetOperations } from './worksheet';
import { fullstoryOperations } from './fullstory';
import { stockOperations } from './stock';
import { earningOperations } from './earning';
import { holderOperations } from './holder';
import { tradingOperations } from './trading';
import { ratioOperations } from './ratio';
import { financialStatementOperations } from './financialStatement';
import { financialDebtOperations } from './financialDebt';
import { financialsOperations } from './financials';
import { estimationOperations } from './estimation';
import { riskFlagsOperations } from './riskFlags';
import { keyStatsOperations } from './keyStats';
import { predictorOperations } from './predictor';
import { privateCompanyFormOperations } from './priv_company_form';
import { privateSubmissionOperations } from './priv_submission';
import { privateCompanyDataOperations } from './priv_company_data';
import { privateCompanyOperations } from './priv_company';
import { privateCompanyBalanceOperations } from './priv_balance';
import { privateCompanyCashOperations } from './priv_cash';
import { privateCompanyIncomeOperations } from './priv_income';
import { privateCompanyFiduciaryOperations } from './priv_fiduciary';
import { privateCompanyEmploymentOperations } from './priv_employment';
import { privateCompanyOwnershipOperations } from './priv_ownership';
import { ipopy1Operations } from './ipopy1';


export default function* rootSaga() {
    yield all([
      ...amplitudeOperations,
      ...attachmentOperations,
      ...companyOperations,
      ...documentOperations,
      ...financialsOperations,
      ...fullstoryOperations,
      ...holderOperations,
      ...industryOperations,
      ...newsOperations,
      ...keyStatsOperations,
      ...programOperations,
      ...worksheetOperations,
      ...estimationOperations,
      ...earningOperations,
      ...predictorOperations,
      ...privateSubmissionOperations,
      ...privateCompanyOperations,
      ...privateCompanyDataOperations,
      ...tradingOperations,
      ...ratioOperations,
      ...riskFlagsOperations,
      ...financialDebtOperations,
      ...financialStatementOperations,
      ...stockOperations,
      ...privateCompanyFormOperations,
      ...privateCompanyEmploymentOperations,
      ...privateCompanyFiduciaryOperations,
      ...privateCompanyOwnershipOperations,
      ...privateCompanyBalanceOperations,
      ...privateCompanyCashOperations,
      ...privateCompanyIncomeOperations,
      ...ipopy1Operations
    ])
  }
