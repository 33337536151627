import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicTable from '../../common/dynamicTable';
import Spinner from '../../common/spinner'
import {
    getCompanyTradingsAction
} from '../../../ducks/trading/actions';
import ErrorAlert from '../../common/errorAlert';

export class CompanyTradeInfo extends Component {

    constructor(props) {
        super(props)
        this.state = { filter: null }
    }

    componentDidMount() {
        if (this.props.company.company_id) {
            this.loadData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.company.company_id !== this.props.company.company_id) {
            this.loadData();
        }
    }

    loadData() {
        this.props.getCompanyTradingsAction(this.props.company.company_id);
    }

    render() {
        return (
            <div className='panel panel-default'>
                <div className='panel-body with-spinner'>
                    <h2>Trading Information</h2>
                    {this.props.companyTradingError ? <ErrorAlert message={this.props.companyTradingError} /> : <Spinner loaded={!_.isEmpty(this.props.companyTrading)}>
                        <div className='row'>
                            <div className='col-xs-4'>
                                <DynamicTable className="table last"
                                    data={this.props.companyTrading.stock_history} />
                            </div>
                            <div className='col-xs-4'>
                                <DynamicTable className="table last"
                                    data={this.props.companyTrading.share_stats} />
                            </div>
                            <div className='col-xs-4'>
                                <DynamicTable className="table last"
                                    data={this.props.companyTrading.dividends_splits} />
                            </div>
                        </div>
                    </Spinner>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        company: _.get(state, 'company.selected') || {},
        companyTrading: _.get(state, 'trading.data') || {},
        companyTradingError: _.get(state, 'error.COMPANY_GET_TRADING'),
    };
}

export default connect(mapStateToProps, {
    getCompanyTradingsAction
})(CompanyTradeInfo);
