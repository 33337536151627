import _ from 'lodash';
import actions from './types';

const defaultState = {
    chart: null,
    chartLoading: true,
    variations: null,
    variationsLoading: true,
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_STOCK_SUCCESS:
            return {
                ...state,
                chartLoading: false,
                chart: _.get(action, 'payload')
            };
        case actions.GET_STOCK_VARIATION_SUCCESS:
            return {
                ...state,
                variationsLoading: false,
                variations: _.get(action, 'payload')
            };
        default:
            return state;
    }
}
