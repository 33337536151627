import React, { Component } from 'react';
import { connect } from 'react-redux';
// import PrivateInfo from './privateInfo';
import PrivateEmployees from './privateEmployees';
import PrivateFiduciary from './privateFiduciary';
// import PrivateEEOC from './privateEEOC';
// import PrivateLitigation from './privateLitigation';
import NoteController from '../../note/noteController'
// const infoProps = require('./mocks/infoProps.json');
const employeesProps = require('./mocks/employeesProps.json');
const fiduciaryProps = require('./mocks/fiduciaryProps.json');
// const litigationProps = require('./mocks/litigationProps.json');
// const EEOCProps = require('./mocks/EEOCProps.json');

export class PrivateOverview extends Component {
    render() {

        return (
            <div className='container'>
                <div style={{ height: '50px' }}></div>
                {/* <PrivateInfo {...infoProps} /> */}
                <PrivateEmployees {...employeesProps} />
                <PrivateFiduciary {...fiduciaryProps}/>   
                {/* <PrivateLitigation {...litigationProps}/>
                <PrivateEEOC {...EEOCProps}/> */}
                <NoteController section="overview"/>
            </div>
        );
    }
}


export default connect(null, {

})(PrivateOverview);
