import React from 'react';

const NotificationAlert = (props) => {

    const fillColor = props.fill ? props.fill: "#fff"; 
    return <svg width="25px" height="25px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Artboard Copy</title>
    <defs>
        <path d="M50.0010417,91.6677083 C48.934375,91.6677083 47.8677083,91.259375 47.0552083,90.446875 L9.55520833,52.946875 C7.92604167,51.3177083 7.92604167,48.684375 9.55520833,47.0552083 L47.0552083,9.55520833 C48.684375,7.92604167 51.3177083,7.92604167 52.946875,9.55520833 L90.446875,47.0552083 C92.0760417,48.6802083 92.0760417,51.3177083 90.446875,52.946875 L52.946875,90.446875 C52.134375,91.2635417 51.0677083,91.6677083 50.0010417,91.6677083 Z M18.3927083,50.0010417 L50.0010417,18.396875 L81.609375,50.0010417 L50.0010417,81.609375 L18.3927083,50.0010417 Z M45.834375,33.334375 L45.834375,58.334375 L54.1677083,58.334375 L54.1677083,33.334375 L45.834375,33.334375 Z M50.0010417,71.8760417 C52.8775247,71.8760417 55.209375,69.5441914 55.209375,66.6677083 C55.209375,63.7912253 52.8775247,61.459375 50.0010417,61.459375 C47.1245586,61.459375 44.7927083,63.7912253 44.7927083,66.6677083 C44.7927083,69.5441914 47.1245586,71.8760417 50.0010417,71.8760417 Z" id="abc-path1"></path>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
            <rect x="0" y="0" width="100" height="100"></rect>
            <mask id="abcmask-2" fill="white">
                <use xlinkHref="#abc-path1"></use>
            </mask>
            <g id="Mask"></g>
            <g id="Mixin/Fill/Black" mask="url(#abcmask-2)" fill={fillColor} fillRule="evenodd">
                <rect x="0" y="0" width="100" height="100"></rect>
            </g>
        </g>
    </g>
</svg>;
};
export default NotificationAlert;
