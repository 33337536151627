import _ from 'lodash';
import actions from './types';

const defaultState = {
    holder: null,
    holderList: null,
    holdersData: {}
};

export default function (state = defaultState, action = null) {
    switch (action.type) {
        case actions.GET_HOLDER_SUCCESS:
            return {
                ...state,
                holder: _.get(action, 'payload.data')
            };
        case actions.GET_HOLDER_LIST:
            return {
                ...state,
                holderListLoaded: false,
                holderList: _.get(action, 'payload.data')
            };
        case actions.GET_HOLDER_LIST_SUCCESS:
            return {
                ...state,
                holdersData: {
                    ...state.holdersData,
                    [_.get(action, 'requestType')]: _.get(action, 'payload')
                }
            };            
        default:
            return state;
    }
}
