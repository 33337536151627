import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import DynamicTable from '../../common/dynamicTable';
import RiskPredictor from '../../common/riskPredictor';
import Spinner from '../../common/spinner'
import { getCompanyPredictorsAction } from '../../../ducks/predictor/actions';
import { Line } from 'rc-progress';
import DataNotFound from "../../common/dataNotFound";
import Select from '../../common/select';
import constants from '../../../constants';
import { convertToBigNumber } from "../../../helpers/utility";
import ErrorAlert from '../../common/errorAlert';

export class CompanyTopRisk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industryFilter: {
                industry: { value: constants.industrySize[constants.industrySizeDefaultIndex].value, label: constants.industrySize[constants.industrySizeDefaultIndex].label }
            },
            hoverIndex: null,
        };
    }

    componentDidMount() {
        if (this.props.companyId) {
            this.props.getCompanyPredictorsAction(this.props.companyId);
        }
    }

    startInterval(index) {
        if (!this.state.intervalId) {
            this.setState({ currentCount: 0 });
            const intervalId = setInterval(() => {
                const count = this.state.currentCount + 1;
                this.setState({ currentCount: count });
            }, 1500);
            this.setState({ intervalId: intervalId, hoverIndex: index });
        }
    }

    clearInterval() {
        clearInterval(this.state.intervalId);
        this.setState({ hoverIndex: null, intervalId: null });
    }

    componentWillUnmount() {
        this.clearInterval();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId) {
            this.props.getCompanyPredictorsAction(this.props.companyId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.data) {
            try {
                this.setState({ data: this.prepareData(nextProps.data) })
            } catch (error) {
                console.error(error);
            }
        }
    }

    prepareData(data) {
        if (!data) {
            return;
        }
        const highColor = '#DA4F4C';
        const mediumColor = '#4A4A4A';
        const lowColor = '#388C04';
        return data.map((item, index) => {
            let color = highColor;
            const selectedItem = item[this.state.industryFilter.industry.value];
            let percent = selectedItem.impact_delta;
            let text = 'High';
            if (selectedItem.impact_delta !== null) {
                if (percent < .8) {
                    color = lowColor;
                    text = 'Low';
                } else if (percent < 1.2) {
                    color = mediumColor;
                    text = 'Medium';
                }

                // Scale for impact on sweet spot should be 0.3 to 3.0 
                if (percent > 3) {
                    percent = 3;
                } else if (percent < .3) {
                    percent = 0.3;
                }
                percent -= .3
                percent = percent / 2.7 * 100;
            }

            const riskStyle = {
                color: color
            };
            let animation;
            if (index === this.state.hoverIndex) {
                animation = this.state.currentCount % 2 === 0 ? 0 : 1;
            }

            let displayedPRank;
            if (selectedItem.chart) {
                displayedPRank = selectedItem.chart.p_rank;
                if (selectedItem.chart.p10 === selectedItem.chart.actual) {
                    displayedPRank = .10;
                } else if (selectedItem.chart.p25 === selectedItem.chart.actual) {
                    displayedPRank = .25;
                } else if (selectedItem.chart.p50 === selectedItem.chart.actual) {
                    displayedPRank = .50;
                } else if (selectedItem.chart.p75 === selectedItem.chart.actual) {
                    displayedPRank = .75;
                } else if (selectedItem.chart.p90 === selectedItem.chart.actual) {
                    displayedPRank = .90;
                }
            }

            return {
                ...item,
                impact_delta: selectedItem.impact_delta !== null ? <div><div style={riskStyle} className="text-left">{text}</div><div><Line strokeWidth="3" trailWidth="3" strokeLinecap="square" percent={percent} strokeColor={color} /></div></div> : null,
                chart: selectedItem.chart !== null ?
                    (typeof selectedItem.chart !== 'string' ? <div className="23"
                        onMouseOver={() => {
                            this.startInterval(index);
                        }}
                        onMouseOut={() => {
                            this.clearInterval();
                        }}><RiskPredictor
                            indicatePositiveValues
                            animation={animation}
                            data={{
                                label: `${convertToBigNumber(selectedItem.chart.actual)} ${displayedPRank && `(${displayedPRank && (displayedPRank * 100).toFixed(0)}%)`} among ${selectedItem.chart.count} companies`,
                                count: selectedItem.chart.count,
                                p10: selectedItem.chart.p10,
                                p25: selectedItem.chart.p25,
                                p50: selectedItem.chart.p50,
                                p75: selectedItem.chart.p75,
                                p90: selectedItem.chart.p90,
                                p_rank: selectedItem.chart.p_rank,
                                min: selectedItem.chart.min,
                                max: selectedItem.chart.max,
                                value: selectedItem.chart.actual,
                                peerGroups: selectedItem.chart.peerGroups,
                            }} /></div> : <div className="text-left">{selectedItem.chart}</div>)
                    : <div style={{ 'minHeight': '100px' }}></div>,
                // impact_deltaClassName: 0 > item.impact_delta ? 'minus' : 'plus'
            }
        }).sort((x, y) => {
            const a = x[this.state.industryFilter.industry.value];
            const b = y[this.state.industryFilter.industry.value];
            const percent1 = a.impact_delta;
            const percent2 = b.impact_delta;

            if (percent1 < 1.2) { // medium
                if (percent2 < .8) {
                    return percent1 - percent2
                } else if (percent2 < 1.2) { // medium
                    if (percent1 < .8) {
                        return percent1 - percent2;
                    } else if (percent2 < 1.2) { // medium
                        return percent2 - percent1
                    }
                }
            }
            return percent2 - percent1
        });
    }



    render() {
        const { error, errorStatus, loaded, data } = this.props;
        const notFoundMsg = 'Insufficient Information in Upstream Data to Provide Analytics';
        let content;

        if (error) {
            content = <ErrorAlert message={errorStatus === 404 ? notFoundMsg : error} />
        } else {
            content = <Spinner loaded={loaded}>
                <div>
                    <Select label="Peer Group Filter"
                        placeholder="Select industry filter..."
                        value={this.state.industryFilter.industry}
                        clearable={false}
                        options={constants.industrySize}
                        onChange={(value) => {
                            this.setState({
                                industryFilter: {
                                    ...this.state.industryFilter,
                                    industry: value
                                }
                            });
                        }}
                    />
                    <p></p>
                </div>

                {!data ? <DataNotFound /> : <DynamicTable className="table last fixedHeader" data={{
                    table: {
                        columns: [
                            { field: 'metric_name', title: 'Metric', subField: 'subtitle', className: 'table-risk-predictor' },
                            { field: 'impact_delta', title: 'Impact on Sweet Spot' },
                            {
                                field: 'chart',
                                title: 'Comparison to Industry'
                            },
                            // {field: 'predictive_power', title: 'Predictive Power'},

                        ],
                        data: this.prepareData(data)
                    }
                }} />}

            </Spinner>
        }
        return (
            <div>
                <div className='panel panel-default'>
                    <div className='panel-body'>
                        <p>
                            The predictive model output is currently only available for public companies incorporated in the U.S. that have not had an IPO within one year of today. Over The Counter stocks are also included. The model output shown here was built on Securities Class Actions and does not include other potential claims like Merger Objections, Capital Regulatory Actions, etc.
                    </p>
                        <div>
                            If you have any issues/concerns with the numbers shown on this tab email <a style={{ color: '#4680ff' }} href="mailto:ArgoPro-Actuarial@argogroupus.com">ArgoPro-Actuarial@argogroupus.com</a>
                        </div>
                    </div>
                </div>
                <div className='panel panel-default'>



                    <div id="top-risk" className='panel-body top-risk with-spinner'>
                        <h2>Top Risk Predictors</h2>
                        {content}

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        companyId: _.get(state, 'company.selected.snp_company_id'),
        company: _.get(state, 'company.selected'),
        loaded: _.get(state, 'predictor.loaded'),
        data: _.get(state, 'predictor.data'),
        error: _.get(state, 'error.COMPANY_GET_PREDICTOR'),
        errorStatus: _.get(state, 'error.COMPANY_GET_PREDICTOR_STATUS'),
    };
}

export default connect(mapStateToProps, {
    getCompanyPredictorsAction
})(CompanyTopRisk);
