import _ from '../../../lodashWrapper';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CompanyPdfFooter from './companyPdfFooter'
import Button from '../../common/button';
import Loading from '../../common/loading';
import Link from "../../common/link";
import { worksheetGeneratePDFAction, worksheetClearPDFAction, worksheetUpdatePDFAction, worksheetArchiveAction } from '../../../ducks/worksheet/actions';

class CompanyPdf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isConfirmModalVisible: false,
            isDoneModalVisible: false,
            isSaveToSystemModalVisible: false,
            pdfDone: false,

        }
        this.pdfGenerating = false;
        this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
        this.handleCloseDoneModal = this.handleCloseDoneModal.bind(this);
        this.handleCloseSaveToSystemModal = this.handleCloseSaveToSystemModal.bind(this);
        this.closeRedirectToSearch = this.closeRedirectToSearch.bind(this);
    }

    openConfirmModal() {
        this.setState({ isConfirmModalVisible: true, review: false })
    }

    openReviewConfirmModal() {
        this.setState({ isConfirmModalVisible: true, review: true })
    }


    openDoneModal() {
        this.setState({ isDoneModalVisible: true, isConfirmModalVisible: false })
    }

    openSaveToSystemModal() {
        this.props.worksheetUpdatePDFAction(this.props.worksheetId);
        this.setState({ isSaveToSystemModalVisible: true });
    }

    loadPdf(nextProps) {
        if (nextProps.worksheetId && nextProps.companyName && nextProps.companyId && this.pdfGenerating === false && this.state.pdfDone === false) {
            this.pdfGenerating = true;
            this.setState({ pdfDone: true });
            this.props.worksheetGeneratePDFAction(nextProps.companyId, nextProps.companyName, nextProps.worksheetId);
        }
        if (nextProps.worksheetArchiving === true) {
            this.openDoneModal();
        }
    }
    componentDidMount() {
        this.loadPdf(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.loadPdf(nextProps);
    }

    componentWillUnmount() {
        this.props.worksheetClearPDFAction();
    }

    closePdfView() {
        const { history, location } = this.props;
        const companyId = _.get(this, 'props.match.params.companyId');
        history.push(`/company/${companyId}/overview${location.search}`);
    }

    closeRedirectToSearch() {
        const { history } = this.props;
        history.push(`/company`);
    }

    handleCloseConfirmModal() {
        this.setState({ isConfirmModalVisible: false });
    }

    handleCloseDoneModal() {
        this.setState({ isDoneModalVisible: false, isDoneFailedModalVisible: false });
    }

    handleCloseSaveToSystemModal() {
        this.setState({ isSaveToSystemModalVisible: false });
    }


    render() {
        if (!this.props.worksheet) {
            return null;
        }

        return (
            <div className="company-pdf">

                {this.props.worksheetPDFURL ? <iframe id="pdf-preview" src={this.props.worksheetPDFURL} /> :
                    (this.props.worksheetPDFStatusError === true ?
                        <div id="pdf-loading"><div className="loading-container">Error! please refresh the page and try again.</div></div>
                        :
                        <div id="pdf-loading"><div className="loading-container"><Loading /></div></div>)
                }

                <CompanyPdfFooter closePdfView={() => this.closePdfView()}
                    openConfirmModal={() => this.openConfirmModal()}
                    openReviewConfirmModal={() => this.openReviewConfirmModal()}
                    openSaveToSystemModal={() => this.openSaveToSystemModal()}
                />
                <Modal className="company-pdf modal-tiny"
                    overlayClassName="modal-overlay"
                    isOpen={this.state.isConfirmModalVisible}
                    onRequestClose={this.handleCloseConfirmModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="icon">
                        <img src="/images/warning.png" />
                    </div>
                    <div className="text">
                        After confirming the submission, this worksheet will be archived to Image Right and you won&#39;t be able to do any
                        editing. Are you sure you want to proceed.
                    </div>
                    <div className="pull-left">
                        <Link onClick={() => this.handleCloseConfirmModal()}>Close</Link>
                    </div>
                    <div className="pull-right">
                        <Button onClick={() => {
                            this.props.worksheetArchiveAction(this.state.review);
                        }}>Confirm</Button>
                    </div>
                </Modal>
                <Modal className="company-pdf modal-tiny"
                    overlayClassName="modal-overlay"
                    isOpen={this.state.isSaveToSystemModalVisible}
                    onRequestClose={this.handleCloseSaveToSystemModal}
                    shouldCloseOnOverlayClick={true}
                >
                    <div className="icon">
                        <img src="/images/thumbsup.png" width="100%" />
                    </div>

                    {this.props.worksheetUpdatingPDF !== false && <div className="thumbsup-wrapper"><Loading /></div>}
                    {this.props.worksheetUpdatingPDF === false && <div>
                        <div className="text">
                            The PDF has been saved. You can still edit the submission or get access to the PDF from My Worksheet
                        </div>
                        <div className="pull-left">
                            <Link onClick={() => this.closePdfView()}>Back to Worksheet</Link>
                        </div>
                        <div className="pull-right">
                            <Button onClick={() => {
                                this.closePdfView();
                            }}>Confirm</Button>
                        </div>
                    </div>}

                </Modal>
                <Modal className="company-pdf modal-tiny"
                    overlayClassName="modal-overlay"
                    isOpen={this.state.isDoneModalVisible}
                    onRequestClose={this.handleCloseDoneModal}
                    shouldCloseOnOverlayClick={true}
                >
                    {this.props.worksheetArchiving === true ? <div id="archive-loading" className="text-center"><Loading /></div> :
                        (this.props.archivingWorksheetSuccess ? <div>
                            <div className="icon">
                                <img src="/images/done.png" />
                            </div>
                            <div className="text">
                                All done! This worksheet has been archived.
                    </div>
                            <div className="done">
                                <Button onClick={() => {
                                    this.handleCloseDoneModal();
                                    this.closeRedirectToSearch();
                                }
                                }>Done</Button>
                            </div>
                        </div> : <div>
                                <div className="icon">
                                    <img src="/images/error.png" width="100%" />
                                </div>
                                <div className="text">
                                    There was an error archiving your worksheet. Make sure the Submission Id is spelled correctly (including dashes) and the submission folder exists in ImageRight.
                            <br />
                                    <br />
                                    Please try again or contact us.
                            <a mailto="appa-support@argogroupus.com">appa-support@argogroupus.com</a>
                                </div>
                                <div className="done">
                                    <Button onClick={() => {
                                        this.handleCloseDoneModal();
                                        this.closeRedirectToSearch();
                                    }
                                    }>Dismiss</Button>
                                </div>
                            </div>)

                    }

                </Modal>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        events: _.get(state, 'company.stockVariation'),
        debtData: _.get(state, 'company.financialDebt'),
        stockData: _.get(state, 'company.stock'),
        companyId: _.get(state, 'company.selected.company_id'),
        companyName: _.get(state, 'company.selected.name'),
        worksheet: _.get(state, 'worksheet.selected'),
        worksheetId: _.get(state, 'worksheet.selected.worksheet_id'),
        worksheetPDFStatusError: _.get(state, 'worksheet.pdfStatusError'),
        worksheetGeneratingPDF: _.get(state, 'worksheet.generatingPDF'),
        worksheetArchiving: _.get(state, 'worksheet.archivingWorksheet'),
        worksheetUpdatingPDF: _.get(state, 'worksheet.updatingPDF'),
        worksheetPDFURL: _.get(state, 'worksheet.pdfURL'),
        note: _.get(state, 'user.note'),
        documentsComplaint: _.get(state, 'industry.searchResult.documents'),
        archivingWorksheetSuccess: _.get(state, 'worksheet.archivingWorksheetSuccess'),
    };
}

export default withRouter(connect(mapStateToProps, {
    worksheetGeneratePDFAction,
    worksheetArchiveAction,
    worksheetClearPDFAction,
    worksheetUpdatePDFAction
})(CompanyPdf));
