import reducer from "./reducers";

import * as privateCompanyActions from "./actions";

export {
  privateCompanyActions
}
export { default as privateCompanyOperations } from "./operations";
export { default as privateCompanyTypes } from "./types";

export default reducer;