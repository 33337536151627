import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { default as actions, default as types } from './types';
import { apiFailMsg } from '../../helpers/utility';
import apigClientWrapper from '../../helpers/apigClientWrapper';

function getMCQuestions(questionSet) {
    return axios.request({
        method: 'get',
        url: `https://argopro-intake.wrapstack.argo-digital.com/api/triage/appi/questionSets/${questionSet}`,
        headers: {
            APPIKEY: process.env.WRAPPER_API_KEY
        }
    });
}

function* submit(action) {
    try {
        console.log(action.payload);
        const response = yield call(apigClientWrapper.submitPrivateCompany, action.companyId, { submission: action.payload, year: action.year, month: action.month });
        console.log(response);
        yield put({ type: types.SUBMIT_SUCCESS, payload: null });
    } catch (e) {
        yield put({ type: types.SUBMIT_FAILED, ...apiFailMsg(e) });
    }
}


function* getQuestions() {
    let questionSets = [];
    try {
        const res = yield all([
            yield call(getMCQuestions, 'PROE'),
            yield call(getMCQuestions, 'PROF'),
            yield call(getMCQuestions, 'PROB'),
            yield call(getMCQuestions, 'PROC'),
            yield call(getMCQuestions, 'PROI'),
            yield call(getMCQuestions, 'PROO'),
            // yield call(getMCQuestions, 'PROL'),
        ])
        for (let d of res) {
            questionSets.push(d.data);
        }
        yield put({ type: types.GET_QUESTIONS_SUCCESS, payload: questionSets });
    } catch (e) {
        yield put({ type: types.GET_QUESTIONS_FAILED, ...apiFailMsg(e) });
        // yield put({ type: types.GET_QUESTIONS_SUCCESS, payload: questionSets });
    }
}

function* getSubmissionData(action) {

    try {
        const queryParams = {
            year: action.year
        };
        const response = yield call(apigClientWrapper.getPrivateSubmissionData, action.companyId, { queryParams });
        yield put({ type: types.GET_SUBMISSION_DATA_SUCCESS, payload: response.data });
    } catch (e) {
        yield put({ type: types.GET_SUBMISSION_DATA_FAILED, ...apiFailMsg(e) });
    }
}

export default [
    takeLatest(actions.GET_QUESTIONS, getQuestions),
    takeLatest(actions.GET_SUBMISSION_DATA, getSubmissionData),
    takeLatest(actions.SUBMIT, submit),
]
